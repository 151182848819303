import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from 'shared/components';

import { ExpandedBreadcrumbs } from './ExpandedBreadcrumbs';

interface IProps {
  breadcrumbs: BreadcrumbData[];
}

const MobileBreadcrumbs = ({ breadcrumbs }: IProps) => {
  const [isExpandedBreadcrumbBar, setIsExpandedBreadcrumbBar] = useState(false);

  if (!isExpandedBreadcrumbBar && breadcrumbs.length > 3) {
    return (
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink
            as={Link}
            to={breadcrumbs[0].match.pathname}
          >
            {breadcrumbs[0].breadcrumb}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem
          onClick={() => setIsExpandedBreadcrumbBar(true)}
          cursor="pointer"
        >
          <Text
            size="md"
            fontWeight="bold"
          >
            ...
          </Text>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <Box fontWeight="bold">
            {breadcrumbs[breadcrumbs.length - 1].breadcrumb}
          </Box>
        </BreadcrumbItem>
      </Breadcrumb>
    );
  }

  return <ExpandedBreadcrumbs breadcrumbs={breadcrumbs} />;
};

export { MobileBreadcrumbs };
