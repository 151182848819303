import { ISelectFieldProps, SelectField } from './SelectField';
import { useTranslation } from 'react-i18next';

interface IProps extends ISelectFieldProps {
  isDisabled?: boolean;
}

const YesNoSelectField = ({ isDisabled, ...props }: IProps) => {
  const { t } = useTranslation('commonFields');
  const options = [
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ];

  return (
    <SelectField
      options={options}
      defaultValue={options[0]}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

export { YesNoSelectField };
