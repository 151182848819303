import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from 'shared/store';
import { useGetTracking } from 'entities/tracking/api';
import { ErrorBoundary, Text } from 'shared/components';
import { EditTracking } from 'widgets/Tracking';

const EditTrackingPage = () => {
  const { t } = useTranslation(['common']);

  const companyId = useStore((state) => state.company?._id);
  const { trackingId } = useParams();

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  if (!trackingId) {
    throw new Error('TrackingId not found');
  }

  const { isLoading, error, data } = useGetTracking(companyId, trackingId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Tracking not found', { ns: 'tracking' })}</Text>;
  }

  return (
    <EditTracking
      tracking={data.data}
      companyId={companyId}
    />
  );
};

export const EditTrackingPageThrowable = () => {
  return (
    <ErrorBoundary>
      <EditTrackingPage />
    </ErrorBoundary>
  );
};
