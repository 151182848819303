import { CompanyFormType } from 'entities/company/model/CompanyFormType';

import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { AxiosResponse } from 'shared/utils/httpClient';

export const useSaveCompany = () => {
  return useMutation((data: CompanyFormType) => {
    return saveCompany(data);
  });
};

const saveCompany = (formData: CompanyFormType) => {
  return httpClient.post<CompanyFormType, AxiosResponse<{ _id: string }>>(
    'companies',
    formData,
  );
};
