import useStore from 'shared/store';
import { AddPersonnel } from '../../../../widgets/Personnel';

const AddPersonnelPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  return <AddPersonnel companyId={companyId} />;
};

export { AddPersonnelPage };
