import shallow from 'zustand/shallow';
import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import { useBreakpointValue } from 'shared/components';
import {
  Table,
  TableRow,
  TableBody,
  createTableStore,
  TableBodyCell,
  ResponsiveHeadTableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import { IQueryParams, ISort } from 'shared/types';

import { usePersonnelTableColumnsConfiguration } from './utils';

import { useGetUsers } from 'entities/user/api';

import { PersonnelTableRow } from './PersonnelTableRow';
import { EmptyStateTableRow } from 'features/EmptyStateTableRow';

interface IProps {
  setOffset: (value: number) => void;
  onSortChange: (sort: ISort[]) => void;
  queryParams: IQueryParams;
  companyId: string;
  attendeeIDs?: string[];
  showInactive?: boolean;
  isImported?: boolean;
  setQueryFields: Dispatch<SetStateAction<string[]>>;
}

const usePersonnelTableState = createTableStore();

export const PersonnelTable = ({
  setQueryFields,
  setOffset,
  onSortChange,
  attendeeIDs,
  queryParams: { limit = 10, offset = 0, ...restQueryParams },
  companyId,
  isImported,
  showInactive = true,
}: IProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const columnsConfiguration = usePersonnelTableColumnsConfiguration();
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);
  const tableState = usePersonnelTableState((state) => state, shallow);

  const filterActive = !showInactive ? 'true' : '';

  const fieldsArray = memoizedColumnsConfiguration
    .map((columnConfiguration) => columnConfiguration.sortedFields)
    .flat();
  fieldsArray.pop();
  const { data, isLoading, refetch } = useGetUsers(
    companyId,
    {
      ...restQueryParams,
      offset: offset,
      limit: limit,
      filters:
        restQueryParams.filters !== '' ? restQueryParams.filters : filterActive,
    },
    { useErrorBoundary: false },
  );

  useEffect(() => {
    !restQueryParams.filters
      ? setQueryFields(
          memoizedColumnsConfiguration
            .map((columnConfiguration) => columnConfiguration.sortedFields)
            .flat(),
        )
      : setQueryFields(fieldsArray);
  }, [restQueryParams.filters]);

  const attendees = data?.data.data.filter((attendee) => {
    if (attendeeIDs) {
      return attendeeIDs?.includes(attendee._id);
    } else {
      return attendee;
    }
  });

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [companyId, isImported]);

  useEffect(() => {
    if (
      (data && data?.data.total <= 10) ||
      (data && offset / limit + 1 > Math.ceil(data?.data.total / limit))
    ) {
      setOffset(0);
    }
  }, [data?.data.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <ResponsiveHeadTableRow
        isMobile={isMobile}
        columnsConfiguration={columnsConfiguration}
      />
      <TableBody isLoading={isLoading}>
        {data && data.data.total > 0 ? (
          <>
            {attendees &&
              attendees?.map((user) => {
                return (
                  <PersonnelTableRow
                    key={user._id}
                    user={user}
                    isMobile={isMobile}
                  />
                );
              })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={data.data.total}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                  itemsPerPage={limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};
