import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const StreetNameField = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Street Name')}:`}>
      <TextField name="address.street" />
    </FormField>
  );
};

export { StreetNameField };
