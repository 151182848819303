import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStore from 'shared/store';
import { useGetTopics } from 'entities/tracking/api';
import { useTopicsTrackingColumnsConfiguration } from './utils';
import { SingleTableWrapper } from 'shared/components';
import { TopicsListFieldTable } from './TopicsListFieldTable';
import { CheckboxListFilter } from 'features/CheckboxListFilter';
import { ISort } from 'shared/types';

interface ITopicsListFieldProps {
  selectedActivityTopicsIds: string[];
}

const limit = 10;

const TopicsListField = ({
  selectedActivityTopicsIds,
}: ITopicsListFieldProps) => {
  const { t } = useTranslation('commonFields');
  const companyId = useStore((state) => state.company?._id);
  const columnsConfiguration = useTopicsTrackingColumnsConfiguration();
  const [searchValue, setSearchValue] = useState('');
  const [tableSort, setTableSort] = useState<ISort[]>([]);
  const [offset, setOffset] = useState(0);

  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const { data, isLoading } = useGetTopics(
    companyId,
    {
      fields: columnsConfiguration
        .map((columnConfiguration) => columnConfiguration.sortedFields)
        .flat(),
      filters: searchValue,
      sort: tableSort,
    },
    { useErrorBoundary: false },
  );

  const filteredTopics = data?.data.data.filter((topic) =>
    selectedActivityTopicsIds.includes(topic._id),
  );

  const filteredData = {
    data: filteredTopics || [],
    total: filteredTopics?.length || 0,
  };

  return (
    <SingleTableWrapper
      title={t('Topics')}
      childContainerStyle={{
        overflow: 'auto',
      }}
    >
      <CheckboxListFilter
        itemsFormName="topics"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        filteredResults={filteredTopics || []}
      />
      <TopicsListFieldTable
        topicsList={filteredData}
        isLoading={isLoading}
        offset={offset}
        limit={limit}
        onSortChange={(sort) => setTableSort(sort)}
        setOffset={(value) => setOffset(value)}
      />
    </SingleTableWrapper>
  );
};

export { TopicsListField };
