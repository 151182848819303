import { IDepartment } from 'entities/company/model';

import { Flex } from 'shared/components';

import { ActiveViewRow, NameViewRow } from 'features/ViewCommonFields';

interface IProps {
  department: IDepartment;
}

const DepartmentView = ({ department }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <NameViewRow name={department.name} />
      <ActiveViewRow active={department.active} />
    </Flex>
  );
};

export { DepartmentView };
