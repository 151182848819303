import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const NicknameField = () => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField label={`${t('Nickname')}:`}>
      <TextField name="name.nickname" />
    </FormField>
  );
};

export { NicknameField };
