import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'shared/components/Form';
import { useForgotPasswordFormValidationSchema } from './useForgotPasswordFormValidationSchema';
import { IForgotPasswordFormModel } from 'entities/user/model';
import { useGetForgotPassword } from 'entities/user/api';
import { Form, Flex } from 'shared/components';
import { SubmitForgotPasswordFormButton } from './SubmitForgotPasswordFormButton';
import { ForgotPasswordFormField } from './ForgotPasswordFormField';

interface IProps {
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
}

const ForgotPasswordForm = ({ onSuccess, onError }: IProps) => {
  const { t } = useTranslation('forgotPasswordForm');
  const validationSchema = useForgotPasswordFormValidationSchema();

  const { mutate, error, data } = useGetForgotPassword();

  const formId = 'forgot-password-form';

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues: { company: '', login: '' },
    formId,
  });

  const handleSubmit = async (model: IForgotPasswordFormModel) => {
    try {
      await mutate(model);
    } catch (e) {
      onError && onError(e);
    }
  };

  useEffect(() => {
    if (data) {
      cleanPersistentStore();
      onSuccess && onSuccess();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      onError && onError(error);
    }
  }, [error]);

  useEffect(() => {
    cleanPersistentStore();
  }, []);

  return (
    <Form<IForgotPasswordFormModel>
      id={formId}
      onSubmit={handleSubmit}
      methods={methods}
    >
      <Flex
        flexDir="column"
        gap="25px"
      >
        <ForgotPasswordFormField
          inputProps={{
            name: 'company',
            placeholder: t('Enter company ID here'),
          }}
          label={t('Company ID')}
        />
        <ForgotPasswordFormField
          inputProps={{
            name: 'login',
            placeholder: t('Enter login ID here'),
          }}
          label={t('Login ID')}
        />
        <SubmitForgotPasswordFormButton />
      </Flex>
    </Form>
  );
};

export { ForgotPasswordForm };
