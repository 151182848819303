import { IAddress } from 'entities/common/model/IAddress';

import { TableCellText, TableCellProps } from 'shared/components';
import { TableBodyCell } from 'shared/components/Table';

interface IProps extends TableCellProps {
  address?: IAddress;
}

const AddressTableCell = ({ address, ...restProps }: IProps) => {
  return (
    <TableBodyCell
      maxW="250px"
      {...restProps}
    >
      <TableCellText>
        {`${address?.streetNumber || ''} ${address?.street || ''}`}
      </TableCellText>
      <TableCellText>{address?.state || ''}</TableCellText>
      <TableCellText>
        {`${address?.city || ''} ${address?.zip || ''}`}
      </TableCellText>
    </TableBodyCell>
  );
};

export { AddressTableCell };
