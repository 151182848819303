import { Navigation } from 'features/Navigation';
import { BrandBar } from 'features/BrandBar';
import { BreadcrumbsBar } from 'widgets/BreadcrumbsBar';
import { MobileNavigationBar } from 'widgets/MobileNavigationBar';
import { useBreakpointValue } from 'shared/hooks/chakraUi';
import useStore from 'shared/store';

const Header = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const privileges = useStore((state) => state.privileges);
  const companyComponents = useStore((state) => state.company?.components || []);
  if (isMobile) {
    return (
      <header>
        <MobileNavigationBar
          isLoggedIn={isLoggedIn}
          activePrivileges={privileges}
          components={companyComponents}
        />
        <BreadcrumbsBar />
      </header>
    );
  }

  return (
    <header>
      <Navigation
        isLoggedIn={isLoggedIn}
        activePrivileges={privileges}
        components={companyComponents}
      />
      <BrandBar />
      <BreadcrumbsBar />
    </header>
  );
};

export { Header };
