import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const NearestCrossStreetField = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Nearest Cross Street')}:`}>
      <TextField name="address.nearestCrossStreet" />
    </FormField>
  );
};

export { NearestCrossStreetField };
