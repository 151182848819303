import { useTranslation } from 'react-i18next';

import { SingleTableWrapper, Text } from 'shared/components';
import { useState } from 'react';
import { AttachmentsTable } from 'features/AttachmentsTable';
import useStore from 'shared/store';

interface IProps {
  attachments?: { _id: string; filename: string }[];
  entityName: 'activities' | 'trackings' | 'customers' | 'vendors';
}

// TODO fix limit when filtering by array will be added
const limit = 1000;

const FormsToCompleteField = ({ attachments, entityName }: IProps) => {
  const { t } = useTranslation('commonFields');

  const companyId = useStore((state) => state.company?._id);

  const [offset, setOffset] = useState(0);

  return (
    <SingleTableWrapper title={t('Forms to Complete')}>
      {companyId && attachments ? (
        <AttachmentsTable
          attachmentList={{
            data: attachments.map((attachment) => ({
              _id: attachment._id,
              name: attachment.filename,
              companyId,
            })),
            total: attachments.length,
          }}
          companyId={companyId}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          isLoading={false}
          entityName={entityName}
          viewMode
        />
      ) : (
        <Text>-</Text>
      )}
    </SingleTableWrapper>
  );
};

export { FormsToCompleteField };
