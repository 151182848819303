import {
  LocationsField,
  DepartmentsField,
  ActivitiesField,
  TrackedByField,
  useContainerProps,
  TitleField,
  TrackingDateFromField,
  TrackingDateToField,
  CustomersField,
  AttendeeField,
  SafetyViolationField,
  KeywordsField,
  LanguageField,
  VendorsField,
} from 'features/FormCommons';

import { Form } from 'shared/components';

import { useAdvancedSearchValidationSchema } from './utils';
import { ActionButtons } from './ActionButtons';
import { useForm } from 'shared/components/Form';
import { IncidentLocationField } from 'features/FormCommons/components/IncidentLocationField';
import useStore from 'shared/store';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

interface IProps {
  companyId: string;
}

const ReportForm = ({ companyId }: IProps) => {
  const containerStyleProps = useContainerProps();
  const validationSchema = useAdvancedSearchValidationSchema();
  const companyComponents = useStore((state) => state.company?.components || []);
  const hasCustomers = companyComponents.includes(ICompanyComponent.Customers);
  const hasVendors = companyComponents.includes(ICompanyComponent.Vendors);
  const formId = `report-form-${companyId}`;

  const { methods } = useForm({
    validationSchema,
    defaultValues: {
      title: '',
      language: 'en',
    },
    formId,
  });

  return (
    <Form
      onSubmit={() => true}
      id={formId}
      resetOnSubmit
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <TitleField />

      <TrackingDateFromField />

      <TrackingDateToField />

      <LocationsField
        companyId={companyId}
        name={'location'}
      />

      <DepartmentsField
        companyId={companyId}
        name="department"
      />
      {hasCustomers && (
        <CustomersField
          companyId={companyId}
          name="customerIDs"
        />
      )}
      {hasVendors && (
        <VendorsField
          companyId={companyId}
          name="vendorIDs"
        />
      )}

      <ActivitiesField
        companyId={companyId}
        name="activity"
      />

      <TrackedByField
        companyId={companyId}
        name="trackedBy"
        isEditing={true}
      />

      <AttendeeField
        companyId={companyId}
        name="attendees"
      />

      <SafetyViolationField />

      <IncidentLocationField />

      <KeywordsField />
      <LanguageField />

      <ActionButtons companyId={companyId} />
    </Form>
  );
};

export { ReportForm };
