import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { ActionFormType } from 'entities/tracking/model';

export const useUpdateAction = (companyId: string, actionId: string) => {
  return useMutation((data: ActionFormType) => {
    return updateAction(data, companyId, actionId);
  });
};

const updateAction = (
  formData: ActionFormType,
  companyId: string,
  actionId: string,
) => {
  return httpClient.patch(
    `actions/${actionId}?companyID=${companyId}`,
    formData,
  );
};
