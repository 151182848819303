import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';
import { useGetTopic } from 'entities/tracking/api';
import { TopicView } from 'features/topics/TopicView';
import { TopicButtons } from 'features/topics/TopicButtons';
import { CreationDetails } from 'features/CreationDetails';

const ViewTopicPageThrowable = () => {
  const { t } = useTranslation('common');
  const { topicId } = useParams();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  if (!topicId) {
    throw new Error('TopicId not found');
  }

  const { isLoading, error, data } = useGetTopic(companyId, topicId);

  if (isLoading) {
    return <Text size="lg">{t('Loading')}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Topic not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <TopicView topic={data.data} />
      <TopicButtons
        topicId={topicId}
        companyId={companyId}
        active={data.data.active}
      />
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewTopicPage = () => {
  return (
    <ErrorBoundary>
      <ViewTopicPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewTopicPage };
