import { routesNames } from 'app/constants/routesConstants';
import { Privileges } from 'entities/role/model';

const {
  signIn,
  forgotPassword,
  resetPassword,
  changePassword,
  news,
  about,
  partners,
  vendor,
  solutions,
  regulation,
  contact,
  dashboard,
  company,
  personnel,
  roles,
  locations,
  departments,
  tracking,
  profile,
  actions,
  topics,
  activities,
  customer,
  list,
  add,
  view,
  edit,
  agency,
  report,
} = routesNames;

const basePublic = '//www.omnistools.com';
const baseProtected = `/${dashboard}`;

const routeBuilder = Object.freeze({
  // Protected //
  company: {
    index: {
      getPath: () => `${baseProtected}/${company}`,
      getLabel: () => 'Company List',
      getAllowedPrivileges: () => [],
    },
    add: {
      getPath: () => `${baseProtected}/${company}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [Privileges.FULL],
    },
    view: {
      getPath: (id: string) => `${baseProtected}/${company}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [],
    },
    edit: {
      getPath: (id: string) => `${baseProtected}/${company}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
  },
  personnel: {
    index: {
      getPath: () => `${baseProtected}/${company}/${personnel}`,
      getLabel: () => 'Personnel List',
      getAllowedPrivileges: () => [
        Privileges.USERS_READ,
        Privileges.USERS_UPDATE,
        Privileges.USERS_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${company}/${personnel}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.USERS_CREATE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${personnel}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.USERS_READ,
        Privileges.USERS_CREATE,
        Privileges.USERS_UPDATE,
        Privileges.USERS_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    changePassword: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${personnel}/${changePassword}/${id}`,
      getLabel: () => 'Change Password',
      getAllowedPrivileges: () => [
        Privileges.USERS_READ,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${personnel}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        // To allow the user to edit his profile without adding privilege User: Update,
        // now we check the privileges and userId in the EditPersonnelPage.tsx component
        // Privileges.USERS_UPDATE,
        // Privileges.USERS_DELETE,
        // Privileges.FULL_OVER_COMPANY,
        // Privileges.FULL,
      ],
    },
  },
  roles: {
    index: {
      getPath: () => `${baseProtected}/${company}/${roles}/${list}`,
      getLabel: () => 'Roles List',
      getAllowedPrivileges: () => [
        // Privileges.ROLES_ASSIGN,
        // Privileges.ROLES_UPDATE,
        // Privileges.ROLES_DELETE,
        // Privileges.FULL_OVER_COMPANY,
        // Privileges.FULL,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${company}/${roles}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.ROLES_CREATE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${roles}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        // Privileges.ROLES_ASSIGN,
        // Privileges.FULL_OVER_COMPANY,
        // Privileges.FULL,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${roles}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.ROLES_UPDATE,
        Privileges.ROLES_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
  },
  locations: {
    index: {
      getPath: () => `${baseProtected}/${company}/${locations}/${list}`,
      getLabel: () => 'Locations List',
      getAllowedPrivileges: () => [
        Privileges.LOCATIONS_READ,
        Privileges.LOCATIONS_UPDATE,
        Privileges.LOCATIONS_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${company}/${locations}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.LOCATIONS_CREATE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${locations}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.LOCATIONS_READ,
        Privileges.LOCATIONS_UPDATE,
        Privileges.LOCATIONS_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${locations}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.LOCATIONS_UPDATE,
        Privileges.LOCATIONS_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
  },
  departments: {
    index: {
      getPath: () => `${baseProtected}/${company}/${departments}/${list}`,
      getLabel: () => 'Departments List',
      getAllowedPrivileges: () => [
        Privileges.DEPARTMENTS_READ,
        Privileges.DEPARTMENTS_UPDATE,
        Privileges.DEPARTMENTS_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${company}/${departments}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.DEPARTMENTS_CREATE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${departments}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.DEPARTMENTS_READ,
        Privileges.DEPARTMENTS_UPDATE,
        Privileges.DEPARTMENTS_DELETE,
        Privileges.FULL_OVER_COMPANY,
        Privileges.FULL,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${company}/${departments}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.DEPARTMENTS_UPDATE,
        Privileges.DEPARTMENTS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
  },

  tracking: {
    index: {
      getPath: () => `${baseProtected}/${tracking}/${list}`,
      getLabel: () => 'Tracking List',
      getAllowedPrivileges: () => [
        Privileges.TRACKINGS_READ,
        Privileges.TRACKINGS_UPDATE,
        Privileges.TRACKINGS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${tracking}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.TRACKINGS_CREATE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    view: {
      getPath: (id: string) => `${baseProtected}/${tracking}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.TRACKINGS_READ,
        Privileges.TRACKINGS_UPDATE,
        Privileges.TRACKINGS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },

    edit: {
      getPath: (id: string) => `${baseProtected}/${tracking}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.TRACKINGS_UPDATE,
        Privileges.TRACKINGS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
  },
  report: {
    index: {
      getPath: () => `${baseProtected}/${tracking}/${report}`,
      getLabel: () => 'Report',
      getAllowedPrivileges: () => [
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
        Privileges.TRACKINGS_READ,
      ],
    },
  },
  actions: {
    index: {
      getPath: () => `${baseProtected}/${tracking}/${actions}/${list}`,
      getLabel: () => 'Actions List',
      getAllowedPrivileges: () => [
        Privileges.ACTIONS_READ,
        Privileges.ACTIONS_UPDATE,
        Privileges.ACTIONS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${tracking}/${actions}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.ACTIONS_CREATE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${tracking}/${actions}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.ACTIONS_READ,
        Privileges.ACTIONS_UPDATE,
        Privileges.ACTIONS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${tracking}/${actions}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.ACTIONS_UPDATE,
        Privileges.ACTIONS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
  },

  activities: {
    index: {
      getPath: () => `${baseProtected}/${tracking}/${activities}/${list}`,
      getLabel: () => 'Activities List',
      getAllowedPrivileges: () => [
        Privileges.ACTIVITIES_READ,
        Privileges.ACTIVITIES_UPDATE,
        Privileges.ACTIVITIES_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${tracking}/${activities}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.ACTIVITIES_CREATE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${tracking}/${activities}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.ACTIVITIES_READ,
        Privileges.ACTIVITIES_UPDATE,
        Privileges.ACTIVITIES_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${tracking}/${activities}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.ACTIVITIES_UPDATE,
        Privileges.ACTIVITIES_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
  },
  topics: {
    index: {
      getPath: () => `${baseProtected}/${tracking}/${topics}/${list}`,
      getLabel: () => 'Topics List',
      getAllowedPrivileges: () => [
        Privileges.TOPICS_READ,
        Privileges.TOPICS_UPDATE,
        Privileges.TOPICS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${tracking}/${topics}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.TOPICS_CREATE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${tracking}/${topics}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.TOPICS_READ,
        Privileges.TOPICS_UPDATE,
        Privileges.TOPICS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${tracking}/${topics}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.TOPICS_UPDATE,
        Privileges.TOPICS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
  },

  customer: {
    index: {
      getPath: () => `${baseProtected}/${customer}/${list}`,
      getLabel: () => 'Customer List',
      getAllowedPrivileges: () => [
        Privileges.CUSTOMERS_READ,
        Privileges.CUSTOMERS_UPDATE,
        Privileges.CUSTOMERS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${customer}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.CUSTOMERS_CREATE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    view: {
      getPath: (id: string) => `${baseProtected}/${customer}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.CUSTOMERS_READ,
        Privileges.CUSTOMERS_UPDATE,
        Privileges.CUSTOMERS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    edit: {
      getPath: (id: string) => `${baseProtected}/${customer}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.CUSTOMERS_UPDATE,
        Privileges.CUSTOMERS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
  },

  vendor: {
    index: {
      getPath: () => `${baseProtected}/${vendor}/${list}`,
      getLabel: () => 'Vendor List',
      getAllowedPrivileges: () => [
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
        Privileges.VENDORS_READ,
        Privileges.VENDORS_UPDATE,
        Privileges.VENDORS_DELETE,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${vendor}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [
        Privileges.VENDORS_CREATE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    view: {
      getPath: (id: string) => `${baseProtected}/${vendor}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.VENDORS_READ,
        Privileges.VENDORS_UPDATE,
        Privileges.VENDORS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    edit: {
      getPath: (id: string) => `${baseProtected}/${vendor}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [
        Privileges.VENDORS_UPDATE,
        Privileges.VENDORS_DELETE,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
  },

  dashboard: {
    index: {
      getPath: () => `${baseProtected}`,
      getLabel: () => 'Dashboard',
      getAllowedPrivileges: () => [],
    },
  },

  regulation: {
    index: {
      getPath: () => `${baseProtected}/${regulation}`,
      getLabel: () => 'Regulation List',
      getAllowedPrivileges: () => [
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
        Privileges.REGULATIONS_READ,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${regulation}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [Privileges.FULL],
    },
    view: {
      getPath: (id: string) => `${baseProtected}/${regulation}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.REGULATIONS_READ,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    edit: {
      getPath: (id: string) => `${baseProtected}/${regulation}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [Privileges.FULL],
    },
  },

  profile: {
    index: {
      getPath: () => `${baseProtected}/${profile}`,
      getLabel: () => 'Profile',
      getAllowedPrivileges: () => [],
    },
  },

  agency: {
    index: {
      getPath: () => `${baseProtected}/${regulation}/${agency}/${list}`,
      getLabel: () => 'Agencies List',
      getAllowedPrivileges: () => [
        Privileges.FULL,
        Privileges.AGENCIES_READ,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    add: {
      getPath: () => `${baseProtected}/${regulation}/${agency}/${add}`,
      getLabel: () => 'Add',
      getAllowedPrivileges: () => [Privileges.FULL],
    },
    view: {
      getPath: (id: string) =>
        `${baseProtected}/${regulation}/${agency}/${view}/${id}`,
      getLabel: () => 'View',
      getAllowedPrivileges: () => [
        Privileges.AGENCIES_READ,
        Privileges.FULL,
        Privileges.FULL_OVER_COMPANY,
      ],
    },
    edit: {
      getPath: (id: string) =>
        `${baseProtected}/${regulation}/${agency}/${edit}/${id}`,
      getLabel: () => 'Edit',
      getAllowedPrivileges: () => [Privileges.FULL],
    },
  },
  // END Protected //

  // Public //
  home: {
    index: {
      getPath: () => basePublic,
      getLabel: () => 'Home',
      getAllowedPrivileges: () => [],
    },
  },

  news: {
    index: {
      getPath: () => `${basePublic}/${news}`,
      getLabel: () => 'News',
      getAllowedPrivileges: () => [],
    },
  },

  about: {
    index: {
      getPath: () => `${basePublic}/${about}`,
      getLabel: () => 'About',
      getAllowedPrivileges: () => [],
    },
    company: {
      getPath: () => `${basePublic}/about`,
      getLabel: () => 'The Company',
      getAllowedPrivileges: () => [],
    },
    board: {
      getPath: () => `${basePublic}/about/board`,
      getLabel: () => 'Advisory Board',
      getAllowedPrivileges: () => [],
    },
  },

  solutions: {
    index: {
      getPath: () => `${basePublic}/${solutions}`,
      getLabel: () => 'Solutions',
      getAllowedPrivileges: () => [],
    },
    overview: {
      getPath: () => `${basePublic}/${solutions}`,
      getLabel: () => 'Overview',
      getAllowedPrivileges: () => [],
    },
    industries: {
      getPath: () => `${basePublic}/${solutions}/industries`,
      getLabel: () => 'Industries',
      getAllowedPrivileges: () => [],
    },
  },

  partners: {
    index: {
      getPath: () => `${basePublic}/${partners}`,
      getLabel: () => 'Partners',
      getAllowedPrivileges: () => [],
    },
    corporations: {
      getPath: () => `${basePublic}/${partners}`,
      getLabel: () => 'Corporations',
      getAllowedPrivileges: () => [],
    },
    testimonials: {
      getPath: () => `${basePublic}/${partners}/testimonials`,
      getLabel: () => 'Testimonials',
      getAllowedPrivileges: () => [],
    },
  },
  covid: {
    index: {
      getPath: () => `${basePublic}/wp-content/uploads/2021/02/BAWH-English-C-19-English.mp4`,
      getLabel: () => 'COVID',
      getAllowedPrivileges: () => [],
    },
    english: {
      getPath: () => `${basePublic}/wp-content/uploads/2021/02/BAWH-English-C-19-English.mp4`,
      getLabel: () => 'English Video',
      getAllowedPrivileges: () => [],
    },
    spanish: {
      getPath: () => `${basePublic}/wp-content/uploads/2021/02/BAWH-C-19-Video-Spanish.mp4`,
      getLabel: () => 'Spanish Video',
      getAllowedPrivileges: () => [],
    }
  },
  contact: {
    index: {
      getPath: () => `${basePublic}/${contact}`,
      getLabel: () => 'Contact',
      getAllowedPrivileges: () => [],
    },
  },

  signIn: {
    index: {
      getPath: () => `/${signIn}`,
      getLabel: () => 'Sign in',
      getAllowedPrivileges: () => [],
    },
  },

  forgotPassword: {
    index: {
      getPath: () => `/${forgotPassword}`,
      getLabel: () => 'Forgot password',
      getAllowedPrivileges: () => [],
    },
  },

  resetPassword: {
    index: {
      getPath: () => `/${resetPassword}`,
      getLabel: () => 'Reset password',
      getAllowedPrivileges: () => [],
    },
  },

  mfaCode: {
    index: {
      getPath: (mfaCodeParam: string) =>
        `${basePublic}/mfa${mfaCodeParam ? `/${mfaCodeParam}` : ''}`,
      getLabel: () => 'MFA Code',
      getAllowedPrivileges: () => [],
    },
  },
  // END Public //
});

export { routeBuilder };
