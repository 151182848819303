export const RolesSearchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.618 11.032a5.5 5.5 0 1 1 1.414-1.414l3.97 3.969L13.586 15l-3.97-3.97ZM10 6.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
        fill="#D1D5DB"
      />
    </svg>
  );
};
