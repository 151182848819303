import { ReactNode, useEffect, useState } from 'react';
import { FlexWrapper, Form } from 'shared/components';

import { SaveButton } from 'features/SaveButton';
import { SaveAndNewButton } from 'features/SaveAndNewButton';
import {
  useContainerProps,
  ActiveField,
  NameField,
  DescriptionField,
} from 'features/FormCommons';
import { useForm } from 'shared/components/Form';
import { TopicFormType } from 'entities/tracking/model';

import { TopicActivitiesField } from './TopicActivitiesField';
import { SubmitFormType } from 'shared/types';
import { useNameValidation } from 'shared/utils/yup';

type TopicFormProps = {
  defaultValues?: TopicFormType;
  handleSubmit: (
    data: TopicFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  formId: string;
  additionalActionButtons?: ReactNode;
  companyId: string;
  topicId?: string;
};
const defaultData: TopicFormType = {
  name: '',
  active: true,
  description: '',
  activities: {},
};

const TopicForm = ({
  defaultValues = defaultData,
  handleSubmit,
  formId,
  isLoading,
  additionalActionButtons,
  companyId,
}: TopicFormProps) => {
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const [forceUpdated, setForceUpdated] = useState(false);
  const validationSchema = useNameValidation();
  const { methods, cleanPersistentStore } = useForm<TopicFormType>({
    defaultValues,
    validationSchema,
    formId,
  });

  useEffect(() => {
    if (formId.includes('edit-topic')) {
      cleanPersistentStore();
    }
  }, []);
  useEffect(() => {
    if (!forceUpdated && Object.keys(defaultValues.activities || {}).length) {
      cleanPersistentStore();
      methods.setValue('activities', defaultValues.activities);
      Object.values(defaultValues.activities || {}).forEach((activity) =>
        methods.setValue(`activities.${activity._id}`, activity),
      );
      setForceUpdated(true);
    }
  }, [Object.keys(defaultValues.activities || {}).length]);

  return (
    <Form<TopicFormType>
      id={formId}
      onSubmit={(data, { reset }) => {
        handleSubmit(
          data,
          () => {
            cleanPersistentStore();
          },
          submitType,
        );
        reset(defaultValues);
      }}
      methods={methods}
      containerProps={{
        ...containerStyleProps,
      }}
    >
      <ActiveField />

      <NameField />

      <DescriptionField />

      <TopicActivitiesField companyId={companyId} />

      <FlexWrapper>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { TopicForm };
