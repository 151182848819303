import { Text, Menu, MenuList, MenuButton, MenuItem } from 'shared/components';
import { UsersGroupIcon } from 'shared/icons';
import { useGetCompanies } from 'entities/company/api';
import useStore from 'shared/store';
import { useQueryClient } from '@tanstack/react-query';

const CompanySwitcher = () => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const company = useStore((state) => state.company);
  const setCompany = useStore((state) => state.setCompany);
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  const { data, isLoading } = useGetCompanies(
    { sort: [{ name: 'name', sortDirection: 'asc' }] },
    {
      useErrorBoundary: false,
      enabled: isLoggedIn,
      retry: false,
    },
  );

  if (!company || isLoading) {
    return null;
  }

  const companies = data?.data.data.filter((item) => item.active);

  return (
    <Menu matchWidth>
      <MenuButton
        p="11px 30px"
        alignSelf={{ base: 'center', lg: 'initial' }}
        minW={{ base: '240px', lg: '320px' }}
        borderRadius="100px"
        fontSize={{ base: '1.4rem', lg: '1.6rem' }}
        fontWeight="bold"
        backgroundColor="secondary.500"
        color="white"
        cursor={companies && companies.length > 1 ? 'pointer' : 'default'}
      >
        {company?.name}
      </MenuButton>
      {companies && companies.length > 1 && (
        <MenuList
          marginTop="-8px"
          padding="0"
          overflow="hidden"
          border="1px solid"
          borderColor="secondary.500"
          borderRadius="20px"
          minW={{ base: '240px', lg: '320px' }}
          maxH="calc((5rem + 1px) * 10)"
          overflowY="auto"
        >
          {companies &&
            companies.map((item) => (
              <MenuItem
                key={item._id}
                onClick={() => {
                  setCompany(item);
                  queryClient.invalidateQueries(
                    queryCache.findAll().map((keys) => keys.queryKey[0]),
                  );
                }}
                height="5rem"
                _notLast={{
                  borderBottom: '1px solid',
                  borderColor: 'primary.500',
                }}
                _hover={{
                  backgroundColor: 'secondary.200',
                }}
              >
                <Text>{item.name}</Text>
              </MenuItem>
            ))}
        </MenuList>
      )}
    </Menu>
  );
};

export { CompanySwitcher };
