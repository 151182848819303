import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';
import { TopicFinalType } from 'entities/tracking/model';

export const useSaveTopic = (companyId: string) => {
  return useMutation((data: TopicFinalType) => {
    return saveTopic(data, companyId);
  });
};

const saveTopic = (formData: TopicFinalType, companyId: string) => {
  return httpClient.post<TopicFinalType, AxiosResponse<{ _id: string }>>(
    `topics?companyID=${companyId}`,
    formData,
  );
};
