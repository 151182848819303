import { useMutation } from 'shared/hooks/reactQuery';

import { httpClient, AxiosResponse } from 'shared/utils/httpClient';
import { ActionFormType } from 'entities/tracking/model';

export const useSaveAction = (companyId: string) => {
  return useMutation((data: ActionFormType) => {
    return saveAction(data, companyId);
  });
};

const saveAction = (formData: ActionFormType, companyId: string) => {
  return httpClient.post<ActionFormType, AxiosResponse<{ _id: string }>>(
    `actions?companyID=${companyId}`,
    formData,
  );
};
