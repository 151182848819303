import { CompanyFormType } from 'entities/company/model/CompanyFormType';
import { ICode } from 'entities/company/model/ICode';

export const convertData = (data: CompanyFormType): CompanyFormType => {
  const fax = data.fax || undefined;
  const code =
    data.code &&
    (Object.entries(data.code).reduce((acc, [key, value]) => {
      if (value) {
        return { ...acc, [key]: parseInt(value) };
      }

      return acc;
    }, {}) as ICode);

  return {
    ...data,
    fax,
    code,
    timeZone: data.timeZone || undefined,
  };
};
