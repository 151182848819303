import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { EditIcon } from 'shared/icons';
import { Button, ButtonProps } from 'shared/components';

interface IProps extends ButtonProps {
  redirectPath: string;
}

const EditItemButton = ({ redirectPath, ...restProps }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Button
      as={Link}
      to={redirectPath}
      variant="primary"
      leftIcon={<EditIcon />}
      {...restProps}
    >
      {t('Edit')}
    </Button>
  );
};

export { EditItemButton };
