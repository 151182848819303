import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { SingleTableWrapper } from 'shared/components';
import useStore from 'shared/store';
import { ISort } from 'shared/types';

import { CheckboxListFilter } from 'features/CheckboxListFilter';

import { useGetVendors } from 'entities/vendor/api';

import { VendorsTrackingTable } from './VendorsTrackingTable';
import { useVendorTrackingColumnsConfiguration } from './utils';

const limit = 10;

type Props = {
  vendorIDs?: string[];
};

const VendorsTracking = ({ vendorIDs }: Props) => {
  const { t } = useTranslation('trackingForm');
  const companyId = useStore((state) => state.company?._id);
  const columnsConfiguration = useVendorTrackingColumnsConfiguration();
  const [searchValue, setSearchValue] = useState('');
  const [tableSort, setTableSort] = useState<ISort[]>([]);
  const [offset, setOffset] = useState(0);

  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const { data, isLoading } = useGetVendors(
    companyId,
    {
      fields: columnsConfiguration
        .map((columnConfiguration) => columnConfiguration.sortedFields)
        .flat(),
      filters: searchValue,
      sort:
        tableSort.length === 0
          ? [{ name: 'name', sortDirection: 'asc' }]
          : tableSort,
      offset: offset,
      limit: limit,
    },
    { useErrorBoundary: false },
  );

  return (
    <SingleTableWrapper
      title={t('Vendor Tracking')}
      overflowX="hidden"
      childContainerStyle={{
        overflow: 'auto',
      }}
    >
      <CheckboxListFilter
        itemsFormName="vendors"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        filteredResults={data?.data.data || []}
      />
      <VendorsTrackingTable
        vendorsList={data?.data}
        vendorsIDs={vendorIDs}
        onSortChange={(sort) => setTableSort(sort)}
        isLoading={isLoading}
        limit={limit}
        offset={offset}
        setOffset={(value) => setOffset(value)}
      />
    </SingleTableWrapper>
  );
};

export { VendorsTracking };
