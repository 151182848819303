import useStore from 'shared/store';
import { AddRole } from 'widgets/Roles';

const AddRolesPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  return <AddRole companyId={companyId} />;
};

export { AddRolesPage };
