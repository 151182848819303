import { useNavigate } from 'react-router-dom';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { IRegulationPayload } from 'entities/regulation/model';

import { useSaveRegulation } from 'entities/regulation/api';
import { RegulationForm } from 'features/regulation/RegulationForm';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddRegulation = ({ companyId }: IProps) => {
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const { mutateAsync, isLoading } = useSaveRegulation(companyId);

  const handleSubmit = async (
    data: IRegulationPayload,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(data);

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.regulation.view.getPath(_id));
      }
      successToast({
        message: t('Successfully added item message', {
          item: t('regulation'),
        }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', {
          item: t('regulation'),
        }),
      });
    }
  };

  return (
    <RegulationForm
      formId="add-regulation-form"
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      companyId={companyId}
    />
  );
};

export { AddRegulation };
