import { IDepartment } from 'entities/company/model';
import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from '../../../shared/utils/httpClient';
import { useGetLocale } from 'shared/utils';

export const useGetDepartment = (
  departmentId: string,
  companyId: string,
  options?: UseQueryOptions<AxiosResponse<IDepartment>, AxiosError>,
) => {
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IDepartment>(
    getDepartmentKey(departmentId, companyId, defaultLang),
    () => getDepartment(departmentId, companyId, defaultLang),
    options,
  );
};

const getDepartment = (departmentId: string, companyId: string, lang = 'en') => {
  return httpClient.get<IDepartment>(
    `departments/${departmentId}?languageCode=${lang}&companyID=${companyId}`,
  );
};

const getDepartmentKey = (departmentId: string, companyId: string, lang = 'en') => [
  'department',
  departmentId,
  companyId,
  lang,
];
