import { useTranslation } from 'react-i18next';

import { Text, ViewRow } from 'shared/components';

interface IProps {
  violation?: boolean;
}

const ViolationViewRow = ({ violation }: IProps) => {
  const { t } = useTranslation('tracking');

  return (
    <ViewRow label={t('Violation')}>
      <Text>{violation ? t('Yes') : t('No')}</Text>
    </ViewRow>
  );
};

export { ViolationViewRow };
