import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import { useCheckboxFilter } from 'features/CheckboxListFilter/utils';
import { useTopicsTrackingColumnsConfiguration } from './utils';
import {
  createTableStore,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeaderText,
  TableHeadSortableCell,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import { Checkbox } from 'shared/components';
import { EmptyStateTableRow } from 'features/EmptyStateTableRow';
import { TopicsListFieldTableRow } from './TopicsListFieldTableRow';
import { IList, ISort } from 'shared/types';

import { ITopic } from 'entities/tracking/model';

interface ITopicsListFieldTableProps {
  topicsList?: IList<ITopic[]>;
  isLoading: boolean;
  offset: number;
  limit: number;
  onSortChange: (sort: ISort[]) => void;
  setOffset: (value: number) => void;
}

const useTopicsTrackingTableState = createTableStore();

const TopicsListFieldTable = ({
  isLoading,
  topicsList,
  offset,
  limit,
  onSortChange,
  setOffset,
}: ITopicsListFieldTableProps) => {
  const columnsConfiguration = useTopicsTrackingColumnsConfiguration();
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);

  const tableState = useTopicsTrackingTableState((state) => state, shallow);
  const { isAllSelected, switchAllCheckboxes } = useCheckboxFilter(
    topicsList?.data || [],
    'topics',
  );

  useEffect(() => {
    if (topicsList && topicsList?.total <= 10) {
      setOffset(0);
    }
  }, [topicsList?.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map(({ title, isSortable, name }) => {
            if (name === 'checkbox') {
              return (
                <TableHeadCell
                  key={name}
                  textAlign="center"
                >
                  <Checkbox
                    size="lg"
                    onChange={() => switchAllCheckboxes(!isAllSelected)}
                    isChecked={isAllSelected}
                  />
                </TableHeadCell>
              );
            }
            return isSortable ? (
              <TableHeadSortableCell
                key={name}
                columnName={name}
              >
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadSortableCell>
            ) : (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>

      <TableBody isLoading={isLoading}>
        {topicsList && topicsList.total > 0 ? (
          <>
            {topicsList.data
              .filter(
                (_, index) =>
                  index >= Math.floor(offset / limit) * limit &&
                  index < Math.floor(offset / limit) * limit + limit,
              )
              .map((topic) => {
                return (
                  <TopicsListFieldTableRow
                    key={topic._id}
                    topic={topic}
                  />
                );
              })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={topicsList.total}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                  itemsPerPage={limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { TopicsListFieldTable };
