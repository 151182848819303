import { useTranslation } from 'react-i18next';
import { SaveAndNewIcon } from 'shared/icons';
import { Button, ButtonProps } from 'shared/components';

type Props = ButtonProps & {
  handleClick: () => void;
};

const SaveAndNewButton = ({ handleClick, ...props }: Props) => {
  const { t } = useTranslation('common');
  return (
    <Button
      type="submit"
      rightIcon={<SaveAndNewIcon />}
      onClick={handleClick}
      {...props}
    >
      {t('Save and New')}
    </Button>
  );
};

export { SaveAndNewButton };
