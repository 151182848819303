import { Icon, IconProps } from 'shared/components';

const DownloadIcon = (props: IconProps) => (
  <Icon
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.57143 2C8.57143 1.44771 9.01914 1 9.57143 1H20.4286C20.9809 1 21.4286 1.44772 21.4286 2V12.4615C21.4286 13.0138 21.8763 13.4615 22.4286 13.4615H27.5314C28.4299 13.4615 28.8725 14.5543 28.2274 15.1796L15.696 27.3254C15.3081 27.7014 14.6919 27.7014 14.304 27.3254L1.77261 15.1796C1.12746 14.5543 1.57013 13.4615 2.46858 13.4615H7.57143C8.12371 13.4615 8.57143 13.0138 8.57143 12.4615V2Z"
      fill="#72B42D"
    />
  </Icon>
);

export { DownloadIcon };
