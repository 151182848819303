import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal as ChakraModal,
  ModalOverlay as ChakraModalOverlay,
  ModalContent as ChakraModalContent,
} from '@chakra-ui/react';
import { SingleTableWrapper, Flex, Text, Button } from 'shared/components';
import { AttentionIcon, CancelIcon } from 'shared/icons';
import { ModalContentProps, ModalProps } from '@chakra-ui/modal';

export interface IModalProps extends Omit<ModalProps, 'children'> {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message?: string;
  children?: ReactNode;
  content?: ReactNode;
  closeButtonLabel?: string;
  modalContentStyleProps?: ModalContentProps;
}

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  message,
  content,
  modalContentStyleProps,
  ...restProps
}: IModalProps) => {
  const { t } = useTranslation('common');

  const modalSize = restProps.size || 'xl';

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      size={modalSize}
      {...restProps}
    >
      <ChakraModalOverlay />
      <ChakraModalContent
        borderRadius="20px"
        mx="10px"
        {...modalContentStyleProps}
      >
        <SingleTableWrapper
          title={title}
          isModal
          childContainerStyle={{ m: '20px 0' }}
        >
          <Flex
            display="flex"
            alignItems="center"
            gap="20px"
            p={{ base: '14px 10px 34px 10px', xl: '14px 20px 34px 20px' }}
            borderBottomWidth="2px"
            borderBottomStyle="solid"
            borderBottomColor="secondary.500"
          >
            {message && (
              <>
                <AttentionIcon boxSize={{ base: '25px', md: '30px' }} />
                <Text size={{ base: 'sm', md: 'md' }}>{message}</Text>
              </>
            )}
            {content}
          </Flex>
          <Flex
            gap="10px"
            pt="20px"
            px={{ base: '10px', xl: '20px' }}
            justifyContent={modalSize === 'xl' ? 'center' : 'end'}
          >
            {children}
            <Button
              variant="primary"
              leftIcon={<CancelIcon />}
              onClick={onClose}
            >
              {restProps?.closeButtonLabel || t('Cancel')}
            </Button>
          </Flex>
        </SingleTableWrapper>
      </ChakraModalContent>
    </ChakraModal>
  );
};

export { Modal };
