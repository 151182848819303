import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';
import useStore from 'shared/store';
import { useGetRole } from 'entities/role/api';
import { EditRole } from 'widgets/Roles';

const EditRolesPageThrowable = () => {
  const { t } = useTranslation(['roles', 'common']);
  const companyId = useStore((state) => state.company?._id);
  const { roleId } = useParams();

  if (!roleId) {
    throw new Error('RoleId not found');
  }

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  const { isLoading, error, data } = useGetRole(companyId, roleId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Role not found')}</Text>;
  }

  return (
    <EditRole
      companyId={companyId}
      role={data.data}
    />
  );
};

export const EditRolesPage = () => {
  return (
    <ErrorBoundary>
      <EditRolesPageThrowable />
    </ErrorBoundary>
  );
};
