import { EmailButton } from './EmailButton';
import { useDisclosure } from '../../shared/hooks/chakraUi';
import { TrackingEmailModal } from './TrackingEmailModal/TrackingEmailModal';
import { ITracking } from '../../entities/tracking/model';

interface IProps {
  companyId: string;
  tracking: ITracking;
}

const TrackingViewEmail = ({ companyId, tracking }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <TrackingEmailModal
        onClose={onClose}
        isOpen={isOpen}
        companyId={companyId}
        tracking={tracking}
      />
      <EmailButton
        type="submit"
        onClick={() => {
          onOpen();
        }}
      />
    </>
  );
};

export { TrackingViewEmail };
