import { useDeleteTrackingAttachment } from 'entities/attachments/api';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Modal, useDisclosure } from 'shared/components';
import { DeleteIcon } from 'shared/icons';
import useStore from '../../shared/store';
import { useToast } from '../../shared/utils';

interface IProps {
  title: string;
  message: string;
  attachmentId: string;
  companyId: string;
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
}

const DeleteAttachment = ({
  title,
  message,
  onSuccess,
  onError,
  attachmentId,
}: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation(['popupMessage', 'common']);
  const { successToast, errorToast } = useToast();

  const mutation = useDeleteTrackingAttachment();

  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);

  const handleDelete = async () => {
    try {
      // TODO restore when api will be working
      // await mutation.mutateAsync({ attachmentId, companyId });
      onSuccess && onSuccess();

      const newFilesToConnect = filesToConnect.filter(
        (fileToConnect) => fileToConnect._id !== attachmentId,
      );
      setFilesToConnect(newFilesToConnect);

      successToast({
        message: t('Successfully deleted item message', {
          item: t('attachment'),
        }),
      });
      onClose();
    } catch (e) {
      errorToast({
        message: t('Error while deleting item message', {
          item: t('attachment'),
        }),
      });
      onError && onError(e);
    }
  };

  return (
    <>
      <IconButton
        icon={<DeleteIcon />}
        aria-label={t('Delete Document', { ns: 'common' })}
        onClick={onOpen}
        w="max-content"
        bg="none"
      >
        {t('Delete', { ns: 'common' })}
      </IconButton>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        message={message}
      >
        <Button
          leftIcon={<DeleteIcon />}
          flex="auto"
          onClick={handleDelete}
          isLoading={mutation.isLoading}
        >
          {t('Delete', { ns: 'common' })}
        </Button>
      </Modal>
    </>
  );
};

export { DeleteAttachment };
