import { IRole } from 'entities/role/model';
import { TableBodyCell, TableCellText } from 'shared/components/Table';

interface IProps {
  roles?: IRole[];
}
const RolesTableCell = ({ roles }: IProps) => {
  if (!roles || roles.length === 0) {
    return (
      <TableBodyCell>
        <TableCellText>-</TableCellText>
      </TableBodyCell>
    );
  }
  return (
    <TableBodyCell>
      {roles?.map((role) => {
        return <TableCellText key={role._id}>{role.name}</TableCellText>;
      })}
    </TableBodyCell>
  );
};

export { RolesTableCell };
