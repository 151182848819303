import {
  Drawer as ChakraDrawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { DrawerProps } from '@chakra-ui/modal';
import { ReactNode } from 'react';

import { CloseButton } from './CloseButton';

interface IProps extends DrawerProps {
  header: ReactNode;
}

const Drawer = ({ header, children, ...restProps }: IProps) => {
  return (
    <ChakraDrawer
      size="full"
      {...restProps}
    >
      <DrawerOverlay />
      <DrawerContent
        py="23px"
        px="20px"
      >
        <CloseButton onClick={restProps.onClose} />
        <DrawerHeader p={0}>{header}</DrawerHeader>
        <DrawerBody
          p={0}
          mt="16px"
        >
          {children}
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
};

export { Drawer };
