import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary, Text } from 'shared/components';
import { useGetDepartment } from 'entities/company/api';
import useStore from 'shared/store';
import { EditDepartment } from '../../../../widgets/Department';

const EditDepartmentPageThrowable = () => {
  const { t } = useTranslation(['departments', 'common']);
  const companyId = useStore((state) => state.company?._id);
  const { departmentId } = useParams();

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  if (!departmentId) {
    throw new Error('DepartmentId not found');
  }

  const { isLoading, error, data } = useGetDepartment(departmentId, companyId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Department not found')}</Text>;
  }

  return (
    <EditDepartment
      companyId={companyId}
      department={data.data}
    />
  );
};

export const EditDepartmentPage = () => {
  return (
    <ErrorBoundary>
      <EditDepartmentPageThrowable />
    </ErrorBoundary>
  );
};
