import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { IActivity } from 'entities/tracking/model';
import { routeBuilder } from 'shared/utils';

interface IProps {
  activity: IActivity;
}
const ActivitiesTableRow = ({ activity }: IProps) => {
  const { t } = useTranslation('common');
  const navigation = useNavigate();

  const handleRedirect = () => {
    navigation(routeBuilder.activities.view.getPath(activity._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{activity.name}</TableCellText>
      </TableBodyCell>

      <TableBodyCell>
        <TableCellText>{activity.active ? t('Yes') : t('No')}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { ActivitiesTableRow };
