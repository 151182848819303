import { useTranslation } from 'react-i18next';
import { NavText, NavTextProps } from '../NavText';

export type NavMenuItemTextProp = {
  isLoggedIn: boolean;
  children: string;
  navTextProps?: Partial<NavTextProps>;
};

export const NavMenuItemText = ({
  children,
  isLoggedIn,
  navTextProps,
}: NavMenuItemTextProp) => {
  const keyPrefix = !isLoggedIn ? 'public' : 'protected';
  const { t } = useTranslation('navigation', {
    keyPrefix,
  });

  return (
    <NavText
      isLoggedIn={isLoggedIn}
      {...navTextProps}
    >
      {t(children)}
    </NavText>
  );
};
