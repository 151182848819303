import { string } from 'yup';
import { useTranslation } from 'react-i18next';

export const usePhoneBasicValidation = () => {
  const { t } = useTranslation('validationMessages');
  const message = t('Invalid phone number');

  // \+([0-9]){3,}$/i
  // \+ - match a literal +
  // ([0-9]){3,} - match a group of 11 digits allow max 15 digits
  // $ - match the end of the string
  // i - case-insensitive
  // https://regex101.com/r/4Q9Q2T/1

  return string()
    .test({
      name: 'is-valid-phone-number-basic',
      message,
      test: (value) => {
        if (!value) {
          return true;
        }
        return /^\+([0-9]){11,15}$/i.test(value);
      },
    })
    .nullable();
};
