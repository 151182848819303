import { useTranslation } from 'react-i18next';

import { Text, ViewRow } from 'shared/components';

interface IProps {
  active?: boolean;
}

const ActiveViewRow = ({ active }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Active')}>
      <Text>{active ? t('Yes') : t('No')}</Text>
    </ViewRow>
  );
};

export { ActiveViewRow };
