import { useNavigate } from 'react-router-dom';
import { useSaveDepartment } from '../../entities/company/api';
import { routeBuilder, useToast } from '../../shared/utils';
import { DepartmentForm } from '../../features/departments/DepartmentForm/DepartmentForm';
import { SubmitFormType } from '../../shared/types';
import { DepartmentFormType } from '../../entities/company/model';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddDepartment = ({ companyId }: IProps) => {
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');
  const { mutateAsync, isLoading } = useSaveDepartment(companyId);

  const handleSubmit = async (
    data: DepartmentFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(data);

      reset();

      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.departments.view.getPath(_id));
      }
      successToast({
        message: t('Successfully added item message', {
          item: t('department'),
        }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', {
          item: t('department'),
        }),
      });
    }
  };

  return (
    <DepartmentForm
      formId={`add-department-form-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export { AddDepartment };
