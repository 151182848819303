import { useNavigate } from 'react-router-dom';
import { ILocation } from 'entities/company/model';
import {
  TableBodyCell,
  TableBodyInfoCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { AddressTableCell } from '../AddressTableCell';
import { useTranslation } from 'react-i18next';
import { routeBuilder } from 'shared/utils';

interface IProps {
  location: ILocation;
  isMobile?: boolean;
}
const LocationTableRow = ({ location, isMobile }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(routeBuilder.locations.view.getPath(location._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      {isMobile ? (
        <>
          <TableBodyCell>
            <TableCellText>{location.name}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{location.phoneNumber || ''}</TableCellText>
          </TableBodyCell>
          <TableBodyInfoCell location={location} />
        </>
      ) : (
        <>
          <TableBodyCell>
            <TableCellText>{location.name}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{location.phoneNumber || ''}</TableCellText>
          </TableBodyCell>
          <AddressTableCell address={location.address && location.address} />
          <TableBodyCell>
            <TableCellText>{location.counters?.trackings || 0}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{location.counters?.users || 0}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>
              {location.active ? t('Yes') : t('No')}
            </TableCellText>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  );
};

export { LocationTableRow };
