import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';
import { DeleteActivity } from 'features/activites/DeleteActivity';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  activityId: string;
  active?: boolean;
}

const ActivityActionButtons = ({ activityId, companyId, active }: IProps) => {
  const {
    createActivityPrivilege,
    updateActivityPrivilege,
    deleteActivityPrivilege,
  } = LayoutPrivilege();
  return (
    <FlexWrapper>
      {createActivityPrivilege && (
        <AddNewItemButton
          redirectPath={routeBuilder.activities.add.getPath()}
        />
      )}
      {active && deleteActivityPrivilege && (
        <DeleteActivity
          companyId={companyId}
          activityId={activityId}
        />
      )}
      {updateActivityPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.activities.edit.getPath(activityId)}
        />
      )}
    </FlexWrapper>
  );
};

export { ActivityActionButtons };
