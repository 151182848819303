import { useMutation } from 'shared/hooks/reactQuery';

import { httpClient, AxiosResponse } from 'shared/utils/httpClient';
import { LocationFormType } from 'entities/company/model';

export const useSaveLocation = (companyId: string) => {
  return useMutation((data: LocationFormType) => {
    return saveLocation(data, companyId);
  });
};

const saveLocation = (formData: LocationFormType, companyId: string) => {
  return httpClient.post<LocationFormType, AxiosResponse<{ _id: string }>>(
    `locations?companyID=${companyId}`,
    formData,
  );
};
