import { useTranslation } from 'react-i18next';
import { SaveIcon } from 'shared/icons';
import { Button, ButtonProps } from 'shared/components';

type Props = ButtonProps & {
  handleClick?: () => void;
};

export const SaveButton = ({ handleClick, ...props }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Button
      type="submit"
      rightIcon={<SaveIcon />}
      onClick={handleClick}
      {...props}
    >
      {t('Save')}
    </Button>
  );
};
