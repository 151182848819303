import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { IRegulationPayload } from 'entities/regulation/model';

export const useUpdateRegulation = (
  companyId: string,
  regulationId: string,
) => {
  return useMutation((data: IRegulationPayload) => {
    return updateRegulation(data, companyId, regulationId);
  });
};

const updateRegulation = (
  formData: IRegulationPayload,
  companyId: string,
  regulationId: string,
) => {
  return httpClient.patch(
    `regulations/${regulationId}?companyID=${companyId}`,
    formData,
  );
};
