import shallow from 'zustand/shallow';
import { Text, useBreakpointValue } from 'shared/components';
import {
  createTableStore,
  ResponsiveHeadTableRow,
  Table,
  TableBody,
} from 'shared/components/Table';

import { EmployeesTableRow } from './EmployeesTableRow';
import { useEmployeesColumnsConfiguration } from './utils';
import { useMemo } from 'react';
import { EmptyStateTableRow } from 'features/EmptyStateTableRow';
import { useGetUsers } from 'entities/user/api';
import useStore from 'shared/store';

interface IProps {
  personId: string;
}

const useEmployeesTableStore = createTableStore();

export const EmployeesTable = ({ personId }: IProps) => {
  const companyId = useStore((state) => state.company?._id);
  if (!companyId) {
    throw new Error('Company Id is missing');
  }
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const { data } = useGetUsers(companyId);
  const columnsConfiguration = useEmployeesColumnsConfiguration();
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);
  const tableState = useEmployeesTableStore((state) => state, shallow);

  const supervisesUsers = data?.data.data.filter((s) => {
    if (personId) {
      return s.supervisor?._id.includes(personId);
    } else {
      return;
    }
  });

  if (supervisesUsers?.length === 0) {
    return <Text>-</Text>;
  }

  return (
    <Table
      data={tableState}
      borderRadius="10px"
      columnsConfiguration={memoizedColumnsConfiguration}
    >
      <ResponsiveHeadTableRow
        isMobile={isMobile}
        columnsConfiguration={columnsConfiguration}
      />
      <TableBody>
        {supervisesUsers && supervisesUsers?.length > 0 ? (
          data &&
          supervisesUsers.map((user) => {
            return (
              <EmployeesTableRow
                key={user._id}
                user={user}
                isMobile={isMobile}
              />
            );
          })
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};
