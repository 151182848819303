import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeaderText,
  TableHeadSortableCell,
  TableRow,
} from 'shared/components/Table';
import useStore from 'shared/store';
import { Pagination } from 'shared/components/Pagination';
import { IQueryParams, ISort } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';

import { useGetTopics } from 'entities/tracking/api';

import { useTopicsColumnsConfiguration } from './utils/useTopicsColumnsConfiguration';
import { TopicTableRow } from './TopicTableRow';

const useTopicsTableState = createTableStore();

interface IProps {
  setOffset: (value: number) => void;
  onSortChange: (sort: ISort[]) => void;
  queryParams: IQueryParams;
  topicIDs?: string[];
}

const TopicsTable = ({
  setOffset,
  onSortChange,
  topicIDs,
  queryParams: { limit = 10, offset = 0, ...restQueryParams },
}: IProps) => {
  const columnsConfiguration = useTopicsColumnsConfiguration();
  const companyId = useStore((state) => state.company?._id);

  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);
  const tableState = useTopicsTableState((state) => state, shallow);

  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const { data, isLoading } = useGetTopics(
    companyId,
    {
      ...restQueryParams,
      offset: offset,
      limit: limit,
      fields: memoizedColumnsConfiguration
        .map((columnConfiguration) => columnConfiguration.sortedFields)
        .flat(),
    },
    {
      useErrorBoundary: false,
    },
  );

  const topics = data?.data.data.filter((topic) => {
    if (topicIDs) {
      return topicIDs?.includes(topic._id);
    } else {
      return topic;
    }
  });

  const pagination = topicIDs ? topics?.length || 0 : data?.data.total || 0;

  useEffect(() => {
    if (
      (data && data?.data.total <= 10) ||
      (data && offset / limit + 1 > Math.ceil(data?.data.total / limit))
    ) {
      setOffset(0);
    }
  }, [data?.data.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map(({ title, isSortable, name }) => {
            return isSortable ? (
              <TableHeadSortableCell
                key={name}
                columnName={name}
                _last={{ width: '120px' }}
              >
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadSortableCell>
            ) : (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody isLoading={isLoading}>
        {data && pagination > 0 ? (
          <>
            {topics?.map((topic) => {
              return (
                <TopicTableRow
                  key={topic._id}
                  topic={topic}
                />
              );
            })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={pagination}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                  itemsPerPage={limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { TopicsTable };
