import { useTranslation } from 'react-i18next';

import { ErrorBoundary, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';
import { useBreakpointValue } from 'shared/hooks/chakraUi';

import {
  AutoComplete,
  AutoCompleteGroup,
  AutoCompleteGroupTitle,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { FormLabel } from 'shared/components';
import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useGetSMSAddressList } from 'entities/sms/api';
import { ISMSAddress } from 'entities/sms/model';
import { useEffect } from 'react';

interface IProps {
  companyId: string;
}

const SMSToSelectFieldThrowable = ({ companyId }: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetSMSAddressList(companyId);
  const height = useBreakpointValue({ base: '38px', md: '46px' });

  const options = data?.data;

  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = 'phoneNumber';
  const value = watch(name);

  useEffect(() => {
    if(value === undefined) setValue('phoneNumber', '+1');
  }, [value]);

  return (
    <Flex
      flexDir="column"
      gap="5px"
    >
      <FormLabel
        size="md"
        lineHeight="18px"
        display="flex"
        margin="0"
        alignItems="center"
      >
        {`${t('To')}:`}
      </FormLabel>
      <AutoComplete
        openOnFocus
        defaultValue={''}
        emptyState={''}
        onChange={(val) => {
          setValue(name, val);
        }}
        suggestWhenEmpty={true}
        disableFilter={isSubmitting}
        value={value}
      >
        <AutoCompleteInput
          isDisabled={isLoading}
          onChange={(e) => setValue(name, e.target.value)}
          value={value === undefined ? '' : value}
          borderColor="grey.700"
          _hover={{
            borderColor: 'secondary.500',
          }}
          _active={{
            borderColor: 'secondary.500',
          }}
          borderRadius="4px"
          borderWidth="2px"
          height={height}
          fontSize={{ base: '1.6rem', md: '1.8rem' }}
        />
        <AutoCompleteList>
          {options &&
            Object.entries(options).map(
              ([group, details]: [string, ISMSAddress[]]) => (
                <AutoCompleteGroup
                  key={group}
                  showDivider
                >
                  <AutoCompleteGroupTitle
                    fontSize={{ base: '1.6rem', md: '1.8rem' }}
                  >
                    {group}
                  </AutoCompleteGroupTitle>
                  {details?.map((detail) => (
                    <AutoCompleteItem
                      key={detail._id}
                      value={detail.phoneNumber}
                      fontSize={{ base: '1rem', md: '1.2rem' }}
                    >
                      {`${detail.phoneNumber} (${detail.name})`}
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteGroup>
              ),
            )}
        </AutoCompleteList>
      </AutoComplete>
    </Flex>
  );
};

const SMSToSelectField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('To')}:`}>
          <SelectField
            name="phoneNumber"
            isDisabled
          />
        </FormField>
      )}
    >
      <SMSToSelectFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { SMSToSelectField };
