import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';

import { useGetRegulation } from 'entities/regulation/api';
import { RegulationView } from 'features/regulation/RegulationView';
import { RegulationActionButtons } from '../../../features/regulation/RegulationActionButtons';
import { CreationDetails } from 'features/CreationDetails';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

const ViewRegulationPageThrowable = () => {
  const { t } = useTranslation('common');
  const { regulationId } = useParams();
  const companyId = useStore((state) => state.company?._id);
  const { fullSuperAdminPrivilege } = LayoutPrivilege();

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  if (!regulationId) {
    throw new Error('RegulationId not found');
  }

  const { isLoading, error, data } = useGetRegulation(companyId, regulationId);

  if (isLoading) {
    return <Text size="lg">{t('Loading')}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Regulation not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <RegulationView regulation={data.data} />

      {fullSuperAdminPrivilege && (
        <RegulationActionButtons
          regulationId={regulationId}
          companyId={companyId}
        />
      )}
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewRegulationPage = () => {
  return (
    <ErrorBoundary>
      <ViewRegulationPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewRegulationPage };
