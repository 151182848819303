export const inputConfiguration = {
  defaultProps: {
    focusBorderColor: 'secondary.500',
  },
  variants: {
    outline: {
      field: {
        borderColor: 'grey.700',
        _hover: {
          borderColor: 'secondary.500',
        },
        _active: {
          borderColor: 'secondary.500',
        },
        borderRadius: '4px',
        borderWidth: '2px',
      },
    },
  },
};
