import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteTracking = (trackingId: string, companyId: string) => {
  return useMutation(() => {
    return deleteTracking(trackingId, companyId);
  });
};

const deleteTracking = (trackingId: string, companyId: string) => {
  return httpClient.delete(`trackings/${trackingId}?companyID=${companyId}`);
};
