import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';

export const useHasError = (name: string) => {
  const {
    formState: { errors },
  } = useFormContext();

  return !!get(errors, name);
};
