import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AddCircleIcon } from 'shared/icons';
import { Button, ButtonProps } from 'shared/components';

interface IProps extends ButtonProps {
  redirectPath: string;
}

const AddNewItemButton = ({ redirectPath, ...restProps }: IProps) => {
  const navigation = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Button
      variant="primary"
      leftIcon={<AddCircleIcon />}
      onClick={() => navigation(redirectPath)}
      {...restProps}
    >
      {t('Add')}
    </Button>
  );
};

export { AddNewItemButton };
