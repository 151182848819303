import { Flex, Link, Text } from 'shared/components';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Flex
      as="footer"
      mt="auto"
      flexDir={{ base: 'column', md: 'row' }}
      gap={{ base: '20px', md: '10px' }}
      alignItems="center"
      bgColor="primary.500"
      rounded="20px"
      paddingX="30px"
      paddingY="25px"
    >
      <Link
        href="tel:408245-6600"
        color="white"
        fontWeight="700"
      >
        <Text
          whiteSpace="nowrap"
          flexGrow="1"
        >
          (408) 245-6600
        </Text>
      </Link>
      <Text
        color="white"
        align="center"
        fontWeight="400"
        justifySelf={'center'}
        px={{ md: '40px' }}
        w="100%"
      >
        {`© ${currentYear} Omnis Tools Inc. All Rights Reserved. OmnisTools is a trademark
        of Omnis Tools, Inc.`}
      </Text>
    </Flex>
  );
};

export { Footer };
