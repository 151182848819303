import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { usePhoneBasicValidation } from 'shared/utils/yup';

export const useLocationFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');

  return object({
    name: string().required(requiredFieldMessage),
    phoneNumber: usePhoneBasicValidation(),
  });
};
