import { IUser } from 'entities/user/model';

import { TableCellText, TableCellProps, Text } from 'shared/components';
import { TableBodyCell } from 'shared/components/Table';

interface IProps extends TableCellProps {
  attendee: IUser[] | undefined;
}

const AttendeeTableCell = ({ attendee, ...restProps }: IProps) => {
  return (
    <TableBodyCell
      maxW="250px"
      {...restProps}
    >
      {attendee ? (
        <>
          {attendee.map((a) => {
            return (
              <TableCellText key={a._id}>
                {`${a?.name.firstname || ''} ${a?.name.lastname || ''}`}
              </TableCellText>
            );
          })}
        </>
      ) : (
        <Text>-</Text>
      )}
    </TableBodyCell>
  );
};

export { AttendeeTableCell };
