import useStore from 'shared/store';
import { AddDepartment } from 'widgets/Department';

const AddDepartmentsPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  return <AddDepartment companyId={companyId} />;
};

export { AddDepartmentsPage };
