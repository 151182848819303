import create from 'zustand';

import { ITableStore } from '../types';

const createTableStore = (store?: Partial<ITableStore>) =>
  create<ITableStore>((set) => ({
    sort: [],
    columnsConfiguration: [],
    setSort: (sort) => set((state) => ({ ...state, sort })),
    setColumnsConfiguration: (columnsConfiguration) =>
      set((state) => ({ ...state, columnsConfiguration })),
    currentlySortedColumnName: '',
    setCurrentlySortedColumnName: (columnName) =>
      set((state) => ({ ...state, currentlySortedColumnName: columnName })),
    ...store,
  }));

export { createTableStore };
