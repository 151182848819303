import { useNavigate } from 'react-router-dom';

import { SubmitFormType } from 'shared/types';
import { routeBuilder, useToast } from 'shared/utils';

import { ViewButton } from 'features/ViewButton';
import { ITopic, TopicFormType } from 'entities/tracking/model';
import { useGetActivities, useUpdateTopic } from 'entities/tracking/api';
import { DeleteTopic } from 'features/topics/TopicButtons';
import { convertTopicFormData } from 'features/topics/TopicForm/utils/convertTopicFormData';
import { TopicForm } from 'features/topics/TopicForm';
import { useTranslation } from 'react-i18next';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  topic: ITopic;
  companyId: string;
}

const EditTopic = ({ topic, companyId }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, credits, companyID, ...defaultData } = topic;
  const navigation = useNavigate();
  const { isLoading, mutateAsync } = useUpdateTopic(_id, companyId);
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();
  const { deleteTopicPrivilege } = LayoutPrivilege();

  const viewPath = routeBuilder.topics.view.getPath(_id);

  const { data: activityData } = useGetActivities(companyId, undefined, {
    useErrorBoundary: false,
  });

  const handleSubmit = async (
    data: TopicFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(convertTopicFormData(data));
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.topics.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('topic') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('topic') }),
      });
    }
  };

  return (
    <TopicForm
      companyId={companyId}
      defaultValues={{
        ...defaultData,
        activities:
          activityData &&
          activityData?.data.data
            .filter((activity) => activity.topicIDs?.includes(topic._id))
            .reduce((acc, topicId) => {
              return {
                ...acc,
                [topicId._id]: { _id: topicId._id, value: true },
              };
            }, {}),
      }}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formId={`edit-topic-form-${_id}-${companyId}`}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          {topic.active && deleteTopicPrivilege && (
            <DeleteTopic
              companyId={companyId}
              topicId={_id}
            />
          )}
        </>
      }
    />
  );
};

export { EditTopic };
