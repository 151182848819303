import { IDepartment } from 'entities/company/model';
import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  departments?: Partial<IDepartment[]>;
}

const DepartmentsViewRow = ({ departments }: IProps) => {
  const { t } = useTranslation('commonFields');
  const departmentName = departments?.map((department) =>
    department?.name ? department.name : '-',
  );
  return (
    <ViewRow label={t('Departments')}>
      <Text>
        {departmentName && departmentName.length > 0 ? departmentName : '-'}
      </Text>
    </ViewRow>
  );
};

export { DepartmentsViewRow };
