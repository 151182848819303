import { NotificationRadioGroup } from 'features/person/PersonnelForm/NotificationRadioGroup';
import { useTranslation } from 'react-i18next';

import { RadioProps } from 'shared/components';
import { FormField } from 'shared/components/Form';

interface IProps extends RadioProps {
  contactPreferences: { phone: boolean; email: boolean };
}
const NotificationField = ({ contactPreferences }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Notifications')}:`}>
      <NotificationRadioGroup contactPreferences={contactPreferences} />
    </FormField>
  );
};

export { NotificationField };
