/* eslint-disable no-console */
import { ChangeEvent, MutableRefObject, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Box, Input, InputGroup, InputRightElement } from 'shared/components';
import { CloseIcon, TimeIcon } from 'shared/icons';
import { DateObject, DateObjectType } from 'shared/utils';
import { TimeContainer } from './TimeContainer';
import { useBreakpointValue } from '../../hooks/chakraUi';
import { format, isValid } from 'date-fns';
import parse from 'date-fns/parse';

interface IProps {
  onChange?: (date: string | undefined) => void;
  time?: string;
}

const parseTime = (time?: string) => {
  let dateObject;
  try {
    dateObject = DateObject(time || new Date());
  } catch($e) { dateObject = DateObject(); }
  const formatedTime = dateObject.format('hh:mm a').toString();
  return { dateObject, formatedTime };
};
const TimePicker = ({ onChange, time }: IProps) => {
  const [open, setIsOpen] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const { dateObject, formatedTime } = parseTime(time);

  const [value, setValue] = useState(formatedTime);
  const [date, setDate] = useState<DateObjectType>(dateObject);

  const [isValueValid, setValueValidity] = useState(true);
  const containerRef = useRef(null);
  const height = useBreakpointValue({ base: '38px', md: '46px' });
  const marginTop = useBreakpointValue({ base: '7px', md: '10px' });


  useEffect(() => {
    const { dateObject, formatedTime } = parseTime(time);
      setValue(formatedTime);
      setDate(dateObject);
  }, [time]);

  useDetectOutsideComponent(containerRef, () => setIsOpen(false));

  const selectDatePart = (caretPosition: number) => {
    let selectionStart;
    if(caretPosition <= 2) {
      selectionStart = 0;
    } else if(caretPosition <= 5) {
      selectionStart = 3;
    } else {
      selectionStart = 6;
    }
    input.current?.setSelectionRange(selectionStart, selectionStart+2);
  };

  const handleManualChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if(value.match(/[^0-9:\sAPM]/i)) return;
    const d = parse(value, 'hh:mm aa', new Date());
    const isValidDate = isValid(d);
    setValue(e.target.value);
    if (open) setIsOpen(false);
    if (isValidDate && value.match(/^([0-9]{2}:[0-9]{2}\s(A|P)M)/)) {
      setDate(DateObject(d));
      setValueValidity(true);
      const caretPosition = input.current?.selectionEnd || 0;
      if(caretPosition <= 6 && isValidDate) { selectDatePart(caretPosition + 2); }
    } else {
      setValueValidity(false);
    }

  };

  useEffect(() => {
    if(input.current !== document.activeElement) setValue(date?.format('hh:mm a') || formatedTime);
    onChange && onChange(date?.getDate().concat(' ', date.getTime()));
  }, [date?.getTime(), input.current]);

  const handleCursor = (e: SyntheticEvent) => {
    const ref = input.current;
    if(!ref || !isValueValid) return;

    const caretPosition = ref.selectionEnd || 0;
    if(e.type === 'click') {
      selectDatePart(caretPosition);
    }
  };
  return (
    <Box
      w="100%"
      position="relative"
      ref={containerRef}
    >
      <InputGroup
        zIndex="0"
        cursor="pointer"
      >
        <Input
          value={value || formatedTime}
          isInvalid={!isValueValid}
          onChange={handleManualChange}
          onClick={handleCursor}
          onSelect={handleCursor}
          ref={input}
          minH={height}
          fontSize={{ base: '1.6rem', md: '1.8rem' }}
        />
        <InputRightElement
          width="60px"
          gap="5px"
          marginTop={marginTop}
        >
          <CloseIcon
            visibility={date ? 'visible' : 'hidden'}
            onClick={() => {
              setIsOpen(false);
              setDate(DateObject(new Date()));
            }}
            width="12px"
            height="12px"
          />
          <TimeIcon onClick={() => setIsOpen((prevState) => !prevState)} />
        </InputRightElement>
      </InputGroup>
      {open && (
        <TimeContainer
          date={date}
          onChange={setDate}
        />
      )}
    </Box>
  );
};

export { TimePicker };

function useDetectOutsideComponent(
  // eslint-disable-next-line
  ref: MutableRefObject<any>,
  onClickOutside: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current?.contains(event.target)) {
        onClickOutside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
