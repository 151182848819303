import { Box } from 'shared/components';
import { TableSortChevronUp } from 'shared/icons';

export const TableSortChevronDown = () => {
  return (
    <Box transform={'rotate(180deg)'}>
      <TableSortChevronUp />
    </Box>
  );
};
