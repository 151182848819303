import { Icon, IconProps } from 'shared/components';

export const UploadIcon = (props: IconProps) => (
  <Icon
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0001 2.53125C8.29257 2.53125 2.83545 8.1248 2.83545 15C2.83545 21.8752 8.29257 27.4688 15.0001 27.4688C21.7076 27.4688 27.1647 21.8752 27.1647 15C27.1647 8.1248 21.7076 2.53125 15.0001 2.53125ZM19.6788 15.9591H15.9358V19.7957C15.9358 20.0501 15.8372 20.294 15.6618 20.4739C15.4863 20.6538 15.2483 20.7548 15.0001 20.7548C14.7519 20.7548 14.5139 20.6538 14.3384 20.4739C14.1629 20.294 14.0643 20.0501 14.0643 19.7957V15.9591H10.3214C10.0732 15.9591 9.8352 15.8581 9.65971 15.6782C9.48422 15.4983 9.38564 15.2544 9.38564 15C9.38564 14.7456 9.48422 14.5017 9.65971 14.3218C9.8352 14.1419 10.0732 14.0409 10.3214 14.0409H14.0643V10.2043C14.0643 9.94995 14.1629 9.70599 14.3384 9.52612C14.5139 9.34625 14.7519 9.24519 15.0001 9.24519C15.2483 9.24519 15.4863 9.34625 15.6618 9.52612C15.8372 9.70599 15.9358 9.94995 15.9358 10.2043V14.0409H19.6788C19.927 14.0409 20.165 14.1419 20.3405 14.3218C20.5159 14.5017 20.6145 14.7456 20.6145 15C20.6145 15.2544 20.5159 15.4983 20.3405 15.6782C20.165 15.8581 19.927 15.9591 19.6788 15.9591Z"
      fill="#72B42D"
    />
    <path
      d="M19.6788 15.9591H15.9358V19.7957C15.9358 20.0501 15.8372 20.294 15.6618 20.4739C15.4863 20.6538 15.2483 20.7548 15.0001 20.7548C14.7519 20.7548 14.5139 20.6538 14.3384 20.4739C14.1629 20.294 14.0643 20.0501 14.0643 19.7957V15.9591H10.3214C10.0732 15.9591 9.8352 15.8581 9.65971 15.6782C9.48422 15.4983 9.38564 15.2544 9.38564 15C9.38564 14.7456 9.48422 14.5017 9.65971 14.3218C9.8352 14.1419 10.0732 14.0409 10.3214 14.0409H14.0643V10.2043C14.0643 9.94995 14.1629 9.70599 14.3384 9.52612C14.5139 9.34625 14.7519 9.24519 15.0001 9.24519C15.2483 9.24519 15.4863 9.34625 15.6618 9.52612C15.8372 9.70599 15.9358 9.94995 15.9358 10.2043V14.0409H19.6788C19.927 14.0409 20.165 14.1419 20.3405 14.3218C20.5159 14.5017 20.6145 14.7456 20.6145 15C20.6145 15.2544 20.5159 15.4983 20.3405 15.6782C20.165 15.8581 19.927 15.9591 19.6788 15.9591Z"
      fill="#fff"
    />
  </Icon>
);
