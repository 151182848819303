import { Text, TextProps } from 'shared/components';

export const TableText = (textProps: TextProps) => {
  return (
    <Text
      whiteSpace="normal"
      textTransform="initial"
      lineHeight="initial"
      {...textProps}
    />
  );
};
