import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';

import { LocationView } from 'features/location/LocationView';

import { useGetLocation } from 'entities/company/api/useGetLocation';

import { LocationActionButtons } from 'widgets/LocationActionButtons';
import { CreationDetails } from 'features/CreationDetails';

const ViewLocationPageThrowable = () => {
  const { t } = useTranslation(['departments', 'common']);
  const { locationId } = useParams();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  if (!locationId) {
    throw new Error('UserId not found');
  }

  const { isLoading, error, data } = useGetLocation(companyId, locationId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Location not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <LocationView location={data.data} />
      <LocationActionButtons
        locationId={locationId}
        companyId={companyId}
        active={data.data.active}
      />
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewLocationPage = () => {
  return (
    <ErrorBoundary>
      <ViewLocationPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewLocationPage };
