import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Flex } from 'shared/components';
import { useForm } from 'shared/components/Form';
import { useResetPassword } from 'entities/user/api/useResetPassword';
import { useResetPasswordFormValidationSchema } from './useResetPasswordFormValidationSchema';
import { ResetPasswordFormField } from './ResetPasswordFormField';
import { SubmitResetPasswordFormButton } from './SubmitResetPasswordFormButton';

interface IProps {
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
  setIsSpinnerVisible: (value: boolean) => void;
  code: string;
  aid: string;
}

interface IFormModel {
  password: string;
  repeatPassword: string;
}

const ResetPasswordForm = ({
  onError,
  onSuccess,
  setIsSpinnerVisible,
  code,
  aid,
}: IProps) => {
  const { t } = useTranslation('resetPasswordForm');
  const validationSchema = useResetPasswordFormValidationSchema();
  const mutation = useResetPassword();
  const formId = 'reset-password-form';

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues: { password: '', repeatPassword: '' },
    formId,
  });

  const handleSubmit = async (model: IFormModel) => {
    setIsSpinnerVisible(true);
    try {
      await mutation.mutateAsync({
        password: model.password,
        code,
        aid,
      });
      cleanPersistentStore();
      onSuccess && onSuccess();
    } catch (e) {
      onError && onError(e);
    } finally {
      setIsSpinnerVisible(false);
    }
  };

  useEffect(() => {
    cleanPersistentStore();
  }, []);

  return (
    <Form<IFormModel>
      id={formId}
      onSubmit={async (event) => {
        await handleSubmit(event);
      }}
      methods={methods}
    >
      <Flex
        flexDir="column"
        gap="25px"
      >
        <ResetPasswordFormField
          inputProps={{
            name: 'password',
            placeholder: t('Enter password here'),
            type: 'password',
          }}
          label={t('Password')}
        />
        <ResetPasswordFormField
          inputProps={{
            name: 'repeatPassword',
            placeholder: t('Repeat password here'),
            type: 'password',
          }}
          label={t('Repeat password')}
        />
        <SubmitResetPasswordFormButton />
      </Flex>
    </Form>
  );
};

export { ResetPasswordForm };
