import { useBreakpointValue } from 'shared/components';

export const useContainerProps = () => {
  const formGap = useBreakpointValue({ base: '10px', md: '15px', lg: '20px' });

  return {
    display: 'grid',
    gap: formGap,
    maxW: {
      md: '100%',
    },
    padding: { sm: '5px', md: '15px', xl: '30px' },
    borderRadius: '20px',
    borderWidth: { md: '2px' },
    borderColor: 'secondary.500',
  };
};
