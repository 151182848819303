import { Dispatch, SetStateAction } from 'react';

import { Text, VStack } from 'shared/components';
import { TimepickerChevronIcon } from 'shared/icons';

import { DateObject, DateObjectType } from 'shared/utils';

interface IProps {
  time: DateObjectType;
  setTime: Dispatch<SetStateAction<DateObjectType>>;
}
const BeforeAfterMidnightBox = ({
  time = DateObject(new Date()),
  setTime,
}: IProps) => {
  const increaseHour = () => {
    setTime(time.increaseHours(12));
  };

  const decreaseHour = () => {
    setTime(time.decreaseHours(12));
  };

  return (
    <VStack>
      <TimepickerChevronIcon
        orientation="up"
        cursor="pointer"
        onClick={increaseHour}
      />
      <Text size="xs">{time?.format('a')}</Text>
      <TimepickerChevronIcon
        orientation="down"
        cursor="pointer"
        onClick={decreaseHour}
      />
    </VStack>
  );
};

export { BeforeAfterMidnightBox };
