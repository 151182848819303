import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { ErrorBoundary, Select, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';

interface IProps {
  name?: string;
}

type OptionType = { value: string; label: string };

const defaultName = 'language';

const LanguageFieldThrowable = ({ name = defaultName }: IProps) => {
  const { t } = useTranslation('commonFields');

  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const options = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
  ];

  const value = watch(name) || 'en';

  return (
    <FormField label={`${t('Report Language')}:`}>
      <Select
        data-testid={name}
        inputId={name}
        isDisabled={isSubmitting}
        options={options}
        isSearchable={false}
        value={options?.find(
          (option) => (option as OptionType).value === value,
        )}
        onChange={(value) => setValue(name, (value as OptionType).value)}
        defaultValue={options[0]}
      />
    </FormField>
  );
};

const LanguageField = ({ name }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Report Language')}:`}>
          <SelectField name={name || defaultName} />
        </FormField>
      )}
    >
      <LanguageFieldThrowable name={name} />
    </ErrorBoundary>
  );
};

export { LanguageField };
