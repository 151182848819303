import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  ResponsiveHeadTableRow,
  Table,
  TableBody,
  TableBodyCell,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import { IQueryParams, ISort } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';

import { useGetCompanies } from 'entities/company/api';

import { useCompaniesColumnsConfiguration } from './utils';
import { CompaniesTableRow } from './CompaniesTableRow';
import { useBreakpointValue } from '@chakra-ui/react';

const useCompaniesTableState = createTableStore();

interface IProps {
  setOffset: (value: number) => void;
  onSortChange: (sort: ISort[]) => void;
  queryParams: IQueryParams;
  showInactive: boolean;
  isSuperAdmin: boolean;
}

const CompaniesTable = ({
  setOffset,
  onSortChange,
  queryParams: { limit = 10, offset = 0, ...restQueryParams },
  showInactive,
  isSuperAdmin,
}: IProps) => {
  const columnsConfiguration = useCompaniesColumnsConfiguration();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);
  const tableState = useCompaniesTableState((state) => state, shallow);

  const filterActive = !showInactive ? 'true' : '';
  const fieldsArray = memoizedColumnsConfiguration
    .map((columnConfiguration) => columnConfiguration.sortedFields)
    .flat();
  fieldsArray.pop();

  const { data, isLoading } = useGetCompanies(
    {
      ...restQueryParams,
      offset: offset,
      limit: limit,
      filters: restQueryParams.filters ? restQueryParams.filters : filterActive,
      fields: !restQueryParams.filters
        ? memoizedColumnsConfiguration
            .map((columnConfiguration) => columnConfiguration.sortedFields)
            .flat()
        : fieldsArray,
    },
    {
      useErrorBoundary: false,
    },
  );

  useEffect(() => {
    if (
      (data && data?.data.total <= 10) ||
      (data && offset / limit + 1 > Math.ceil(data?.data.total / limit))
    ) {
      setOffset(0);
    }
  }, [data?.data.total]);

  return isSuperAdmin ? (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <ResponsiveHeadTableRow
        isMobile={isMobile}
        columnsConfiguration={columnsConfiguration}
      />
      <TableBody isLoading={isLoading}>
        {data && data.data.total > 0 ? (
          <>
            {data.data.data.map((company) => {
              return (
                <CompaniesTableRow
                  key={company._id}
                  company={company}
                  isMobile={isMobile}
                />
              );
            })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={data?.data.total || 0}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                  itemsPerPage={limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  ) : null;
};

export { CompaniesTable };
