import { useNavigate } from 'react-router-dom';
import {
  TableBodyCell,
  TableBodyInfoCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { useTranslation } from 'react-i18next';
import { DateObject, routeBuilder } from 'shared/utils';
import { ITracking } from 'entities/tracking/model';
import { TopicTableCell } from './TopicTableCell';
import { AttendeeTableCell } from 'features/activites/AttendeeTableCell.tsx';
import { Text } from '@chakra-ui/react';

interface IProps {
  tracking: ITracking;
  isMobile?: boolean;
}
const TrackingTableRow = ({ tracking, isMobile }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(routeBuilder.tracking.view.getPath(tracking._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      {isMobile ? (
        <>
          <TableBodyCell>
            <TableCellText>
              {DateObject(tracking.trackedAt?.utc).getDate()}
            </TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{tracking.location?.name || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyInfoCell tracking={tracking} />
        </>
      ) : (
        <>
          <TableBodyCell>
            <TableCellText>
              {DateObject(tracking.trackedAt?.utc).getDate()}
            </TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{tracking.location?.name || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{tracking.incidentLocation || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{tracking.department?.name || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{tracking.activity?.name || '-'}</TableCellText>
          </TableBodyCell>
          <TopicTableCell topic={tracking.topics} />
          <TableBodyCell>
            {tracking.trackedBy?.name.firstname ||
            tracking.trackedBy?.name.lastname ? (
              <TableCellText>
                {`${tracking.trackedBy?.name.firstname || ''} ${
                  tracking.trackedBy?.name.lastname || ''
                } `}
              </TableCellText>
            ) : (
              <Text>-</Text>
            )}
          </TableBodyCell>
          <AttendeeTableCell attendee={tracking.attendees} />
          <TableBodyCell>
            <TableCellText>
              {tracking.violation?.safetyViolation ? t('Yes') : t('No')}
            </TableCellText>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  );
};

export { TrackingTableRow };
