import { Flex } from 'shared/components';
import { ITopic } from 'entities/tracking/model';
import {
  ActiveViewRow,
  DescriptionViewRow,
  NameViewRow,
} from 'features/ViewCommonFields';
import { ActivitiesViewRow } from 'features/ViewCommonFields/ActivitiesViewRow';

interface IProps {
  topic: ITopic;
}

const TopicView = ({ topic }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <ActiveViewRow active={topic.active} />
      <NameViewRow name={topic.name} />
      <DescriptionViewRow description={topic.description} />
      <ActivitiesViewRow topicId={topic._id} />
    </Flex>
  );
};

export { TopicView };
