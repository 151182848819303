import { IUser, IUserMe } from 'entities/user/model';

import { PersonView } from 'pages/Company/Personnel/PersonView';
import { PersonActionButtons } from 'features/person/PersonActionButtons';
import { CreationDetails } from 'features/CreationDetails';

import { ViewPageWrapper } from 'shared/components';

interface IProps {
  person: IUserMe | IUser;
  companyId: string;
}

const PersonViewContainer = ({ person, companyId }: IProps) => {
  return (
    <ViewPageWrapper>
      <PersonView person={person} />
      <PersonActionButtons
        userId={person._id}
        active={person.active}
        companyId={companyId}
      />
      {person?.credits && <CreationDetails dates={person.credits} />}
    </ViewPageWrapper>
  );
};

export { PersonViewContainer };
