import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ILoginFormModel } from 'entities/user/model';
import { useLoginUser } from 'entities/user/api';

import { Form, Button, Flex } from 'shared/components';
import { LoginFormField } from './LoginFormField';
import { useLoginFormValidationSchema } from './useLoginFormValidationSchema';
import { SubmitLoginFormButton } from './SubmitLoginFormButton';
import { useForm } from '../../shared/components/Form';
import { routeBuilder } from '../../shared/utils';

interface IProps {
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
}

const LoginForm = ({ onError, onSuccess }: IProps) => {
  const { t } = useTranslation('loginForm');
  const validationSchema = useLoginFormValidationSchema();
  const mutation = useLoginUser();
  const navigate = useNavigate();

  const formId = 'login-form';

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues: { company: '', login: '', password: '' },
    formId,
    persistenceProps: {
      exclude: ['password'],
    },
  });

  const handleSubmit = async (model: ILoginFormModel) => {
    try {
      await mutation.mutateAsync(model);
      cleanPersistentStore();
      onSuccess && onSuccess();
    } catch (e) {
      onError && onError(e);
    }
  };

  useEffect(() => {
    cleanPersistentStore();
  }, []);

  return (
    <Form<ILoginFormModel>
      id={formId}
      onSubmit={handleSubmit}
      methods={methods}
    >
      <Flex
        flexDir="column"
        gap="25px"
      >
        <LoginFormField
          inputProps={{
            name: 'company',
            placeholder: t('Enter company ID here'),
            autoCapitalize: 'off'
          }}
          label={t('Company ID')}
        />
        <LoginFormField
          inputProps={{
            name: 'login',
            placeholder: t('Enter login ID here'),
            autoCapitalize: 'off'
          }}
          label={t('Login ID')}
        />
        <LoginFormField
          inputProps={{
            name: 'password',
            placeholder: t('Enter password here'),
            type: 'password',
          }}
          label={t('Password')}
        />
        <SubmitLoginFormButton />
        <Button
          variant="outline"
          mt="10px"
          onClick={() => {
            navigate(routeBuilder.forgotPassword.index.getPath());
          }}
        >
          {t('Forgot your password?')}
        </Button>
      </Flex>
    </Form>
  );
};

export { LoginForm };
