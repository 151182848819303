import {
  Box,
  ISelectOption,
  ReactSelectProps,
  Select,
} from 'shared/components/index';
import { timezonesList } from 'shared/utils';
import { useTranslation } from 'react-i18next';

interface IProps extends Omit<ReactSelectProps, 'onChange' | 'value'> {
  onChange: (value: ISelectOption | null) => void;
  zone?: string | null;
}

const ZoneSelect = ({ onChange, zone, ...restProps }: IProps) => {
  const { t } = useTranslation('commonFields');
  const options = timezonesList.map((timezone) => {
    return {
      value: timezone.timezone,
      label: timezone.name,
    };
  });

  return (
    <Box
      w="100%"
      sx={{
        'div[class$="control"]': {
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderLeftWidth: '1px',
        },
      }}
    >
      <Select
        placeholder={t('Time Zone')}
        options={options}
        onChange={(newValue) => {
          const selectedOption = newValue as ISelectOption;
          if (!selectedOption.value) {
            onChange(null);
            return;
          }
          onChange(newValue as ISelectOption);
        }}
        value={options.find((option) => option.value === zone)}
        {...restProps}
      />
    </Box>
  );
};

export { ZoneSelect };
