import { HStack } from 'shared/components';
import { BreadcrumbHomeIcon } from 'shared/icons';

import { BreadcrumbTranslation } from './BreadcrumbTranslation';
import { routeBuilder } from 'shared/utils';

export const routes = [
  {
    path: routeBuilder.home.index.getPath(),
    breadcrumb: () => (
      <HStack
        gap="8px"
        sx={{ p: { marginInlineStart: '0px !important' } }}
      >
        <BreadcrumbHomeIcon size={20} />
        <BreadcrumbTranslation
          translationKey={routeBuilder.home.index.getLabel()}
        />
      </HStack>
    ),
  },
  {
    path: routeBuilder.signIn.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.signIn.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.profile.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.profile.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.company.index.getPath(),
    breadcrumb: () => <BreadcrumbTranslation translationKey="Company" />,
  },
  {
    path: routeBuilder.company.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.company.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.company.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.company.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.company.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.company.edit.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.personnel.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.personnel.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.personnel.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.personnel.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.personnel.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.personnel.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.personnel.changePassword.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.personnel.changePassword.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.personnel.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.personnel.edit.getLabel()}
      />
    ),
  },

  {
    path: routeBuilder.roles.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.roles.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.roles.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.roles.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.roles.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.roles.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.roles.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.roles.edit.getLabel()}
      />
    ),
  },

  {
    path: routeBuilder.locations.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.locations.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.locations.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.locations.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.locations.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.locations.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.locations.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.locations.edit.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.departments.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.departments.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.departments.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.departments.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.departments.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.departments.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.departments.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.departments.edit.getLabel()}
      />
    ),
  },

  {
    path: routeBuilder.tracking.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.tracking.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.tracking.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.tracking.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.tracking.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.tracking.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.tracking.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.tracking.edit.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.report.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.report.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.activities.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.activities.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.activities.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.activities.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.activities.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.activities.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.activities.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.activities.edit.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.actions.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.actions.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.actions.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.actions.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.actions.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.actions.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.actions.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.actions.edit.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.topics.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.topics.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.topics.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.topics.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.topics.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.topics.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.topics.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.topics.edit.getLabel()}
      />
    ),
  },

  {
    path: routeBuilder.customer.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.customer.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.customer.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.customer.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.customer.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.customer.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.customer.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.customer.edit.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.vendor.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.vendor.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.vendor.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.vendor.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.vendor.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.vendor.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.vendor.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.vendor.edit.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.agency.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.agency.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.agency.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.agency.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.agency.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.agency.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.agency.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.agency.edit.getLabel()}
      />
    ),
  },

  {
    path: routeBuilder.regulation.index.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.regulation.index.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.regulation.add.getPath(),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.regulation.add.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.regulation.view.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.regulation.view.getLabel()}
      />
    ),
  },
  {
    path: routeBuilder.regulation.edit.getPath(''),
    breadcrumb: () => (
      <BreadcrumbTranslation
        translationKey={routeBuilder.regulation.edit.getLabel()}
      />
    ),
  },
];
