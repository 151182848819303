import { UseQueryOptions } from '@tanstack/react-query';

import { IUser } from 'entities/user/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { useGetParsedQueryParams } from 'shared/utils';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { IQueryParams } from 'shared/types';
import { IAttendeeListReduce } from '../../tracking/model';

interface IUsersQueryParams extends IQueryParams {
  searchFields?: IAttendeeListReduce;
}

export const useGetAuthorityUsers = (
  companyId: string,
  query?: IUsersQueryParams,
  options?: UseQueryOptions<
    AxiosResponse<Pick<IUser, '_id' | 'name' | 'roleIDs' | 'roleNames' | 'active'>[]>,
    AxiosError
  >,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);

  const finalQuery = `companyID=${companyId}${
    parsedQueryParams ? '&' : ''
  }${parsedQueryParams}`;

  return useReactQuery<Pick<IUser, '_id' | 'name' | 'roleIDs' | 'roleNames' | 'active'>[]>(
    getAuthorityUsersKey(finalQuery),
    () => getAuthorityUsers(companyId, finalQuery),
    options,
  );
};

const getAuthorityUsers = (companyId?: string, queryParams?: string) => {
  return httpClient.get<
    Pick<IUser, '_id' | 'name' | 'roleIDs' | 'roleNames' | 'active'>[]
  >(buildUrl('users/authorities', queryParams));
};

const getAuthorityUsersKey = (queryParams?: string) => [
  'usersAuthority',
  queryParams,
];
