import { useTranslation } from 'react-i18next';

import { useBreakpointValue } from 'shared/hooks/chakraUi';
import { Privileges } from 'entities/role/model';
import { Image, Text, Flex } from 'shared/components';
import { MobileMenu } from 'features/MobileMenu';
import omnis_logo from '../../assets/omnistools_logo.png';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

const MobileNavigationBar = ({
  isLoggedIn,
  activePrivileges,
  components = [],
}: {
  isLoggedIn: boolean;
  activePrivileges: Privileges[];
  components?: ICompanyComponent[];
}) => {
  const { t } = useTranslation();
  const showBrandText = useBreakpointValue({
    base: false,
    md: true,
    lg: false,
  });
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      px="20px"
      pt="10px"
      pb={{ base: '10px', sm: '15px' }}
      gap="10px"
    >
      <Image
        src={omnis_logo}
        alt={t('OmnisTools logo')}
        w="auto"
        h="23px"
      />
      {showBrandText && (
        <Text
          size="sm"
          color="primary.500"
        >
          {t('OmnisTools Efficiency Tracking Software')}
        </Text>
      )}

      <MobileMenu
        isLoggedIn={isLoggedIn}
        activePrivileges={activePrivileges}
        components={components}
      />
    </Flex>
  );
};

export { MobileNavigationBar };
