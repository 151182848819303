import { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';
import { useTheme } from '@chakra-ui/react';

export const useDropdownIndicatorStyle = (provided: CSSObjectWithLabel) => {
  const { colors } = useTheme();

  return {
    ...provided,
    color: colors.grey[800],
  };
};
