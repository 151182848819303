import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme, Menu, MenuList, MenuButton, Flex } from '@chakra-ui/react';

import { Privileges } from 'entities/role/model';
import { Box, Center } from 'shared/components';
import useStore from 'shared/store';
import { ChevronNavMenuIcon } from 'shared/icons';
import { NavItemText } from '../NavItemText';
import { NavMenuItem } from '../NavMenuItem';
import { NavMenuDivider } from '../NavMenuDivider';
import { navItemStyles } from '../navItemStyles';
import { NavMenuItemText } from '../NavMenuItemText';
import { SubItemMenu } from '../SubItemMenu';

export type NavSubItem = {
  rootLabel?: string;
  key: string;
  path: string;
  label: string;
  allowedPrivileges: Privileges[];
};

export type NavItemMenuProps = {
  label: string;
  menuItems: NavSubItem[];
  mainKey?: string;
};

export const NavItemMenu = ({
  label,
  menuItems,
  mainKey,
}: NavItemMenuProps) => {
  const theme = useTheme();
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const privileges = useStore((state) => state.privileges);

  const [openedSubItemMenu, setOpenedSubItemMenu] = useState<string | false>(
    false,
  );

  const subMenuItems = menuItems
    .filter((item) => {
      if (item.allowedPrivileges.length === 0) return true;

      return privileges.find((activePrivilege) =>
        item.allowedPrivileges.includes(activePrivilege),
      );
    })
    .reduce((acc: { [index: string]: NavSubItem[] }, item) => {
      if (acc[item.key]) {
        acc[item.key] = [...acc[item.key], item];
      } else {
        acc[item.key] = [item];
      }

      return acc;
    }, {});

  return (
    <Center position="relative">
      <Menu>
        {({ isOpen }) => (
          <>
            <MenuButton
              {...navItemStyles}
              {...(isOpen && {
                borderRadius: '20px',
                backgroundColor: 'secondary.500',
                _active: { border: '2px solid transparent' },
              })}
            >
              <Center>
                <NavItemText>{label}</NavItemText>
                <Box marginLeft={'6px'}>
                  <ChevronNavMenuIcon isOpen={isOpen} />
                </Box>
              </Center>
            </MenuButton>
            <MenuList
              marginTop="-5px"
              padding={0}
              overflow="hidden"
              backgroundColor="primary.500"
              border={`2px solid ${theme.colors.secondary['500']}`}
              boxShadow={`0px 2px 5px rgba(0, 0, 0, 0.06),
             0px 4px 6px -1px rgba(0, 0, 0, 0.1)`}
              borderRadius={'20px'}
              minW="fit-content"
            >
              {Object.entries(subMenuItems).map(([index, value], i, arr) => {
                const isNotLast = i !== arr.length - 1;

                if (index === mainKey) {
                  return value.map((item, j) => (
                    <Fragment key={`${index}-${j}`}>
                      <Link to={item.path}>
                        <NavMenuItem
                          key={item.label}
                          isLoggedIn={isLoggedIn}
                        >
                          {item.label}
                        </NavMenuItem>
                      </Link>
                      {isNotLast && <NavMenuDivider />}
                    </Fragment>
                  ));
                } else {
                  return (
                    <Fragment key={`${index}-${i}`}>
                      <Flex onMouseLeave={() => setOpenedSubItemMenu(false)}>
                        <Flex
                          justifyContent="space-between"
                          padding={{
                            base: '12px 4px 12px 12px',
                            xl: '12px 16px',
                          }}
                          width="100%"
                          cursor="pointer"
                          backgroundColor={'primary.500'}
                          _hover={{ backgroundColor: 'primary.700' }}
                          _focus={{ backgroundColor: 'primary.700' }}
                          onMouseOver={() =>
                            setOpenedSubItemMenu((prev) => {
                              if (prev === index) {
                                return prev;
                              }

                              return index;
                            })
                          }
                        >
                          <Box>
                            <NavMenuItemText
                              isLoggedIn={isLoggedIn}
                              navTextProps={{ size: { base: 'xs', xl: 'sm' } }}
                            >
                              {value[0].rootLabel || value[0].label}
                            </NavMenuItemText>
                          </Box>
                          <Box>
                            <ChevronNavMenuIcon
                              isSubItem
                              marginLeft="2px"
                              boxSize="3em"
                              isSubItemOpen={openedSubItemMenu === index}
                            />
                          </Box>
                        </Flex>
                        {openedSubItemMenu === index && (
                          <SubItemMenu
                            isLoggedIn={isLoggedIn}
                            items={value}
                          />
                        )}
                      </Flex>
                      {isNotLast && <NavMenuDivider />}
                    </Fragment>
                  );
                }
              })}
            </MenuList>
          </>
        )}
      </Menu>
    </Center>
  );
};
