import { useTranslation } from 'react-i18next';

import { useGetCustomers } from 'entities/customer/api';

import { ErrorBoundary, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';

interface IProps {
  companyId: string;
  name?: string;
}

const CustomersFieldThrowable = ({
  companyId,
  name = 'customers[0]',
}: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetCustomers(
    companyId,
    {
      sort: [{ name: 'name', sortDirection: 'asc' }],
    },
    { useErrorBoundary: false },
  );

  const options = [
    { value: null, label: t('By Customer') },
    ...(data
      ? data.data.data.map((customer) => {
          return {
            value: customer._id,
            label: customer.name,
          };
        })
      : []),
  ];

  return (
    <FormField label={`${t('Customers')}:`}>
      <SelectField
        name={name}
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
      />
    </FormField>
  );
};

const CustomersField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Customers')}:`}>
          <SelectField
            name="customers"
            isDisabled
          />
        </FormField>
      )}
    >
      <CustomersFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { CustomersField };
