import { useTranslation } from 'react-i18next';

import { Button, FlexWrapper } from 'shared/components';
import { PdfIcon, PrinterIcon, XlsIcon } from 'shared/icons';
import { useFormContext } from 'react-hook-form';
import { useHandleDownloadReport, useHandleOpenFileInNewTab } from './utils';
import { ReportReportFileFormat } from './utils/useHandleDownloadReport';
import { useComputeAdvancedSearchQuery } from '../tracking/utils';
import { buildUrl } from 'shared/utils/httpClient';

interface IProps {
  companyId: string;
}

const ActionButtons = ({ companyId }: IProps) => {
  const { t } = useTranslation('common');
  const { watch } = useFormContext();
  const fileUrl = buildUrl(
    'trackings/report',
    useComputeAdvancedSearchQuery(companyId, watch()),
  );
  const excelFileUrl = buildUrl(
    'trackings',
    useComputeAdvancedSearchQuery(companyId, watch()),
  );
  const handleDownloadReportFile = useHandleDownloadReport();
  const handleOpenFileInNewTab = useHandleOpenFileInNewTab();

  return (
    <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
      <Button
        leftIcon={
          <XlsIcon
            width="25px"
            height="auto"
            bgColor="transparent"
          />
        }
        mt={4}
        onClick={() =>
          handleDownloadReportFile(excelFileUrl, ReportReportFileFormat.XLSX)
        }
      >
        Excel
      </Button>

      <Button
        leftIcon={
          <PdfIcon
            width="25px"
            height="auto"
            bgColor="transparent"
          />
        }
        mt={4}
        onClick={() =>
          handleDownloadReportFile(fileUrl, ReportReportFileFormat.PDF)
        }
      >
        PDF
      </Button>

      <Button
        leftIcon={
          <PrinterIcon
            width="25px"
            height="auto"
          />
        }
        mt={4}
        onClick={() =>
          // handleOpenFileInNewTab(fileUrl, ReportReportFileFormat.PDF)
          handleOpenFileInNewTab(fileUrl)
        }
      >
        {t('Print Preview')}
      </Button>
    </FlexWrapper>
  );
};

export { ActionButtons };
