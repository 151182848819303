import { Icon, IconProps } from '../../components';

export const SearchIcon = ({ fill = '#0F0F10', ...restProps }: IconProps) => {
  return (
    <Icon
      width="24px"
      height="24px"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      {...restProps}
    >
      <path d="m21.407 19.753-4.41-4.41a8.148 8.148 0 0 0 1.633-4.903c0-4.516-3.674-8.19-8.19-8.19s-8.19 3.674-8.19 8.19 3.674 8.19 8.19 8.19a8.148 8.148 0 0 0 4.902-1.633l4.41 4.41a1.171 1.171 0 0 0 1.655-1.654ZM4.59 10.44a5.85 5.85 0 1 1 5.85 5.85 5.857 5.857 0 0 1-5.85-5.85Z" />
    </Icon>
  );
};
