import { Dispatch, SetStateAction } from 'react';
import { FormControl, FormLabel, Switch } from 'shared/components';

interface IProps {
  label: string;
  switchId: string;
  setShowInactive: Dispatch<SetStateAction<boolean>>;
}

const SwitchInactive = ({ label, switchId, setShowInactive }: IProps) => {
  const toggleSwitchInactive = () => setShowInactive((current) => !current);
  return (
    <FormControl
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <FormLabel
        htmlFor="email-alerts"
        mb="0"
      >
        {label}
      </FormLabel>
      <Switch
        id={switchId}
        size={'lg'}
        onChange={toggleSwitchInactive}
      />
    </FormControl>
  );
};

export { SwitchInactive };
