import { object, string, ref } from 'yup';
import { useTranslation } from 'react-i18next';

export const useResetPasswordFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');
  const passwordMatchMessage = t('Passwords must match');

  return object({
    password: string().required(requiredFieldMessage),
    repeatPassword: string()
      .required(requiredFieldMessage)
      .oneOf([ref('password'), null], passwordMatchMessage),
  });
};
