import { UseQueryOptions } from '@tanstack/react-query';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { useGetParsedQueryParams } from 'shared/utils';

import { ICustomer } from 'entities/customer/model';

export const useGetCustomers = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<ICustomer[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);

  return useReactQuery<IList<ICustomer[]>>(
    getCustomersKey(companyId, parsedQueryParams),
    () => getCustomers(companyId, parsedQueryParams),
    options,
  );
};

const getCustomers = (companyId: string, query?: string) => {
  return httpClient.get<IList<ICustomer[]>>(
    buildUrl('customers', `companyID=${companyId}&${query}`),
  );
};

const getCustomersKey = (companyId: string, query: string) => [
  'customers',
  companyId,
  query,
];
