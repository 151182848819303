import { useTranslation } from 'react-i18next';

export const useAttachmentsColumnsConfiguration = (viewMode: boolean) => {
  const { t } = useTranslation('common');

  const columns = [
    {
      name: 'name',
      sortedFields: ['name'],
      title: t('Name'),
      isSortable: false,
    },
    {
      name: 'view',
      sortedFields: [],
      title: '',
      isSortable: false,
    },
  ];

  if (!viewMode) {
    columns.push({
      name: 'delete',
      sortedFields: [],
      title: '',
      isSortable: false,
    });
  }

  return columns;
};
