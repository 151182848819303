import { useTranslation } from 'react-i18next';

import { DatepickerField, FormField } from 'shared/components/Form';

const TrackingDateToField = () => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField label={`${t('To')}:`}>
      <DatepickerField name="to" />
    </FormField>
  );
};

export { TrackingDateToField };
