import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { DepartmentFormType } from 'entities/company/model';

export const useUpdateDepartment = (
  departmentId: string,
  companyId: string,
) => {
  return useMutation((data: DepartmentFormType) => {
    return updateDepartment(data, departmentId, companyId);
  });
};

const updateDepartment = (
  formData: DepartmentFormType,
  departmentId: string,
  companyId: string,
) => {
  return httpClient.patch(
    `departments/${departmentId}?companyID=${companyId}`,
    formData,
  );
};
