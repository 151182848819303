import { useTranslation } from 'react-i18next';

import { SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';

import { usaStates } from 'entities/common/usaStates';

interface IProps {
  name: string;
}

const StateField = ({ name }: IProps) => {
  const { t } = useTranslation('commonFields');

  const options = usaStates.map((state) => {
    return {
      value: state.abbreviation,
      label: state.name,
    };
  });

  return (
    <FormField label={`${t('State')}:`}>
      <SelectField
        name={name}
        options={options}
        placeholder={t('Select one')}
      />
    </FormField>
  );
};

export { StateField };
