import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from 'shared/components';

const SelectAllButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');
  return (
    <Button
      variant={'link'}
      {...props}
    >
      {t('Select all')}
    </Button>
  );
};

export { SelectAllButton };
