import { ILocation } from '../model';

export const mockedLocation = (location?: Partial<ILocation>): ILocation => {
  return {
    _id: '123',
    name: 'Location name',
    active: true,
    address: {
      city: 'City',
      street: 'Street',
      streetNumber: '123',
      state: 'State',
      zip: '81-661',
      nearestCrossStreet: 'Nearest street cross',
    },
    phoneNumber: '+123-456-789',
    timeZone: 'US/Pacific',
    credits: {
      createdAt: new Date().toString(),
      createdByID: '123123123',
    },
    ...location,
  };
};
