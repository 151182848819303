import { useTranslation } from 'react-i18next';

export const useVendorTrackingColumnsConfiguration = () => {
  const { t } = useTranslation(['trackingForm', 'commonFields']);
  return [
    {
      name: 'checkbox',
      sortedFields: [],
      title: '',
      isSortable: false,
    },
    {
      name: 'name',
      sortedFields: ['name'],
      title: t('Vendor'),
      isSortable: true,
    },
    {
      name: 'abbreviation',
      sortedFields: ['abbreviation'],
      title: t('Abbreviation', { ns: 'commonFields' }),
      isSortable: true,
    },
  ];
};
