import { IAttachment } from 'entities/attachments/model';
import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

export const useImportVendors = (companyId: string) => {
  return useMutation((data: FormData) => {
    return importVendors(data, companyId);
  });
};

const importVendors = (formData: FormData, companyId: string) => {
  return httpClient.post<
    IAttachment,
    AxiosResponse<{ _id: string; filename: string }>
  >(`vendors/import?companyID=${companyId}`, formData);
};
