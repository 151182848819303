const ChevronRightIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2 4.59 3.41 9.17 8l-4.58 4.59L6 14l6-6-6-6Z"
        fill="#006FCA"
      />
    </svg>
  );
};

export { ChevronRightIcon };
