import { Controller } from 'react-hook-form';

import { Select, ReactSelectProps } from 'shared/components';

import { FieldPrototype } from './FieldPrototype';
import { useEffect, useState } from 'react';

type OptionType = { value: string; label: string };
export interface ISelectFieldProps extends ReactSelectProps {
  name: string;
  defaultSelectSingleOption?: boolean;
}

const SelectField = (
  { name, options, defaultSelectSingleOption, ...selectProps }: ISelectFieldProps
  ) => {
  return (
    <FieldPrototype name={name}>
      <Controller
        name={name}
        render={({
          field: { onChange, value, ...rest },
          formState: { isSubmitting },
        }) => {
          const [wasSetInitially, setInitially] = useState(false);
          useEffect(() => {
            if (wasSetInitially || !defaultSelectSingleOption) return;
            if (!value && (options?.length || 0) <= 2) {
              onChange(
                (options?.[1] as OptionType)?.value
                || (options?.[0] as OptionType)?.value);
              setInitially(true);
            }
          }, [wasSetInitially, value, name, options]);
          return (
            <Select
              data-testid={name}
              inputId={name}
              isDisabled={isSubmitting}
              isSearchable={false}
              value={options?.find(
                (option) => (option as OptionType).value === value,
              )}
              onChange={(value) => { setInitially(true); onChange((value as OptionType).value); }}
              options={options}
              {...rest}
              {...selectProps}
            />
          );
        }}
      />
    </FieldPrototype>
  );
};

export { SelectField };
