import { useQueryClient } from '@tanstack/react-query';

import { httpClient } from 'shared/utils/httpClient';
import { useMutation } from 'shared/hooks/reactQuery';
import useStore from 'shared/store';

export const useLogoutUser = (
  onSuccessFn?: () => void,
  onFailureFn?: () => void,
) => {
  const queryClient = useQueryClient();
  const setIsLoggedIn = useStore((state) => state.setIsLoggedIn);
  const setRoles = useStore((state) => state.setRoles);
  const setPrivileges = useStore((state) => state.setPrivileges);
  const setCompany = useStore((state) => state.setCompany);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);

  return useMutation(() => logoutUser(), {
    onSuccess: async () => {
      setIsLoggedIn(false);
      setRoles([]);
      setPrivileges([]);
      setCompany(null);
      setFilesToConnect([]);

      await queryClient.clear();

      onSuccessFn && onSuccessFn();
    },
    onError: async () => {
      setIsLoggedIn(false);
      setRoles([]);
      setPrivileges([]);
      setCompany(null);
      setFilesToConnect([]);

      await queryClient.clear();

      onFailureFn && onFailureFn();
    },
  });
};

const logoutUser = () => {
  return httpClient.post('auth/logout');
};
