import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ErrorBoundary, Text } from 'shared/components';

import { useGetCompany } from 'entities/company/api';
import { EditCompany } from '../../../widgets/Company';

const EditCompanyPageThrowable = () => {
  const { t } = useTranslation('common');
  const { companyId } = useParams();

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  const { isLoading, error, data } = useGetCompany(companyId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Company not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return <EditCompany company={data.data} />;
};

const EditCompanyPage = () => {
  return (
    <ErrorBoundary>
      <EditCompanyPageThrowable />
    </ErrorBoundary>
  );
};
export { EditCompanyPage };
