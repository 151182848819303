import { UseQueryOptions } from '@tanstack/react-query';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { useGetParsedQueryParams } from 'shared/utils';

import { IRegulation } from 'entities/regulation/model';

export const useGetRegulations = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IRegulation[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);

  return useReactQuery<IList<IRegulation[]>>(
    getRegulationsKey(companyId, parsedQueryParams),
    () => getRegulations(companyId, parsedQueryParams),
    options,
  );
};

const getRegulations = (companyId: string, query?: string) => {
  return httpClient.get<IList<IRegulation[]>>(
    buildUrl('regulations', `companyID=${companyId}&${query}`),
  );
};

const getRegulationsKey = (companyId: string, query: string) => [
  'regulations',
  companyId,
  query,
];
