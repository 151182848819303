import { useTranslation } from 'react-i18next';

export const usePersonnelTableColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'lastName',
      sortedFields: ['name.lastname'],
      title: t('Last Name'),
      isSortable: true,
    },
    {
      name: 'firstName',
      sortedFields: ['name.firstname'],
      title: t('First Name'),
      isSortable: true,
    },
    {
      name: 'locations',
      sortedFields: ['locations.0.name'],
      title: t('Locations'),
      isSortable: true,
    },
    {
      name: 'departments',
      sortedFields: ['departments.0.name'],
      title: t('Departments'),
      isSortable: true,
    },
    {
      name: 'supervisor',
      sortedFields: ['supervisor.name.firstname', 'supervisor.name.lastname'],
      title: t('Supervisor'),
      isSortable: true,
    },
    {
      name: 'roles',
      sortedFields: ['roleNames'],
      title: t('Role'),
      isSortable: false,
    },
    {
      name: 'login',
      sortedFields: ['login'],
      title: t('Login'),
      isSortable: true,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active'),
      isSortable: true,
    },
  ];
};
