import { useMutation } from 'shared/hooks/reactQuery';

import { httpClient } from 'shared/utils/httpClient';

import { LocationFormType } from 'entities/company/model';

export const useUpdateLocation = (companyId: string, locationId: string) => {
  return useMutation((data: LocationFormType) => {
    return updateLocation(data, companyId, locationId);
  });
};

const updateLocation = (
  formData: LocationFormType,
  companyId: string,
  locationId: string,
) => {
  return httpClient.patch(
    `locations/${locationId}?companyID=${companyId}`,
    formData,
  );
};
