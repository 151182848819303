import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { DeleteCompany } from 'features/company/DeleteCompany';
import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  active?: boolean;
}

const CompanyActionButtons = ({ companyId, active }: IProps) => {
  const { fullSuperAdminPrivilege, updateCompanyPrivilege } = LayoutPrivilege();

  return (
    <FlexWrapper>
      {fullSuperAdminPrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.company.add.getPath()} />
      )}
      {active && fullSuperAdminPrivilege && (
        <DeleteCompany companyId={companyId} />
      )}
      {updateCompanyPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.company.edit.getPath(companyId)}
        />
      )}
    </FlexWrapper>
  );
};

export { CompanyActionButtons };
