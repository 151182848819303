import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeaderText,
  TableHeadSortableCell,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import { Checkbox } from 'shared/components';
import { IList, ISort } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';
import { useCheckboxFilter } from 'features/CheckboxListFilter/utils';

import { IVendor } from 'entities/vendor/model';

import { useVendorTrackingColumnsConfiguration } from './utils';
import { VendorTrackingTableRow } from './VendorTrackingTableRow';

const useVendorTrackingTableState = createTableStore();

interface IProps {
  onSortChange: (sort: ISort[]) => void;
  isLoading: boolean;
  offset: number;
  setOffset: (value: number) => void;
  vendorsList?: IList<IVendor[]>;
  limit: number;
  vendorsIDs?: string[];
}

const VendorsTrackingTable = ({
  onSortChange,
  isLoading,
  vendorsList,
  vendorsIDs,
  offset,
  setOffset,
  limit,
}: IProps) => {
  const columnsConfiguration = useVendorTrackingColumnsConfiguration();
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);

  const tableState = useVendorTrackingTableState((state) => state, shallow);
  const { isAllSelected, switchAllCheckboxes } = useCheckboxFilter(
    vendorsList?.data || [],
    'vendors',
  );

  useEffect(() => {
    if (vendorsList && vendorsList?.total <= 10) {
      setOffset(0);
    }
  }, [vendorsList?.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map(({ title, isSortable, name }) => {
            if (name === 'checkbox') {
              return (
                <TableHeadCell
                  key={name}
                  textAlign="center"
                >
                  <Checkbox
                    size="lg"
                    onChange={() => switchAllCheckboxes(!isAllSelected)}
                    isChecked={isAllSelected}
                  />
                </TableHeadCell>
              );
            }
            return isSortable ? (
              <TableHeadSortableCell
                key={name}
                columnName={name}
              >
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadSortableCell>
            ) : (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody isLoading={isLoading}>
        {vendorsList && vendorsList.total > 0 ? (
          <>
            {vendorsList.data.map((vendor) => {
              if (vendorsIDs) {
                return (
                  <VendorTrackingTableRow
                    key={vendor._id}
                    vendor={vendor}
                  />
                );
              }

              return (
                <VendorTrackingTableRow
                  key={vendor._id}
                  vendor={vendor}
                />
              );
            })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={vendorsList.total}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { VendorsTrackingTable };
