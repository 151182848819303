import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteLocation = (locationId: string, companyId: string) => {
  return useMutation(() => {
    return deleteLocation(locationId, companyId);
  });
};

const deleteLocation = (locationId: string, companyId: string) => {
  return httpClient.delete(`locations/${locationId}?companyID=${companyId}`);
};
