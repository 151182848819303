import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';
import useStore from 'shared/store';
import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { useGetCustomer } from 'entities/customer/api/useGetCustomer';
import { AddCustomer } from 'entities/customer/model';
import { useUpdateCustomer } from 'entities/customer/api';

import { CustomerVendorForm } from 'widgets/CustomerVendorForm/CustomerVendorForm';

import { ViewButton } from 'features/ViewButton';
import { DeleteCustomer } from 'features/customer/DeleteCustomer';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

const CustomerEditPageThrowable = () => {
  const { t } = useTranslation(['popupMessage', 'common']);

  const companyId = useStore((state) => state.company?._id);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);

  const { customerId } = useParams();
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { deleteCustomerPrivilege } = LayoutPrivilege();

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  if (!customerId) {
    throw new Error('CustomerId not found');
  }

  const formId = `customer-edit-form-${customerId}-${companyId}`;

  const { isLoading, error, data } = useGetCustomer(companyId, customerId);
  const mutation = useUpdateCustomer(customerId, companyId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Customer not found', { ns: 'common' })}</Text>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { credits, companyID, ...defaultValue } = data.data;

  const viewPath = routeBuilder.customer.view.getPath(customerId);

  const handleSubmit = async (
    data: AddCustomer,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutation.mutateAsync({
        ...data,
        attachmentIDs: [
          ...filesToConnect
            .filter((file) => file.parent === formId)
            .map((filesToConnect) => filesToConnect._id),
        ],
      });

      setFilesToConnect(
        filesToConnect.filter((file) => file.parent !== formId),
      );

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.customer.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', {
          item: t('customer'),
        }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', {
          item: t('customer'),
        }),
      });
    }
  };

  return (
    <CustomerVendorForm
      formId={formId}
      defaultValues={defaultValue}
      isLoading={mutation.isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          {data.data.active && deleteCustomerPrivilege && (
            <DeleteCustomer
              companyId={companyId}
              customerId={customerId}
            />
          )}
        </>
      }
      variant="customer"
    />
  );
};

export const CustomerEditPage = () => {
  return (
    <ErrorBoundary>
      <CustomerEditPageThrowable />
    </ErrorBoundary>
  );
};
