import { useNavigate } from 'react-router-dom';

import {
  TableBodyCell,
  TableBodyInfoCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { routeBuilder } from 'shared/utils';

import { IRegulation } from 'entities/regulation/model';

interface IProps {
  regulation: IRegulation;
  isMobile?: boolean;
}
const RegulationsTableRow = ({ regulation, isMobile }: IProps) => {
  const navigation = useNavigate();

  const handleRedirect = () => {
    navigation(routeBuilder.regulation.view.getPath(regulation._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      {isMobile ? (
        <>
          <TableBodyCell>
            <TableCellText>{regulation.agency?.name || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{regulation.title || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyInfoCell regulation={regulation} />
        </>
      ) : (
        <>
          <TableBodyCell>
            <TableCellText>{regulation.agency?.name || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{regulation.title || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{regulation.section || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{regulation.description || '-'}</TableCellText>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  );
};

export { RegulationsTableRow };
