import { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Box } from 'shared/components/Box';

import { RedText } from './RedText';

interface IProps {
  name: string;
  children: ReactNode;
}

const FieldPrototype = ({ name, children }: IProps) => {
  const methods = useFormContext();

  return (
    <Box
      w="100%"
      minH={{ base: '38px', md: '46px' }}
    >
      {children}
      <ErrorMessage
        errors={methods.formState.errors}
        name={name}
        render={({ message }) => {
          return (
            <RedText
              size={{ base: 'xs' }}
              textAlign="right"
            >
              {message}
            </RedText>
          );
        }}
      />
    </Box>
  );
};

export { FieldPrototype };
