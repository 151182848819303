import { Icon, IconProps } from 'shared/components';

const MobileCloseIcon = (props: IconProps) => {
  return (
    <Icon
      width="28px"
      height="27px"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="3.12108"
        y1="2"
        x2="25.7485"
        y2="24.6274"
        stroke="#00467F"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="2.25122"
        y1="24.8787"
        x2="24.8786"
        y2="2.25126"
        stroke="#00467F"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </Icon>
  );
};

export { MobileCloseIcon };
