import { ReactNode, useState } from 'react';

import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { SaveButton } from 'features/SaveButton';
import {
  AgencyField,
  DescriptionField,
  SectionField,
  TitleField,
  useContainerProps,
} from 'features/FormCommons';

import { FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';
import { useForm } from 'shared/components/Form';

import { IRegulationPayload } from 'entities/regulation/model';
import { useRegulationValidationSchema } from './useRegulationValidationSchema';

interface AgencyFormProps {
  handleSubmit: (
    data: IRegulationPayload,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  companyId: string;
  defaultValues?: IRegulationPayload;
  formId: string;
  additionalActionButtons?: ReactNode;
}

const defaultRegulation = {
  agencyID: '',
  title: '',
  section: '',
  description: '',
};

const RegulationForm = ({
  defaultValues = defaultRegulation,
  formId,
  isLoading,
  additionalActionButtons,
  handleSubmit,
  companyId,
}: AgencyFormProps) => {
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const validationSchema = useRegulationValidationSchema();

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });

  return (
    <Form<IRegulationPayload>
      onSubmit={(data, { reset }) =>
        handleSubmit(
          data,
          () => {
            reset(defaultValues);
            cleanPersistentStore();
          },
          submitType,
        )
      }
      id={formId}
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <AgencyField
        companyId={companyId}
        isRequired
      />

      <TitleField isRequired />

      <SectionField isRequired />

      <DescriptionField isRequired />

      <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { RegulationForm };
