import { Text } from 'shared/components';

interface IProps {
  dayOfMonth: number;
}

const DatepickerDay = ({ dayOfMonth }: IProps) => {
  return (
    <Text
      size="xs"
      transform="translateY(50%)"
      m="auto"
    >
      {dayOfMonth}
    </Text>
  );
};

export { DatepickerDay };
