import { ICompany } from 'entities/company/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useGetCompany = (companyId: string) => {
  return useReactQuery<ICompany>(getCompanyKey(companyId), () =>
    getCompany(companyId),
  );
};

const getCompany = (companyId: string) => {
  return httpClient.get<ICompany>(`companies/${companyId}`);
};

const getCompanyKey = (companyId: string) => ['company', companyId];
