import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';
import useStore from 'shared/store';

import { useGetRegulation } from 'entities/regulation/api';
import { EditRegulation } from 'widgets/Regulation/EditRegulation';

const EditRegulationPageThrowable = () => {
  const { t } = useTranslation('common');
  const companyId = useStore((state) => state.company?._id);
  const { regulationId } = useParams();

  if (!regulationId) {
    throw new Error('RegulationId not found');
  }

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  const { isLoading, error, data } = useGetRegulation(companyId, regulationId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Regulation not found')}</Text>;
  }

  return (
    <EditRegulation
      companyId={companyId}
      regulation={data.data}
    />
  );
};

export const EditRegulationPage = () => {
  return (
    <ErrorBoundary>
      <EditRegulationPageThrowable />
    </ErrorBoundary>
  );
};
