type SaveAndNewIconProps = {};

export const SaveAndNewIcon = ({}: SaveAndNewIconProps) => {
  return (
    <svg
      width="40"
      height="30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.094 3.125a2.969 2.969 0 0 0-2.969 2.969v17.812c0 1.64 1.33 2.969 2.969 2.969h17.812c1.64 0 2.969-1.33 2.969-2.969V10.292c0-.787-.313-1.542-.87-2.099l-4.198-4.198a2.969 2.969 0 0 0-2.1-.87H6.095ZM5.5 5.5v4.75h14.25V5.5H5.5ZM15 22.125A3.563 3.563 0 1 0 15 15a3.563 3.563 0 0 0 0 7.125Z"
        fill="#72B42D"
      />
      <path
        d="M29.5 5C23.71 5 19 9.71 19 15.5S23.71 26 29.5 26 40 21.29 40 15.5 35.29 5 29.5 5Zm4.038 11.308h-3.23v3.23a.808.808 0 0 1-1.616 0v-3.23h-3.23a.808.808 0 0 1 0-1.616h3.23v-3.23a.808.808 0 0 1 1.616 0v3.23h3.23a.808.808 0 0 1 0 1.616Z"
        fill="#F6F9FB"
      />
      <path
        d="M33.538 16.308h-3.23v3.23a.808.808 0 0 1-1.616 0v-3.23h-3.23a.808.808 0 0 1 0-1.616h3.23v-3.23a.808.808 0 0 1 1.616 0v3.23h3.23a.808.808 0 0 1 0 1.616Z"
        fill="#72B42D"
      />
    </svg>
  );
};
