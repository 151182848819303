import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteRegulation = (
  regulationId: string,
  companyId: string,
) => {
  return useMutation(() => {
    return deleteRegulation(regulationId, companyId);
  });
};

const deleteRegulation = (regulationId: string, companyId: string) => {
  return httpClient.delete(
    `regulations/${regulationId}?companyID=${companyId}`,
  );
};
