import { ITracking } from 'entities/tracking/model';
import { format } from 'date-fns';

export const useTrackingSMSMessageParser = (tracking: ITracking) => {
  const parsedDateTime = `A new tracking has been added on ${format(
    new Date(tracking.trackedAt.utc),
    'yyyy/MM/dd',
  )}`;

  const parsedTrackedBy = tracking.trackedBy
    ? `Tracked By: ${tracking.trackedBy.name.firstname} ${tracking.trackedBy.name.lastname}`
    : '';

  const parsedActivity = tracking.activity
    ? `Activity: ${tracking.activity.name}`
    : '';

  const parsedDocumentation = tracking.attachments
    ? `Documentation: ${tracking.attachments.reduce((acc, attachment) => {
        return `${acc} ${process.env.REACT_APP_API_URL}/trackings/attachments/${attachment._id}, `;
      }, '')}`
    : '';

  return [parsedDateTime, parsedTrackedBy, parsedActivity, parsedDocumentation]
    .filter((value) => value !== '')
    .join(', ');
};
