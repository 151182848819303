import { useEffect } from 'react';
import { SetFieldValue } from 'react-hook-form';
import { isArray, isEmpty } from 'lodash';

export interface IFormPersistConfig {
  formId: string;
  // eslint-disable-next-line
  watch: (names?: string | string[]) => any;
  // eslint-disable-next-line
  setValue: SetFieldValue<any>;
  // eslint-disable-next-line
  defaultValues?: any;
  exclude?: string[];
  validate?: boolean;
  dirty?: boolean;
  touch?: boolean;
}

export const useFormPersist = ({
  formId,
  watch,
  setValue,
  exclude = [],
  validate = false,
  dirty = false,
  touch = false,
  defaultValues,
}: IFormPersistConfig) => {
  const watchedValues = watch();

  const getStorage = () => window.localStorage;

  const clearStorage = () => getStorage().removeItem(formId);

  // eslint-disable-next-line
  const setFormValuesRecursion = (obj: any, stack: string[] = []) => {
    const path = stack.join('.');
    const options = {
      shouldValidate: validate,
      shouldDirty: dirty,
      shouldTouch: touch,
    };

    if (isArray(obj)) {
      setValue(path, obj, options);
      return;
    }
    if (typeof obj === 'object') {
      if (isEmpty(obj)) {
        setValue(path, obj, options);
        return;
      }
      Object.keys(obj).forEach((key) =>
        setFormValuesRecursion(obj[key], [...stack, key]),
      );
    } else {
      setValue(path, obj, options);
    }
  };

  useEffect(() => {
    const storage = getStorage().getItem(formId);

    if (defaultValues) {
      setFormValuesRecursion(defaultValues);
    }

    if (storage) {
      const values = JSON.parse(storage);
      setFormValuesRecursion(values);
    }
  }, [formId, setValue]);

  useEffect(() => {
    const values = exclude.length
      ? Object.entries(watchedValues)
          .filter(([key]) => !exclude.includes(key))
          .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {})
      : Object.assign({}, watchedValues);

    if (Object.entries(values).length) {
      getStorage().setItem(formId, JSON.stringify(values));
    }
  }, [watchedValues]);

  return {
    clear: clearStorage,
  };
};
