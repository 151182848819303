import { useTranslation } from 'react-i18next';

import { Modal } from 'shared/components';
import { IModalProps } from 'shared/components/Modal';
import { ITracking } from 'entities/tracking/model';

import { useForm } from 'shared/components/Form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routeBuilder, useToast } from 'shared/utils';
import {
  prepareEmailMessageForBackend,
  useTrackingSMSModalFormValidationSchema,
} from 'features/TrackingEmail/TrackingEmailModal/utils';
import { SendSMSButton } from '../SendSMSButton';
import { useSendSMS } from 'entities/sms/api';
import { TrackingSMSModalForm } from '../TrackingSMSModalForm';
import { useTrackingSMSMessageParser } from '../TrackingSMSModalForm/utils';
import { ISMSPayload } from 'entities/sms/model';

interface IProps extends Omit<IModalProps, 'title'> {
  companyId: string;
  tracking: ITracking;
  isLoading?: boolean;
}

const defaultValues = {
  phoneNumber: '',
  message: '',
};

const TrackingSMSModal = ({
  companyId,
  tracking,
  onClose,
  ...restProps
}: IProps) => {
  const { t } = useTranslation([
    'popupMessage',
    'common',
    'validationMessages',
  ]);
  const formId = `tracking-view-sms-form-${tracking._id}-${companyId}`;
  const { successToast, errorToast } = useToast();
  const validationSchema = useTrackingSMSModalFormValidationSchema();
  const parsedMessage = useTrackingSMSMessageParser(tracking);
  const { mutateAsync } = useSendSMS();
  const navigation = useNavigate();

  //it's a hack, because default values are set once when but not all data is
  //fetched yet in useTrackingEmailMessageParser
  useEffect(() => {
    methods.setValue('message', parsedMessage);
  }, [parsedMessage]);

  const { methods, cleanPersistentStore } = useForm<ISMSPayload>({
    defaultValues: {
      ...defaultValues,
      message: parsedMessage,
    },
    formId,
    validationSchema,
    persistenceProps: {
      exclude: ['message', 'phoneNumber'],
    },
  });

  const redirectToView = () =>
    navigation(routeBuilder.tracking.view.getPath(tracking._id));

  const handleSubmit = async () => {
    try {
      await mutateAsync({
        ...methods.getValues(),
        message: prepareEmailMessageForBackend(methods.getValues('message')),
      });
      methods.reset();
      cleanPersistentStore();
      successToast({
        message: t('Successfully sent message'),
      });
      onClose();
    } catch (e) {
      const error = e as {
        request: {
          responseText: string;
        };
      };
      if (error.request.responseText.includes('phoneNumber must match')) {
        errorToast({
          message: t(
            // eslint-disable-next-line max-len
            'Invalid phone number. Please use the format starting with + and country code (e.g. +158401234567). Country code for USA is +1',
            { ns: 'validationMessages' },
          ),
        });
      } else {
        errorToast({
          message: t('Error while sending message'),
        });
      }
    } finally {
      redirectToView();
    }
  };

  return (
    <Modal
      title={t('Text Message', { ns: 'common' })}
      content={
        <TrackingSMSModalForm
          companyId={companyId}
          methods={methods}
          formId={formId}
        />
      }
      size="5xl"
      onClose={() => {
        onClose();
        redirectToView();
      }}
      {...restProps}
    >
      <SendSMSButton
        onClick={() => handleSubmit()}
        isLoading={methods.formState.isSubmitting}
      />
    </Modal>
  );
};

export { TrackingSMSModal };
