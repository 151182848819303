import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';

import { DeleteAction } from './DeleteAction';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  actionId: string;
  companyId: string;
  active?: boolean;
}

const ActionsButtons = ({ actionId, companyId, active }: IProps) => {
  const {
    createActionPrivilege,
    updateActionPrivilege,
    deleteActionPrivilege,
  } = LayoutPrivilege();
  return (
    <FlexWrapper mt={{ base: '20px', lg: '50px' }}>
      {createActionPrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.actions.add.getPath()} />
      )}
      {active && deleteActionPrivilege && (
        <DeleteAction
          actionId={actionId}
          companyId={companyId}
        />
      )}
      {updateActionPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.actions.edit.getPath(actionId)}
        />
      )}
    </FlexWrapper>
  );
};

export { ActionsButtons };
