import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  description?: string;
}

const DescriptionViewRow = ({ description }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Description')}>
      <Text>{description || '-'}</Text>
    </ViewRow>
  );
};

export { DescriptionViewRow };
