import { useNavigate } from 'react-router-dom';
import { prepareData } from '../../features/location/LocationForm/utils';
import { useSaveLocation } from '../../entities/company/api';
import { LocationFormType } from '../../entities/company/model';
import { routeBuilder, useToast } from '../../shared/utils';
import { SubmitFormType } from '../../shared/types';
import { LocationForm } from '../../features/location/LocationForm';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddLocation = ({ companyId }: IProps) => {
  const navigation = useNavigate();
  const { mutateAsync, isLoading } = useSaveLocation(companyId);
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const handleSubmit = async (
    data: LocationFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(prepareData(data));
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.locations.view.getPath(_id));
      }

      successToast({
        message: t('Successfully added item message', { item: t('location') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('location') }),
      });
    }
  };

  return (
    <LocationForm
      formId={`add-location-form-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export { AddLocation };
