import { UseQueryOptions } from '@tanstack/react-query';
import { useReactQuery } from 'shared/hooks/reactQuery';
import {
  buildUrl,
  httpClient,
  AxiosError,
  AxiosResponse,
} from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';
import { useGetLocale, useGetParsedQueryParams } from 'shared/utils';
import { ITopic } from 'entities/tracking/model';

export const useGetTopics = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<ITopic[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IList<ITopic[]>>(
    getTopicsKey(companyId, parsedQueryParams, defaultLang),
    () => getTopics(companyId, parsedQueryParams, defaultLang),
    options,
  );
};

const getTopics = (companyId: string, query?: string, lang = 'en') => {
  return httpClient.get<IList<ITopic[]>>(
    buildUrl('topics', `languageCode=${lang}&companyID=${companyId}&${query}`),
  );
};

const getTopicsKey = (companyId: string, query: string, lang = 'en') => [
  'topics',
  companyId,
  query,
  lang
];
