import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSaveTracking } from 'entities/tracking/api';
import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';
import { TrackingFormType } from 'entities/tracking/model';
import { TrackingForm } from 'features/tracking/TrackingForm';
import { convertTrackingFormData } from 'features/tracking/TrackingForm/utils';
import { TrackingEmail } from '../../features/TrackingEmail';
import { useState } from 'react';
import { useDisclosure } from '../../shared/hooks/chakraUi';
import { TrackingSMS } from 'features/tracking/TrackingSMS';

interface IProps {
  companyId: string;
}

const AddTracking = ({ companyId }: IProps) => {
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');
  const [trackingId, setTrackingId] = useState<null | string>(null);
  const openEmail = useDisclosure();
  const openSMS = useDisclosure();

  const { isLoading, mutateAsync } = useSaveTracking(companyId);

  const handleSubmit = async (
    data: TrackingFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(convertTrackingFormData(data));
      reset();

      successToast({
        message: t('Successfully added item message', { item: t('tracking') }),
      });

      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.tracking.view.getPath(_id));
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(0);
      }
      if (submitType === SubmitFormType.Email) {
        setTrackingId(_id);
        openEmail.onOpen();
      }
      if (submitType === SubmitFormType.SMS) {
        setTrackingId(_id);
        openSMS.onOpen();
      }
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('tracking') }),
      });
    }
  };

  return (
    <>
      {openEmail.isOpen && (
        <TrackingEmail
          companyId={companyId}
          trackingId={trackingId}
          isOpen={openEmail.isOpen}
          onClose={openEmail.onClose}
        />
      )}
      {openSMS.isOpen && (
        <TrackingSMS
          companyId={companyId}
          trackingId={trackingId}
          isOpen={openSMS.isOpen}
          onClose={openSMS.onClose}
        />
      )}
      <TrackingForm
        companyId={companyId}
        formId={`add-tracking-form-${companyId}`}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
};

export { AddTracking };
