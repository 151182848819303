import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';

import { useGetActivity } from 'entities/tracking/api';
import { ActivityView } from 'features/activites/ActivityView';
import { ActivityActionButtons } from 'widgets/ActivityActionButtons';
import { CreationDetails } from 'features/CreationDetails';

const ViewActivityPageThrowable = () => {
  const { t } = useTranslation(['common', 'activities']);
  const { activityId } = useParams();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  if (!activityId) {
    throw new Error('ActivityId not found');
  }

  const { isLoading, error, data } = useGetActivity(companyId, activityId);

  if (isLoading) {
    return <Text size="lg">{t('Loading')}...</Text>;
  }

  if (!data) {
    return (
      <Text size="lg">{t('Activity not found', { ns: 'activities' })}</Text>
    );
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <ActivityView activity={data.data} />
      <ActivityActionButtons
        activityId={activityId}
        companyId={companyId}
        active={data.data.active}
      />
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewActivityPage = () => {
  return (
    <ErrorBoundary>
      <ViewActivityPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewActivityPage };
