import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { ErrorBoundary, Select, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';
import { useGetAuthorityUsers } from 'entities/user/api/useGetAuthorityUsers';
import { useGetMyUser } from 'entities/user/api';
import { useEffect } from 'react';
import { ISelectFieldProps } from 'shared/components/Form/SelectField';

interface IProps {
  companyId: string;
  name?: string;
  inputProps?: Partial<ISelectFieldProps>;
  isEditing?: boolean;
}

type OptionType = { value: string; label: string };

const defaultName = 'trackedBy[0]';

const TrackedByFieldThrowable = ({ companyId, name = defaultName, ...restProps }: IProps) => {
  const { t } = useTranslation('commonFields');
  const userQuery = useGetMyUser(true);
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();
  const { isLoading, data } = useGetAuthorityUsers(
    companyId,
    {
      sort: [{ name: 'name.lastname', sortDirection: 'asc' }],
    },
    { useErrorBoundary: false },
  );
  const options = [
    { value: null, label: t('Tracked By') },
    ...(data?.data
      ? data.data.map((user) => {
          return {
            value: user._id,
            label: `${user.name.firstname} ${user.name.lastname}`,
          };
        })
      : []),
  ];

  const value = watch(name);
  useEffect(() => {
    if(restProps.isEditing) {
      const id = userQuery.data?.data?._id;
      // set yourself
      if (!value && id && options.find(({ value }) => value === id)) setValue(name, id);
      if (
        (!value && value !== null)
        || (value && !options.some(({ value: _value }) => value === _value))
      )
        setValue(name, null);
    }
  }, [userQuery.data?.data?._id, options, restProps.isEditing]);

  if (!options) {
    return null;
  }

  return (
    <FormField label={`${t('Tracked By')}:`}>
      <Select
        data-testid={name}
        inputId={name}
        isDisabled={isSubmitting}
        value={options?.find(
          (option) => (option as OptionType).value === value,
        )}
        onChange={(value) => setValue(name, (value as OptionType).value)}
        options={options}
        isLoading={isLoading}
        placeholder={t('Select one')}
        {...restProps.inputProps}
      />
    </FormField>
  );
};

const TrackedByField = ({ companyId, name, ...restProps }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Tracked By')}:`}>
          <SelectField name={name || defaultName} {...restProps.inputProps} />
        </FormField>
      )}
    >
      <TrackedByFieldThrowable
        companyId={companyId}
        name={name}
        inputProps={restProps.inputProps}
        isEditing={restProps.isEditing}
      />
    </ErrorBoundary>
  );
};

export { TrackedByField };
