import { useTranslation } from 'react-i18next';

export const useCustomerTrackingColumnsConfiguration = () => {
  const { t } = useTranslation(['commonFields', 'trackingForm']);
  return [
    {
      name: 'checkbox',
      sortedFields: [],
      title: '',
      isSortable: false,
    },
    {
      name: 'name',
      sortedFields: ['name'],
      title: t('Customer', { ns: 'trackingForm' }),
      isSortable: true,
    },
    {
      name: 'abbreviation',
      sortedFields: ['abbreviation'],
      title: t('Abbreviation'),
      isSortable: true,
    },
  ];
};
