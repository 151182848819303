import { Controller } from 'react-hook-form';
import { InputProps } from '@chakra-ui/react';

import { useBreakpointValue } from 'shared/hooks/chakraUi';
import { Input } from 'shared/components/Input';

import { FieldPrototype } from './FieldPrototype';
import { useHasError } from './useHasError';

export interface ITextFieldProps extends InputProps {
  name: string;
}

const TextField = ({ name, ...inputProps }: ITextFieldProps) => {
  const height = useBreakpointValue({ base: '38px', md: '46px' });
  const hasError = useHasError(name);

  return (
    <FieldPrototype name={name}>
      <Controller
        name={name}
        render={(props) => {
          const {
            field: { onChange, onBlur, value },
            formState: { isSubmitting },
          } = props;
          return (
            <Input
              data-testid={name}
              id={name}
              isInvalid={hasError}
              isDisabled={isSubmitting}
              onChange={onChange}
              onBlur={onBlur}
              value={value || ''}
              minH={height}
              fontSize={{ base: '1.6rem', md: '1.8rem' }}
              {...inputProps}
            />
          );
        }}
      />
    </FieldPrototype>
  );
};

export { TextField };
