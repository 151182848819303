import { Icon, IconProps } from 'shared/components';

const PrinterIcon = (props: IconProps) => (
  <Icon
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2359_1433)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.20001 11.1597L24.1241 11.3419V4.80772C24.1241 4.44918 23.8504 4.15833 23.5121 4.15833H6.81243C6.47449 4.15833 6.20036 4.44918 6.20036 4.80808V11.1593L6.20001 11.1597Z"
        stroke="#00467F"
        strokeWidth="3.168"
      />
      <path
        d="M9.80311 0.143433H18.5776L22.4331 4.58636V11.7337H8.33173V1.70416C8.33173 1.49914 8.3698 1.29613 8.44377 1.10673C8.51774 0.917325 8.62616 0.745239 8.76283 0.600302C8.8995 0.455365 9.06174 0.340417 9.2403 0.262026C9.41885 0.183635 9.61021 0.143337 9.80345 0.143433H9.80311Z"
        fill="#F6F9FB"
      />
      <path
        d="M22.4331 4.58636H20.4304C19.9475 4.58636 19.4844 4.38284 19.1429 4.02058C18.8015 3.65831 18.6097 3.16697 18.6097 2.65465V0.143433L22.4331 4.58636Z"
        fill="#00467F"
      />
      <path
        d="M26.8528 11.6763H3.0838C1.80422 11.6763 0.766907 12.7768 0.766907 14.1344V22.8601C0.766907 24.2177 1.80422 25.3182 3.0838 25.3182H26.8528C28.1324 25.3182 29.1697 24.2177 29.1697 22.8601V14.1344C29.1697 12.7768 28.1324 11.6763 26.8528 11.6763Z"
        fill="#72B42D"
        stroke="#00467F"
        strokeWidth="3.168"
      />
      <path
        d="M25.5283 15.9834C26.2321 15.9834 26.8027 15.378 26.8027 14.6312C26.8027 13.8845 26.2321 13.2791 25.5283 13.2791C24.8244 13.2791 24.2538 13.8845 24.2538 14.6312C24.2538 15.378 24.8244 15.9834 25.5283 15.9834Z"
        fill="#00467F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.10069 21.0648C6.06953 21.0648 6.03871 21.0717 6.01016 21.0849C5.98161 21.0981 5.95594 21.1175 5.93477 21.1417C5.9136 21.166 5.89738 21.1946 5.88712 21.2258C5.87686 21.257 5.8728 21.2902 5.87517 21.3231L6.1431 24.9795C6.14753 25.0397 6.17321 25.0959 6.21501 25.137C6.25682 25.178 6.31167 25.2008 6.36862 25.2008H23.9559C24.0128 25.2008 24.0677 25.178 24.1095 25.137C24.1513 25.0959 24.1769 25.0397 24.1814 24.9795L24.4493 21.3231C24.4517 21.2902 24.4476 21.2571 24.4374 21.2259C24.4272 21.1947 24.411 21.1661 24.3898 21.1419C24.3687 21.1176 24.3431 21.0983 24.3146 21.085C24.286 21.0717 24.2553 21.0649 24.2241 21.0648H6.10069Z"
        stroke="#00467F"
        strokeWidth="3.168"
      />
      <path
        d="M7.41588 22.4912H22.909L24.3942 28.8761C24.4222 28.9968 24.424 29.1227 24.3994 29.2442C24.3748 29.3658 24.3245 29.4798 24.2522 29.5776C24.1799 29.6755 24.0876 29.7546 23.9823 29.809C23.8769 29.8634 23.7613 29.8917 23.6442 29.8917H6.68036C6.56322 29.8916 6.44763 29.8633 6.34233 29.8089C6.23703 29.7545 6.14478 29.6753 6.07256 29.5775C6.00033 29.4796 5.95003 29.3656 5.92546 29.2441C5.90088 29.1226 5.90267 28.9968 5.9307 28.8761L7.41588 22.4912Z"
        fill="#F6F9FB"
      />
    </g>
    <defs>
      <clipPath id="clip0_2359_1433">
        <rect
          width="30"
          height="30"
          fill="white"
        />
      </clipPath>
    </defs>
  </Icon>
);

export { PrinterIcon };
