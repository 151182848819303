import { reduce } from 'lodash';
import {
  useParseDepartment,
  useParseLocation,
  useParseRole,
  useParseUser,
} from './helpers';
import { IAttendeeListReduce } from '../../../entities/tracking/model';

export const useComputeAttendeeListReduceQuery = (
  companyId: string,
  values?: IAttendeeListReduce,
  limit?: number,
  offset?: number,
) => {
  const locationName = useParseLocation(companyId, values?.locationId);
  const departmentName = useParseDepartment(
    companyId,
    values?.departmentId || '',
  );
  const supervisor = useParseUser(values?.supervisorId);
  const role = useParseRole(companyId, values?.roleId);

  const computedLimit =
    limit !== undefined && !isNaN(limit) ? `limit=${limit}` : '';
  const computedOffset =
    offset !== undefined && !isNaN(offset) ? `offset=${offset}` : '';

  const isEmptyObject =
    values && Object.values(values).every((value) => !value || value === '');

  if (!values || isEmptyObject) {
    return [`companyID=${companyId}`, computedLimit, computedOffset]
      .filter((value) => value !== '')
      .join('&');
  }

  const payload: Record<string, unknown> = {
    'locations.name': locationName,
    'departments.name': departmentName,
    roleNames: role?.name,
    'supervisor.name.firstname': supervisor?.name?.firstname,
    'supervisor.name.lastname': supervisor?.name?.lastname,
  };

  if(values.active !== undefined) { payload['active'] = values.active; }

  const computedFilters = reduce(
    Object.keys(payload),
    (result, key) => {
      if (
        !payload[key as keyof typeof payload] ||
        payload[key as keyof typeof payload] === ''
      ) {
        return result;
      }
      return `${result}${key}$${payload[key as keyof typeof payload]},`;
    },
    `companyID=${companyId}&filterOperator=and&filters=`,
  ).slice(0, -1);

  return [computedFilters, computedLimit, computedOffset]
    .filter((value) => value !== '')
    .join('&');
};
