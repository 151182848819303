import { useTranslation } from 'react-i18next';

import { FormControlProps, TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const TitleField = (props: FormControlProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField
      label={`${t('Title')}:`}
      {...props}
    >
      <TextField name="title" />
    </FormField>
  );
};

export { TitleField };
