import React from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useGetLocale } from 'shared/utils';

import { DatepickerInput } from './DatepickerInput';
import { DatepickerContainer } from './DatepickerContainer';
import { DatepickerHeader } from './DatepickerHeader';
import { DatepickerDay } from './DatepickerDay';

export type DatepickerProps = ReactDatePickerProps;

const Datepicker = (props: DatepickerProps) => {
  const { selected, ...restProps } = props;
  const locale = useGetLocale();
  const value = selected ? new Date(selected) : null;

  return (
    <DatepickerContainer>
      <ReactDatePicker
        dateFormat="MM/dd/yyyy"
        calendarStartDay={1}
        customInput={React.createElement(DatepickerInput)}
        renderDayContents={(dayOfMonth) => (
          <DatepickerDay dayOfMonth={dayOfMonth} />
        )}
        renderCustomHeader={(params) => <DatepickerHeader {...params} />}
        locale={locale}
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, -12],
            },
          },
        ]}
        popperPlacement="bottom-end"
        selected={value}
        {...restProps}
      />
    </DatepickerContainer>
  );
};

export { Datepicker };
