import { Link } from 'react-router-dom';
import { Fragment } from 'react';

import { Box } from 'shared/components';
import { theme } from 'shared/utils/theme';
import { NavMenuItem } from './NavMenuItem';
import { NavSubItem } from './NavItemMenu/NavItemMenu';
import { NavMenuDivider } from './NavMenuDivider';

export type SubItemMenuProps = {
  items: NavSubItem[];
  isLoggedIn: boolean;
};

const styles = {
  zIndex: '1000',
  left: '100%',
  borderRadius: '20px',
  marginLeft: '-3px',
  border: `2px solid ${theme.colors.secondary['500']}`,
  _hover: { backgroundColor: 'secondary.500' },
  overflow: 'hidden',
};

const SubItemMenu = ({ items, isLoggedIn }: SubItemMenuProps) => {
  return (
    <Box
      position="absolute"
      {...styles}
    >
      {items.map((item, i) => {
        const isNotLast = i !== items.length - 1;

        return (
          <Fragment key={item.label}>
            <Link
              to={item.path}
              key={`${item.key}-${item.path}`}
            >
              <NavMenuItem
                menuItemProps={{ minWidth: '108px' }}
                isLoggedIn={isLoggedIn}
              >
                {item.label}
              </NavMenuItem>
            </Link>
            {isNotLast && <NavMenuDivider menuDividerProps={{ opacity: 1 }} />}
          </Fragment>
        );
      })}
    </Box>
  );
};

export { SubItemMenu };
