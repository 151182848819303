import { ReactNode } from 'react';
import {
  AccordionItem as ChakraAccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
} from '@chakra-ui/react';
import { AccordionItemProps } from '@chakra-ui/accordion';

import { ChevronNavMenuIcon } from 'shared/icons';

interface IProps extends Omit<AccordionItemProps, 'children'> {
  sectionTitle: ReactNode;
  children: ReactNode;
}

const AccordionItem = ({ sectionTitle, children, ...restProps }: IProps) => {
  return (
    <ChakraAccordionItem
      border="none"
      sx={{
        button: {
          _hover: {
            backgroundColor: 'transparent',
          },
        },
      }}
      {...restProps}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton>
            <Box
              flex="1"
              textAlign="left"
              fontWeight="700"
              p="12px 5px"
            >
              {sectionTitle}
            </Box>
            <ChevronNavMenuIcon
              isOpen={isExpanded}
              fill="secondary.500"
            />
          </AccordionButton>
          <AccordionPanel>{children}</AccordionPanel>
        </>
      )}
    </ChakraAccordionItem>
  );
};

export { AccordionItem };
