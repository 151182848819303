import { UseQueryOptions } from '@tanstack/react-query';

import { ILocation } from 'entities/company/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { useGetLocale, useGetParsedQueryParams } from 'shared/utils';

export const useGetLocations = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<ILocation[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  const { code: lang } = useGetLocale();
  const _lang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IList<ILocation[]>>(
    getLocationsKey(companyId, parsedQueryParams, _lang),
    () => getLocations(companyId, parsedQueryParams, _lang),
    options,
  );
};

const getLocations = (companyId: string, query?: string, lang = 'en') => {
  return httpClient.get<IList<ILocation[]>>(
    buildUrl('locations', `languageCode=${lang}&companyID=${companyId}&${query}`),
  );
};

const getLocationsKey = (companyId: string, query: string, lang = 'en') => [
  'locations',
  companyId,
  query,
  lang
];
