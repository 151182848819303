import { IconButton, IconButtonProps } from 'shared/components';

import { MobileCloseIcon } from 'shared/icons';

const CloseButton = (props: Omit<IconButtonProps, 'aria-label'>) => {
  return (
    <IconButton
      aria-label="Close"
      icon={<MobileCloseIcon />}
      position="absolute"
      top="23px"
      right="20px"
      background="transparent"
      _hover={{
        background: 'transparent',
      }}
      _active={{
        background: 'transparent',
      }}
      {...props}
    />
  );
};

export { CloseButton };
