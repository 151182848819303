import { useTranslation } from 'react-i18next';
import { IRole, Privileges } from 'entities/role/model';
import { Flex, Text, ViewRow } from 'shared/components';
import { PrivilegesViewRow } from 'features/ViewCommonFields';

interface IProps {
  role: Partial<IRole>;
}

const RoleView = ({ role }: IProps) => {
  const { t } = useTranslation(['commonFields', 'common']);

  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <ViewRow label={t('Active')}>
        <Text>
          {role.active ? t('Yes', { ns: 'common' }) : t('No', { ns: 'common' })}
        </Text>
      </ViewRow>
      <ViewRow label={t('Name')}>
        <Text>{role.name}</Text>
      </ViewRow>
      <PrivilegesViewRow
        privilegesItem={role.privileges}
        privilegesAll={role.privileges
          ?.join()
          .includes(Privileges.FULL_OVER_COMPANY)}
      />
    </Flex>
  );
};

export { RoleView };
