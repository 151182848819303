import { Icon, IconProps } from 'shared/components';

const DeleteIcon = (props: IconProps) => (
  <Icon
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 4.25C13.1685 4.25 12.8505 4.3817 12.6161 4.61612C12.3817 4.85054 12.25 5.16848 12.25 5.5V6.75H19.75V5.5C19.75 5.16848 19.6183 4.85054 19.3839 4.61612C19.1495 4.3817 18.8315 4.25 18.5 4.25H13.5ZM22.25 6.75V5.5C22.25 4.50544 21.8549 3.55161 21.1516 2.84835C20.4484 2.14509 19.4946 1.75 18.5 1.75H13.5C12.5054 1.75 11.5516 2.14509 10.8483 2.84835C10.1451 3.55161 9.75 4.50544 9.75 5.5V6.75H7.25C6.55964 6.75 6 7.30964 6 8V25.5C6 26.4946 6.39509 27.4484 7.09835 28.1516C7.80161 28.8549 8.75544 29.25 9.75 29.25H22.25C23.2446 29.25 24.1984 28.8549 24.9016 28.1516C25.6049 27.4484 26 26.4946 26 25.5V8C26 7.30964 25.4404 6.75 24.75 6.75H22.25ZM8.5 9.25V25.5C8.5 25.8315 8.6317 26.1495 8.86612 26.3839C9.10054 26.6183 9.41848 26.75 9.75 26.75H22.25C22.5815 26.75 22.8995 26.6183 23.1339 26.3839C23.3683 26.1495 23.5 25.8315 23.5 25.5V9.25H8.5Z"
      fill="#72B42D"
    />
    <path
      d="M8.5 25.5V9.25H23.5V25.5C23.5 25.8315 23.3683 26.1495 23.1339 26.3839C22.8995 26.6183 22.5815 26.75 22.25 26.75H9.75C9.41848 26.75 9.10054 26.6183 8.86612 26.3839C8.6317 26.1495 8.5 25.8315 8.5 25.5Z"
      fill="#72B42D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 8C3.5 7.30964 4.05964 6.75 4.75 6.75H27.25C27.9404 6.75 28.5 7.30964 28.5 8C28.5 8.69036 27.9404 9.25 27.25 9.25H4.75C4.05964 9.25 3.5 8.69036 3.5 8Z"
      fill="#72B42D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 13C14.1904 13 14.75 13.5596 14.75 14.25V21.75C14.75 22.4404 14.1904 23 13.5 23C12.8096 23 12.25 22.4404 12.25 21.75V14.25C12.25 13.5596 12.8096 13 13.5 13Z"
      fill="#006FCA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 13C19.1904 13 19.75 13.5596 19.75 14.25V21.75C19.75 22.4404 19.1904 23 18.5 23C17.8096 23 17.25 22.4404 17.25 21.75V14.25C17.25 13.5596 17.8096 13 18.5 13Z"
      fill="#006FCA"
    />
  </Icon>
);

export { DeleteIcon };
