import { MenuItem, MenuItemProps } from 'shared/components';
import { NavMenuItemText, NavMenuItemTextProp } from '../NavMenuItemText';

type NavMenuItemProps = NavMenuItemTextProp & {
  menuItemProps?: MenuItemProps;
};

export const NavMenuItem = ({
  isLoggedIn,
  children,
  menuItemProps,
}: NavMenuItemProps) => {
  return (
    <MenuItem
      padding={'12px 16px'}
      backgroundColor={'primary.500'}
      _hover={{ backgroundColor: 'primary.700' }}
      _focus={{ backgroundColor: 'primary.700' }}
      {...menuItemProps}
    >
      <NavMenuItemText
        navTextProps={{ size: { base: 'xs', xl: 'sm' } }}
        isLoggedIn={isLoggedIn}
      >
        {children}
      </NavMenuItemText>
    </MenuItem>
  );
};
