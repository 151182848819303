import { ReactNode, useState } from 'react';

import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { SaveButton } from 'features/SaveButton';
import {
  AbbreviationField,
  NameField,
  useContainerProps,
} from 'features/FormCommons';

import { FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';

import { IAgencyPayload } from 'entities/agency/model';
import { useAgencyValidationSchema } from './useAgencyValidationSchema';
import { useForm } from 'shared/components/Form';

interface AgencyFormProps {
  handleSubmit: (
    data: IAgencyPayload,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  defaultValues?: IAgencyPayload;
  formId: string;
  additionalActionButtons?: ReactNode;
}

const defaultAgency = {
  name: '',
  abbreviation: '',
};

const AgencyForm = ({
  defaultValues = defaultAgency,
  formId,
  isLoading,
  additionalActionButtons,
  handleSubmit,
}: AgencyFormProps) => {
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const validationSchema = useAgencyValidationSchema();

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });

  return (
    <Form<IAgencyPayload>
      onSubmit={(data, { reset }) =>
        handleSubmit(
          data,
          () => {
            reset(defaultValues);
            cleanPersistentStore();
          },
          submitType,
        )
      }
      id={formId}
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <NameField />

      <AbbreviationField isRequired />

      <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { AgencyForm };
