import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { Button } from 'shared/components';
import { useFormContext } from 'shared/components/Form';

const SubmitResetPasswordFormButton = () => {
  const { t } = useTranslation('resetPasswordForm');
  const {
    formState: { isSubmitting, errors },
  } = useFormContext();

  const isValid = isEmpty(errors);

  return (
    <Button
      variant="secondary"
      type="submit"
      w="100%"
      isDisabled={isSubmitting || !isValid}
      isLoading={isSubmitting}
    >
      {t('Reset password')}
    </Button>
  );
};

export { SubmitResetPasswordFormButton };
