import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Button,
  Text,
  Drawer,
  Image,
  HStack,
  Accordion,
  AccordionItem,
  Box,
  VStack,
  Flex,
} from 'shared/components';
import { useDisclosure } from 'shared/hooks/chakraUi';
import { UserIcon } from 'shared/icons';

import omnis_logo from '../../assets/omnistools_logo.png';
import { protectedItemsMobile, publicItemsMobile } from './mobileMenuData';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { NavItemMobileLogout } from './NavItemMobileLogout';
import { Privileges } from 'entities/role/model';
import { filterWithPrivileges } from 'shared/utils/navigation';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

const MobileMenu = ({
  isLoggedIn,
  activePrivileges,
  components = []
}: {
  isLoggedIn: boolean;
  activePrivileges: Privileges[];
  components?: ICompanyComponent[];

}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const itemsMobile = isLoggedIn
    ? protectedItemsMobile
      .filter(filterWithPrivileges(activePrivileges))
      .filter((item) => item.componentName ? components.includes(item.componentName) : true)
    : publicItemsMobile;

  return (
    <>
      <Button
        w="150px"
        minH={{ base: '43px', md: '48px' }}
        onClick={onOpen}
      >
        {t('Menu')}
      </Button>

      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        header={
          <Image
            src={omnis_logo}
            alt={t('OmnisTools logo')}
            w="auto"
            h="23px"
          />
        }
      >
        {isLoggedIn && (
          <HStack
            p="10px 20px"
            borderColor="secondary.500"
            borderWidth="2px"
            borderRadius="20px"
            gap="8px"
            mb="14px"
          >
            <Text fontWeight="700">Super</Text>
          </HStack>
        )}
        <Accordion>
          {itemsMobile
            .map((menuItem, index) => {
            if (menuItem.items?.length) {
              return (
                <AccordionItem
                  sectionTitle={menuItem.component}
                  mb="10px"
                  key={index}
                >
                  <VStack alignItems="start">
                    {menuItem.items &&
                      menuItem.items
                        .filter(filterWithPrivileges(activePrivileges))
                        .filter(
                          (item) => item.componentName ?
                            components.includes(item.componentName) :
                            true
                        )
                        .map((subItem, index) => {
                          if (subItem.path) {
                            return (
                              <Flex
                                p="12px 16px"
                                as={Link}
                                to={subItem.path}
                                onClick={onClose}
                                key={index}
                              >
                                {subItem.component}
                              </Flex>
                            );
                          }
                          return (
                            <Box
                              p="12px 16px"
                              onClick={onClose}
                              key={index}
                            >
                              {subItem.component}
                            </Box>
                          );
                        })}
                  </VStack>
                </AccordionItem>
              );
            }

            if (menuItem.path) {
              return (
                <Flex
                  p="12px 16px"
                  mb="10px"
                  as={Link}
                  to={menuItem.path}
                  onClick={onClose}
                  key={index}
                  fontWeight="700"
                >
                  {menuItem.component}
                </Flex>
              );
            }
          })}

          {isLoggedIn && <NavItemMobileLogout />}
        </Accordion>

        <LanguageSwitcher btnProps={{ width: '100%', maxW: '100%' }} />
      </Drawer>
    </>
  );
};

export { MobileMenu };
