import { UseQueryOptions } from '@tanstack/react-query';
import { IDepartment } from 'entities/company/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { IList, IQueryParams } from 'shared/types';
import { useGetLocale, useGetParsedQueryParams } from 'shared/utils';
import {
  AxiosError,
  AxiosResponse,
  buildUrl,
  httpClient,
} from 'shared/utils/httpClient';

export const useGetDepartments = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IDepartment[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  const { code: lang } = useGetLocale();
  const _lang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IList<IDepartment[]>>(
    getDepartmentsKey(companyId, parsedQueryParams, _lang),
    () => getDepartments(companyId, parsedQueryParams, _lang),
    options,
  );
};

const getDepartments = (companyId: string, query?: string, lang = 'en') => {
  return httpClient.get<IList<IDepartment[]>>(
    buildUrl('departments', `languageCode=${lang}&companyID=${companyId}&${query}`),
  );
};

const getDepartmentsKey = (companyId: string, query: string, lang = 'en') => [
  'departments',
  companyId,
  query,
  lang
];
