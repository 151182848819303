import { useTranslation } from 'react-i18next';

import { Text, ViewRow } from 'shared/components';
import { useState } from 'react';
import { ISort } from 'shared/types';
import { PersonnelTable } from 'features/person/PersonnelTable';

interface IProps {
  attendeeIDs?: string[];
  companyId: string;
}

// TODO fix limit when filtering by array will be added
const limit = 1000;

const AttendeesViewRow = ({ attendeeIDs, companyId }: IProps) => {
  const { t } = useTranslation('commonFields');
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<ISort[]>();
  const [queryFields, setQueryFields] = useState<string[]>([]);

  return (
    <ViewRow label={t('Attendees')}>
      {attendeeIDs && attendeeIDs.length > 0 ? (
        <PersonnelTable
          setQueryFields={setQueryFields}
          onSortChange={(sort) => setSort(sort)}
          queryParams={{
            limit: limit,
            offset: offset,
            sort: sort,
            fields: queryFields,
          }}
          setOffset={(value) => setOffset(value)}
          attendeeIDs={attendeeIDs}
          companyId={companyId}
        />
      ) : (
        <Text>-</Text>
      )}
    </ViewRow>
  );
};

export { AttendeesViewRow };
