import { ReactNode, useEffect, useState } from 'react';
import { Flex, FlexWrapper, Form } from 'shared/components';
import { Roles } from 'widgets/Roles';
import { PersonnelFormType } from 'entities/user/model';
import { SupervisorContainer } from 'widgets/SupervisorContainer';
import {
  ActiveField,
  DepartmentsField,
  EmailField,
  FirstNameField,
  LastNameField,
  LocationsField,
  LoginField,
  MiddleNameField,
  NicknameField,
  NotificationField,
  PasswordField,
  PasswordVerifyField,
  PhoneNumberField,
  SuffixField,
  TwoFactorAuthField,
  useContainerProps,
} from 'features/FormCommons';
import { usePersonnelFormValidationSchema } from './utils';
import { SubmitFormType } from 'shared/types';
import { SaveButton } from 'features/SaveButton';
import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { useForm } from 'shared/components/Form';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';
import { SupervisorField } from 'features/FormCommons/components/SupervisorField';

type PersonnelFormProps = {
  defaultValues?: PersonnelFormType;
  handleSubmit: (
    data: PersonnelFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  formId: string;
  additionalActionButtons?: ReactNode;
  companyId: string;
  personId?: string;
};

const activeOnlyFilter = { active: true };

const defaultData: PersonnelFormType = {
  name: {
    firstname: '',
    middlename: '',
    lastname: '',
    nickname: '',
  },
  locationIDs: '',
  departmentIDs: '',
  roles: [] as unknown as PersonnelFormType['roles'],
  phoneNumber: '',
  suffix: '',
  email: '',
  active: true,
  supervisorID: '',
  mfaEnabled: false,
  contactPreferences: {
    phone: true,
    email: true,
  },
};
const PersonnelForm = ({
  defaultValues = defaultData,
  handleSubmit,
  formId,
  isLoading,
  additionalActionButtons,
  companyId,
  personId,
}: PersonnelFormProps) => {
  const validationSchema = usePersonnelFormValidationSchema();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const [rolesKey, setRolesKey] = useState(0);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const containerStyleProps = useContainerProps();
  const {
    deletePersonnelPrivilege,
    updatePersonnelPrivilege,
    assignRolePrivilege,
  } = LayoutPrivilege();

  const { methods, cleanPersistentStore } = useForm<PersonnelFormType>({
    validationSchema,
    defaultValues,
    formId,
    persistenceProps: {
      exclude: ['password', 'passwordVerify'],
    },
  });
  const watchRoles = methods.watch('roles') || {};
  const watchRoleIdsArray = Object.values(watchRoles)
    .map((role) => {
      if (role.value) {
        return role._id;
      }
    })
    .filter((item) => item);

  const excludeFieldFromEditForm = formId.includes('edit-personnnel')
    ? 'none'
    : 'grid';

  const disableUpdateWithoutPrivilege =
    formId.includes('edit-personnnel') && !updatePersonnelPrivilege
      ? true
      : false;

  useEffect(() => {
    if (formId.includes('edit-personnel')) {
      cleanPersistentStore();
    }
  }, []);
  return (
    <Form<PersonnelFormType>
      id={formId}
      onSubmit={(data, { reset, getValues }) => {
        handleSubmit(
          data,
          () => {
            cleanPersistentStore();
          },
          submitType,
        );
        setRolesKey(roles => roles + 1);
        reset();
      }}
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <Flex
        alignItems="flex-start"
        gap="20px"
        flexDir="column"
      >
        <Flex
          gap={{ base: '20px', md: '30px', lg: '20px' }}
          width="100%"
          direction={{
            base: 'column',
            lg: 'row',
          }}
        >
          <Flex
            flexDir="column"
            flexGrow="1"
            gap={{
              base: '15px',
              md: '25px',
              lg: '15px',
              xl: '25px',
            }}
          >
            <LocationsField
              filters={activeOnlyFilter}
              isDisabled={disableUpdateWithoutPrivilege}
              companyId={companyId}
            />

            <DepartmentsField
              filters={activeOnlyFilter}
              isDisabled={disableUpdateWithoutPrivilege}
              companyId={companyId}
            />

            <FirstNameField />

            <MiddleNameField />

            <LastNameField />

            <PhoneNumberField />

            <NicknameField />

            <SuffixField />

            <EmailField />

            <LoginField isDisabled={disableUpdateWithoutPrivilege} />

            <PasswordField display={excludeFieldFromEditForm} />

            <PasswordVerifyField display={excludeFieldFromEditForm} />

            <SupervisorField
              omit={[personId || '']}
              currentValue={defaultValues.supervisorID}
              isDisabled={!updatePersonnelPrivilege} companyId={companyId} />

            <TwoFactorAuthField isDisabled={disableUpdateWithoutPrivilege} />

            <ActiveField
              isDisabled={
                formId.includes('edit-personnnel') && !deletePersonnelPrivilege
                  ? true
                  : false
              }
            />

            {formId.includes('edit-personnnel') && (
              <NotificationField
                contactPreferences={defaultValues.contactPreferences}
              />
            )}
          </Flex>
          <Roles
            key={rolesKey}
            isDisabled={
              formId.includes('edit-personnnel') && !assignRolePrivilege
                ? true
                : false
            }
            activeOnly
            companyId={companyId}
            flexGrow="1"
            h="fit-content"
            maxHeight="530px"
            setIsSupervisor={setIsSupervisor}
            watchRoleIdsArray={watchRoleIdsArray}
          />
        </Flex>
        {isSupervisor && (
          <SupervisorContainer
            companyId={companyId}
            personId={personId}
          />
        )}
        <FlexWrapper>
          <SaveButton
            handleClick={() => setSubmitType(SubmitFormType.Save)}
            isLoading={isLoading}
          />
          <SaveAndNewButton
            handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
            isLoading={isLoading}
          />
          {additionalActionButtons}
        </FlexWrapper>
      </Flex>
    </Form>
  );
};

export { PersonnelForm };
