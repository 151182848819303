import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { IRole } from 'entities/role/model';

export const useUpdateRole = (companyId: string, roleId: string) => {
  return useMutation((data: Partial<IRole>) => {
    return updateRole(data, companyId, roleId);
  });
};

const updateRole = (
  formData: Partial<IRole>,
  companyId: string,
  roleId: string,
) => {
  return httpClient.patch(`roles/${roleId}?companyID=${companyId}`, formData);
};
