import { useNavigate } from 'react-router-dom';
import { prepareData } from '../../features/location/LocationForm/utils';
import { useUpdateLocation } from 'entities/company/api';
import { routeBuilder, useToast } from 'shared/utils';
import { ILocation, LocationFormType } from 'entities/company/model';
import { SubmitFormType } from '../../shared/types';
import { LocationForm } from '../../features/location/LocationForm';
import { DeleteLocation } from '../../features/location/DeleteLocation';
import { ViewButton } from '../../features/ViewButton';
import { useTranslation } from 'react-i18next';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  location: ILocation;
}

const EditLocation = ({ companyId, location }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { credits, _id, ...defaultValue } = location;
  const navigation = useNavigate();
  const { mutateAsync, isLoading } = useUpdateLocation(companyId, _id);
  const viewPath = routeBuilder.locations.view.getPath(_id);
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();
  const { deleteLocationPrivilege } = LayoutPrivilege();

  const handleSubmit = async (
    data: LocationFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(prepareData(data));
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.locations.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('location') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('location') }),
      });
    }
  };
  return (
    <LocationForm
      formId={`edit-location-form-${_id}-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          {location.active && deleteLocationPrivilege && (
            <DeleteLocation
              locationId={_id}
              companyId={companyId}
            />
          )}
          <ViewButton onClick={() => navigation(viewPath)} />
        </>
      }
      defaultValues={defaultValue}
    />
  );
};

export { EditLocation };
