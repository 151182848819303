import { useFormContext } from 'react-hook-form';

import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { Checkbox } from 'shared/components';
import { ITopic } from 'entities/tracking/model';
import { useTranslation } from 'react-i18next';

interface IProps {
  topic: ITopic;
}

const TopicsTableRow = ({ topic }: IProps) => {
  const { t } = useTranslation('common');

  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = `topics.${topic._id}`;
  const value = watch(name);

  return (
    <TableRow
      key={topic._id}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell textAlign="center">
        <Checkbox
          isDisabled={isSubmitting}
          size="lg"
          isChecked={value?.value}
          onChange={(event) => {
            setValue(name, {
              _id: topic._id,
              value: event.target.checked,
            });
          }}
        />
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{topic.name}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{topic.active ? t('Yes') : t('No')}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { TopicsTableRow };
