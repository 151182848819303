import { useTranslation } from 'react-i18next';

export const useAgenciesColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'name',
      sortedFields: ['name'],
      title: t('Name'),
      isSortable: true,
    },
    {
      name: 'abbreviation',
      sortedFields: ['abbreviation'],
      title: t('Abbreviation'),
      isSortable: true,
    },
  ];
};
