import { useNavigate } from 'react-router-dom';

import { IUser } from 'entities/user/model';
import {
  TableBodyCell,
  TableBodyInfoCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { routeBuilder } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import { RolesTableCell } from './RolesTableCell';
import { SupervisorTableCell } from './SupervisorTableCell';

interface IProps {
  user: IUser;
  isMobile?: boolean;
}

const PersonnelTableRow = ({ user, isMobile }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('commonFields');

  const handleRedirect = () => {
    navigate(routeBuilder.personnel.view.getPath(user._id));
  };

  const locationName = user.locations
    ? user?.locations?.map((location) => (location?.name ? location.name : '-'))
    : '-';
  const departmentName = user.departments
    ? user?.departments?.map((department) =>
        department?.name ? department.name : '-',
      )
    : '-';

  return (
    <TableRow
      onClick={handleRedirect}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      {isMobile ? (
        <>
          <TableBodyCell>
            <TableCellText>{user.name.lastname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{user.name.firstname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyInfoCell user={user} />
        </>
      ) : (
        <>
          <TableBodyCell>
            <TableCellText>{user.name.lastname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{user.name.firstname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>
              {locationName && locationName.length > 0 ? locationName : '-'}
            </TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>
              {departmentName && departmentName.length > 0
                ? departmentName
                : '-'}
            </TableCellText>
          </TableBodyCell>
          <SupervisorTableCell
            firstName={user.supervisor?.name.firstname}
            lastName={user.supervisor?.name.lastname}
          />
          <RolesTableCell roles={user.roles} />
          <TableBodyCell>
            <TableCellText>{user.login || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{user.active ? t('Yes') : t('No')}</TableCellText>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  );
};

export { PersonnelTableRow };
