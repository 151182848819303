import {
  FormControl,
  FormLabel,
  Box,
  RequiredStar,
  GridProps,
} from 'shared/components/index';

interface IProps extends GridProps {
  label: string;
  isRequired?: boolean;
}

const FormField = ({ label, children, isRequired, ...props }: IProps) => {
  return (
    <FormControl
      gap={{
        base: '10px',
        md: '20px',
        lg: '10px',
        xl: '20px',
      }}
      display="grid"
      gridTemplateColumns={{ md: '250px 1fr' }}
      w="100%"
      h="auto"
      alignItems="center"
      {...props}
    >
      <FormLabel
        size={{ base: 'sm', md: 'md' }}
        lineHeight="18px"
        display="flex"
        gap="5px"
        margin="0"
      >
        {label}
        {isRequired && <RequiredStar />}
      </FormLabel>
      <Box
        w="100%"
        maxW={{ base: '100%', lg: '600px' }}
        justifySelf={{ md: 'end', lg: 'start' }}
      >
        {children}
      </Box>
    </FormControl>
  );
};

export { FormField };
