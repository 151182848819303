import { useTranslation } from 'react-i18next';

import { ErrorBoundary, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';
import { useGetActivities } from 'entities/tracking/api';
import { ISelectFieldProps } from 'shared/components/Form/SelectField';

interface IProps {
  companyId: string;
  name?: string;
  inputProps?: Partial<ISelectFieldProps>;
}

const ActivitiesFieldThrowable = ({
  companyId,
  name = 'activities',
  ...restProps
}: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetActivities(
    companyId,
    {
      sort: [{ name: 'name', sortDirection: 'asc' }],
    },
    { useErrorBoundary: false },
  );

  const options = [
    { value: '', label: t('By Activity') },
    ...(data
      ? data.data.data.map((activity) => {
          return {
            value: activity._id,
            label: activity.name,
          };
        })
      : []),
  ];
  return (
    <FormField label={`${t('Activity')}:`}>
      <SelectField
        name={name}
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
        {...restProps.inputProps}
      />
    </FormField>
  );
};

const ActivitiesField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Activity')}:`}>
          <SelectField
            name={props.name || 'activities'}
            isDisabled
            {...props.inputProps}
          />
        </FormField>
      )}
    >
      <ActivitiesFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { ActivitiesField };
