import { useEffect, useState } from 'react';

import { DateObject, DateObjectType } from 'shared/utils';
import { Flex, Text } from 'shared/components';

import { HourBox } from './HourBox';
import { MinutesBox } from './MinutesBox';
import { BeforeAfterMidnightBox } from './BeforeAfterMidnightBox';

interface IProps {
  onChange?: (date: DateObjectType) => void;
  date: DateObjectType | null;
}

const TimeContainer = ({ onChange, date }: IProps) => {
  const [localDate, setLocalDate] = useState<DateObjectType>(
    date || DateObject(new Date()),
  );

  useEffect(() => {
    onChange && onChange(localDate);
  }, [localDate]);

  return (
    <Flex
      h="125px"
      border="2px solid"
      borderColor="secondary.500"
      position="absolute"
      backgroundColor="white"
      zIndex="10"
      right="0"
      maxW="300px"
    >
      <Flex
        dir="column"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        px="30px"
        gap="30px"
      >
        <Flex
          dir="column"
          gap="10px"
        >
          <Text
            size="xs"
            fontWeight="700"
          >
            {localDate.format('hh:mm')}
          </Text>
          <Text
            size="xs"
            fontWeight="700"
          >
            {localDate.format('a')}
          </Text>
        </Flex>

        <Flex
          dir="column"
          alignItems="center"
          gap="16px"
        >
          <Flex
            dir="column"
            alignItems="center"
            gap="8px"
          >
            <HourBox
              time={localDate}
              setTime={setLocalDate}
            />
            <Text size="xs">:</Text>
            <MinutesBox
              time={localDate}
              setTime={setLocalDate}
            />
          </Flex>
          <BeforeAfterMidnightBox
            time={localDate}
            setTime={setLocalDate}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export { TimeContainer };
