import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';
import useStore from 'shared/store';
import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { useGetVendor, useUpdateVendor } from 'entities/vendor/api';
import { AddVendor } from 'entities/vendor/model';

import { CustomerVendorForm } from 'widgets/CustomerVendorForm/CustomerVendorForm';
import { ViewButton } from 'features/ViewButton';
import { DeleteVendor } from 'features/vendor/DeleteVendor';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

const VendorEditPageThrowable = () => {
  const { t } = useTranslation(['popupMessage', 'common']);

  const companyId = useStore((state) => state.company?._id);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);

  const { vendorId } = useParams();
  const { successToast, errorToast } = useToast();
  const { deleteVendorPrivilege } = LayoutPrivilege();

  const formId = `vendor-edit-form-${vendorId}-${companyId}`;

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  if (!vendorId) {
    throw new Error('VendorId not found');
  }

  const mutation = useUpdateVendor(vendorId, companyId);
  const navigation = useNavigate();

  const { isLoading, error, data } = useGetVendor(companyId, vendorId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Vendor not found', { ns: 'common' })}</Text>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { credits, companyID, ...defaultValue } = data.data;

  const viewPath = routeBuilder.vendor.view.getPath(vendorId);

  const handleSubmit = async (
    data: AddVendor,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutation.mutateAsync({
        ...data,
        attachmentIDs: [
          ...filesToConnect
            .filter((file) => file.parent === formId)
            .map((filesToConnect) => filesToConnect._id),
        ],
      });

      setFilesToConnect(
        filesToConnect.filter((file) => file.parent !== formId),
      );

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.vendor.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('vendor') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('vendor') }),
      });
    }
  };

  return (
    <CustomerVendorForm
      handleSubmit={handleSubmit}
      defaultValues={defaultValue}
      isLoading={mutation.isLoading}
      formId={formId}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          {data.data.active && deleteVendorPrivilege && (
            <DeleteVendor
              companyId={companyId}
              vendorId={vendorId}
            />
          )}
        </>
      }
      variant="vendor"
    />
  );
};

export const VendorEditPage = () => {
  return (
    <ErrorBoundary>
      <VendorEditPageThrowable />
    </ErrorBoundary>
  );
};
