import { useTranslation } from 'react-i18next';
import { Text, TextProps } from 'shared/components';

const MobileMenuItem = ({ children, ...restProps }: TextProps) => {
  const { t } = useTranslation('breadcrumbs');

  return (
    <Text
      overflowWrap="anywhere"
      {...restProps}
    >
      {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore */}
      {t(children)}
    </Text>
  );
};

export { MobileMenuItem };
