import { ReactNode, useEffect, useState } from 'react';

import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { SaveButton } from 'features/SaveButton';
import {
  ActiveField,
  NameField,
  useContainerProps,
} from 'features/FormCommons';
import { ActionFormType } from 'entities/tracking/model';

import { FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';

import { useNameValidation } from 'shared/utils/yup';
import { useForm } from 'shared/components/Form';

type ActionFormProps = {
  handleSubmit: (
    data: ActionFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  defaultValues?: ActionFormType;
  formId: string;
  additionalActionButtons?: ReactNode;
};

const defaultAction = {
  name: '',
  active: true,
};

const ActionForm = ({
  defaultValues = defaultAction,
  formId,
  isLoading,
  additionalActionButtons,
  handleSubmit,
}: ActionFormProps) => {
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const validationSchema = useNameValidation();

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });

  useEffect(() => {
    if (formId.includes('edit-action')) {
      cleanPersistentStore();
    }
  }, []);

  return (
    <Form<ActionFormType>
      onSubmit={(data, { reset }) => {
        handleSubmit(
          data,
          () => {
            cleanPersistentStore();
          },
          submitType,
        );
        reset(defaultValues);
      }}
      id={formId}
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <ActiveField />

      <NameField />

      <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { ActionForm };
