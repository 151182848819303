import { ITopic } from 'entities/tracking/model';
import { TableCellText, TableCellProps, Text } from 'shared/components';
import { TableBodyCell } from 'shared/components/Table';

interface IProps extends TableCellProps {
  topic: ITopic[] | undefined;
}

const TopicTableCell = ({ topic, ...restProps }: IProps) => {
  return (
    <TableBodyCell
      maxW="250px"
      {...restProps}
    >
      {topic ? (
        <>
          {topic.map((t) => {
            return (
              <TableCellText key={t._id}>{`${t?.name || '-'}`}</TableCellText>
            );
          })}
        </>
      ) : (
        <Text>-</Text>
      )}
    </TableBodyCell>
  );
};

export { TopicTableCell };
