import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  contactPersonName?: string;
}

const ContactPersonNameViewRow = ({ contactPersonName }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Contact Person')}>
      <Text>{contactPersonName || '-'}</Text>
    </ViewRow>
  );
};

export { ContactPersonNameViewRow };
