import { useTranslation } from 'react-i18next';

export const useRegulationsColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'agency',
      sortedFields: ['agency.name'],
      title: t('Agency'),
      isSortable: true,
    },
    {
      name: 'title',
      sortedFields: ['title'],
      title: t('Title'),
      isSortable: true,
    },
    {
      name: 'section',
      sortedFields: ['section'],
      title: t('Section'),
      isSortable: true,
    },
    {
      name: 'description',
      sortedFields: ['description'],
      title: t('Description'),
      isSortable: true,
    },
  ];
};
