import { BrowserRouter } from 'react-router-dom';

import { ChildrenProp } from 'shared/types';

import { ReactQueryClientProvider } from 'app/providers/ReactQueryClientProvider';
import { ChakraProvider } from 'app/providers/ChakraProvider';

type Props = ChildrenProp;

const Providers = ({ children }: Props) => {
  return (
    <ReactQueryClientProvider>
      <ChakraProvider>
        <BrowserRouter>{children}</BrowserRouter>
      </ChakraProvider>
    </ReactQueryClientProvider>
  );
};

export { Providers };
