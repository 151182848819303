import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

import { PersonnelFinalType } from 'entities/user/model';

export const useSaveUser = (companyId: string) => {
  return useMutation((data: PersonnelFinalType) => {
    return saveUser(data, companyId);
  });
};

const saveUser = (formData: PersonnelFinalType, companyId: string) => {
  return httpClient.post<PersonnelFinalType, AxiosResponse<{ _id: string }>>(
    `users?companyID=${companyId}`,
    formData,
  );
};
