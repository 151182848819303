import { FieldPrototype } from './FieldPrototype';
import { Controller } from 'react-hook-form';
import { TimeZonePicker } from '../TimeZonePicker';
import { forwardRef } from 'react';

export interface ITimeZonePickerField {
  name: string;
  trackingTime: {
    time: string;
    zone: string;
  };
}

const TimeZonePickerField = forwardRef(
  ({ name, trackingTime }: ITimeZonePickerField, ref) => {
    return (
      <FieldPrototype name={name}>
        <Controller
          name={name}
          render={({
            field: { onChange, value },
            formState: { isSubmitting },
          }) => {
            return (
              <TimeZonePicker
                data-testid={name}
                isDisabled={isSubmitting}
                onChange={onChange}
                ref={ref}
                value={{ ...value, ...trackingTime }}
              />
            );
          }}
        />
      </FieldPrototype>
    );
  },
);
TimeZonePickerField.displayName = 'TimeZonePickerField';
export { TimeZonePickerField };
