import { ITimeZone } from './ITimeZone';

export const timezonesList: ITimeZone[] = JSON.parse(`[
  {
     "name":"(−12:00) Etc/GMT+12",
     "timezone":"Etc/GMT+12"
  },
  {
     "name":"(−11:00) Etc/GMT+11",
     "timezone":"Etc/GMT+11"
  },
  {
     "name":"(−11:00) Pacific/Niue",
     "timezone":"Pacific/Niue"
  },
  {
     "name":"(−11:00) Pacific/Pago_Pago",
     "timezone":"Pacific/Pago_Pago"
  },
  {
     "name":"(−10:00) America/Adak",
     "timezone":"America/Adak"
  },
  {
     "name":"(−10:00) Etc/GMT+10",
     "timezone":"Etc/GMT+10"
  },
  {
     "name":"(−10:00) HST",
     "timezone":"HST"
  },
  {
     "name":"(−10:00) Pacific/Honolulu",
     "timezone":"Pacific/Honolulu"
  },
  {
     "name":"(−10:00) Pacific/Rarotonga",
     "timezone":"Pacific/Rarotonga"
  },
  {
     "name":"(−10:00) Pacific/Tahiti",
     "timezone":"Pacific/Tahiti"
  },
  {
     "name":"(−09:30) Pacific/Marquesas",
     "timezone":"Pacific/Marquesas"
  },
  {
     "name":"(−09:00) America/Anchorage",
     "timezone":"America/Anchorage"
  },
  {
     "name":"(−09:00) America/Juneau",
     "timezone":"America/Juneau"
  },
  {
     "name":"(−09:00) America/Metlakatla",
     "timezone":"America/Metlakatla"
  },
  {
     "name":"(−09:00) America/Nome",
     "timezone":"America/Nome"
  },
  {
     "name":"(−09:00) America/Sitka",
     "timezone":"America/Sitka"
  },
  {
     "name":"(−09:00) America/Yakutat",
     "timezone":"America/Yakutat"
  },
  {
     "name":"(−09:00) Etc/GMT+9",
     "timezone":"Etc/GMT+9"
  },
  {
     "name":"(−09:00) Pacific/Gambier",
     "timezone":"Pacific/Gambier"
  },
  {
     "name":"(−08:00) America/Los_Angeles",
     "timezone":"America/Los_Angeles"
  },
  {
     "name":"(−08:00) America/Tijuana",
     "timezone":"America/Tijuana"
  },
  {
     "name":"(−08:00) America/Vancouver",
     "timezone":"America/Vancouver"
  },
  {
     "name":"(−08:00) Etc/GMT+8",
     "timezone":"Etc/GMT+8"
  },
  {
     "name":"(−08:00) Pacific/Pitcairn",
     "timezone":"Pacific/Pitcairn"
  },
  {
     "name":"(−08:00) PST8PDT",
     "timezone":"PST8PDT"
  },
  {
     "name":"(−07:00) America/Boise",
     "timezone":"America/Boise"
  },
  {
     "name":"(−07:00) America/Cambridge_Bay",
     "timezone":"America/Cambridge_Bay"
  },
  {
     "name":"(−07:00) America/Ciudad_Juarez",
     "timezone":"America/Ciudad_Juarez"
  },
  {
     "name":"(−07:00) America/Dawson",
     "timezone":"America/Dawson"
  },
  {
     "name":"(−07:00) America/Dawson_Creek",
     "timezone":"America/Dawson_Creek"
  },
  {
     "name":"(−07:00) America/Denver",
     "timezone":"America/Denver"
  },
  {
     "name":"(−07:00) America/Edmonton",
     "timezone":"America/Edmonton"
  },
  {
     "name":"(−07:00) America/Fort_Nelson",
     "timezone":"America/Fort_Nelson"
  },
  {
     "name":"(−07:00) America/Hermosillo",
     "timezone":"America/Hermosillo"
  },
  {
     "name":"(−07:00) America/Inuvik",
     "timezone":"America/Inuvik"
  },
  {
     "name":"(−07:00) America/Mazatlan",
     "timezone":"America/Mazatlan"
  },
  {
     "name":"(−07:00) America/Phoenix",
     "timezone":"America/Phoenix"
  },
  {
     "name":"(−07:00) America/Whitehorse",
     "timezone":"America/Whitehorse"
  },
  {
     "name":"(−07:00) Etc/GMT+7",
     "timezone":"Etc/GMT+7"
  },
  {
     "name":"(−07:00) MST",
     "timezone":"MST"
  },
  {
     "name":"(−07:00) MST7MDT",
     "timezone":"MST7MDT"
  },
  {
     "name":"(−06:00) America/Bahia_Banderas",
     "timezone":"America/Bahia_Banderas"
  },
  {
     "name":"(−06:00) America/Belize",
     "timezone":"America/Belize"
  },
  {
     "name":"(−06:00) America/Chicago",
     "timezone":"America/Chicago"
  },
  {
     "name":"(−06:00) America/Chihuahua",
     "timezone":"America/Chihuahua"
  },
  {
     "name":"(−06:00) America/Costa_Rica",
     "timezone":"America/Costa_Rica"
  },
  {
     "name":"(−06:00) America/El_Salvador",
     "timezone":"America/El_Salvador"
  },
  {
     "name":"(−06:00) America/Guatemala",
     "timezone":"America/Guatemala"
  },
  {
     "name":"(−06:00) America/Indiana/Knox",
     "timezone":"America/Indiana/Knox"
  },
  {
     "name":"(−06:00) America/Indiana/Tell_City",
     "timezone":"America/Indiana/Tell_City"
  },
  {
     "name":"(−06:00) America/Managua",
     "timezone":"America/Managua"
  },
  {
     "name":"(−06:00) America/Matamoros",
     "timezone":"America/Matamoros"
  },
  {
     "name":"(−06:00) America/Menominee",
     "timezone":"America/Menominee"
  },
  {
     "name":"(−06:00) America/Merida",
     "timezone":"America/Merida"
  },
  {
     "name":"(−06:00) America/Mexico_City",
     "timezone":"America/Mexico_City"
  },
  {
     "name":"(−06:00) America/Monterrey",
     "timezone":"America/Monterrey"
  },
  {
     "name":"(−06:00) America/North_Dakota/Beulah",
     "timezone":"America/North_Dakota/Beulah"
  },
  {
     "name":"(−06:00) America/North_Dakota/Center",
     "timezone":"America/North_Dakota/Center"
  },
  {
     "name":"(−06:00) America/North_Dakota/New_Salem",
     "timezone":"America/North_Dakota/New_Salem"
  },
  {
     "name":"(−06:00) America/Ojinaga",
     "timezone":"America/Ojinaga"
  },
  {
     "name":"(−06:00) America/Rankin_Inlet",
     "timezone":"America/Rankin_Inlet"
  },
  {
     "name":"(−06:00) America/Regina",
     "timezone":"America/Regina"
  },
  {
     "name":"(−06:00) America/Resolute",
     "timezone":"America/Resolute"
  },
  {
     "name":"(−06:00) America/Swift_Current",
     "timezone":"America/Swift_Current"
  },
  {
     "name":"(−06:00) America/Tegucigalpa",
     "timezone":"America/Tegucigalpa"
  },
  {
     "name":"(−06:00) America/Winnipeg",
     "timezone":"America/Winnipeg"
  },
  {
     "name":"(−06:00) CST6CDT",
     "timezone":"CST6CDT"
  },
  {
     "name":"(−06:00) Etc/GMT+6",
     "timezone":"Etc/GMT+6"
  },
  {
     "name":"(−06:00) Pacific/Easter",
     "timezone":"Pacific/Easter"
  },
  {
     "name":"(−06:00) Pacific/Galapagos",
     "timezone":"Pacific/Galapagos"
  },
  {
     "name":"(−05:00) America/Bogota",
     "timezone":"America/Bogota"
  },
  {
     "name":"(−05:00) America/Cancun",
     "timezone":"America/Cancun"
  },
  {
     "name":"(−05:00) America/Detroit",
     "timezone":"America/Detroit"
  },
  {
     "name":"(−05:00) America/Eirunepe",
     "timezone":"America/Eirunepe"
  },
  {
     "name":"(−05:00) America/Grand_Turk",
     "timezone":"America/Grand_Turk"
  },
  {
     "name":"(−05:00) America/Guayaquil",
     "timezone":"America/Guayaquil"
  },
  {
     "name":"(−05:00) America/Havana",
     "timezone":"America/Havana"
  },
  {
     "name":"(−05:00) America/Indiana/Indianapolis",
     "timezone":"America/Indiana/Indianapolis"
  },
  {
     "name":"(−05:00) America/Indiana/Marengo",
     "timezone":"America/Indiana/Marengo"
  },
  {
     "name":"(−05:00) America/Indiana/Petersburg",
     "timezone":"America/Indiana/Petersburg"
  },
  {
     "name":"(−05:00) America/Indiana/Vevay",
     "timezone":"America/Indiana/Vevay"
  },
  {
     "name":"(−05:00) America/Indiana/Vincennes",
     "timezone":"America/Indiana/Vincennes"
  },
  {
     "name":"(−05:00) America/Indiana/Winamac",
     "timezone":"America/Indiana/Winamac"
  },
  {
     "name":"(−05:00) America/Iqaluit",
     "timezone":"America/Iqaluit"
  },
  {
     "name":"(−05:00) America/Jamaica",
     "timezone":"America/Jamaica"
  },
  {
     "name":"(−05:00) America/Kentucky/Louisville",
     "timezone":"America/Kentucky/Louisville"
  },
  {
     "name":"(−05:00) America/Kentucky/Monticello",
     "timezone":"America/Kentucky/Monticello"
  },
  {
     "name":"(−05:00) America/Lima",
     "timezone":"America/Lima"
  },
  {
     "name":"(−05:00) America/New_York",
     "timezone":"America/New_York"
  },
  {
     "name":"(−05:00) America/Panama",
     "timezone":"America/Panama"
  },
  {
     "name":"(−05:00) America/Port-au-Prince",
     "timezone":"America/Port-au-Prince"
  },
  {
     "name":"(−05:00) America/Rio_Branco",
     "timezone":"America/Rio_Branco"
  },
  {
     "name":"(−05:00) America/Toronto",
     "timezone":"America/Toronto"
  },
  {
     "name":"(−05:00) EST",
     "timezone":"EST"
  },
  {
     "name":"(−05:00) EST5EDT",
     "timezone":"EST5EDT"
  },
  {
     "name":"(−05:00) Etc/GMT+5",
     "timezone":"Etc/GMT+5"
  },
  {
     "name":"(−04:00) America/Asuncion",
     "timezone":"America/Asuncion"
  },
  {
     "name":"(−04:00) America/Barbados",
     "timezone":"America/Barbados"
  },
  {
     "name":"(−04:00) America/Boa_Vista",
     "timezone":"America/Boa_Vista"
  },
  {
     "name":"(−04:00) America/Campo_Grande",
     "timezone":"America/Campo_Grande"
  },
  {
     "name":"(−04:00) America/Caracas",
     "timezone":"America/Caracas"
  },
  {
     "name":"(−04:00) America/Cuiaba",
     "timezone":"America/Cuiaba"
  },
  {
     "name":"(−04:00) America/Glace_Bay",
     "timezone":"America/Glace_Bay"
  },
  {
     "name":"(−04:00) America/Goose_Bay",
     "timezone":"America/Goose_Bay"
  },
  {
     "name":"(−04:00) America/Guyana",
     "timezone":"America/Guyana"
  },
  {
     "name":"(−04:00) America/Halifax",
     "timezone":"America/Halifax"
  },
  {
     "name":"(−04:00) America/La_Paz",
     "timezone":"America/La_Paz"
  },
  {
     "name":"(−04:00) America/Manaus",
     "timezone":"America/Manaus"
  },
  {
     "name":"(−04:00) America/Martinique",
     "timezone":"America/Martinique"
  },
  {
     "name":"(−04:00) America/Moncton",
     "timezone":"America/Moncton"
  },
  {
     "name":"(−04:00) America/Porto_Velho",
     "timezone":"America/Porto_Velho"
  },
  {
     "name":"(−04:00) America/Puerto_Rico",
     "timezone":"America/Puerto_Rico"
  },
  {
     "name":"(−04:00) America/Santiago",
     "timezone":"America/Santiago"
  },
  {
     "name":"(−04:00) America/Santo_Domingo",
     "timezone":"America/Santo_Domingo"
  },
  {
     "name":"(−04:00) America/Thule",
     "timezone":"America/Thule"
  },
  {
     "name":"(−04:00) Atlantic/Bermuda",
     "timezone":"Atlantic/Bermuda"
  },
  {
     "name":"(−04:00) Etc/GMT+4",
     "timezone":"Etc/GMT+4"
  },
  {
     "name":"(−03:30) America/St_Johns",
     "timezone":"America/St_Johns"
  },
  {
     "name":"(−03:00) America/Araguaina",
     "timezone":"America/Araguaina"
  },
  {
     "name":"(−03:00) America/Argentina/Buenos_Aires",
     "timezone":"America/Argentina/Buenos_Aires"
  },
  {
     "name":"(−03:00) America/Argentina/Catamarca",
     "timezone":"America/Argentina/Catamarca"
  },
  {
     "name":"(−03:00) America/Argentina/Cordoba",
     "timezone":"America/Argentina/Cordoba"
  },
  {
     "name":"(−03:00) America/Argentina/Jujuy",
     "timezone":"America/Argentina/Jujuy"
  },
  {
     "name":"(−03:00) America/Argentina/La_Rioja",
     "timezone":"America/Argentina/La_Rioja"
  },
  {
     "name":"(−03:00) America/Argentina/Mendoza",
     "timezone":"America/Argentina/Mendoza"
  },
  {
     "name":"(−03:00) America/Argentina/Rio_Gallegos",
     "timezone":"America/Argentina/Rio_Gallegos"
  },
  {
     "name":"(−03:00) America/Argentina/Salta",
     "timezone":"America/Argentina/Salta"
  },
  {
     "name":"(−03:00) America/Argentina/San_Juan",
     "timezone":"America/Argentina/San_Juan"
  },
  {
     "name":"(−03:00) America/Argentina/San_Luis",
     "timezone":"America/Argentina/San_Luis"
  },
  {
     "name":"(−03:00) America/Argentina/Tucuman",
     "timezone":"America/Argentina/Tucuman"
  },
  {
     "name":"(−03:00) America/Argentina/Ushuaia",
     "timezone":"America/Argentina/Ushuaia"
  },
  {
     "name":"(−03:00) America/Bahia",
     "timezone":"America/Bahia"
  },
  {
     "name":"(−03:00) America/Belem",
     "timezone":"America/Belem"
  },
  {
     "name":"(−03:00) America/Cayenne",
     "timezone":"America/Cayenne"
  },
  {
     "name":"(−03:00) America/Fortaleza",
     "timezone":"America/Fortaleza"
  },
  {
     "name":"(−03:00) America/Maceio",
     "timezone":"America/Maceio"
  },
  {
     "name":"(−03:00) America/Miquelon",
     "timezone":"America/Miquelon"
  },
  {
     "name":"(−03:00) America/Montevideo",
     "timezone":"America/Montevideo"
  },
  {
     "name":"(−03:00) America/Paramaribo",
     "timezone":"America/Paramaribo"
  },
  {
     "name":"(−03:00) America/Punta_Arenas",
     "timezone":"America/Punta_Arenas"
  },
  {
     "name":"(−03:00) America/Recife",
     "timezone":"America/Recife"
  },
  {
     "name":"(−03:00) America/Santarem",
     "timezone":"America/Santarem"
  },
  {
     "name":"(−03:00) America/Sao_Paulo",
     "timezone":"America/Sao_Paulo"
  },
  {
     "name":"(−03:00) Antarctica/Palmer",
     "timezone":"Antarctica/Palmer"
  },
  {
     "name":"(−03:00) Antarctica/Rothera",
     "timezone":"Antarctica/Rothera"
  },
  {
     "name":"(−03:00) Atlantic/Stanley",
     "timezone":"Atlantic/Stanley"
  },
  {
     "name":"(−03:00) Etc/GMT+3",
     "timezone":"Etc/GMT+3"
  },
  {
     "name":"(−02:00) America/Noronha",
     "timezone":"America/Noronha"
  },
  {
     "name":"(−02:00) America/Nuuk",
     "timezone":"America/Nuuk"
  },
  {
     "name":"(−02:00) Atlantic/South_Georgia",
     "timezone":"Atlantic/South_Georgia"
  },
  {
     "name":"(−02:00) Etc/GMT+2",
     "timezone":"Etc/GMT+2"
  },
  {
     "name":"(−01:00) America/Scoresbysund",
     "timezone":"America/Scoresbysund"
  },
  {
     "name":"(−01:00) Atlantic/Azores",
     "timezone":"Atlantic/Azores"
  },
  {
     "name":"(−01:00) Atlantic/Cape_Verde",
     "timezone":"Atlantic/Cape_Verde"
  },
  {
     "name":"(−01:00) Etc/GMT+1",
     "timezone":"Etc/GMT+1"
  },
  {
     "name":"(+00:00) Africa/Abidjan",
     "timezone":"Africa/Abidjan"
  },
  {
     "name":"(+00:00) Africa/Bissau",
     "timezone":"Africa/Bissau"
  },
  {
     "name":"(+00:00) Africa/Monrovia",
     "timezone":"Africa/Monrovia"
  },
  {
     "name":"(+00:00) Africa/Sao_Tome",
     "timezone":"Africa/Sao_Tome"
  },
  {
     "name":"(+00:00) America/Danmarkshavn",
     "timezone":"America/Danmarkshavn"
  },
  {
     "name":"(+00:00) Antarctica/Troll",
     "timezone":"Antarctica/Troll"
  },
  {
     "name":"(+00:00) Atlantic/Canary",
     "timezone":"Atlantic/Canary"
  },
  {
     "name":"(+00:00) Atlantic/Faroe",
     "timezone":"Atlantic/Faroe"
  },
  {
     "name":"(+00:00) Atlantic/Madeira",
     "timezone":"Atlantic/Madeira"
  },
  {
     "name":"(+00:00) Etc/GMT",
     "timezone":"Etc/GMT"
  },
  {
     "name":"(+00:00) Etc/UTC",
     "timezone":"Etc/UTC"
  },
  {
     "name":"(+00:00) Europe/Lisbon",
     "timezone":"Europe/Lisbon"
  },
  {
     "name":"(+00:00) Europe/London",
     "timezone":"Europe/London"
  },
  {
     "name":"(+00:00) Factory",
     "timezone":"Factory"
  },
  {
     "name":"(+00:00) WET",
     "timezone":"WET"
  },
  {
     "name":"(+01:00) Africa/Algiers",
     "timezone":"Africa/Algiers"
  },
  {
     "name":"(+01:00) Africa/Casablanca",
     "timezone":"Africa/Casablanca"
  },
  {
     "name":"(+01:00) Africa/Ceuta",
     "timezone":"Africa/Ceuta"
  },
  {
     "name":"(+01:00) Africa/El_Aaiun",
     "timezone":"Africa/El_Aaiun"
  },
  {
     "name":"(+01:00) Africa/Lagos",
     "timezone":"Africa/Lagos"
  },
  {
     "name":"(+01:00) Africa/Ndjamena",
     "timezone":"Africa/Ndjamena"
  },
  {
     "name":"(+01:00) Africa/Tunis",
     "timezone":"Africa/Tunis"
  },
  {
     "name":"(+01:00) CET",
     "timezone":"CET"
  },
  {
     "name":"(+01:00) Etc/GMT-1",
     "timezone":"Etc/GMT-1"
  },
  {
     "name":"(+01:00) Europe/Andorra",
     "timezone":"Europe/Andorra"
  },
  {
     "name":"(+01:00) Europe/Belgrade",
     "timezone":"Europe/Belgrade"
  },
  {
     "name":"(+01:00) Europe/Berlin",
     "timezone":"Europe/Berlin"
  },
  {
     "name":"(+01:00) Europe/Brussels",
     "timezone":"Europe/Brussels"
  },
  {
     "name":"(+01:00) Europe/Budapest",
     "timezone":"Europe/Budapest"
  },
  {
     "name":"(+01:00) Europe/Dublin",
     "timezone":"Europe/Dublin"
  },
  {
     "name":"(+01:00) Europe/Gibraltar",
     "timezone":"Europe/Gibraltar"
  },
  {
     "name":"(+01:00) Europe/Madrid",
     "timezone":"Europe/Madrid"
  },
  {
     "name":"(+01:00) Europe/Malta",
     "timezone":"Europe/Malta"
  },
  {
     "name":"(+01:00) Europe/Paris",
     "timezone":"Europe/Paris"
  },
  {
     "name":"(+01:00) Europe/Prague",
     "timezone":"Europe/Prague"
  },
  {
     "name":"(+01:00) Europe/Rome",
     "timezone":"Europe/Rome"
  },
  {
     "name":"(+01:00) Europe/Tirane",
     "timezone":"Europe/Tirane"
  },
  {
     "name":"(+01:00) Europe/Vienna",
     "timezone":"Europe/Vienna"
  },
  {
     "name":"(+01:00) Europe/Warsaw",
     "timezone":"Europe/Warsaw"
  },
  {
     "name":"(+01:00) Europe/Zurich",
     "timezone":"Europe/Zurich"
  },
  {
     "name":"(+01:00) MET",
     "timezone":"MET"
  },
  {
     "name":"(+02:00) Africa/Cairo",
     "timezone":"Africa/Cairo"
  },
  {
     "name":"(+02:00) Africa/Johannesburg",
     "timezone":"Africa/Johannesburg"
  },
  {
     "name":"(+02:00) Africa/Juba",
     "timezone":"Africa/Juba"
  },
  {
     "name":"(+02:00) Africa/Khartoum",
     "timezone":"Africa/Khartoum"
  },
  {
     "name":"(+02:00) Africa/Maputo",
     "timezone":"Africa/Maputo"
  },
  {
     "name":"(+02:00) Africa/Tripoli",
     "timezone":"Africa/Tripoli"
  },
  {
     "name":"(+02:00) Africa/Windhoek",
     "timezone":"Africa/Windhoek"
  },
  {
     "name":"(+02:00) Asia/Beirut",
     "timezone":"Asia/Beirut"
  },
  {
     "name":"(+02:00) Asia/Famagusta",
     "timezone":"Asia/Famagusta"
  },
  {
     "name":"(+02:00) Asia/Gaza",
     "timezone":"Asia/Gaza"
  },
  {
     "name":"(+02:00) Asia/Hebron",
     "timezone":"Asia/Hebron"
  },
  {
     "name":"(+02:00) Asia/Jerusalem",
     "timezone":"Asia/Jerusalem"
  },
  {
     "name":"(+02:00) Asia/Nicosia",
     "timezone":"Asia/Nicosia"
  },
  {
     "name":"(+02:00) EET",
     "timezone":"EET"
  },
  {
     "name":"(+02:00) Etc/GMT-2",
     "timezone":"Etc/GMT-2"
  },
  {
     "name":"(+02:00) Europe/Athens",
     "timezone":"Europe/Athens"
  },
  {
     "name":"(+02:00) Europe/Bucharest",
     "timezone":"Europe/Bucharest"
  },
  {
     "name":"(+02:00) Europe/Chisinau",
     "timezone":"Europe/Chisinau"
  },
  {
     "name":"(+02:00) Europe/Helsinki",
     "timezone":"Europe/Helsinki"
  },
  {
     "name":"(+02:00) Europe/Kaliningrad",
     "timezone":"Europe/Kaliningrad"
  },
  {
     "name":"(+02:00) Europe/Kyiv",
     "timezone":"Europe/Kyiv"
  },
  {
     "name":"(+02:00) Europe/Riga",
     "timezone":"Europe/Riga"
  },
  {
     "name":"(+02:00) Europe/Sofia",
     "timezone":"Europe/Sofia"
  },
  {
     "name":"(+02:00) Europe/Tallinn",
     "timezone":"Europe/Tallinn"
  },
  {
     "name":"(+02:00) Europe/Vilnius",
     "timezone":"Europe/Vilnius"
  },
  {
     "name":"(+03:00) Africa/Nairobi",
     "timezone":"Africa/Nairobi"
  },
  {
     "name":"(+03:00) Asia/Amman",
     "timezone":"Asia/Amman"
  },
  {
     "name":"(+03:00) Asia/Baghdad",
     "timezone":"Asia/Baghdad"
  },
  {
     "name":"(+03:00) Asia/Damascus",
     "timezone":"Asia/Damascus"
  },
  {
     "name":"(+03:00) Asia/Qatar",
     "timezone":"Asia/Qatar"
  },
  {
     "name":"(+03:00) Asia/Riyadh",
     "timezone":"Asia/Riyadh"
  },
  {
     "name":"(+03:00) Etc/GMT-3",
     "timezone":"Etc/GMT-3"
  },
  {
     "name":"(+03:00) Europe/Istanbul",
     "timezone":"Europe/Istanbul"
  },
  {
     "name":"(+03:00) Europe/Kirov",
     "timezone":"Europe/Kirov"
  },
  {
     "name":"(+03:00) Europe/Minsk",
     "timezone":"Europe/Minsk"
  },
  {
     "name":"(+03:00) Europe/Moscow",
     "timezone":"Europe/Moscow"
  },
  {
     "name":"(+03:00) Europe/Simferopol",
     "timezone":"Europe/Simferopol"
  },
  {
     "name":"(+03:00) Europe/Volgograd",
     "timezone":"Europe/Volgograd"
  },
  {
     "name":"(+03:30) Asia/Tehran",
     "timezone":"Asia/Tehran"
  },
  {
     "name":"(+04:00) Asia/Baku",
     "timezone":"Asia/Baku"
  },
  {
     "name":"(+04:00) Asia/Dubai",
     "timezone":"Asia/Dubai"
  },
  {
     "name":"(+04:00) Asia/Tbilisi",
     "timezone":"Asia/Tbilisi"
  },
  {
     "name":"(+04:00) Asia/Yerevan",
     "timezone":"Asia/Yerevan"
  },
  {
     "name":"(+04:00) Etc/GMT-4",
     "timezone":"Etc/GMT-4"
  },
  {
     "name":"(+04:00) Europe/Astrakhan",
     "timezone":"Europe/Astrakhan"
  },
  {
     "name":"(+04:00) Europe/Samara",
     "timezone":"Europe/Samara"
  },
  {
     "name":"(+04:00) Europe/Saratov",
     "timezone":"Europe/Saratov"
  },
  {
     "name":"(+04:00) Europe/Ulyanovsk",
     "timezone":"Europe/Ulyanovsk"
  },
  {
     "name":"(+04:00) Indian/Mauritius",
     "timezone":"Indian/Mauritius"
  },
  {
     "name":"(+04:30) Asia/Kabul",
     "timezone":"Asia/Kabul"
  },
  {
     "name":"(+05:00) Antarctica/Mawson",
     "timezone":"Antarctica/Mawson"
  },
  {
     "name":"(+05:00) Asia/Aqtau",
     "timezone":"Asia/Aqtau"
  },
  {
     "name":"(+05:00) Asia/Aqtobe",
     "timezone":"Asia/Aqtobe"
  },
  {
     "name":"(+05:00) Asia/Ashgabat",
     "timezone":"Asia/Ashgabat"
  },
  {
     "name":"(+05:00) Asia/Atyrau",
     "timezone":"Asia/Atyrau"
  },
  {
     "name":"(+05:00) Asia/Dushanbe",
     "timezone":"Asia/Dushanbe"
  },
  {
     "name":"(+05:00) Asia/Karachi",
     "timezone":"Asia/Karachi"
  },
  {
     "name":"(+05:00) Asia/Oral",
     "timezone":"Asia/Oral"
  },
  {
     "name":"(+05:00) Asia/Qyzylorda",
     "timezone":"Asia/Qyzylorda"
  },
  {
     "name":"(+05:00) Asia/Samarkand",
     "timezone":"Asia/Samarkand"
  },
  {
     "name":"(+05:00) Asia/Tashkent",
     "timezone":"Asia/Tashkent"
  },
  {
     "name":"(+05:00) Asia/Yekaterinburg",
     "timezone":"Asia/Yekaterinburg"
  },
  {
     "name":"(+05:00) Etc/GMT-5",
     "timezone":"Etc/GMT-5"
  },
  {
     "name":"(+05:00) Indian/Maldives",
     "timezone":"Indian/Maldives"
  },
  {
     "name":"(+05:30) Asia/Colombo",
     "timezone":"Asia/Colombo"
  },
  {
     "name":"(+05:30) Asia/Kolkata",
     "timezone":"Asia/Kolkata"
  },
  {
     "name":"(+05:45) Asia/Kathmandu",
     "timezone":"Asia/Kathmandu"
  },
  {
     "name":"(+06:00) Asia/Almaty",
     "timezone":"Asia/Almaty"
  },
  {
     "name":"(+06:00) Asia/Bishkek",
     "timezone":"Asia/Bishkek"
  },
  {
     "name":"(+06:00) Asia/Dhaka",
     "timezone":"Asia/Dhaka"
  },
  {
     "name":"(+06:00) Asia/Omsk",
     "timezone":"Asia/Omsk"
  },
  {
     "name":"(+06:00) Asia/Qostanay",
     "timezone":"Asia/Qostanay"
  },
  {
     "name":"(+06:00) Asia/Thimphu",
     "timezone":"Asia/Thimphu"
  },
  {
     "name":"(+06:00) Asia/Urumqi",
     "timezone":"Asia/Urumqi"
  },
  {
     "name":"(+06:00) Etc/GMT-6",
     "timezone":"Etc/GMT-6"
  },
  {
     "name":"(+06:00) Indian/Chagos",
     "timezone":"Indian/Chagos"
  },
  {
     "name":"(+06:30) Asia/Yangon",
     "timezone":"Asia/Yangon"
  },
  {
     "name":"(+07:00) Antarctica/Davis",
     "timezone":"Antarctica/Davis"
  },
  {
     "name":"(+07:00) Asia/Bangkok",
     "timezone":"Asia/Bangkok"
  },
  {
     "name":"(+07:00) Asia/Barnaul",
     "timezone":"Asia/Barnaul"
  },
  {
     "name":"(+07:00) Asia/Ho_Chi_Minh",
     "timezone":"Asia/Ho_Chi_Minh"
  },
  {
     "name":"(+07:00) Asia/Hovd",
     "timezone":"Asia/Hovd"
  },
  {
     "name":"(+07:00) Asia/Jakarta",
     "timezone":"Asia/Jakarta"
  },
  {
     "name":"(+07:00) Asia/Krasnoyarsk",
     "timezone":"Asia/Krasnoyarsk"
  },
  {
     "name":"(+07:00) Asia/Novokuznetsk",
     "timezone":"Asia/Novokuznetsk"
  },
  {
     "name":"(+07:00) Asia/Novosibirsk",
     "timezone":"Asia/Novosibirsk"
  },
  {
     "name":"(+07:00) Asia/Pontianak",
     "timezone":"Asia/Pontianak"
  },
  {
     "name":"(+07:00) Asia/Tomsk",
     "timezone":"Asia/Tomsk"
  },
  {
     "name":"(+07:00) Etc/GMT-7",
     "timezone":"Etc/GMT-7"
  },
  {
     "name":"(+08:00) Asia/Choibalsan",
     "timezone":"Asia/Choibalsan"
  },
  {
     "name":"(+08:00) Asia/Hong_Kong",
     "timezone":"Asia/Hong_Kong"
  },
  {
     "name":"(+08:00) Asia/Irkutsk",
     "timezone":"Asia/Irkutsk"
  },
  {
     "name":"(+08:00) Asia/Kuching",
     "timezone":"Asia/Kuching"
  },
  {
     "name":"(+08:00) Asia/Macau",
     "timezone":"Asia/Macau"
  },
  {
     "name":"(+08:00) Asia/Makassar",
     "timezone":"Asia/Makassar"
  },
  {
     "name":"(+08:00) Asia/Manila",
     "timezone":"Asia/Manila"
  },
  {
     "name":"(+08:00) Asia/Shanghai",
     "timezone":"Asia/Shanghai"
  },
  {
     "name":"(+08:00) Asia/Singapore",
     "timezone":"Asia/Singapore"
  },
  {
     "name":"(+08:00) Asia/Taipei",
     "timezone":"Asia/Taipei"
  },
  {
     "name":"(+08:00) Asia/Ulaanbaatar",
     "timezone":"Asia/Ulaanbaatar"
  },
  {
     "name":"(+08:00) Australia/Perth",
     "timezone":"Australia/Perth"
  },
  {
     "name":"(+08:00) Etc/GMT-8",
     "timezone":"Etc/GMT-8"
  },
  {
     "name":"(+08:45) Australia/Eucla",
     "timezone":"Australia/Eucla"
  },
  {
     "name":"(+09:00) Asia/Chita",
     "timezone":"Asia/Chita"
  },
  {
     "name":"(+09:00) Asia/Dili",
     "timezone":"Asia/Dili"
  },
  {
     "name":"(+09:00) Asia/Jayapura",
     "timezone":"Asia/Jayapura"
  },
  {
     "name":"(+09:00) Asia/Khandyga",
     "timezone":"Asia/Khandyga"
  },
  {
     "name":"(+09:00) Asia/Pyongyang",
     "timezone":"Asia/Pyongyang"
  },
  {
     "name":"(+09:00) Asia/Seoul",
     "timezone":"Asia/Seoul"
  },
  {
     "name":"(+09:00) Asia/Tokyo",
     "timezone":"Asia/Tokyo"
  },
  {
     "name":"(+09:00) Asia/Yakutsk",
     "timezone":"Asia/Yakutsk"
  },
  {
     "name":"(+09:00) Etc/GMT-9",
     "timezone":"Etc/GMT-9"
  },
  {
     "name":"(+09:00) Pacific/Palau",
     "timezone":"Pacific/Palau"
  },
  {
     "name":"(+09:30) Australia/Adelaide",
     "timezone":"Australia/Adelaide"
  },
  {
     "name":"(+09:30) Australia/Broken_Hill",
     "timezone":"Australia/Broken_Hill"
  },
  {
     "name":"(+09:30) Australia/Darwin",
     "timezone":"Australia/Darwin"
  },
  {
     "name":"(+10:00) Antarctica/Macquarie",
     "timezone":"Antarctica/Macquarie"
  },
  {
     "name":"(+10:00) Asia/Ust-Nera",
     "timezone":"Asia/Ust-Nera"
  },
  {
     "name":"(+10:00) Asia/Vladivostok",
     "timezone":"Asia/Vladivostok"
  },
  {
     "name":"(+10:00) Australia/Brisbane",
     "timezone":"Australia/Brisbane"
  },
  {
     "name":"(+10:00) Australia/Hobart",
     "timezone":"Australia/Hobart"
  },
  {
     "name":"(+10:00) Australia/Lindeman",
     "timezone":"Australia/Lindeman"
  },
  {
     "name":"(+10:00) Australia/Melbourne",
     "timezone":"Australia/Melbourne"
  },
  {
     "name":"(+10:00) Australia/Sydney",
     "timezone":"Australia/Sydney"
  },
  {
     "name":"(+10:00) Etc/GMT-10",
     "timezone":"Etc/GMT-10"
  },
  {
     "name":"(+10:00) Pacific/Guam",
     "timezone":"Pacific/Guam"
  },
  {
     "name":"(+10:00) Pacific/Port_Moresby",
     "timezone":"Pacific/Port_Moresby"
  },
  {
     "name":"(+10:30) Australia/Lord_Howe",
     "timezone":"Australia/Lord_Howe"
  },
  {
     "name":"(+11:00) Antarctica/Casey",
     "timezone":"Antarctica/Casey"
  },
  {
     "name":"(+11:00) Asia/Magadan",
     "timezone":"Asia/Magadan"
  },
  {
     "name":"(+11:00) Asia/Sakhalin",
     "timezone":"Asia/Sakhalin"
  },
  {
     "name":"(+11:00) Asia/Srednekolymsk",
     "timezone":"Asia/Srednekolymsk"
  },
  {
     "name":"(+11:00) Etc/GMT-11",
     "timezone":"Etc/GMT-11"
  },
  {
     "name":"(+11:00) Pacific/Bougainville",
     "timezone":"Pacific/Bougainville"
  },
  {
     "name":"(+11:00) Pacific/Efate",
     "timezone":"Pacific/Efate"
  },
  {
     "name":"(+11:00) Pacific/Guadalcanal",
     "timezone":"Pacific/Guadalcanal"
  },
  {
     "name":"(+11:00) Pacific/Kosrae",
     "timezone":"Pacific/Kosrae"
  },
  {
     "name":"(+11:00) Pacific/Norfolk",
     "timezone":"Pacific/Norfolk"
  },
  {
     "name":"(+11:00) Pacific/Noumea",
     "timezone":"Pacific/Noumea"
  },
  {
     "name":"(+12:00) Asia/Anadyr",
     "timezone":"Asia/Anadyr"
  },
  {
     "name":"(+12:00) Asia/Kamchatka",
     "timezone":"Asia/Kamchatka"
  },
  {
     "name":"(+12:00) Etc/GMT-12",
     "timezone":"Etc/GMT-12"
  },
  {
     "name":"(+12:00) Pacific/Auckland",
     "timezone":"Pacific/Auckland"
  },
  {
     "name":"(+12:00) Pacific/Fiji",
     "timezone":"Pacific/Fiji"
  },
  {
     "name":"(+12:00) Pacific/Kwajalein",
     "timezone":"Pacific/Kwajalein"
  },
  {
     "name":"(+12:00) Pacific/Nauru",
     "timezone":"Pacific/Nauru"
  },
  {
     "name":"(+12:00) Pacific/Tarawa",
     "timezone":"Pacific/Tarawa"
  },
  {
     "name":"(+12:45) Pacific/Chatham",
     "timezone":"Pacific/Chatham"
  },
  {
     "name":"(+13:00) Etc/GMT-13",
     "timezone":"Etc/GMT-13"
  },
  {
     "name":"(+13:00) Pacific/Apia",
     "timezone":"Pacific/Apia"
  },
  {
     "name":"(+13:00) Pacific/Fakaofo",
     "timezone":"Pacific/Fakaofo"
  },
  {
     "name":"(+13:00) Pacific/Kanton",
     "timezone":"Pacific/Kanton"
  },
  {
     "name":"(+13:00) Pacific/Tongatapu",
     "timezone":"Pacific/Tongatapu"
  },
  {
     "name":"(+14:00) Etc/GMT-14",
     "timezone":"Etc/GMT-14"
  },
  {
     "name":"(+14:00) Pacific/Kiritimati",
     "timezone":"Pacific/Kiritimati"
  }
]`);
