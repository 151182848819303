import { ButtonProps as ChakraButtonProps, forwardRef } from '@chakra-ui/react';
import { Button as ChakraButton } from '@chakra-ui/react';

export interface ButtonProps extends Omit<ChakraButtonProps, 'variant'> {
  variant?: 'primary' | 'secondary' | 'outline' | 'link';
}

export const Button = forwardRef(({ variant = 'primary', ...props }, ref) => {
  return (
    <ChakraButton
      variant={variant}
      iconSpacing="12px"
      ref={ref}
      whiteSpace="normal"
      h="fit-content"
      sx={{
        ...(props.isLoading && {
          span: {
            height: 0,
          },
          _hover: {},
        }),
      }}
      {...props}
    />
  );
});
