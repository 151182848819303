export const checkboxConfiguration = {
  baseStyle: {
    control: {
      width: '23px',
      height: '25px',
      borderWidth: '2px',
      borderColor: 'secondary.500',
      borderRadius: '4px',
    },
  },
  defaultProps: {
    colorScheme: null,
  },
};
