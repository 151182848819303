import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams, useNavigate } from 'react-router-dom';

import { MFACodeForm } from 'features/MFACodeForm';
import { Center, Container, MessagePopups, Spinner } from 'shared/components';
import { MessageTypeKeys } from 'shared/components/MessagePopups/MessagePopups';
import { useMyUserData } from 'shared/hooks/auth';
import useStore from '../../shared/store';
import { routeBuilder } from '../../shared/utils';
import { useAddMfaCode } from '../../entities/user/api';

const containerWidth = 506;
const popupMessages = {
  success: 'You have successfully added MFA code',
  attention: 'Check the correctness of the entered MFA code',
  error: 'Invalid MFA code. Please try again.',
};

type PopupType = MessageTypeKeys | false;

const MFACodePage = () => {
  const queryClient = useQueryClient();
  const { refetch } = useMyUserData();
  const navigate = useNavigate();
  const mutation = useAddMfaCode();

  const setIsMFALoggedIn = useStore((state) => state.setIsMFALoggedIn);
  const isMFALoggedIn = useStore((state) => state.isMFALoggedIn);
  const { mfaCodeParam } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (mfaCodeParam) {
      setIsLoading(true);

      (async function () {
        try {
          await mutation.mutateAsync({
            mfa: mfaCodeParam,
          });
          onSuccess && (await onSuccess());
        } catch (e) {
          onError && onError();
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [mfaCodeParam]);

  useEffect(() => {
    if (isMFALoggedIn) {
      navigate(routeBuilder.signIn.index.getPath(), { replace: true });
    }
  }, [isMFALoggedIn]);

  const [popup, setPopup] = useState<PopupType>(false);

  const onSuccess = async () => {
    await refetch();
    setPopup('success');
    setIsMFALoggedIn(true);
  };

  const onError = () => {
    setPopup('error');
  };

  const onMessagePopupsClose = () => {
    setPopup(false);
  };

  useEffect(() => {
    queryClient.clear();
  }, []);

  return (
    <Container
      maxW={containerWidth}
      p={{ base: '15px', xl: '30px' }}
      position="relative"
    >
      {isLoading && (
        <Center my="25px">
          <Spinner />
        </Center>
      )}
      {popup && (
        <Center
          position={{ lg: 'absolute' }}
          left={{ lg: containerWidth }}
          mb="40px"
        >
          <MessagePopups
            type={popup}
            message={popup ? popupMessages[popup] : ''}
            onClose={onMessagePopupsClose}
          />
        </Center>
      )}
      <MFACodeForm
        mfa={mfaCodeParam}
        onSuccess={onSuccess}
        onError={onError}
      />
    </Container>
  );
};

export { MFACodePage };
