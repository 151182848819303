import { ISelectOption } from '../../../shared/components';

export enum FilterActionType {
  MUTATE_FILTERS = 'MUTATE_FILTERS',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
}

export interface FilterAction {
  type: FilterActionType;
  payload?: Partial<IFiltersState>;
}

export interface IFiltersState {
  active?: boolean;
  locationFilter: ISelectOption | null;
  departmentFilter: ISelectOption | null;
  roleFilter: ISelectOption | null;
  supervisorFilter: ISelectOption | null;
}

export const attendeeListReduce = (
  state: IFiltersState,
  action: FilterAction,
) => {
  const { type, payload } = action;
  switch (type) {
    case FilterActionType.CLEAR_FILTERS:
      return {
        locationFilter: null,
        departmentFilter: null,
        roleFilter: null,
        supervisorFilter: null,
        active: true,
      };
    case FilterActionType.MUTATE_FILTERS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
