import { useTranslation } from 'react-i18next';

import { Text, ViewRow } from 'shared/components';

interface IProps {
  mfaEnabled?: boolean;
}

const TwoFactorAuthView = ({ mfaEnabled }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Two-Factor Authentication')}>
      <Text>{mfaEnabled ? t('Yes') : t('No')}</Text>
    </ViewRow>
  );
};

export { TwoFactorAuthView };
