import { UseQueryOptions } from '@tanstack/react-query';
import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { IList, IQueryParams, ISort } from 'shared/types';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { useGetParsedQueryParams } from 'shared/utils';
import { ITracking } from 'entities/tracking/model';
import { IAdvancedSearchForm } from '../model/IAdvancedSearchForm';
import { useComputeAdvancedSearchQuery } from '../../../features/tracking/utils';

export interface ITrackingsQueryParams extends IQueryParams {
  searchFields?: IAdvancedSearchForm;
}

export const useGetTrackings = (
  companyId: string,
  query?: ITrackingsQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<ITracking[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  const advancedQueryParams = useComputeAdvancedSearchQuery(
    companyId,
    query?.searchFields,
    query?.limit,
    query?.offset,
    query?.languageCode,
  );
  const sort = query?.sort || [];
  const computeSorting = (sort: ISort[]) => {
    if (sort.length === 0) {
      return '';
    }
    return sort
      .reduce(
        (result, currentValue) =>
          `${result}${currentValue.name}$${currentValue.sortDirection},`,
        'sort=',
      )
      .slice(0, -1);
  };
  const finalQuery = query?.filters
    ? `companyID=${companyId}&${parsedQueryParams}`
    : `${advancedQueryParams}&${computeSorting(sort)}`;

  return useReactQuery<IList<ITracking[]>>(
    getTrackingsKey(companyId, finalQuery),
    () => getTrackings(companyId, finalQuery),
    options,
  );
};

const getTrackings = (companyId: string, query?: string) => {
  return httpClient.get<IList<ITracking[]>>(buildUrl('trackings', query));
};

const getTrackingsKey = (companyId: string, query: string) => [
  'trackings',
  companyId,
  query,
];
