import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  ResponsiveHeadTableRow,
  Table,
  TableBody,
  TableBodyCell,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import useStore from 'shared/store';
import { ISort } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';

import { useTrackingsColumnsConfiguration } from './utils';
import { useGetTrackings } from 'entities/tracking/api';
import { TrackingTableRow } from './TrackingTableRow';
import { ITrackingsQueryParams } from 'entities/tracking/api/useGetTrackings';
import { useBreakpointValue } from '@chakra-ui/react';
import { useGetLocale } from 'shared/utils';

const useTrackingsTableState = createTableStore();

interface IProps {
  setOffset: (value: number) => void;
  onSortChange: (sort: ISort[]) => void;
  queryParams: ITrackingsQueryParams;
  setQueryFields: Dispatch<SetStateAction<string[]>>;
}

const TrackingsTable = ({
  setQueryFields,
  setOffset,
  onSortChange,
  queryParams: { limit = 10, offset = 0, ...restQueryParams },
}: IProps) => {
  const { code: lang } = useGetLocale();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const columnsConfiguration = useTrackingsColumnsConfiguration();
  const companyId = useStore((state) => state.company?._id);

  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);
  const tableState = useTrackingsTableState((state) => state, shallow);

  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const fieldsArray = memoizedColumnsConfiguration
    .map((columnConfiguration) => columnConfiguration.sortedFields)
    .flat();
  fieldsArray.pop();

  useEffect(() => {
    !restQueryParams.filters
      ? setQueryFields(
          memoizedColumnsConfiguration
            .map((columnConfiguration) => columnConfiguration.sortedFields)
            .flat(),
        )
      : setQueryFields(fieldsArray);
  }, [restQueryParams.filters]);

  const { data, isLoading } = useGetTrackings(
    companyId,
    {
      ...restQueryParams,
      languageCode: (lang || '').slice(0, 2) || 'en',
      offset: offset,
      limit: limit,
    },
    {
      useErrorBoundary: false,
    },
  );
  useEffect(() => {
    if (
      (data && data?.data.total <= 10) ||
      (data && offset / limit + 1 > Math.ceil(data?.data.total / limit))
    ) {
      setOffset(0);
    }
  }, [data?.data.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <ResponsiveHeadTableRow
        isMobile={isMobile}
        columnsConfiguration={columnsConfiguration}
      />
      <TableBody isLoading={isLoading}>
        {data && data.data.total > 0 ? (
          <>
            {data.data.data.map((tracking) => {
              return (
                <TrackingTableRow
                  key={tracking._id}
                  tracking={tracking}
                  isMobile={isMobile}
                />
              );
            })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={data.data.total}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                  itemsPerPage={limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { TrackingsTable };
