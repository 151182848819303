import { Container, Grid, GridProps } from 'shared/components';

const ViewPageWrapper = (props: GridProps) => {
  return (
    <Container
      p={{ md: '15px', lg: '30px' }}
      maxW="100%"
      borderRadius={{ md: '20px' }}
      borderWidth={{ md: '2px' }}
      borderColor={{ md: 'secondary.500' }}
    >
      <Grid
        gap="20px"
        height={'full'}
        {...props}
      />
    </Container>
  );
};

export { ViewPageWrapper };
