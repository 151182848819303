import { AxiosResponse } from 'axios';

import {
  IconButton,
  SingleTableWrapper,
  useBreakpointValue,
} from 'shared/components';
import { CsvIcon, PdfIcon, XlsIcon, XmlIcon } from 'shared/icons';
import { FileFormat } from 'shared/types/FileFormat';
import { httpClient } from 'shared/utils/httpClient';
import { hasQueryParams, useGetLocale } from 'shared/utils';

interface Props {
  title: string;
  fileUrl: string;
  fileName: string;
}

const ExportDataTable = ({ title, fileUrl, fileName }: Props) => {
  const { code: _lang } = useGetLocale();
  const lang = (_lang || '').slice(0, 2) || 'en';

  const gap = useBreakpointValue({
    base: '10px',
    sm: '15px',
    md: '20px',
    lg: '25px',
  });

  const handleDownloadFile = async (format: FileFormat) => {
    const fileUrlWithFormat = hasQueryParams(fileUrl)
      ? `${fileUrl}&languageCode=${lang}&format=${format}`
      : `${fileUrl}?languageCode=${lang}&format=${format}`;
    httpClient
      .get(fileUrlWithFormat, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse<Blob>) => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], {
          type: type,
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${fileName}.${format}`;
        link.click();
      });
  };

  return (
    <SingleTableWrapper
      title={title}
      childContainerStyle={{
        py: '15px',
        gap: gap,
        flexDir: 'row',
        justifyContent: 'center',
      }}
    >
      <IconButton
        aria-label="Export XLS"
        icon={<XlsIcon />}
        onClick={() => handleDownloadFile(FileFormat.XLSX)}
      />
      <IconButton
        aria-label="Export PDF"
        icon={<PdfIcon />}
        onClick={() => handleDownloadFile(FileFormat.PDF)}
      />
      <IconButton
        aria-label="Export CSV"
        icon={<CsvIcon />}
        onClick={() => handleDownloadFile(FileFormat.CSV)}
      />
      <IconButton
        aria-label="Export XML"
        icon={<XmlIcon />}
        onClick={() => handleDownloadFile(FileFormat.XML)}
      />
    </SingleTableWrapper>
  );
};

export { ExportDataTable };
