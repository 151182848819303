import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { DeleteVendor } from 'features/vendor/DeleteVendor';
import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  vendorId: string;
  active?: boolean;
}

const VendorActionButtons = ({ vendorId, companyId, active }: IProps) => {
  const {
    createVendorPrivilege,
    deleteVendorPrivilege,
    updateVendorPrivilege,
  } = LayoutPrivilege();

  return (
    <FlexWrapper>
      {createVendorPrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.vendor.add.getPath()} />
      )}
      {active && deleteVendorPrivilege && (
        <DeleteVendor
          companyId={companyId}
          vendorId={vendorId}
        />
      )}
      {updateVendorPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.vendor.edit.getPath(vendorId)}
        />
      )}
    </FlexWrapper>
  );
};

export { VendorActionButtons };
