import useStore from 'shared/store';

import { AddAction } from 'widgets/Action/AddAction';

const AddActionPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  return <AddAction companyId={companyId} />;
};

export { AddActionPage };
