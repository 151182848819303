import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { Form, FormLabel, TextareaField, Flex } from 'shared/components';
import { useContainerProps } from 'features/FormCommons';
import { ISMSPayload } from 'entities/sms/model';
import { SMSToSelectField } from './SMSToSelectField';

interface IProps {
  companyId: string;
  methods: UseFormReturn<ISMSPayload>;
  formId: string;
}

const TrackingSMSModalForm = ({ companyId, methods, formId }: IProps) => {
  const containerStyleProps = useContainerProps();
  const { t } = useTranslation('commonFields');

  return (
    <Form<ISMSPayload>
      onSubmit={() => true}
      id={formId}
      containerProps={{ ...containerStyleProps, width: '100%' }}
      methods={methods}
    >
      <SMSToSelectField companyId={companyId} />

      <Flex
        flexDir="column"
        gap="5px"
      >
        <FormLabel
          size="md"
          lineHeight="18px"
          display="flex"
          margin="0"
          alignItems="center"
        >
          {t('Message')}
        </FormLabel>
        <TextareaField
          name="message"
          minH="200px"
        />
      </Flex>
    </Form>
  );
};

export { TrackingSMSModalForm };
