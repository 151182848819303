import { IDepartment } from '../model';

export const mockedDepartment = (
  department?: Partial<IDepartment>,
): IDepartment => {
  return {
    companyID: '1234567',
    _id: '1231',
    name: 'Department name',
    active: true,
    credits: {
      createdAt: new Date().toString(),
      createdByID: 'User 123',
    },
    ...department,
  };
};
