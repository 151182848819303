import { useTranslation } from 'react-i18next';

import { FormControlProps, TextareaField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const DescriptionField = (props: FormControlProps) => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField
      {...props}
      label={`${t('Description')}:`}
    >
      <TextareaField
        name="description"
        minH={{ base: '180px', md: '210px', lg: '280px', xl: '349px' }}
      />
    </FormField>
  );
};

export { DescriptionField };
