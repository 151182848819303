import { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';
import { IndicatorsContainerProps } from 'react-select/dist/declarations/src/components/containers';

import { useBreakpointValue } from 'shared/hooks/chakraUi';

export const useIndicatorContainerStyle = (
  provided: CSSObjectWithLabel,
  state: IndicatorsContainerProps,
) => {
  const height = useBreakpointValue({
    base: '28px',
    md: 'auto',
  });

  return {
    ...provided,
    height: height,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
  };
};
