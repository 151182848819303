export const TableSortChevronUp = () => {
  return (
    <svg
      width="24"
      height="8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.707 7.207a1 1 0 0 1-1.414 0L12 2.914 7.707 7.207a1 1 0 0 1-1.414-1.414l5-5a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414Z"
        fill="#fff"
      />
    </svg>
  );
};
