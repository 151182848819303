import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';

import { ICustomer } from 'entities/customer/model';
import { routeBuilder } from 'shared/utils';

interface IProps {
  customer: ICustomer;
}
const CustomerTableRow = ({ customer }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(routeBuilder.customer.view.getPath(customer._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{customer.name}</TableCellText>
      </TableBodyCell>

      <TableBodyCell>
        <TableCellText>{customer.active ? t('Yes') : t('No')}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { CustomerTableRow };
