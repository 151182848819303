export const SignInIcon = () => {
  return (
    <svg
      width="30"
      height="27"
      fill="none"
    >
      <g
        clipPath="url(#b)"
        fill="#72B42D"
        stroke="#00467F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3.143 11c-.694 0-1.252.372-1.604.833A2.756 2.756 0 0 0 1 13.5c0 .613.188 1.206.54 1.667.351.461.909.833 1.603.833h13.714c.694 0 1.252-.372 1.604-.833.35-.461.539-1.054.539-1.667s-.188-1.206-.54-1.667c-.351-.461-.909-.833-1.603-.833H3.143Z" />
        <path d="m27.579 24.725-.675-.733.675.733A4.922 4.922 0 0 1 24.25 26h-5c-1.163 0-2.25-.886-2.25-2.15 0-1.264 1.087-2.15 2.25-2.15h5a.309.309 0 0 0 .207-.073l.677.736-.677-.736c.039-.036.043-.065.043-.077V5.45c0-.012-.004-.041-.043-.077a.309.309 0 0 0-.207-.073h-5C18.087 5.3 17 4.414 17 3.15 17 1.886 18.087 1 19.25 1h5c1.234 0 2.433.45 3.329 1.275C28.477 3.1 29 4.242 29 5.45v16.1c0 1.208-.523 2.349-1.421 3.175Z" />
        <path d="m13.787 13.5-4.14 3.925a2.044 2.044 0 0 0 0 2.983c.832.79 2.16.79 2.992 0l5.714-5.416a2.044 2.044 0 0 0 0-2.984L12.64 6.592a2.188 2.188 0 0 0-2.992 0 2.044 2.044 0 0 0 0 2.983l.688-.726-.688.726 4.14 3.925Z" />
      </g>
      <defs>
        <clipPath id="b">
          <path
            fill="#fff"
            d="M0 0h30v27H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
