import { ReactNode, useEffect, useState } from 'react';

import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { SaveButton } from 'features/SaveButton';
import {
  ActiveField,
  NameField,
  useContainerProps,
} from 'features/FormCommons';

import { DepartmentFormType } from 'entities/company/model';
import { ActionFormType } from 'entities/tracking/model';

import { FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';

import { useAddDepartmentsFormValidationSchema } from './useAddDepartmentsFormValidationSchema';
import { useForm } from 'shared/components/Form';

type DepartmentsFormProps = {
  defaultValues?: DepartmentFormType;
  handleSubmit: (
    data: ActionFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  formId: string;
  additionalActionButtons?: ReactNode;
};

const defaultDepartment = {
  name: '',
  active: true,
};

const DepartmentForm = ({
  defaultValues = defaultDepartment,
  formId,
  isLoading,
  additionalActionButtons,
  handleSubmit,
}: DepartmentsFormProps) => {
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const validationSchema = useAddDepartmentsFormValidationSchema();

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });

  useEffect(() => {
    if (formId.includes('edit-department')) {
      cleanPersistentStore();
    }
  }, []);

  return (
    <Form<DepartmentFormType>
      onSubmit={(data, { reset }) => {
        handleSubmit(
          data,
          () => {
            cleanPersistentStore();
          },
          submitType,
        );
        reset(defaultValues);
      }}
      id={formId}
      containerProps={{
        ...containerStyleProps,
        height: '100%',
      }}
      methods={methods}
    >
      <ActiveField />

      <NameField />

      <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { DepartmentForm };
