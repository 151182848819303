import {
  Box,
  BoxProps,
  HStack,
  Icon,
  IconProps,
  Text,
  VStack,
} from 'shared/components';
import { useBoolean, useTheme } from 'shared/hooks/chakraUi';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'shared/icons';
import { ErrorIcon } from 'shared/icons/ErrorIcon/ErrorIcon';
import { SuccessIcon } from 'shared/icons/SuccessIcon/SuccessIcon';
import { AttentionIcon } from 'shared/icons/AttentionIcon';

export type MessageTypeKeys = 'error' | 'success' | 'attention';

interface IProps extends BoxProps {
  type: MessageTypeKeys;
  message: string;
  onClose?: () => void;
}

export type MessageType = Record<
  MessageTypeKeys,
  {
    header: string;
    color: string;
    icon: (props: IconProps) => JSX.Element;
  }
>;

const messageType: MessageType = {
  error: {
    header: 'ERROR',
    color: 'red.400',
    icon: ErrorIcon,
  },
  success: {
    header: 'SUCCESS',
    color: 'secondary.500',
    icon: SuccessIcon,
  },
  attention: {
    header: 'ATTENTION',
    color: 'yellow',
    icon: AttentionIcon,
  },
};

const MessagePopups = ({ type, message, onClose }: IProps) => {
  const theme = useTheme();
  const [toggleCloseButton, setToggleCloseButton] = useBoolean();
  const [closePopup, setClosePopup] = useBoolean();
  const { t } = useTranslation('popupMessage');

  const { header, color, icon } = messageType[type];

  const handleClose = () => {
    setClosePopup.on;
    onClose && onClose();
  };

  return (
    <Box
      onMouseEnter={setToggleCloseButton.on}
      onMouseLeave={setToggleCloseButton.off}
      maxW={{ base: '270px', xl: '320px', '2xl': '362px' }}
      w={{ base: '270px', xl: '320px', '2xl': '362px' }}
      h="fit-content"
      overflow="hidden"
      bgColor="white"
      borderWidth="5px"
      borderStyle="solid"
      borderColor={color}
      boxShadow={theme.shadows.btn}
      borderRadius="20px"
      p={{ base: '10px', xl: '15px', '2xl': '20px' }}
      display={closePopup ? 'none' : 'flex'}
      flexDirection="row-reverse"
    >
      <CloseIcon
        visibility={toggleCloseButton ? 'visible' : 'hidden'}
        boxSize={{ base: '16px', xl: '19px' }}
        cursor="pointer"
        transition=".3s all ease"
        onClick={() => handleClose()}
      />
      <HStack
        h="100%"
        w="100%"
      >
        <Icon
          as={icon}
          boxSize={{ base: '35px', lg: '40px', xl: '45px', '2xl': '50px' }}
          maxW={{ base: '35px', lg: '40px', xl: '45px', '2xl': '50px' }}
        />
        <VStack
          textAlign="center"
          w="100%"
          maxW="240px"
        >
          <Text
            p="0"
            fontSize={{ base: '18px', xl: '22px', '2xl': '24px' }}
            fontWeight="700"
            lineHeight="29px"
          >
            {t(header)}
          </Text>
          <Text
            mt="0px !important"
            fontSize={{ base: '18px', xl: '20px', '2xl': '24px' }}
            fontWeight="400"
            p="0"
            px="5px"
            lineHeight="29px"
            w="100%"
            overflowWrap="anywhere"
          >
            {t(message)}
          </Text>
        </VStack>
      </HStack>
    </Box>
  );
};

export { MessagePopups };
