import { ICompany } from 'entities/company/model';

import { Flex, ViewRow, Text } from 'shared/components';

import {
  AbbreviationViewRow,
  ActiveViewRow,
  AddressViewRow,
  FaxViewRow,
  LoginViewRow,
  NAISCViewRow,
  NameViewRow,
  PhoneViewRow,
  SICViewRow,
  ZoneViewRow,
} from 'features/ViewCommonFields';
import { useTranslation } from 'react-i18next';

interface IProps {
  company: ICompany;
}

const firstLetterCapitalize = (str: string) => str.charAt(0).toLocaleUpperCase() + str.slice(1);

const CompanyView = ({ company }: IProps) => {
  const { t } = useTranslation('commonFields');
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <NameViewRow name={company.name} />
      <AbbreviationViewRow abbreviation={company.abbreviation} />
      <LoginViewRow login={company.login} />
      <AddressViewRow address={company.address} />
      <PhoneViewRow phone={company?.phoneNumber} />
      <FaxViewRow fax={company?.fax} />
      <NAISCViewRow naisc={company.code?.NAISC} />
      <SICViewRow sic={company.code?.SIC} />
      <ZoneViewRow zone={company.timeZone} />
      <ActiveViewRow active={company.active} />
      <ViewRow label={t('Components')}>
        <Text>{
          (company?.components || []).map(firstLetterCapitalize).map(t).join(', ') || '-'
        }</Text>
    </ViewRow>
    </Flex>
  );
};

export { CompanyView };
