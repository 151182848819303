import { ILocation } from 'entities/company/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from '../../../shared/utils/httpClient';

export const useGetLocation = (
  companyId: string,
  locationId: string,
  options?: UseQueryOptions<AxiosResponse<ILocation>, AxiosError>,
) => {
  return useReactQuery<ILocation>(
    getLocationsKey(companyId, locationId),
    () => getLocation(companyId, locationId),
    options,
  );
};

const getLocation = (companyId: string, locationId: string) => {
  return httpClient.get<ILocation>(
    `locations/${locationId}?companyID=${companyId}`,
  );
};

const getLocationsKey = (companyId: string, locationId: string) => [
  'location',
  companyId,
  locationId,
];
