import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, SingleTableWrapper, Text } from 'shared/components';
import { FormField, TextField, useFormContext } from 'shared/components/Form';

interface IProps {
  isDisabled?: boolean;
}

const CompanyComponentsField = ({ isDisabled }: IProps) => {
  const { t } = useTranslation('commonFields');
  const {
    watch,
    setValue,
  } = useFormContext();
  const components = watch('components') || [];

  const handleChange = (component: ICompanyComponent) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(
        'components',
        e.target.checked
          ? [...components, component]
          : components.filter((c: ICompanyComponent) => c !== component)
        );
    };
  return (
    <SingleTableWrapper title={t('Components')}>
      <Checkbox
        isChecked={components.includes(ICompanyComponent.Customers)}
        onChange={handleChange(ICompanyComponent.Customers)}
      >
        <Text textTransform="capitalize">{t(ICompanyComponent.Customers)}</Text>
      </Checkbox>
      <Checkbox
        isChecked={components.includes(ICompanyComponent.Vendors)}
        onChange={handleChange(ICompanyComponent.Vendors)}
      >
        <Text textTransform="capitalize">{t(ICompanyComponent.Vendors)}</Text>
      </Checkbox>
      <Checkbox
        isChecked={components.includes(ICompanyComponent.Regulations)}
        onChange={handleChange(ICompanyComponent.Regulations)}
      >
        <Text textTransform="capitalize">{t(ICompanyComponent.Regulations)}</Text>
      </Checkbox>
    </SingleTableWrapper>
  );
};

export { CompanyComponentsField };
