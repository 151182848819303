import { Controller } from 'react-hook-form';

import { TextareaProps, Textarea } from 'shared/components';

import { FieldPrototype } from './FieldPrototype';
import { useHasError } from './useHasError';
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';

export interface ITextareaFieldProps extends TextareaProps {
  name: string;
}

const TextareaField = ({ name, ...inputProps }: ITextareaFieldProps) => {
  const hasError = useHasError(name);

  return (
    <FieldPrototype name={name}>
      <Controller
        name={name}
        render={(props) => {
          const {
            field: { onChange, onBlur, value },
            formState: { isSubmitting },
          } = props;
          return (
            <GrammarlyEditorPlugin clientId="client_RDAbCzzNarL9XxksoAcCG3">
              <Textarea
                data-testid={name}
                id={name}
                isInvalid={hasError}
                isDisabled={isSubmitting}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                {...inputProps}
              />
            </GrammarlyEditorPlugin>
          );
        }}
      />
    </FieldPrototype>
  );
};

export { TextareaField };
