import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormField, TimeZonePickerField } from 'shared/components/Form';
interface IProps {
  trackingTime: {
    time: string;
    zone: string;
  };
}

const TrackingTime = forwardRef(({ trackingTime }: IProps, ref) => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField
      isRequired
      label={`${t('Tracking Time')}:`}
    >
      <TimeZonePickerField
        ref={ref}
        name={'trackingTime'}
        trackingTime={trackingTime}
      />
    </FormField>
  );
});
TrackingTime.displayName = 'TrackingTime';
export { TrackingTime };
