import { useTranslation } from 'react-i18next';

import { TextareaField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const ViolationDescriptionField = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Violation Description')}:`}>
      <TextareaField
        name="violation.description"
        height="150px"
      />
    </FormField>
  );
};

export { ViolationDescriptionField };
