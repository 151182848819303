import { Tbody, TableBodyProps, Center } from '@chakra-ui/react';
import { Spinner } from '../Spinner';
import { TableRow } from './TableRow';
import { TableBodyCell } from './TableBodyCell';
import { useTableContextConsumer } from './TableContextProvider';

interface IProps extends TableBodyProps {
  isLoading?: boolean;
}

const TableBody = ({ children, isLoading, ...restProps }: IProps) => {
  const { columnsConfiguration } = useTableContextConsumer();
  return (
    <Tbody {...restProps}>
      {isLoading ? (
        <TableRow>
          <TableBodyCell colSpan={columnsConfiguration.length}>
            <Center my="25px">
              <Spinner />
            </Center>
          </TableBodyCell>
        </TableRow>
      ) : (
        children
      )}
    </Tbody>
  );
};

export { TableBody };
