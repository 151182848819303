import { useTheme } from '@chakra-ui/react';
import { MenuDivider, MenuDividerProps } from 'shared/components';

export type NavMenuDividerProps = {
  menuDividerProps?: MenuDividerProps;
};

export const NavMenuDivider = ({ menuDividerProps }: NavMenuDividerProps) => {
  const theme = useTheme();

  return (
    <MenuDivider
      margin={0}
      borderBottom={`2px solid ${theme.colors.secondary['500']}`}
      {...menuDividerProps}
    />
  );
};
