import { Dispatch, SetStateAction } from 'react';
import { range } from 'lodash';

import { Text, VStack } from 'shared/components';

import { DateObject, DateObjectType } from 'shared/utils';

interface IProps {
  time: DateObjectType;
  setTime: Dispatch<SetStateAction<DateObjectType>>;
}

const HourBox = ({ time = DateObject(new Date()), setTime }: IProps) => {
  const hours = range(1, 13)
    .reverse()
    .map((hour) => {
      if (hour < 10) {
        return `0${hour}`;
      }
      return hour.toString();
    });

  const isAM = Number(time.format('H')) < 12;

  const currentHour = time.format('hh');

  return (
    <VStack
      overflowY="scroll"
      h="100px"
    >
      {hours.map((hour) => (
        <Text
          cursor="pointer"
          textAlign="center"
          w="30px"
          px="5px"
          key={hour}
          size="xs"
          _hover={{
            backgroundColor: 'gray.200',
          }}
          onClick={() => {
            setTime(time.setHours(Number(isAM ? hour : Number(hour) + 12)));
          }}
          backgroundColor={currentHour === hour ? 'gray.400' : 'transparent'}
        >
          {hour}
        </Text>
      ))}
    </VStack>
  );
};

export { HourBox };
