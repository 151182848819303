import { Center } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { Box } from 'shared/components';
import { UserIcon } from 'shared/icons';
import useStore from 'shared/store';
import { navItemStyles } from '../navItemStyles';
import { NavItemText } from '../NavItemText';

type NavItemHomeProps = {
  link: string;
  iconMargin: string;
};

export const NavItemHome = ({ link, iconMargin }: NavItemHomeProps) => {
  const roles = useStore((state) => state.roles);

  const getLabel = () => {
    if (roles.includes('super-admin')) {
      return 'Super';
    }

    if (roles.includes('company-admin')) {
      return 'Admin';
    }

    return 'Home';
  };

  return (
    <Link to={link}>
      <Center {...navItemStyles}>
        <NavItemText>{getLabel()}</NavItemText>
      </Center>
    </Link>
  );
};
