import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteAgency = (agencyId: string, companyId: string) => {
  return useMutation(() => {
    return deleteAgency(agencyId, companyId);
  });
};

const deleteAgency = (agencyId: string, companyId: string) => {
  return httpClient.delete(`agencies/${agencyId}?companyID=${companyId}`);
};
