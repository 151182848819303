import { Icon, IconProps } from 'shared/components';

const XlsIcon = (props: IconProps) => (
  <Icon
    width="58"
    height="58"
    viewBox="0 0 58 58"
    bgColor="white"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M49.8438 16.3125H38.0625V4.53125C38.0625 4.2909 37.967 4.06039 37.7971 3.89043C37.6271 3.72048 37.3966 3.625 37.1562 3.625H11.7812C11.5409 3.625 11.3104 3.72048 11.1404 3.89043C10.9705 4.06039 10.875 4.2909 10.875 4.53125V29.9062C10.875 30.1466 10.9705 30.3771 11.1404 30.5471C11.3104 30.717 11.5409 30.8125 11.7812 30.8125H36.25V45.3125H11.7812C11.5409 45.3125 11.3104 45.408 11.1404 45.5779C10.9705 45.7479 10.875 45.9784 10.875 46.2188V53.4688C10.875 53.7091 10.9705 53.9396 11.1404 54.1096C11.3104 54.2795 11.5409 54.375 11.7812 54.375H49.8438C50.0841 54.375 50.3146 54.2795 50.4846 54.1096C50.6545 53.9396 50.75 53.7091 50.75 53.4688V17.2188C50.75 16.9784 50.6545 16.7479 50.4846 16.5779C50.3146 16.408 50.0841 16.3125 49.8438 16.3125Z"
      fill="#EEEEEE"
    />
    <path
      d="M37.1562 29H8.15625C7.65574 29 7.25 29.4057 7.25 29.9062V46.2188C7.25 46.7193 7.65574 47.125 8.15625 47.125H37.1562C37.6568 47.125 38.0625 46.7193 38.0625 46.2188V29.9062C38.0625 29.4057 37.6568 29 37.1562 29Z"
      fill="#185C37"
    />
    <path
      d="M50.4872 16.5757L37.7997 3.8882C37.6729 3.76039 37.511 3.67323 37.3345 3.63782C37.158 3.60242 36.975 3.62037 36.8087 3.6894C36.6425 3.75842 36.5006 3.87539 36.4011 4.02539C36.3016 4.1754 36.249 4.35163 36.25 4.53164V17.2191C36.25 17.4595 36.3455 17.69 36.5154 17.86C36.6854 18.0299 36.9159 18.1254 37.1563 18.1254H49.8438C50.0238 18.1264 50.2 18.0739 50.35 17.9743C50.5 17.8748 50.617 17.7329 50.686 17.5667C50.755 17.4004 50.773 17.2174 50.7376 17.0409C50.7022 16.8644 50.615 16.7025 50.4872 16.5757V16.5757Z"
      fill="#E0E0E0"
    />
    <path
      d="M18.3574 42.1817H16.2086L14.7926 39.8299L13.3469 42.1817H11.2971L13.6885 38.4683L11.3912 34.8095H13.535L14.8619 37.0325L16.2235 34.8095H18.2782L15.966 38.3941L18.3574 42.1817Z"
      fill="white"
    />
    <path
      d="M24.5902 42.1817H19.6688V34.8095H21.5206V40.7755H24.5902V42.1817Z"
      fill="white"
    />
    <path
      d="M28.3574 42.3153C27.793 42.3153 27.2962 42.2609 26.8672 42.1519C26.4414 42.043 26.0453 41.9077 25.6789 41.746V39.9933H25.8472C26.2169 40.3068 26.6245 40.5478 27.0701 40.7161C27.5157 40.8845 27.9481 40.9686 28.3673 40.9686C28.473 40.9686 28.6116 40.9571 28.7832 40.934C28.9582 40.9109 29.0984 40.8746 29.2041 40.8251C29.3328 40.7689 29.4417 40.6914 29.5308 40.5923C29.62 40.49 29.6645 40.3563 29.6645 40.1913C29.6645 40.0065 29.5919 39.8563 29.4467 39.7408C29.3014 39.6252 29.1282 39.5411 28.9268 39.4883C28.676 39.4222 28.4069 39.3595 28.1198 39.3001C27.8359 39.2407 27.5702 39.1681 27.3227 39.0823C26.7516 38.8809 26.3423 38.6103 26.0948 38.2703C25.8472 37.9303 25.7234 37.5045 25.7234 36.9929C25.7234 36.303 26.0156 35.7452 26.5998 35.3194C27.184 34.8903 27.9135 34.6758 28.7882 34.6758C29.2272 34.6758 29.6629 34.7203 30.0953 34.8095C30.531 34.8986 30.9205 35.0191 31.2637 35.1709V36.8543H31.1003C30.8264 36.6199 30.4914 36.4219 30.0953 36.2601C29.7025 36.0984 29.2981 36.0175 28.8823 36.0175C28.7205 36.0175 28.5753 36.0291 28.4466 36.0522C28.3178 36.0753 28.1825 36.1199 28.0406 36.1859C27.925 36.2387 27.8244 36.3179 27.7385 36.4235C27.656 36.5258 27.6148 36.6414 27.6148 36.7701C27.6148 36.9582 27.6808 37.1084 27.8128 37.2206C27.9481 37.3296 28.2073 37.4286 28.5901 37.5177C28.8377 37.5738 29.0753 37.6283 29.3031 37.6811C29.5341 37.7339 29.7801 37.8065 30.0408 37.8989C30.5524 38.0838 30.932 38.333 31.1796 38.6466C31.4304 38.9568 31.5558 39.3678 31.5558 39.8794C31.5558 40.6122 31.267 41.2013 30.6894 41.6469C30.1151 42.0925 29.3378 42.3153 28.3574 42.3153Z"
      fill="white"
    />
    <g clipPath="url(#clip0_1499_12249)">
      <path
        d="M25.9577 9.53125H20.5498C20.3706 9.53125 20.1987 9.60245 20.072 9.72918C19.9452 9.85591 19.874 10.0278 19.874 10.207V13.08L25.9577 16.6288L28.9996 17.9124L32.0414 16.6288V13.08L25.9577 9.53125Z"
        fill="#21A366"
      />
      <path
        d="M19.874 13.0801H25.9577V16.6288H19.874V13.0801Z"
        fill="#107C41"
      />
      <path
        d="M31.3657 9.53125H25.9578V13.08H32.0415V10.207C32.0415 10.0278 31.9703 9.85591 31.8435 9.72918C31.7168 9.60245 31.5449 9.53125 31.3657 9.53125V9.53125Z"
        fill="#33C481"
      />
      <path
        d="M25.9577 16.6289H19.874V23.051C19.874 23.1398 19.8915 23.2276 19.9255 23.3096C19.9594 23.3916 20.0092 23.4661 20.072 23.5289C20.1347 23.5916 20.2092 23.6414 20.2912 23.6753C20.3732 23.7093 20.4611 23.7268 20.5498 23.7268H31.366C31.4547 23.7268 31.5426 23.7093 31.6246 23.6753C31.7066 23.6414 31.7811 23.5916 31.8438 23.5289C31.9066 23.4661 31.9564 23.3916 31.9903 23.3096C32.0243 23.2276 32.0418 23.1398 32.0418 23.051V20.1777L25.9577 16.6289Z"
        fill="#185C37"
      />
      <path
        d="M25.9578 16.6289H32.0415V20.1777H25.9578V16.6289Z"
        fill="#107C41"
      />
      <path
        opacity="0.1"
        d="M24.2671 12.0664H19.874V21.6992H24.2671C24.4462 21.699 24.6179 21.6278 24.7448 21.5013C24.8716 21.3748 24.9432 21.2032 24.9439 21.0241V12.7422C24.9433 12.5629 24.8718 12.3912 24.745 12.2646C24.6182 12.1379 24.4463 12.0667 24.2671 12.0664V12.0664Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M23.7612 12.5732H19.874V22.2061H23.7612C23.9401 22.2055 24.1116 22.1342 24.2382 22.0078C24.3648 21.8813 24.4362 21.7099 24.437 21.531V13.249C24.4364 13.07 24.3651 12.8984 24.2384 12.7718C24.1118 12.6452 23.9403 12.5738 23.7612 12.5732V12.5732Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M23.7612 12.5732H19.874V21.1919H23.7612C23.9403 21.1913 24.1118 21.12 24.2384 20.9933C24.3651 20.8667 24.4364 20.6952 24.437 20.5161V13.249C24.4364 13.07 24.3651 12.8984 24.2384 12.7718C24.1118 12.6452 23.9403 12.5738 23.7612 12.5732V12.5732Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M23.2543 12.5732H19.874V21.1919H23.2543C23.4333 21.1913 23.6049 21.12 23.7315 20.9933C23.8581 20.8667 23.9295 20.6952 23.9301 20.5161V13.249C23.9295 13.07 23.8581 12.8984 23.7315 12.7718C23.6049 12.6452 23.4333 12.5738 23.2543 12.5732V12.5732Z"
        fill="black"
      />
      <path
        d="M16.4939 12.5732H23.2551C23.4343 12.5732 23.6062 12.6444 23.7329 12.7712C23.8597 12.8979 23.9309 13.0698 23.9309 13.249V20.0102C23.9309 20.1894 23.8597 20.3613 23.7329 20.4881C23.6062 20.6148 23.4343 20.686 23.2551 20.686H16.4939C16.4051 20.686 16.3171 20.6685 16.235 20.6344C16.153 20.6004 16.0784 20.5506 16.0157 20.4877C15.9529 20.4248 15.9032 20.3502 15.8693 20.2681C15.8354 20.186 15.818 20.098 15.8181 20.0092V13.249C15.8181 13.0698 15.8893 12.8979 16.016 12.7712C16.1428 12.6444 16.3147 12.5732 16.4939 12.5732V12.5732Z"
        fill="#107C41"
      />
      <path
        d="M17.6094 19.1646L19.2499 16.6225L17.7473 14.0947H18.9565L19.7767 15.7105C19.852 15.8636 19.9039 15.978 19.9325 16.0538H19.9432C19.9972 15.9313 20.0538 15.8124 20.1131 15.6968L20.9896 14.0947H22.0996L20.5582 16.6084L22.137 19.1646H20.957L20.0094 17.3895C19.9648 17.314 19.9271 17.2346 19.8965 17.1524H19.8824C19.8548 17.2328 19.8181 17.3098 19.773 17.382L18.7993 19.1646H17.6094Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1499_12249">
        <rect
          width="16.2238"
          height="16.2238"
          fill="white"
          transform="translate(15.8181 8.51758)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export { XlsIcon };
