import { useMutation } from 'shared/hooks/reactQuery';

import { httpClient } from 'shared/utils/httpClient';

export const useDeleteUser = (userId: string, companyId: string) => {
  return useMutation(() => {
    return deleteUser(userId, companyId);
  });
};

const deleteUser = (userId: string, companyId: string) => {
  return httpClient.delete(`users/${userId}?companyID=${companyId}`);
};
