import { extendTheme } from '@chakra-ui/react';

import { inputConfiguration } from 'shared/utils/theme/themeConfiguration/inputConfiguration';
//only direct path is working, when it's shared/components then storybook throw error
import { buttonConfiguration } from 'shared/components/Button';
import { checkboxConfiguration } from 'shared/components/Checkbox/checkboxConfiguration';
import { textConfiguration } from 'shared/components/Text/textConfiguration';
import { textareaConfiguration } from 'shared/components/Textarea/textareaConfiguration';

import '@fontsource/open-sans';

// COLORS
const grannySmithApple = '#CAEAAA';
const mainBackground = '#F6F9FB';
const lightGreen = 'rgba(114, 180, 45, 0.55)';
const green = '#72B42D';
const blue = '#006FCA';
const superLightBlue = '#DCF6FF';
const darkerBlue = '#025EA9';
const darkBlue = '#00467F';
const greyStroke = '#5F646A';
const greyIcon = '#4B5563';
const greyBreadcrumbs = '#EBEBEB';
const greyText = '#9CA3AF';
const greyBorder = '#D1D5DB';
const greyModalOverlay = 'rgba(209, 213, 219, 0.5)';
const black = '#0F0F10';
const red = '#FF0000';
const redError = '#D92626';
const greyBtn = 'rgba(15, 15, 16, 0.5)';
const yellow = '#FFBB35';

//SHADOWS
const btnShadow = '0px 8px 24px rgba(0, 32, 51, 0.12)';
const btnPrimaryShadow = '0px 8px 20px rgba(0, 32, 51, 0.3)';

export const themeConfiguration = extendTheme({
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Tahoma, Open Sans, sans-serif',
        fontSize: '62.5%',
      },
      '.css-r6z5ec': { width: '100%' },
    },
  },
  fonts: {
    heading: 'Tahoma',
  },
  colors: {
    mainBackground,
    primary: {
      200: superLightBlue,
      500: blue,
      600: darkerBlue,
      700: darkBlue,
    },
    secondary: {
      200: grannySmithApple,
      300: lightGreen,
      500: green,
    },
    grey: {
      300: greyModalOverlay,
      400: greyBtn,
      500: greyBreadcrumbs,
      700: greyStroke,
      800: greyIcon,
    },
    greyText,
    greyBorder,
    black,
    red: {
      400: redError,
      500: red,
    },
    yellow,
  },
  shadows: {
    btn: btnShadow,
    btnPrimary: btnPrimaryShadow,
  },
  components: {
    Textarea: textareaConfiguration,
    Input: inputConfiguration,
    Button: buttonConfiguration,
    Checkbox: checkboxConfiguration,
    Text: textConfiguration,
    FormLabel: textConfiguration,
  },
});
