import React, { forwardRef } from 'react';
import {
  InputGroup,
  InputRightElement,
  useBreakpointValue,
} from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/input';

import { Input } from 'shared/components';
import { CalendarIcon } from 'shared/icons';

interface IProps extends InputProps {
  value: string;
  onClick: () => void;
}

const DatepickerInput = forwardRef<
  (HTMLInputElement & InputProps) | null,
  IProps
>(({ value, onClick, ...restProps }, ref) => {
  const height = useBreakpointValue({ base: '38px', md: '46px' });
  return (
    <InputGroup>
      <Input
        h={height}
        fontSize={{
          base: '1.6rem',
          md: '1.8rem',
        }}
        value={value}
        onClick={onClick}
        ref={ref}
        {...restProps}
      />
      <InputRightElement
        onClick={onClick}
        alignItems="center"
        w="fit-content"
        p="14px 16px 14px 16px"
        h={height}
        zIndex={0}
      >
        <CalendarIcon />
      </InputRightElement>
    </InputGroup>
  );
});

DatepickerInput.displayName = 'DatepickerInput';

export { DatepickerInput };
