import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IMfaCodeFormModel } from 'entities/user/model';
import { useAddMfaCode } from 'entities/user/api';
import { Form, Flex } from 'shared/components';
import { MFACodeFormField } from './MFACodeFormField';
import { useMfaCodeFormValidationSchema } from './useMfaCodeFormValidationSchema';
import { SubmitMfaCodeFormButton } from './SubmitMfaCodeFormButton';
import { useForm } from '../../shared/components/Form';

interface IProps {
  onSuccess?: () => void;
  onError?: (error?: unknown) => void;
  mfa?: string;
}

const MFACodeForm = ({ onError, onSuccess, mfa }: IProps) => {
  const { t } = useTranslation('mfaCodeForm');
  const validationSchema = useMfaCodeFormValidationSchema();
  const mutation = useAddMfaCode();
  const formId = 'mfa-code-form';

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues: { mfa: '' },
    formId,
  });

  const handleSubmit = async (model: IMfaCodeFormModel) => {
    try {
      await mutation.mutateAsync(model);
      cleanPersistentStore();
      onSuccess && onSuccess();
    } catch (e) {
      onError && onError(e);
    }
  };

  useEffect(() => {
    cleanPersistentStore();
  }, []);

  return (
    <Form<IMfaCodeFormModel>
      id={formId}
      onSubmit={async (event) => {
        await handleSubmit(event);
      }}
      methods={methods}
    >
      <Flex
        flexDir="column"
        gap="25px"
      >
        <MFACodeFormField
          inputProps={{
            name: 'mfa',
            placeholder: t('Enter MFA code here'),
            ...(mfa && { value: mfa }),
          }}
          label={t('MFA Code')}
        />
        <SubmitMfaCodeFormButton />
      </Flex>
    </Form>
  );
};

export { MFACodeForm };
