import { useTranslation } from 'react-i18next';

import { useGetMyUser } from 'entities/user/api';
import { PersonViewContainer } from 'widgets/PersonViewContainer';
import { Text, ErrorBoundary } from 'shared/components';
import useStore from 'shared/store';

const ViewMyUserThrowable = () => {
  const { t } = useTranslation(['person', 'common']);
  const { isLoading, error, data: myUserData } = useGetMyUser(true);
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!myUserData) {
    return <Text size="lg">{t('User not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <PersonViewContainer
      person={myUserData.data}
      companyId={companyId}
    />
  );
};

const ProfilePage = () => {
  return (
    <ErrorBoundary>
      <ViewMyUserThrowable />
    </ErrorBoundary>
  );
};

export { ProfilePage };
