import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

export const useSaveCustomerAttachment = (companyId: string) => {
  return useMutation((data: FormData) => {
    return saveAttachment(data, companyId);
  });
};

const saveAttachment = (formData: FormData, companyId: string) => {
  return httpClient.post<
    FormData,
    AxiosResponse<{ _id: string; filename: string }>
  >(`customers/attachments?companyID=${companyId}`, formData);
};
