import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';

import { useGetVendor } from 'entities/vendor/api';

import { VendorView } from 'features/vendor/VendorView';

import { VendorActionButtons } from 'widgets/VendorActionButons';
import { CreationDetails } from 'features/CreationDetails';

const VendorViewPageThrowable = () => {
  const { t } = useTranslation('common');
  const { vendorId } = useParams();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  if (!vendorId) {
    throw new Error('VendorId not found');
  }

  const { isLoading, error, data } = useGetVendor(companyId, vendorId);

  if (isLoading) {
    return <Text size="lg">{t('Loading')}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Vendor not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <VendorView vendor={data.data} />
      <VendorActionButtons
        vendorId={vendorId}
        companyId={companyId}
        active={data.data.active}
      />
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const VendorViewPage = () => {
  return (
    <ErrorBoundary>
      <VendorViewPageThrowable />
    </ErrorBoundary>
  );
};

export { VendorViewPage };
