import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { ChildrenProp } from 'shared/types';
import { chakra } from '@chakra-ui/react';
import { HTMLChakraProps } from '@chakra-ui/system';
import { useEffect } from 'react';

interface IProps<T extends FieldValues> extends ChildrenProp {
  // eslint-disable-next-line no-unused-vars
  onSubmit: (model: T, methods: UseFormReturn<T>) => void;
  methods: UseFormReturn<T>;
  id?: string;
  resetOnSubmit?: boolean;
  containerProps?: HTMLChakraProps<'form'>;
}

const Form = <T extends FieldValues>({
  onSubmit,
  children,
  resetOnSubmit = false,
  id,
  containerProps,
  methods,
}: IProps<T>) => {
  const handleSubmit = async (model: T) => {
    await onSubmit(model, methods);
    resetOnSubmit && methods.reset();
  };

  useEffect(() => {
    methods.reset();
  }, []);

  return (
    <FormProvider {...methods}>
      <chakra.form
        // eslint-disable-next-line no-console
        onSubmit={methods.handleSubmit(handleSubmit, console.log)}
        id={id}
        data-testid={id}
        {...containerProps}
      >
        {children}
      </chakra.form>
    </FormProvider>
  );
};

export { Form };
