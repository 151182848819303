import { useGetTracking } from '../../entities/tracking/api';
import { TrackingEmailModal } from './TrackingEmailModal/TrackingEmailModal';

interface IProps {
  companyId: string;
  trackingId: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const TrackingEmail = ({ companyId, trackingId, isOpen, onClose }: IProps) => {
  const { data } = useGetTracking(companyId, trackingId || '', {
    useErrorBoundary: false,
  });

  if (!data) {
    return null;
  }

  return (
    <TrackingEmailModal
      onClose={onClose}
      isOpen={isOpen}
      companyId={companyId}
      tracking={data.data}
    />
  );
};

export { TrackingEmail };
