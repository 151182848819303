export const countries = [
  {
    name: '-',
    abbreviation: '',
  },
  {
    name: 'USA',
    abbreviation: 'USA',
  },
];
