import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteDepartment = () => {
  return useMutation(
    ({
      departmentId,
      companyId,
    }: {
      departmentId: string;
      companyId: string;
    }) => {
      return deleteDepartment(departmentId, companyId);
    },
  );
};

const deleteDepartment = (departmentId: string, companyId: string) => {
  return httpClient.delete(
    `departments/${departmentId}?companyID=${companyId}`,
  );
};
