import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { ISMSPayload } from '../model';

export const useSendSMS = () => {
  return useMutation((data: ISMSPayload) => {
    return sendSMS(data);
  });
};

const sendSMS = (formData: ISMSPayload) => {
  return httpClient.post<ISMSPayload>('sms', formData);
};
