import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { EditItemButton } from 'features/EditItemButton';

import { AddNewPersonButton } from './AddNewPersonButton';
import { DeletePerson } from './DeletePerson/DeletePerson';
import { ChangePasswordButton } from './ChangePasswordButton';
import { useGetMyUser } from 'entities/user/api';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  userId: string;
  active?: boolean;
  companyId: string;
}

const PersonActionButtons = ({ userId, active, companyId }: IProps) => {
  const { data: myUserData } = useGetMyUser(true);
  const {
    fullAndFullOverCompany,
    createPersonnelPrivilege,
    updatePersonnelPrivilege,
    deletePersonnelPrivilege,
  } = LayoutPrivilege();

  const changePasswordVisibility =
    myUserData?.data._id === userId || fullAndFullOverCompany;

  const editPersonnelPermission =
    updatePersonnelPrivilege || myUserData?.data._id === userId;

  return (
    <FlexWrapper>
      {createPersonnelPrivilege && <AddNewPersonButton />}

      {editPersonnelPermission && (
        <EditItemButton
          redirectPath={routeBuilder.personnel.edit.getPath(userId)}
        />
      )}

      {changePasswordVisibility && (
        <ChangePasswordButton
          redirectPath={routeBuilder.personnel.changePassword.getPath(userId)}
        />
      )}

      {active && deletePersonnelPrivilege && (
        <DeletePerson
          userId={userId}
          companyId={companyId}
        />
      )}
    </FlexWrapper>
  );
};

export { PersonActionButtons };
