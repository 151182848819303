import { TableBodyCell, TableRow } from 'shared/components/Table';
import { Center, EmptyState } from 'shared/components';

interface IProps {
  colSpan: number;
}

const EmptyStateTableRow = ({ colSpan }: IProps) => {
  return (
    <TableRow>
      <TableBodyCell colSpan={colSpan}>
        <Center my="25px">
          <EmptyState />
        </Center>
      </TableBodyCell>
    </TableRow>
  );
};

export { EmptyStateTableRow };
