import { useNavigate } from 'react-router-dom';

import { useSaveAction } from 'entities/tracking/api';
import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { ActionForm } from 'features/actions/ActionForm/ActionForm';
import { ActionFormType } from 'entities/tracking/model';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddAction = ({ companyId }: IProps) => {
  const { isLoading, mutateAsync } = useSaveAction(companyId);
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const handleSubmit = async (
    data: ActionFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(data);

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.actions.view.getPath(_id));
      }

      successToast({
        message: t('Successfully added item message', { item: t('action') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('action') }),
      });
    }
  };

  return (
    <ActionForm
      formId={`add-action-form-${companyId}`}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export { AddAction };
