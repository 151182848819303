import { Icon, IconProps } from 'shared/components';

export const TimeIcon = (props: IconProps) => (
  <Icon
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 22.5C17.0751 22.5 22 17.5751 22 11.5C22 5.42487 17.0751 0.5 11 0.5C4.92487 0.5 0 5.42487 0 11.5C0 17.5751 4.92487 22.5 11 22.5ZM12.1 10.9273V2.7H9.9V12.0727L15.8692 16.2512L17.1308 14.4488L12.1 10.9273Z"
      fill="#4B5563"
    />
  </Icon>
);
