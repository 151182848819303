import {
  useGetDepartment,
  useGetLocation,
} from '../../../entities/company/api';
import { useGetCustomer } from '../../../entities/customer/api/useGetCustomer';
import { useGetActivity, useGetTopic } from '../../../entities/tracking/api';
import { useGetUser } from '../../../entities/user/api';
import { useGetAction } from '../../../entities/tracking/api/useGetAction';
import { useGetRole } from '../../../entities/role/api';

export const useParseLocation = (companyId: string, locationId?: string) => {
  const { data } = useGetLocation(companyId, locationId || '', {
    useErrorBoundary: false,
  });

  if (!locationId) {
    return '';
  }

  if (data) {
    return data.data.name;
  }
  return '';
};

export const useParseViolationAction = (
  companyId: string,
  actionId?: string,
) => {
  const { data } = useGetAction(companyId, actionId || '', {
    useErrorBoundary: false,
  });

  if (!actionId) {
    return '';
  }

  if (data) {
    return data.data.name;
  }
  return '';
};

export const useParseDepartment = (
  companyId: string,
  departmentId?: string,
) => {
  const { data } = useGetDepartment(departmentId || '', companyId, {
    useErrorBoundary: false,
  });

  if (!departmentId) {
    return '';
  }

  if (data) {
    return data.data.name;
  }
  return '';
};

export const useParseCustomer = (companyId: string, customerId?: string) => {
  const { data } = useGetCustomer(companyId, customerId || '', {
    useErrorBoundary: false,
  });
  if (!customerId) {
    return '';
  }

  if (data) {
    return data.data.name;
  }
  return '';
};

export const useParseActivity = (companyId: string, activityId?: string) => {
  const { data } = useGetActivity(companyId, activityId || '', {
    useErrorBoundary: false,
  });
  if (!activityId) {
    return '';
  }

  if (data) {
    return data.data.name;
  }
  return '';
};

export const useParseTopic = (companyId: string, topicId?: string) => {
  const { data } = useGetTopic(companyId, topicId || '', {
    useErrorBoundary: false,
  });
  if (!topicId) {
    return '';
  }

  if (data) {
    return data.data.name;
  }
  return '';
};

export const useParseUser = (userId?: string) => {
  const { data } = useGetUser(userId || '', {
    useErrorBoundary: false,
  });

  if (!userId) {
    return null;
  }

  if (data) {
    return data.data;
  }
  return null;
};

export const useParseRole = (companyId: string, roleId?: string) => {
  const { data } = useGetRole(companyId, roleId || '', {
    useErrorBoundary: false,
  });

  if (!roleId) {
    return null;
  }

  if (data) {
    return data.data;
  }
  return null;
};
