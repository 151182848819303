import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { IForgotPasswordFormModel } from '../model';

export const useGetForgotPassword = () => {
  return useMutation((formData: IForgotPasswordFormModel) => getData(formData));
};

const getData = ({ login, company }: IForgotPasswordFormModel) => {
  return httpClient
    .get<string>(`auth/password/reset?login=${login}&company=${company}`)
    .then((response) => {
      return response;
    });
};
