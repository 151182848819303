import { useGetAction } from 'entities/tracking/api/useGetAction';
import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  actionId: string;
  companyId: string;
}

const ViolationActionViewRow = ({ actionId, companyId }: IProps) => {
  const { t } = useTranslation('commonFields');
  const { data } = useGetAction(companyId, actionId);

  return (
    <ViewRow label={t('Violation Action')}>
      <Text>{data?.data.name || '-'}</Text>
    </ViewRow>
  );
};

export { ViolationActionViewRow };
