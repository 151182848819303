import { useTranslation } from 'react-i18next';

export const useLocationsColumnsConfiguration = () => {
  const { t } = useTranslation(['commonFields', 'locations']);
  return [
    {
      name: 'location',
      sortedFields: ['name'],
      title: t('Location Name'),
      isSortable: true,
    },
    {
      name: 'phone',
      sortedFields: ['phoneNumber'],
      title: t('Phone'),
      isSortable: true,
    },
    {
      name: 'address',
      sortedFields: [
        'address.streetNumber',
        'address.street',
        'address.city',
        'address.state',
        'address.zip',
      ],
      title: t('Address'),
      isSortable: true,
    },
    {
      name: 'trackings',
      sortedFields: ['counters.trackings'],
      title: t('Trackings', { ns: 'locations' }),
      isSortable: true,
    },
    {
      name: 'personnel',
      sortedFields: ['counters.users'],
      title: t('Personnel', { ns: 'locations' }),
      isSortable: true,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active'),
      isSortable: true,
    },
  ];
};
