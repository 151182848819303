import useStore from 'shared/store';

import { AddLocation } from 'widgets/Location';

const AddLocationsPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  return <AddLocation companyId={companyId} />;
};

export { AddLocationsPage };
