import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from 'shared/components';
import { ViewIcon } from 'shared/icons';

const ViewButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');

  return (
    <Button
      rightIcon={<ViewIcon />}
      {...props}
    >
      {t('View')}
    </Button>
  );
};

export { ViewButton };
