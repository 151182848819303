import { useMutation } from 'shared/hooks/reactQuery';

import { httpClient } from 'shared/utils/httpClient';

import { ILoginFormModel } from 'entities/user/model';

export const useLoginUser = () => {
  return useMutation((data: ILoginFormModel) => {
    return loginUser(data);
  });
};

const loginUser = (formData: ILoginFormModel) => {
  return httpClient.post<ILoginFormModel>('auth/login', formData);
};
