import { UseQueryOptions } from '@tanstack/react-query';
import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { ISMSAddressList, ISMSAddressListDTO } from '../model';

interface IQuery {
  q?: string;
}

export const useGetSMSAddressList = (
  companyId: string,
  query?: IQuery,
  options?: UseQueryOptions<AxiosResponse<ISMSAddressList>, AxiosError>,
) => {
  return useReactQuery<ISMSAddressList>(
    getSMSAddressListKey(companyId, query),
    () => getSMSAddressList(companyId, query),
    options,
  );
};

const getSMSAddressList = (companyId: string, query?: IQuery) => {
  return httpClient
    .get<ISMSAddressListDTO>(
      buildUrl(
        'sms/phone-number',
        `companyID=${companyId}&q=${query?.q || ''}`,
      ),
    )
    .then((result) => {
      return {
        ...result,
        data: {
          ...result.data,
          users: result.data.users.map((user) => {
            return {
              ...user,
              name: `${user.name.firstname} ${user.name.lastname}`,
            };
          }),
          customers: result.data.customers.map((customer) => {
            return {
              _id: customer._id,
              name: customer.name,
              phoneNumber: customer.contactPerson.phoneNumber,
            };
          }),
          vendors: result.data.vendors.map((vendor) => {
            return {
              _id: vendor._id,
              name: vendor.name,
              phoneNumber: vendor.contactPerson.phoneNumber,
            };
          }),
        },
      };
    });
};

const getSMSAddressListKey = (companyId: string, query?: IQuery) => [
  'list-phone-numbers',
  companyId,
  query,
];
