import { useTranslation } from 'react-i18next';

import { Select } from 'shared/components';
import { ISelectOption } from 'shared/components/Select';

import { useGetLocations } from 'entities/company/api';

interface IProps {
  locationFilter: ISelectOption | null;
  onChange: (value: ISelectOption | null) => void;
  companyId: string;
}

const FilterByLocation = ({ onChange, locationFilter, companyId }: IProps) => {
  const { t } = useTranslation('common');
  const { isLoading, data, isError } = useGetLocations(companyId, undefined, {
    useErrorBoundary: false,
  });

  const options = [
    { value: null, label: 'By Location' },
    ...(data
      ? data.data.data.map((location) => {
          return {
            value: location._id,
            label: location.name,
          };
        })
      : []),
  ];

  return (
    <Select
      isLoading={isLoading}
      isDisabled={isError}
      placeholder={t('By Location')}
      options={options}
      value={locationFilter}
      onChange={(newValue) => {
        const selectedOption = newValue as ISelectOption;
        if (!selectedOption.value) {
          onChange(null);
          return;
        }
        onChange(newValue as ISelectOption);
      }}
    />
  );
};

export { FilterByLocation };
