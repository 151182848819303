import { IAttachment } from 'entities/attachments/model';
import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

export const useImportCustomers = (companyId: string) => {
  return useMutation((data: FormData) => {
    return importCustomer(data, companyId);
  });
};

const importCustomer = (formData: FormData, companyId: string) => {
  return httpClient.post<
    IAttachment,
    AxiosResponse<{ _id: string; filename: string }>
  >(`customers/import?companyID=${companyId}`, formData);
};
