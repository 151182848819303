import { Box } from 'shared/components';

import { ChildrenProp } from 'shared/types';

type Props = ChildrenProp;

const DatepickerContainer = ({ children }: Props) => {
  return (
    <Box
      sx={{
        '.react-datepicker__triangle': {
          display: 'none',
        },
        '.react-datepicker__day': {
          w: '32px',
          h: '32px',
          lineHeight: '16px',
        },
        '.react-datepicker__month-container': {
          p: { base: '0', sm: '10px', md: '20px' },
          borderWidth: '2px',
          borderColor: 'secondary.500',
          borderRadius: '4px',
        },
        '.react-datepicker__day--selected': {
          backgroundColor: 'primary.500',
          borderRadius: '16px',
        },
        '.react-datepicker__day--keyboard-selected': {
          backgroundColor: 'primary.500',
          borderRadius: '16px',
        },
        '.react-datepicker__day--outside-month': {
          color: 'greyText',
        },

        '.react-datepicker__header': {
          backgroundColor: 'transparent',
          border: 'none',
          padding: 0,
        },
        '.react-datepicker__day-names': {
          display: 'flex',
          paddingX: '10px',
          pb: '10px',
          justifyContent: 'space-between',
        },
        '.react-datepicker__day-name': {
          fontSize: '1rem',
          color: 'grayText',
          textTransform: 'capitalize',
        },
        '.react-datepicker': {
          border: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};

export { DatepickerContainer };
