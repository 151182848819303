import { useQueryClient } from '@tanstack/react-query';
import { useImportCustomers } from 'entities/customer/api';
import { useImportUsers } from 'entities/user/api';
import { useImportVendors } from 'entities/vendor/api';
import { FileUpload } from 'features/FileUpload';
import { useState } from 'react';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Modal, SingleTableWrapper } from 'shared/components';
import { DownloadIcon } from 'shared/icons';
import { useToast } from 'shared/utils';
import ImportUploadModalContent from './ImportUploadModalContent';
import { ImportResults } from './ImportResults.type';

const links = {
  personnel:
    'https://api.devtest.omnistools.com/public/templates/Personnel.xlsx',
  customer:
    'https://api.devtest.omnistools.com/public/templates/Customers.xlsx',
  vendor: 'https://api.devtest.omnistools.com/public/templates/Vendors.xlsx',
};

interface IProps {
  title: string;
  companyId: string;
  setIsImported?: Dispatch<SetStateAction<boolean>>;
  entityName: 'personnel' | 'customer' | 'vendor';
}

const ImportUploadTable = ({
  title,
  companyId,
  entityName,
  setIsImported,
}: IProps) => {
  const { t } = useTranslation(['popupMessage', 'common']);
  const { mutateAsync: vendorMutateAsync } = useImportVendors(companyId);
  const { mutateAsync: customerMutateAsync } = useImportCustomers(companyId);
  const { mutateAsync: personnelMutateAsync } = useImportUsers(companyId);
  const [modalState, setModalState]
    = useState({ open: false, loading: true, result: null as ImportResults });
  const fileHref = links[entityName];

  const { register } = useForm();
  const { successToast, errorToast } = useToast();
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  const handleCloseModal = () => {
    if (modalState.loading) {
      errorToast({
        message: t('You need to wait until the upload completes before closing this window'),
      });
    }
    else {
      setModalState((state) => ({ ...state, open: false }));
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFile = e.target.files[0];
      if (!newFile) {
        return;
      }
      const isPersonUploading = entityName === 'personnel';
      if (isPersonUploading) setModalState({ open: true, loading: true, result: null });

      let result: ImportResults;

      const formData = new FormData();
      formData.append('file', newFile);
      try {
        switch (entityName) {
          case 'vendor':
            await vendorMutateAsync(formData);
            break;
          case 'customer':
            await customerMutateAsync(formData);
            break;
          case 'personnel':
            result = (await personnelMutateAsync(formData)).data as unknown as ImportResults;
            break;
          default:
            break;
        }
        queryClient.invalidateQueries(
          queryCache.findAll().map((keys) => keys.queryKey[0]),
        );
        setIsImported && setIsImported(true);
        if (isPersonUploading) setModalState(state => ({ ...state, loading: false, result }));

        else {
          successToast({
            message: t('Successfully added item message', {
              item: t('attachment'),
              ns: 'popupMessage',
            }),
          });
        }
      } catch (e) {
        if (isPersonUploading)
          setModalState(state => ({ ...state, loading: true, open: false  }));
        errorToast({
          message: t('Error while adding item message', {
            item: t('attachment'),
            ns: 'popupMessage',
          }),
        });
      }
    }
  };
  const handleUploadClick = async () => {
    return;
  };
  return (
    <SingleTableWrapper
      title={title}
      childContainerStyle={{
        gap: '15px',
        flexDir: { base: 'column', sm: 'row' },
        justifyContent: 'center',
      }}
    >
      <Modal
        title={t('Uploading results')}
        onClose={handleCloseModal}
        isOpen={modalState.open}
        size="6xl"
        closeButtonLabel='Close'
        content={
          <ImportUploadModalContent
            isLoading={modalState.loading}
            results={modalState.result}
          />
        }
      />
      <Button
        as="a"
        whiteSpace="nowrap"
        minW="min-content"
        w={{ base: '100%' }}
        href={fileHref}
        download={`${entityName}-template.xlsx`}
        title={`${entityName}-template.xlsx`}
        rel="noreferrer"
        rightIcon={<DownloadIcon boxSize={'25px'} />}
      >
        {t('Download Template', { ns: 'common' })}
      </Button>
      <FileUpload
        register={register('file_')}
        handleUploadClick={handleUploadClick}
        handleFileChange={handleFileChange}
      />
    </SingleTableWrapper>
  );
};

export { ImportUploadTable };
