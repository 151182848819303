import { Th } from '@chakra-ui/react';
import { TableColumnHeaderProps } from '@chakra-ui/table';

import { getBorder } from 'shared/utils';

const borderRight = getBorder(2, 'solid', 'secondary.500', 'Right');

const TableHeadCell = ({ children, ...restProps }: TableColumnHeaderProps) => {
  return (
    <Th
      padding={{ base: '14px 8px', xl: '16px 10px' }}
      _notLast={borderRight}
      {...(restProps.onClick && {
        cursor: 'pointer',
        userSelect: 'none',
      })}
      {...restProps}
    >
      {children}
    </Th>
  );
};

export { TableHeadCell };
