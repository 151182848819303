import { MobileMenuItem } from './MobileMenuItem';
import { LogoutIcon } from 'shared/icons';
import { HStack } from 'shared/components';
import { useLogoutUser } from 'entities/user/api/useLogoutUser';

const NavItemMobileLogout = () => {
  const logout = useLogoutUser();
  return (
    <HStack
      gap="10px"
      p="12px 16px"
      mb="10px"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        logout.mutate();
      }}
    >
      <MobileMenuItem fontWeight="700">Logout</MobileMenuItem>
      <LogoutIcon />
    </HStack>
  );
};

export { NavItemMobileLogout };
