import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { ITracking } from 'entities/tracking/model';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from '../../../shared/utils/httpClient';
import { useGetLocale } from 'shared/utils';

export const useGetTracking = (
  companyId: string,
  trackingId: string,
  options?: UseQueryOptions<AxiosResponse<ITracking>, AxiosError>,
) => {
  const { code: lang } = useGetLocale();
  const _lang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<ITracking>(
    getTrackingKey(companyId, trackingId, _lang),
    () => getTracking(companyId, trackingId, _lang),
    options,
  );
};

const getTracking = (companyId: string, trackingId: string, languageCode?: string) => {
  return httpClient.get<ITracking>(
    `trackings/${trackingId}?languageCode=${languageCode||'en'}&companyID=${companyId}`,
  );
};

const getTrackingKey = (companyId: string, trackingId: string, languageCode?: string) => [
  'tracking',
  trackingId,
  companyId,
  languageCode || 'en'
];
