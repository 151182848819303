import { useNavigate } from 'react-router-dom';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { ViewButton } from 'features/ViewButton';
import { RegulationForm } from 'features/regulation/RegulationForm';
import { DeleteRegulation } from 'features/regulation/RegulationActionButtons';

import { IRegulation, IRegulationPayload } from 'entities/regulation/model';
import { useUpdateRegulation } from 'entities/regulation/api';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
  regulation: IRegulation;
}
const EditRegulation = ({ regulation, companyId }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, agency, credits, ...defaultValue } = regulation;
  const { mutateAsync, isLoading } = useUpdateRegulation(companyId, _id);
  const navigation = useNavigate();
  const viewPath = routeBuilder.regulation.view.getPath(_id);
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();

  const handleSubmit = async (
    data: IRegulationPayload,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(data);
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.regulation.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', {
          item: t('regulation'),
        }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', {
          item: t('regulation'),
        }),
      });
    }
  };

  return (
    <RegulationForm
      companyId={companyId}
      formId={`edit-regulation-form-${_id}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          <DeleteRegulation
            regulationId={_id}
            companyId={companyId}
          />
        </>
      }
      defaultValues={{
        ...defaultValue,
        agencyID: agency?._id || '',
      }}
    />
  );
};

export { EditRegulation };
