import {
  DeepPartial,
  FieldValues,
  useForm as useReactHookForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { UseFormProps } from 'react-hook-form/dist/types';
import Yup from 'yup';
import { IFormPersistConfig, useFormPersist } from './useFormPersist';

interface IProps<T extends FieldValues> extends UseFormProps<T> {
  defaultValues: DeepPartial<T>;
  formId: string;
  persistenceProps?: Omit<IFormPersistConfig, 'setValue' | 'watch' | 'formId'>;
  validationSchema?: Yup.AnyObjectSchema;
}

export const useForm = <T extends FieldValues>(props: IProps<T>) => {
  const { persistenceProps, defaultValues, validationSchema, formId } = props;
  const methods = useReactHookForm<T>({
    ...(validationSchema && {
      resolver: yupResolver(validationSchema),
    }),
    mode: 'all',
  });

  const { clear } = useFormPersist({
    defaultValues,
    watch: methods.watch,
    setValue: methods.setValue,
    formId,
    ...persistenceProps,
  });

  return {
    methods,
    cleanPersistentStore: clear,
  };
};
