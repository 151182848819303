import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  abbreviation?: string;
}

const AbbreviationViewRow = ({ abbreviation }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Abbreviation')}>
      <Text>{abbreviation || '-'}</Text>
    </ViewRow>
  );
};

export { AbbreviationViewRow };
