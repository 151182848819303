import { TableCellText, TableHeaderText } from 'shared/components';
import { ColumnsTitles } from '../types';
import { useTranslation } from 'react-i18next';
import { Active, IUser } from 'entities/user/model';

interface IProps {
  user: IUser;
}

const UserMobileDetails = ({ user }: IProps) => {
  const { t } = useTranslation(['commonFields']);

  const userRole = user?.roles.map((role) => role.name).join(', ') || '-';
  const userActive = user?.active ? Active.Yes : Active.No;
  const locationName = user?.locations
    ? user?.locations?.map((location) => (location?.name ? location.name : '-'))
    : '-';
  const departmentName = user?.departments
    ? user?.departments?.map((department) =>
        department?.name ? department.name : '-',
      )
    : '-';
  const supervisor = () => {
    const firstName = user?.supervisor?.name.firstname
      ? user?.supervisor?.name.firstname
      : '-';
    const lastName = user?.supervisor?.name.lastname
      ? user?.supervisor?.name.lastname
      : '';
    return `${firstName} ${lastName}`;
  };
  return (
    <>
      <TableHeaderText color="black">
        {t(ColumnsTitles.firstName)}
      </TableHeaderText>
      <TableCellText>{user?.name.firstname}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.lastName)}
      </TableHeaderText>
      <TableCellText>{user?.name.lastname}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.locations)}
      </TableHeaderText>
      <TableCellText>
        {locationName && locationName.length > 0 ? locationName : '-'}
      </TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.departments)}
      </TableHeaderText>
      <TableCellText>
        {departmentName && departmentName.length > 0 ? departmentName : '-'}
      </TableCellText>{' '}
      <TableHeaderText color="black">
        {t(ColumnsTitles.supervisor)}
      </TableHeaderText>
      <TableCellText>{supervisor()}</TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.roles)}</TableHeaderText>
      <TableCellText>{userRole}</TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.login)}</TableHeaderText>
      <TableCellText>{user?.login || '-'}</TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.active)}</TableHeaderText>
      <TableCellText>{t(userActive)}</TableCellText>
    </>
  );
};

export { UserMobileDetails };
