import { IMenuItemWrapper } from 'features/Navigation/Navigation';
import { Privileges } from 'entities/role/model';

const filterWithPrivileges =
  (activePrivileges: Privileges[]) => (item: IMenuItemWrapper) => {
    if (item.allowedPrivileges.length === 0) return true;

    return activePrivileges.find((activePrivilege) =>
      item.allowedPrivileges.includes(activePrivilege),
    );
  };

export { filterWithPrivileges };
