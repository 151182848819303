import { useTranslation } from 'react-i18next';

import { DatepickerField, FormField } from 'shared/components/Form';

const TrackingDateFromField = () => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField label={`${t('Tracking Date From')}:`}>
      <DatepickerField name="from" />
    </FormField>
  );
};

export { TrackingDateFromField };
