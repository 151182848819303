import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { PersonnelFinalType } from '../model';

export const useUpdateUser = (userId: string, companyId: string) => {
  return useMutation((data: PersonnelFinalType) => {
    return updateUser(data, userId, companyId);
  });
};

const updateUser = (
  formData: PersonnelFinalType,
  userId: string,
  companyId: string,
) => {
  return httpClient.patch(`users/${userId}?companyID=${companyId}`, formData);
};
