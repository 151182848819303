import { useTranslation } from 'react-i18next';

import { Button } from 'shared/components';
import { useFormContext } from 'shared/components/Form';
import { isEmpty } from 'lodash';

const SubmitMfaCodeFormButton = () => {
  const { t } = useTranslation('mfaCodeForm');
  const {
    formState: { isSubmitting, errors },
  } = useFormContext();

  const isValid = isEmpty(errors);

  return (
    <Button
      variant="secondary"
      type="submit"
      w="100%"
      isDisabled={isSubmitting || !isValid}
      isLoading={isSubmitting}
    >
      {t('Send')}
    </Button>
  );
};

export { SubmitMfaCodeFormButton };
