import { useFormContext } from 'react-hook-form';

import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { Checkbox } from 'shared/components';

import { IUser } from 'entities/user/model';

interface IProps {
  user: IUser;
}

const EmployeeTrackingTableRow = ({ user }: IProps) => {
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = `attendees.${user._id}`;
  const value = watch(name);

  return (
    <TableRow
      key={user._id}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell textAlign="center">
        <Checkbox
          isDisabled={isSubmitting}
          size="lg"
          isChecked={value?.value}
          onChange={(event) => {
            setValue(name, {
              _id: user._id,
              value: event.target.checked,
            });
          }}
        />
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{user.name.lastname}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{user.name.firstname}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { EmployeeTrackingTableRow };
