import { UseQueryOptions } from '@tanstack/react-query';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { useGetParsedQueryParams } from 'shared/utils';

import { IAgency } from '../model';

export const useGetAgencies = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IAgency[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);

  return useReactQuery<IList<IAgency[]>>(
    getAgenciesKey(companyId, parsedQueryParams),
    () => getAgencies(companyId, parsedQueryParams),
    options,
  );
};

const getAgencies = (companyId: string, query?: string) => {
  return httpClient.get<IList<IAgency[]>>(
    buildUrl('agencies', `companyID=${companyId}&${query}`),
  );
};

const getAgenciesKey = (companyId: string, query: string) => [
  'agencies',
  companyId,
  query,
];
