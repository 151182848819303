import { useTranslation } from 'react-i18next';

export const useTrackingsColumnsConfiguration = () => {
  const { t } = useTranslation(['commonFields', 'tracking']);
  return [
    {
      name: 'date',
      sortedFields: ['trackedAt.utc'],
      title: t('Tracking Date'),
      isSortable: true,
    },
    {
      name: 'location',
      sortedFields: ['location.name'],
      title: t('Location', { ns: 'tracking' }),
      isSortable: true,
    },
    {
      name: 'incidentLocation',
      sortedFields: ['incidentLocation'],
      title: t('Incident Location', { ns: 'tracking' }),
      isSortable: true,
    },
    {
      name: 'department',
      sortedFields: ['department.name'],
      title: t('Department', { ns: 'tracking' }),
      isSortable: true,
    },
    {
      name: 'activity',
      sortedFields: ['activity.name'],
      title: t('Activity'),
      isSortable: true,
    },
    {
      name: 'topic',
      sortedFields: ['topics.name'],
      title: t('Topic', { ns: 'tracking' }),
      isSortable: false,
    },
    {
      name: 'trackedBy',
      sortedFields: ['trackedBy.name.firstname', 'trackedBy.name.lastname'],
      title: t('Tracked By'),
      isSortable: true,
    },
    {
      name: 'attendee',
      sortedFields: ['attendees.name.firstname', 'attendees.name.lastname'],
      title: t('Attendee', { ns: 'tracking' }),
      isSortable: false,
    },
    {
      name: 'violation',
      sortedFields: ['violation.safetyViolation'],
      title: t('Violation', { ns: 'tracking' }),
      isSortable: true,
    },
  ];
};
