import { ActivityFormType } from 'entities/tracking/model';

export const convertActivityFormData = (data: ActivityFormType) => {
  const topicIDs =
    data.topics &&
    Object.keys(data.topics)
      ?.filter((key) => data.topics && data.topics[key].value)
      .reduce(
        (result: string[], key) =>
          data.topics ? [...result, data.topics[key]._id] : [...result],
        [],
      );

  delete data.topics;
  delete data.attachmentIDs;
  delete data.attachments;

  return {
    ...data,
    topicIDs,
  };
};
