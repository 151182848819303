import { useNavigate } from 'react-router-dom';

import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { routeBuilder } from 'shared/utils';

import { IAgency } from 'entities/agency/model';

interface IProps {
  agency: IAgency;
}
const AgenciesTableRow = ({ agency }: IProps) => {
  const navigation = useNavigate();

  const handleRedirect = () => {
    navigation(routeBuilder.agency.view.getPath(agency._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{agency.name}</TableCellText>
      </TableBodyCell>

      <TableBodyCell>
        <TableCellText>{agency.abbreviation}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { AgenciesTableRow };
