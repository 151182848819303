import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

import { AddVendor } from 'entities/vendor/model';

export const useSaveVendor = (companyId: string) => {
  return useMutation((data: AddVendor) => {
    return saveCustomer(data, companyId);
  });
};

const saveCustomer = (formData: AddVendor, companyId: string) => {
  return httpClient.post<AddVendor, AxiosResponse<{ _id: string }>>(
    `vendors?companyID=${companyId}`,
    formData,
  );
};
