import { useToast } from 'shared/utils';
import { httpClient } from 'shared/utils/httpClient';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

//szpachla do refaktoryzacji, prawie to samo co handleDownloadReportFile
export const useHandleOpenFileInNewTab = () => {
  const { errorToast } = useToast();
  const { t } = useTranslation('validationMessages');

  return async (fileUrl: string) => {
    httpClient
      .get(fileUrl, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
      .then((response: AxiosResponse<Blob>) => {
        if (response.status === 204) {
          errorToast({
            message: t('No tracking events matched your selections'),
          });
        } else {
          const blob = new Blob([response.data], {
            type: 'application/pdf',
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
        }
      })
      .catch(() => {
        errorToast({
          message: t('Error during downloading file'),
        });
      });
  };
};
