import { useTranslation } from 'react-i18next';

import { DeleteIcon } from 'shared/icons';
import { Button, ButtonProps } from 'shared/components';

const DeleteButton = ({ ...props }: ButtonProps) => {
  const { t } = useTranslation('common');

  return (
    <Button
      leftIcon={<DeleteIcon />}
      {...props}
    >
      {t('Delete')}
    </Button>
  );
};

export { DeleteButton };
