import { useTranslation } from 'react-i18next';

import { YesNoSelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';

interface IProps {
  isDisabled?: boolean;
}

const ActiveField = ({ isDisabled }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Active')}:`}>
      <YesNoSelectField
        name="active"
        isDisabled={isDisabled}
      />
    </FormField>
  );
};

export { ActiveField };
