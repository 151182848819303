import { Icon, IconProps } from 'shared/components';

const EmailIcon = (props: IconProps) => (
  <Icon
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 7V24L8 15.5L0 7Z"
      fill="#72B42D"
    />
    <path
      d="M1 5L12.3229 15.9617C13.753 17.3461 16.247 17.3461 17.6771 15.9617L29 5H1Z"
      fill="#72B42D"
    />
    <path
      d="M19.4666 17.5911C18.2751 18.7911 16.6881 19.4534 15 19.4534C13.3119 19.4534 11.7249 18.7911 10.5334 17.5911L9.94585 17L1 26H29L20.0542 17L19.4666 17.5911Z"
      fill="#72B42D"
    />
    <path
      d="M22 15.5L30 24V7L22 15.5Z"
      fill="#72B42D"
    />
  </Icon>
);

export { EmailIcon };
