import { ErrorBoundary } from 'shared/components';
import useStore from 'shared/store';

import { ReportForm } from 'features/report';

const ReportPageThrowable = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  return <ReportForm companyId={companyId} />;
};

const ReportPage = () => {
  return (
    <ErrorBoundary>
      <ReportPageThrowable />
    </ErrorBoundary>
  );
};

export { ReportPage };
