import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  login?: string;
}

const LoginViewRow = ({ login }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Login')}>
      <Text>{login || '-'}</Text>
    </ViewRow>
  );
};

export { LoginViewRow };
