import { Icon, IconProps } from 'shared/components';

interface IProps extends IconProps {
  isOpen?: boolean;
  isSubItem?: boolean;
  isSubItemOpen?: boolean;
}

export const ChevronNavMenuIcon = ({
  isOpen,
  isSubItem,
  isSubItemOpen,
  ...restProps
}: IProps) => {
  return (
    <Icon
      width={{ base: '24px', lg: '20px', xl: '24px' }}
      height="auto"
      viewBox="0 0 29 23"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...(isOpen && !isSubItem && { transform: 'rotate(180deg)' })}
      {...(isSubItem && { transform: 'rotate(-90deg)' })}
      {...restProps}
    >
      <path d="M25.9624 8.1296L25.9623 8.12954L24.4595 6.66082C23.8735 6.08801 22.9068 6.04819 22.2897 6.64685L14.4286 14.1937L6.56766 6.64698C5.9505 6.04818 4.98378 6.088 4.39778 6.66072C4.39777 6.66074 4.39775 6.66076 4.39773 6.66078L2.89485 8.12954L2.8948 8.12958C2.29574 8.71512 2.25421 9.70505 2.88515 10.3214L2.88513 10.3215L2.89136 10.3275L13.3381 20.3585L13.3402 20.3605C13.6452 20.6517 14.0345 20.7856 14.412 20.7849C14.8051 20.7983 15.2141 20.6585 15.5244 20.3533L25.9658 10.3275L25.9658 10.3275L25.972 10.3214C26.603 9.70503 26.5614 8.71512 25.9624 8.1296Z" />
    </Icon>
  );
};
