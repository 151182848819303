import { UseQueryOptions } from '@tanstack/react-query';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';

import { IEmailAddressList, IEmailAddressListDTO } from '../model';

interface IQuery {
  q?: string;
}

export const useGetEmailAddressList = (
  companyId: string,
  query?: IQuery,
  options?: UseQueryOptions<AxiosResponse<IEmailAddressList>, AxiosError>,
) => {
  return useReactQuery<IEmailAddressList>(
    getEmailAddressListKey(companyId, query),
    () => getEmailAddressList(companyId, query),
    options,
  );
};

const getEmailAddressList = (companyId: string, query?: IQuery) => {
  return httpClient
    .get<IEmailAddressListDTO>(
      buildUrl('email/address', `companyID=${companyId}&q=${query?.q || ''}`),
    )
    .then((result) => {
      return {
        ...result,
        data: {
          ...result.data,
          users: result.data.users.map((user) => {
            return {
              ...user,
              name: `${user.name.firstname} 
              ${user.name.middlename} 
              ${user.name.lastname} 
              ${user.name.nickname}`,
            };
          }),
        },
      };
    });
};

const getEmailAddressListKey = (companyId: string, query?: IQuery) => [
  'email-address-list',
  companyId,
  query,
];
