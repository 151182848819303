import { useSaveCompany } from '../../entities/company/api';
import { useNavigate } from 'react-router-dom';
import { convertData } from '../../features/company/CompanyForm/utils';
import { CompanyFormType } from '../../entities/company/model';
import { routeBuilder, useToast } from '../../shared/utils';
import { SubmitFormType } from '../../shared/types';
import { CompanyForm } from '../../features/company/CompanyForm';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

const AddCompany = () => {
  const { mutateAsync, isLoading } = useSaveCompany();
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');
  const queryClient = useQueryClient();

  const handleSubmit = async (
    data: CompanyFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    const dataForBackend = convertData(data);

    try {
      const {
        data: { _id },
      } = await mutateAsync(dataForBackend);

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.company.view.getPath(_id));
      }

      queryClient.invalidateQueries(['companies']);
      successToast({
        message: t('Successfully added item message', { item: t('company') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('company') }),
      });
    }
  };

  return (
    <CompanyForm
      formId="add-company-form"
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export { AddCompany };
