import { useTranslation } from 'react-i18next';

import { Modal } from 'shared/components';
import { IModalProps } from 'shared/components/Modal';
import { TrackingEmailModalForm } from './TrackingEmailModalForm';
import { ITracking } from 'entities/tracking/model';
import {
  useTrackingEmailMessageParser,
  useTrackingEmailModalFormValidationSchema,
  useTrackingEmailTitleParser,
  prepareEmailMessageForBackend,
} from './utils';
import { SendEmailButton } from './SendEmailButton';
import { useForm } from '../../../shared/components/Form';
import { useSendEmail } from '../../../entities/email/api';
import { routeBuilder, useToast } from '../../../shared/utils';
import { IEmailPayload } from '../../../entities/email/model';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps extends Omit<IModalProps, 'title'> {
  companyId: string;
  tracking: ITracking;
  isLoading?: boolean;
}

const defaultValues = {
  recipient: '',
  message: '',
  title: '',
};

const TrackingEmailModal = ({
  companyId,
  tracking,
  onClose,
  ...restProps
}: IProps) => {
  const { t } = useTranslation(['popupMessage', 'common']);
  const formId = `tracking-view-email-form-${tracking._id}-${companyId}`;
  const { successToast, errorToast } = useToast();
  const validationSchema = useTrackingEmailModalFormValidationSchema();
  const parsedMessage = useTrackingEmailMessageParser(tracking, companyId);
  const parsedTitle = useTrackingEmailTitleParser(tracking);
  const { mutateAsync } = useSendEmail();
  const navigation = useNavigate();

  //it's a hack, because default values are set once when but not all data is
  //fetched yet in useTrackingEmailMessageParser
  useEffect(() => {
    methods.setValue('message', parsedMessage);
  }, [parsedMessage]);

  useEffect(() => {
    methods.setValue('title', parsedMessage);
  }, [parsedTitle]);

  const { methods, cleanPersistentStore } = useForm<IEmailPayload>({
    defaultValues: {
      ...defaultValues,
      message: parsedMessage,
      title: parsedTitle,
    },
    formId,
    validationSchema,
    persistenceProps: {
      exclude: ['message', 'title', 'recipient'],
    },
  });

  const redirectToView = () =>
    navigation(routeBuilder.tracking.view.getPath(tracking._id));

  const handleSubmit = async () => {
    try {
      await mutateAsync({
        ...methods.getValues(),
        message: prepareEmailMessageForBackend(methods.getValues('message')),
      });
      methods.reset();
      cleanPersistentStore();
      successToast({
        message: t('Successfully sent message'),
      });
      onClose();
    } catch (e) {
      const error = e as {
        request: {
          responseText: string;
        };
      };
      if (error.request.responseText.includes('recipient must be a string')) {
        errorToast({
          message: t('Required email address'),
        });
      } else {
        errorToast({
          message: t('Error while sending message'),
        });
      }
    } finally {
      redirectToView();
    }
  };

  return (
    <Modal
      title={t('Email', { ns: 'common' })}
      content={
        <TrackingEmailModalForm
          companyId={companyId}
          methods={methods}
          formId={formId}
        />
      }
      size="5xl"
      onClose={() => {
        onClose();
        redirectToView();
      }}
      {...restProps}
    >
      <SendEmailButton
        onClick={() => handleSubmit()}
        isLoading={methods.formState.isSubmitting}
      />
    </Modal>
  );
};

export { TrackingEmailModal };
