import { Flex } from 'shared/components';
import {
  ActivityViewRow,
  AttachmentsViewRow,
  AttendeesViewRow,
  CustomersViewRow,
  DepartmentNameViewRow,
  LocationNameViewRow,
  TopicsViewRow,
  TrackedByViewRow,
  TrackingDateViewRow,
  TrackingDescriptionViewRow,
  TrackingTimeViewRow,
  VendorsViewRow,
  ViolationActionDetailsViewRow,
  ViolationActionViewRow,
  ViolationDescriptionViewRow,
  ViolationViewRow,
  ZoneViewRow,
} from 'features/ViewCommonFields';
import { ITracking } from 'entities/tracking/model';
import { IncidentLocationViewRow } from 'features/ViewCommonFields/IncidentLocationViewRow';
import useStore from 'shared/store';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

interface IProps {
  tracking: ITracking;
}

const TrackingView = ({ tracking }: IProps) => {
  const companyComponents = useStore((state) => state.company?.components || []);
  const hasCustomers = companyComponents.includes(ICompanyComponent.Customers);
  const hasVendors = companyComponents.includes(ICompanyComponent.Vendors);

  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <LocationNameViewRow locationName={tracking.location?.name} />
      <DepartmentNameViewRow departmentName={tracking.department?.name} />
      <TrackedByViewRow
        firstName={tracking.trackedBy?.name.firstname}
        lastName={tracking.trackedBy?.name.lastname}
      />
      <ActivityViewRow activity={tracking.activity?.name} />
      <TrackingDateViewRow trackingDate={tracking.trackedAt?.utc} />
      <TrackingTimeViewRow trackingDate={tracking.trackedAt?.utc} />
      <ZoneViewRow zone={tracking.trackedAt?.timeZone} />
      <IncidentLocationViewRow value={tracking.incidentLocation} />
      <TrackingDescriptionViewRow description={tracking.description} />
      <ViolationViewRow violation={tracking.violation?.safetyViolation} />
      {tracking.violation?.description && (
        <ViolationDescriptionViewRow
          description={tracking.violation?.description}
        />
      )}
      {tracking.violation?.actionID && (
        <ViolationActionViewRow
          actionId={tracking.violation?.actionID}
          companyId={tracking.companyID}
        />
      )}
      {tracking.violation?.actionDetail && (
        <ViolationActionDetailsViewRow
          actionDetail={tracking.violation?.actionDetail}
        />
      )}
      <TopicsViewRow topicIDs={tracking.topics?.map((topic) => topic._id)} />
      {hasCustomers && (
        <CustomersViewRow customerIDs={tracking.customerIDs} />
      )}
      {hasVendors && (
        <VendorsViewRow vendorIDs={tracking.vendorIDs} />
      )}
      <AttendeesViewRow
        attendeeIDs={tracking.attendees?.map((attendee) => attendee._id)}
        companyId={tracking.companyID}
      />
      <AttachmentsViewRow
        attachments={tracking.attachments}
        entityName="trackings"
      />
    </Flex>
  );
};

export { TrackingView };
