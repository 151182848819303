import { ReactNode, useEffect, useState } from 'react';
import { Flex, FlexWrapper, Form } from 'shared/components';
import { CompanyFormType } from 'entities/company/model';
import { SaveButton } from 'features/SaveButton';
import { SaveAndNewButton } from 'features/SaveAndNewButton';
import {
  AbbreviationField,
  ActiveField,
  CityField,
  FaxField,
  LoginCodeField,
  NAISCField,
  NameField,
  NearestCrossStreetField,
  PhoneNumberField,
  SICField,
  StateField,
  StreetNameField,
  StreetNumberField,
  TimeZoneField,
  useContainerProps,
  ZipField,
} from 'features/FormCommons';
import { useCompanyFormValidationSchema } from './utils';
import { SubmitFormType } from 'shared/types';
import { useForm } from 'shared/components/Form';
import { CompanyComponentsField } from 'features/FormCommons/components/CompanyComponentsField';

type CompanyFormProps = {
  defaultValues?: CompanyFormType;
  handleSubmit: (
    data: CompanyFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  formId: string;
  additionalActionButtons?: ReactNode;
};

const defaultData: CompanyFormType = {
  name: '',
  abbreviation: '',
  login: '',
  address: {
    street: '',
    streetNumber: '',
    city: '',
    state: '',
    zip: '',
    nearestCrossStreet: '',
  },
  phoneNumber: '',
  fax: '',
  timeZone: '',
  active: true,
};

const CompanyForm = ({
  defaultValues = defaultData,
  formId,
  isLoading,
  additionalActionButtons,
  handleSubmit,
}: CompanyFormProps) => {
  const containerProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const validationSchema = useCompanyFormValidationSchema();

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });

  useEffect(() => {
    if (formId.includes('edit-company')) {
      cleanPersistentStore();
    }
  }, []);

  return (
    <Form<CompanyFormType>
      onSubmit={(data, { reset }) => {
        handleSubmit(
          data,
          () => {
            cleanPersistentStore();
          },
          submitType,
        );
        reset(defaultValues);
      }}
      id={formId}
      containerProps={{
        ...containerProps,
      }}
      methods={methods}
    >
      <Flex
        gap={{ base: '20px', md: '30px', lg: '20px' }}
        width="100%"
        direction={{
          base: 'column',
          lg: 'row',
        }}
      >
        <Flex
          flexDir="column"
          flexGrow="1"
          gap={{
            base: '15px',
            md: '25px',
            lg: '15px',
            xl: '25px',
          }}
        >
          <NameField autoCapitalize='off' />

          <AbbreviationField />

          <LoginCodeField />

          <StreetNumberField />

          <StreetNameField />

          <CityField />

          <StateField name="address.state" />

          <ZipField />

          <NearestCrossStreetField />

          <PhoneNumberField isRequired />

          <FaxField />

          <NAISCField />

          <SICField />

          <TimeZoneField />

          <ActiveField />
        </Flex>
        <Flex
          flexDir="column"
          flexGrow="1"
          gap={{
            base: '15px',
            md: '25px',
            lg: '15px',
            xl: '25px',
          }}
        >
          <CompanyComponentsField />
        </Flex>
      </Flex>
      <FlexWrapper>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { CompanyForm };
