import { Privileges } from '../../entities/role/model';

const privilegesBuilder = (privilege: Privileges) => {
  const [resource, action] = privilege.split('/');
  return {
    resource,
    action,
    privilege,
  };
};

export { privilegesBuilder };
