import { Flex } from 'shared/components';

import {
  ActiveViewRow,
  AttachmentsViewRow,
  DescriptionViewRow,
  NameViewRow,
  TopicsViewRow,
} from 'features/ViewCommonFields';
import { IActivity } from 'entities/tracking/model';

interface IProps {
  activity: IActivity;
}

const ActivityView = ({ activity }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <ActiveViewRow active={activity.active} />
      <NameViewRow name={activity.name} />
      <DescriptionViewRow description={activity.description} />
      <TopicsViewRow topicIDs={activity.topicIDs} />
      <AttachmentsViewRow
        attachments={activity.attachments}
        entityName="activities"
      />
    </Flex>
  );
};

export { ActivityView };
