import { IAction } from 'entities/tracking/model';

import { Flex } from 'shared/components';

import { ActiveViewRow, NameViewRow } from 'features/ViewCommonFields';

interface IProps {
  action: Partial<IAction>;
}

const ActionView = ({ action }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <ActiveViewRow active={action.active} />
      <NameViewRow name={action.name} />
    </Flex>
  );
};

export { ActionView };
