import { useTranslation } from 'react-i18next';

export const useActionsColumnsConfiguration = () => {
  const { t } = useTranslation(['actions', 'commonFields']);
  return [
    {
      name: 'action',
      sortedFields: ['name'],
      title: t('Action'),
      isSortable: true,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active', { ns: 'commonFields' }),
      isSortable: true,
    },
  ];
};
