import { UseQueryOptions } from '@tanstack/react-query';
import { useReactQuery } from 'shared/hooks/reactQuery';
import {
  buildUrl,
  httpClient,
  AxiosError,
  AxiosResponse,
} from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';
import { useGetLocale, useGetParsedQueryParams } from 'shared/utils';
import { IAction } from 'entities/tracking/model';

export const useGetActions = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IAction[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';

  return useReactQuery<IList<IAction[]>>(
    getActionsKey(companyId, parsedQueryParams, defaultLang),
    () => getActions(companyId, parsedQueryParams, defaultLang),
    options,
  );
};

const getActions = (companyId: string, query?: string, lang = 'en') => {
  return httpClient.get<IList<IAction[]>>(
    buildUrl('actions', `languageCode=${lang}&companyID=${companyId}&${query}`),
  );
};

const getActionsKey = (companyId: string, query: string, lang = 'en') => [
  'actions',
  companyId,
  query,
  lang,
];
