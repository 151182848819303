import { Flex, FlexProps } from 'shared/components';

const FlexWrapper = (props: FlexProps) => {
  return (
    <Flex
      gap={{ base: '10px', md: '20px' }}
      flexDir={{ base: 'column', md: 'row' }}
      alignSelf={{ base: 'center', md: 'flex-start' }}
      w="100%"
      {...props}
    />
  );
};

export { FlexWrapper };
