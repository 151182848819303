import { useTranslation } from 'react-i18next';
import { IRole } from 'entities/role/model';
import { Box, EmptyState, SingleTableWrapper, Text } from 'shared/components';

const PersonRolesTable = ({ roles }: Record<string, IRole[]>) => {
  const { t } = useTranslation(['commonFields']);

  return (
    <SingleTableWrapper
      title={t('Role')}
      childContainerStyle={{ m: '0', borderRadius: '0  0 20px 20px' }}
      maxW={{ base: '100%', md: '400px' }}
      w="100%"
      mt={{ base: '5px', sm: '0px' }}
    >
      <Box>
        {roles.length > 0 ? (
          roles.map((role) => {
            return (
              <Text
                p={{ base: '10px', md: '10px 20px' }}
                borderBottomWidth="2px"
                borderBottomColor="secondary.500"
                borderBottomStyle="solid"
                _last={{ borderBottom: 'none', borderRadius: '0 0 20px 20px' }}
                key={role._id}
                _hover={{ bgColor: 'grey.500' }}
              >
                {role.name}
              </Text>
            );
          })
        ) : (
          <EmptyState p={{ base: '10px', md: '10px 20px' }} />
        )}
      </Box>
    </SingleTableWrapper>
  );
};
export { PersonRolesTable };
