import { AxiosError } from 'shared/utils/httpClient';

import { Box } from 'shared/components/Box';
import { Text } from 'shared/components/Text';
import { useTranslation } from 'react-i18next';

interface FallbackProps<ErrorType> {
  error: ErrorType;
}

type IProps = FallbackProps<Error>;

const ErrorPageStrategy = ({ error }: IProps) => {
  const { t } = useTranslation('common');
  //@todo: When there will be some design than each case could have it's own unique component
  if (error instanceof AxiosError) {
    switch (error.response?.status) {
      case 400:
        return (
          <Box>
            <Text size={'lg'}>{error.message}</Text>
          </Box>
        );
      case 401:
      case 403:
        return (
          <Box>
            <Text size={'lg'}>{t('Not allowed!')}</Text>
          </Box>
        );
      case 404:
        return (
          <Box>
            <Text size={'lg'}>{t('Page not found')}</Text>
          </Box>
        );
      case 500:
        return (
          <Box>
            <Text size={'lg'}>{t('Internal server error')}</Text>
          </Box>
        );
      default:
        return (
          <Box>
            <Text size={'lg'}>{t('Internal server error')}</Text>
          </Box>
        );
    }
  }

  if (error?.message === 'not allowed') {
    return (
      <Box>
        <Text size={'lg'}>{t('You are not allowed to access this page')}</Text>
      </Box>
    );
  }

  return (
    <Box>
      <Text size={'lg'}>{t('Internal server error')}</Text>
    </Box>
  );
};

export { ErrorPageStrategy };
