import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ITracking, TrackingFormType } from 'entities/tracking/model';
import { useUpdateTracking } from 'entities/tracking/api/useUpdateTracking';
import { DateObject, routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';
import { convertTrackingFormData } from 'features/tracking/TrackingForm/utils';
import { TrackingForm } from 'features/tracking/TrackingForm';
import { ViewButton } from 'features/ViewButton';
import { lngs } from 'features/LanguageSwitcher/LanguageSwitcher';
import { useGetActivity } from 'entities/tracking/api';
import { TrackingEmail } from 'features/TrackingEmail';
import { useState } from 'react';
import { useDisclosure } from 'shared/hooks/chakraUi';
import { DeleteTracking } from 'features/tracking/DeleteTracking';
import { getDefaultTimezonePickerValue } from 'shared/components';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  tracking: ITracking;
  companyId: string;
}

const EditTracking = ({ tracking, companyId }: IProps) => {
  const { t, i18n } = useTranslation('popupMessage');
  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;
  const { successToast, errorToast } = useToast();
  const navigation = useNavigate();

  const { isLoading, mutateAsync } = useUpdateTracking(tracking._id, companyId);
  const activityId = tracking.activity?._id || '';
  const viewPath = routeBuilder.tracking.view.getPath(tracking._id);
  const { data: activityData } = useGetActivity(companyId, activityId, {
    enabled: false,
  });
  const [trackingId, setTrackingId] = useState<null | string>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deleteTrackingPrivilege } = LayoutPrivilege();

  const handleSubmit = async (
    data: TrackingFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(convertTrackingFormData(data));
      reset();

      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.tracking.add.getPath());
      }
      if (submitType === SubmitFormType.Email) {
        setTrackingId(_id);
        onOpen();
      }
      successToast({
        message: t('Successfully edited item message', { item: t('tracking') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('tracking') }),
      });
    }
  };

  const allTopics = activityData?.data.topicIDs?.reduce((acc, topicId) => {
    return { ...acc, [topicId]: { _id: topicId, value: false } };
  }, {});
  const checkedTopics = tracking?.topics?.reduce((acc, topicId) => {
    return { ...acc, [topicId._id]: { _id: topicId._id, value: true } };
  }, {});

  const defaultTimeZone = getDefaultTimezonePickerValue().zone || '';

  return (
    <>
      {isOpen && (
        <TrackingEmail
          companyId={companyId}
          trackingId={trackingId}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <TrackingForm
        companyId={companyId}
        formId={`edit-tracking-form-${tracking._id}-${companyId}`}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        additionalActionButtons={
          <>
            <ViewButton onClick={() => navigation(viewPath)} />
            {deleteTrackingPrivilege && (
              <DeleteTracking
                companyId={companyId}
                trackingId={tracking._id}
              />
            )}
          </>
        }
        attachmentsFromApi={tracking.attachments}
        defaultValues={{
          locations: tracking.location ? [tracking.location._id] : [],
          locationID: tracking.location?._id,
          departments: tracking.department ? [tracking.department._id] : [],
          departmentID: tracking.department?._id,
          activities: tracking.activity && tracking.activity?._id,
          topics: { ...allTopics, ...checkedTopics },
          //  @TODO
          // activityAttachments: activityData?.data.attachmentIDs,
          trackedAt: new Date(tracking.trackedAt.utc),
          trackingTime: {
            time: DateObject(tracking.trackedAt.utc)
              .getDate()
              .concat(' ', DateObject(tracking.trackedAt.utc).getTime()),

            zone: tracking.trackedAt.timeZone || defaultTimeZone,
          },
          trackedBy: tracking.trackedBy && [tracking.trackedBy._id],
          incidentLocation: tracking.incidentLocation || '',
          description:
            tracking.description.translations.find(
              (translation) => translation.languageCode === resolvedLng,
            )?.content ||
            tracking.description.translations.find(
              (translation) =>
                translation.languageCode ===
                tracking.description.originalLanguageCode,
            )?.content ||
            '',
          attendees: tracking.attendees?.reduce((acc, attendeeId) => {
            return {
              ...acc,
              [attendeeId._id]: { _id: attendeeId._id, value: true },
            };
          }, {}),
          customers: tracking.customerIDs?.reduce((acc, customerId) => {
            return { ...acc, [customerId]: { _id: customerId, value: true } };
          }, {}),
          vendors: tracking.vendorIDs?.reduce((acc, vendorId) => {
            return { ...acc, [vendorId]: { _id: vendorId, value: true } };
          }, {}),
          violation: tracking.violation,
        }}
      />
    </>
  );
};

export { EditTracking };
