import { TextProps } from 'shared/components';

import { TableText } from './TableText';

export const TableHeaderText = (textProps: TextProps) => {
  return (
    <TableText
      color="white"
      whiteSpace="normal"
      fontWeight="bold"
      {...textProps}
    />
  );
};
