import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';
import { TrackingFormType } from 'entities/tracking/model';
import { TrackingFinalType } from '../model/TrackingFinalType';

export const useSaveTracking = (companyId: string) => {
  return useMutation((data: Partial<TrackingFinalType>) => {
    return saveTracking(data, companyId);
  });
};

const saveTracking = (
  formData: Partial<TrackingFinalType>,
  companyId: string,
) => {
  return httpClient.post<TrackingFormType, AxiosResponse<{ _id: string }>>(
    `trackings?companyID=${companyId}`,
    formData,
  );
};
