import { getDefaultZoneValue } from 'shared/components/ZoneSelect';

export const defaultLocation = {
  active: true,
  name: '',
  address: {
    street: '',
    zip: '',
    city: '',
    state: '',
    streetNumber: '',
    nearestCrossStreet: '',
  },
  timeZone: getDefaultZoneValue(),
};
