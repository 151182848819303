import { object, string, ref } from 'yup';
import { useTranslation } from 'react-i18next';

export const useChangePasswordValidation = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');
  const matchPassword = t('Your passwords do not match.');

  return object({
    password: string()
      .min(4, t('password must be longer than 3 characters'))
      .required(requiredFieldMessage),
    repeatPassword: string()
      .oneOf([ref('password'), null], matchPassword)
      .required(requiredFieldMessage),
  });
};

export const useChangeMyPasswordValidation = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');
  const matchPassword = t('Your passwords do not match.');

  return object({
    oldPassword: string().required(requiredFieldMessage),
    password: string().required(requiredFieldMessage),
    repeatPassword: string()
      .oneOf([ref('password'), null], matchPassword)
      .required(requiredFieldMessage),
  });
};
