import {
  LocationsField,
  DepartmentsField,
  ActivitiesField,
  TrackedByField,
  useContainerProps,
  TrackingDateFromField,
  TrackingDateToField,
  CustomersField,
  AttendeeField,
  SafetyViolationField,
  KeywordsField,
  ViolationActionField,
  TopicSelectField,
  VendorsField,
} from 'features/FormCommons';

import { Form } from 'shared/components';
import { IAdvancedSearchForm } from 'entities/tracking/model/IAdvancedSearchForm';
import { UseFormReturn } from 'react-hook-form';
import { IncidentLocationField } from 'features/FormCommons/components/IncidentLocationField';
import useStore from 'shared/store';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

interface IProps {
  companyId: string;
  formId?: string;
  methods: UseFormReturn<IAdvancedSearchForm>;
}

const TrackingAdvancedSearchModalForm = ({
  companyId,
  formId,
  methods,
}: IProps) => {
  const containerStyleProps = useContainerProps();
  const companyComponents = useStore((state) => state.company?.components || []);
  const hasCustomers = companyComponents.includes(ICompanyComponent.Customers);
  const hasVendors = companyComponents.includes(ICompanyComponent.Vendors);

  return (
    <Form<IAdvancedSearchForm>
      onSubmit={() => true}
      id={formId}
      resetOnSubmit
      containerProps={{
        ...containerStyleProps,
        width: '100%',
      }}
      methods={methods}
    >
      <TrackingDateFromField />

      <TrackingDateToField />

      <LocationsField
        companyId={companyId}
        name={'location'}
      />

      <DepartmentsField
        companyId={companyId}
        name="department"
      />
      {hasCustomers && (
        <CustomersField
          companyId={companyId}
          name="customerIDs"
        />
      )}
      {hasVendors && (
        <VendorsField
          companyId={companyId}
          name="vendorIDs"
        />
      )}
      <ActivitiesField
        companyId={companyId}
        name="activity"
      />

      <TopicSelectField
        companyId={companyId}
        name="topics"
      />

      <TrackedByField
        companyId={companyId}
        name="trackedBy"
      />

      <AttendeeField
        companyId={companyId}
        name="attendees"
      />

      <SafetyViolationField />

      <IncidentLocationField />

      <KeywordsField />

      <ViolationActionField companyId={companyId} />
    </Form>
  );
};

export { TrackingAdvancedSearchModalForm };
