import { useTranslation } from 'react-i18next';
import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import { useGetDepartment } from 'entities/company/api/useGetDepartment';
import { useParams } from 'react-router-dom';
import { DepartmentView } from 'features/departments/DepartmentView';
import { DepartmentActionButtons } from 'features/departments/DepartmentActionButtons';
import useStore from 'shared/store';
import { CreationDetails } from 'features/CreationDetails';

const ViewDepartmentsPageThrowable = () => {
  const { t } = useTranslation(['departments', 'common']);
  const { departmentId } = useParams();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  if (!departmentId) {
    throw new Error('departmentId not found');
  }

  const { isLoading, error, data } = useGetDepartment(departmentId, companyId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Department not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <DepartmentView department={data.data} />
      <DepartmentActionButtons
        departmentId={departmentId}
        companyId={companyId}
        active={data.data.active}
      />
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewDepartmentsPage = () => {
  return (
    <ErrorBoundary>
      <ViewDepartmentsPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewDepartmentsPage };
