import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

import { IAgencyPayload } from 'entities/agency/model';

export const useSaveAgency = (companyId: string) => {
  return useMutation((data: IAgencyPayload) => {
    return saveAgency(data, companyId);
  });
};

const saveAgency = (formData: IAgencyPayload, companyId: string) => {
  return httpClient.post<IAgencyPayload, AxiosResponse<{ _id: string }>>(
    `agencies?companyID=${companyId}`,
    formData,
  );
};
