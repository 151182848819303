import { useTranslation } from 'react-i18next';

export const useActivitiesColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'activity',
      sortedFields: ['name'],
      title: t('Activity'),
      isSortable: true,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active'),
      isSortable: true,
    },
  ];
};
