import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeaderText,
  TableHeadSortableCell,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import useStore from 'shared/store';
import { IQueryParams, ISort } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';

import { useGetVendors } from 'entities/vendor/api';

import { useVendorsColumnsConfiguration } from './utils';
import { VendorTableRow } from './VendorTableRow';

const useVendorsTableState = createTableStore();

interface IProps {
  setOffset: (value: number) => void;
  onSortChange: (sort: ISort[]) => void;
  queryParams: IQueryParams;
  vendorIDs?: string[];
  isImported?: boolean;
}

const VendorsTable = ({
  setOffset,
  onSortChange,
  vendorIDs,
  isImported,
  queryParams: { limit = 10, offset = 0, ...restQueryParams },
}: IProps) => {
  const columnsConfiguration = useVendorsColumnsConfiguration();

  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);
  const tableState = useVendorsTableState((state) => state, shallow);
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const { data, isLoading, refetch } = useGetVendors(
    companyId,
    {
      ...restQueryParams,
      offset: offset,
      limit: limit,
      fields: memoizedColumnsConfiguration
        .map((columnConfiguration) => columnConfiguration.sortedFields)
        .flat(),
    },
    {
      useErrorBoundary: false,
    },
  );

  useEffect(() => {
    (async () => {
      await refetch();
    })();
  }, [isImported]);

  const vendors = data?.data.data.filter((vendor) => {
    if (vendorIDs) {
      return vendorIDs?.includes(vendor._id);
    } else {
      return vendor;
    }
  });

  const pagination = vendorIDs ? vendors?.length || 0 : data?.data.total || 0;

  useEffect(() => {
    if (
      (data && data?.data.total <= 10) ||
      (data && offset / limit + 1 > Math.ceil(data?.data.total / limit))
    ) {
      setOffset(0);
    }
  }, [data?.data.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map(({ title, isSortable, name }) => {
            return isSortable ? (
              <TableHeadSortableCell
                key={name}
                columnName={name}
                _last={{ w: '120px' }}
              >
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadSortableCell>
            ) : (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody isLoading={isLoading}>
        {data && pagination > 0 ? (
          <>
            {vendors?.map((vendor) => {
              return (
                <VendorTableRow
                  key={vendor._id}
                  vendor={vendor}
                />
              );
            })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={pagination}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                  itemsPerPage={limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { VendorsTable };
