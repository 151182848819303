import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import { FormField } from 'shared/components/Form';
import { DocumentationSection } from 'widgets/DocumentationSection';

interface IProps {
  isLoading: boolean;
  isRequired?: boolean;
  mutateAsync: UseMutateAsyncFunction<
    AxiosResponse<{
      _id: string;
    }>,
    unknown,
    FormData
  >;
  entityName: 'activities' | 'trackings';
  attachments: { _id: string; filename: string }[];
  formId: string;
}

const DocumentationField = ({
  mutateAsync,
  isLoading,
  formId,
  entityName,
  attachments,
  isRequired = false,
}: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField
      isRequired={isRequired}
      label={`${t('Documentation')}:`}
    >
      <DocumentationSection
        attachments={attachments}
        mutateAsync={mutateAsync}
        isLoading={isLoading}
        entityName={entityName}
        formId={formId}
      />
    </FormField>
  );
};

export { DocumentationField };
