import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { IDescription } from 'entities/common/model';
import { lngs } from 'features/LanguageSwitcher/LanguageSwitcher';
import { useGetCustomers } from 'entities/customer/api';
import { useGetAction } from 'entities/tracking/api/useGetAction';
import { useGetVendors } from 'entities/vendor/api';

export const getParsedTrackedAt = (trackedAt?: Date) => {
  const title = 'Date';

  if (!trackedAt) {
    return '';
  }

  const formattedDate = format(
    trackedAt ? new Date(trackedAt) : new Date(),
    'yyyy-MM-dd',
  );

  return `${title}: ${formattedDate}`;
};

export const getParsedDescription = (description?: IDescription) => {
  const { i18n } = useTranslation();
  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;
  const defaultContent = description?.translations
    .filter((lang) => lang.languageCode.includes('en'))
    .map((l) => l.content);
  const content =
    description?.translations.find((lang) =>
      lang.languageCode.includes(resolvedLng),
    )?.content || '';
  return defaultContent ? `Description: ${defaultContent}` : content;
};

export const useGetParsedCustomers = (
  companyId: string,
  customerIDs?: string[],
) => {
  const { data } = useGetCustomers(companyId);
  const title = 'Customers';

  if (!data || !customerIDs || customerIDs.length === 0) {
    return '';
  }

  const filteredCustomers = data.data.data.filter((customer) => {
    return customerIDs.find((key) => key === customer._id);
  });

  return filteredCustomers.reduce((acc: string, customer) => {
    return `${acc} ${customer.name}, `;
  }, `${title}: `);
};

export const useGetParsedVendors = (
  companyId: string,
  vendorIDs?: string[],
) => {
  const { data } = useGetVendors(companyId);
  const title = 'Vendors';

  if (!data || !vendorIDs || vendorIDs.length === 0) {
    return '';
  }

  const filteredCustomers = data.data.data.filter((vendor) => {
    return vendorIDs.find((key) => key === vendor._id);
  });

  return filteredCustomers.reduce((acc: string, customer) => {
    return `${acc} ${customer.name}, `;
  }, `${title}: `);
};

export const useGetParsedViolation = (
  companyId: string,
  violation?: {
    safetyViolation?: boolean;
    description?: string;
    actionID?: string;
    actionDetail?: string;
  },
) => {
  const { t } = useTranslation('commonFields');
  const { data } = useGetAction(companyId, violation?.actionID || '', {
    useErrorBoundary: false,
  });

  if (!data) {
    return '';
  }

  return `${`Safety violation: ${
    violation?.safetyViolation ? t('Yes') : t('No')
  }`}
${
  violation?.description
    ? `Violation Description: ${violation?.description}`
    : ''
}
${data.data.name ? `Violation Action: ${data.data.name}` : ''}
${violation?.actionDetail ? `Action Details: ${violation?.actionDetail}` : ''}`;
};
