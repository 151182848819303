import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';
import { DeleteAgency } from 'features/agency/AgencyActionButtons';

import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

interface IProps {
  companyId: string;
  agencyId: string;
}

const AgencyActionButtons = ({ agencyId, companyId }: IProps) => {
  return (
    <FlexWrapper>
      <AddNewItemButton redirectPath={routeBuilder.agency.add.getPath()} />
      <DeleteAgency
        companyId={companyId}
        agencyId={agencyId}
      />
      <EditItemButton
        redirectPath={routeBuilder.agency.edit.getPath(agencyId)}
      />
    </FlexWrapper>
  );
};

export { AgencyActionButtons };
