import { useFormContext } from 'react-hook-form';

export const useCheckboxFilter = <T extends { _id: string }>(
  filteredResults: T[],
  itemsFormName: string,
) => {
  const { setValue, watch } = useFormContext();

  const defaultItems = filteredResults
    ?.map((result) => result._id)
    ?.reduce((acc, roleId) => {
      return { ...acc, [roleId]: { _id: roleId, value: false } };
    }, {});

  const items = { ...defaultItems, ...watch(itemsFormName) } as {
    [key: string]: { _id: string; value: boolean };
  };

  const isAllSelected =
    items &&
    filteredResults &&
    filteredResults.every((filteredResults) => {
      return Object.keys(items).find(
        (key) => key === filteredResults._id && items[key]?.value,
      );
    });

  const switchAllCheckboxes = (value: boolean) => {
    Object.keys(items)
      .map((key) => {
        setValue(`${itemsFormName}.${key}`, items[key]);
        return items[key];
      })
      .filter((item) => {
        return filteredResults.find(
          (filteredResults) => filteredResults._id === item?._id,
        );
      })
      .forEach((item) => {
        setValue(`${itemsFormName}.${item._id}`, { ...item, value: value });
      });
  };

  const resetAllCheckboxes = () => {
    Object.keys(items).map((key) =>
      setValue(`${itemsFormName}.${key}`, { ...items[key], value: false }),
    );
  };

  return {
    isAllSelected,
    switchAllCheckboxes,
    resetAllCheckboxes,
  };
};
