import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';

import { DeleteRole } from './DeleteRole';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  roleId: string;
  companyId: string;
  active?: boolean;
  roleProtected?: boolean;
}

const ActionsButtons = ({
  roleId,
  companyId,
  active,
  roleProtected,
}: IProps) => {
  const { createRolePrivilege, updateRolePrivilege, deleteRolePrivilege } =
    LayoutPrivilege();
  return (
    <FlexWrapper mt={{ base: '20px', lg: '50px' }}>
      {createRolePrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.roles.add.getPath()} />
      )}
      {active && !roleProtected && deleteRolePrivilege && (
        <DeleteRole
          roleId={roleId}
          companyId={companyId}
        />
      )}
      {!roleProtected && updateRolePrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.roles.edit.getPath(roleId)}
        />
      )}
    </FlexWrapper>
  );
};

export { ActionsButtons };
