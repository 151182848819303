import { useToast as useChakraToast } from '@chakra-ui/react';
import { UseToastOptions } from '@chakra-ui/toast';

import { MessagePopups } from 'shared/components';

interface IToastOptions extends UseToastOptions {
  message: string;
}

export const useToast = () => {
  const toast = useChakraToast();
  const defaultToastOptions: UseToastOptions = {
    duration: 5000,
    position: 'top-right',
  };

  const successToast = ({ message, ...options }: IToastOptions) =>
    toast({
      status: 'success',
      render: ({ onClose }) => {
        return (
          <MessagePopups
            message={message}
            type="success"
            onClose={onClose}
          />
        );
      },
      ...defaultToastOptions,
      ...options,
    });

  const errorToast = ({ message, ...options }: IToastOptions) =>
    toast({
      status: 'success',
      render: ({ onClose }) => {
        return (
          <MessagePopups
            message={message}
            type="error"
            onClose={onClose}
          />
        );
      },
      ...defaultToastOptions,
      ...options,
    });

  const attentionToast = ({ message, ...options }: IToastOptions) =>
    toast({
      status: 'info',
      render: ({ onClose }) => {
        return (
          <MessagePopups
            message={message}
            type="attention"
            onClose={onClose}
          />
        );
      },
      ...defaultToastOptions,
      ...options,
    });

  return {
    successToast,
    errorToast,
    attentionToast,
  };
};
