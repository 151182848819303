import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';
import useStore from 'shared/store';
import { useGetAction } from 'entities/tracking/api/useGetAction';
import { EditAction } from 'widgets/Action/EditAction';

const EditActionPageThrowable = () => {
  const { t } = useTranslation(['actions', 'common']);
  const companyId = useStore((state) => state.company?._id);
  const { actionId } = useParams();

  if (!actionId) {
    throw new Error('ActionId not found');
  }

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  const { isLoading, error, data } = useGetAction(companyId, actionId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Action not found')}</Text>;
  }

  return (
    <EditAction
      companyId={companyId}
      action={data.data}
    />
  );
};

export const EditActionPage = () => {
  return (
    <ErrorBoundary>
      <EditActionPageThrowable />
    </ErrorBoundary>
  );
};
