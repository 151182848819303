import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

import { Privileges } from 'entities/role/model';
import { ICompany } from 'entities/company/model';
import { IFileToConnect } from 'widgets/DocumentationSection/DocumentationSection';

interface IStore {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;

  isMFALoggedIn: boolean;
  setIsMFALoggedIn: (isMFALoggedIn: boolean) => void;

  privileges: Privileges[];
  setPrivileges: (privileges: Privileges[]) => void;

  roles: string[];
  setRoles: (roles: string[]) => void;

  company: Partial<ICompany> | null;
  setCompany: (company: Partial<ICompany> | null) => void;

  filesToConnect: IFileToConnect[];
  setFilesToConnect: (files: IFileToConnect[]) => void;
}

const useStore = create<IStore>()(
  persist(
    devtools((set) => ({
      isLoggedIn: false,
      setIsLoggedIn: (isLoggedIn) => set((state) => ({ ...state, isLoggedIn })),

      isMFALoggedIn: true,
      setIsMFALoggedIn: (isMFALoggedIn) =>
        set((state) => ({ ...state, isMFALoggedIn })),

      roles: [],
      setRoles: (roles) => set((state) => ({ ...state, roles })),

      privileges: [],
      setPrivileges: (privileges) => set((state) => ({ ...state, privileges })),

      company: null,
      setCompany: (company) => set((state) => ({ ...state, company })),

      filesToConnect: [],
      setFilesToConnect: (filesToConnect) =>
        set((state) => ({ ...state, filesToConnect })),
    })),
    {
      name: 'omnistools-storage',
    },
  ),
);

export default useStore;
