import { theme } from 'shared/utils/theme';

export const navItemStyles = {
  borderRadius: '20px',
  padding: { base: '12px 8px', xl: '12px 16px' },
  alignItems: 'center',
  border: '2px solid transparent',
  _hover: { backgroundColor: 'secondary.500' },
  _active: { border: `2px solid ${theme.colors.primary['700']}` },
};
