import useStore from 'shared/store';
import { AddActivity } from 'widgets/Activity';

const AddActivityPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  return <AddActivity companyId={companyId} />;
};

export { AddActivityPage };
