import { useTranslation } from 'react-i18next';

import { Modal } from 'shared/components';
import { IModalProps } from 'shared/components/Modal';
import { DeleteButton } from 'features/DeleteButton';

interface IProps extends Omit<IModalProps, 'title' | 'message'> {
  onDelete: () => void;
  isLoading: boolean;
}

const DeleteModal = ({ onDelete, isLoading, ...restProps }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <Modal
      title={t('Confirm delete')}
      message={t('Do you really want to delete?')}
      {...restProps}
    >
      <DeleteButton
        flex="auto"
        onClick={onDelete}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export { DeleteModal };
