import { forwardRef, LegacyRef } from 'react';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import { CheckboxProps } from '@chakra-ui/checkbox';

import { CheckIcon } from 'shared/icons';

export type ICheckboxProps = CheckboxProps;

const Checkbox = forwardRef((props: ICheckboxProps, ref) => {
  return (
    <ChakraCheckbox
      ref={ref as LegacyRef<HTMLInputElement>}
      icon={
        <CheckIcon
          boxSize="15px"
          isChecked={props.isChecked}
          isIndeterminate={props.isIndeterminate}
        />
      }
      //there is bug in chakra and height is not overrided so needed to add sx
      sx={{
        '.chakra-checkbox__control': {
          height: '23px',
          width: '23px',
        },
      }}
      {...props}
    />
  );
});

Checkbox.displayName = 'Checkbox';

export { Checkbox };
