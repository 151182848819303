import { Icon, IconProps } from 'shared/components';

const MessageIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    {...props}
  >
    <path
      d="M18 2H6C3.794 2 2 3.794 2 6v7.034c0 2.206 1.794 4 4 4h1.021v3.904a1 1 0 0 0 .601.917s.577.283 1.08-.184l4.996-4.637h4.303c2.206 0 4-1.794 4-4V6A4.006 4.006 0 0 0 18 2zm-1.396 10.632H7.396a1 1 0 0 1 0-2h9.208a1 1 0 1 1 0 2zm0-4H7.396a1 1 0 0 1 0-2h9.208a1 1 0 1 1 0 2z"
      fill="#72B42D"
    />
  </Icon>
);

export { MessageIcon };

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  id="text-message"
>
  <path d="M18 2H6C3.794 2 2 3.794 2 6v7.034c0 2.206 1.794 4 4 4h1.021v3.904a1 1 0 0 0 .601.917s.577.283 1.08-.184l4.996-4.637h4.303c2.206 0 4-1.794 4-4V6A4.006 4.006 0 0 0 18 2zm-1.396 10.632H7.396a1 1 0 0 1 0-2h9.208a1 1 0 1 1 0 2zm0-4H7.396a1 1 0 0 1 0-2h9.208a1 1 0 1 1 0 2z"></path>
</svg>;
