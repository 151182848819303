import { ExportDataTable } from 'features/ExportDataTable';
import { lngs } from 'features/LanguageSwitcher/LanguageSwitcher';
import { SwitchInactive } from 'features/SwitchInactive';
import { CompaniesTable } from 'features/company/CompaniesTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlexWrapper } from 'shared/components';
import { ISort } from 'shared/types';
import { useGetParsedQueryParams } from 'shared/utils';
import { buildUrl } from 'shared/utils/httpClient';
import { SearchBar } from 'widgets/SearchBar';

interface IProps {
  isSuperAdmin: boolean;
}

const CompanyList = ({ isSuperAdmin }: IProps) => {
  const { t } = useTranslation(['companiesList', 'common']);
  const [globalFilter, setGlobalFilter] = useState('');
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<ISort[]>();
  const [showInactive, setShowInactive] = useState(false);
  const filterActive = !showInactive ? 'true' : '';
  const { i18n } = useTranslation();
  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;
  const limit = 10;

  const companiesFieldsTranslations =
    // eslint-disable-next-line max-len
    'fields=name$Nombre,abbreviation$Abreviatura,login$Acceso,address$Dirección,code$Código NAICS - Código SIC,phoneNumber$Teléfono,fax,counters$Cuenta';

  const query = useGetParsedQueryParams({
    sort,
    filters: globalFilter ? globalFilter : filterActive,
  });
  const downloadFileUrl = buildUrl(
    'companies',
    resolvedLng === 'es' ? companiesFieldsTranslations : query,
  );

  return (
    <>
      <SearchBar
        title={t('Company List')}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <SwitchInactive
        label={t('Show inactive Companies')}
        switchId="inactive-companies"
        setShowInactive={setShowInactive}
      />
      <CompaniesTable
        isSuperAdmin={isSuperAdmin}
        showInactive={showInactive}
        onSortChange={(sort) => setSort(sort)}
        queryParams={{
          limit: limit,
          offset: offset,
          filters: globalFilter,
          sort: sort,
        }}
        setOffset={(value) => setOffset(value)}
      />
      <FlexWrapper>
        <ExportDataTable
          fileUrl={downloadFileUrl}
          fileName={t('Company List')}
          title={t('Export All Data', { ns: 'common' })}
        />
      </FlexWrapper>
    </>
  );
};

export { CompanyList };
