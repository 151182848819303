import { useFormContext } from 'react-hook-form';

import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { Checkbox } from 'shared/components';

import { ICustomer } from 'entities/customer/model';

interface IProps {
  customer: ICustomer;
}

const CustomerTrackingTableRow = ({ customer }: IProps) => {
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = `customers.${customer._id}`;
  const value = watch(name);

  return (
    <TableRow
      key={customer._id}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell textAlign="center">
        <Checkbox
          isDisabled={isSubmitting}
          size="lg"
          isChecked={value?.value}
          onChange={(event) => {
            setValue(name, {
              _id: customer._id,
              value: event.target.checked,
            });
          }}
        />
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{`${customer.name}`}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{customer.abbreviation || '-'}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { CustomerTrackingTableRow };
