import { useFormContext } from 'react-hook-form';

import { IPrivilegeItem } from '../PrivilegesField/PrivilegesField';
import { Checkbox, Box, Text } from 'shared/components';
import { useTranslation } from 'react-i18next';

interface IProps {
  privilegeItem: IPrivilegeItem;
}

const PrivilegesFieldItem = ({ privilegeItem }: IProps) => {
  const { t } = useTranslation('privileges');
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();
  const name = `privilegesObject.${privilegeItem._id}`;
  const value = watch(name);

  return (
    <Box key={privilegeItem._id}>
      <Checkbox
        isDisabled={isSubmitting}
        size="lg"
        gap="12px"
        isChecked={value?.value}
        onChange={(event) => {
          setValue(name, {
            _id: privilegeItem._id,
            value: event.target.checked,
          });
        }}
      >
        <Text textTransform="capitalize">{t(privilegeItem.name)}</Text>
      </Checkbox>
    </Box>
  );
};

export { PrivilegesFieldItem };
