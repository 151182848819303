import { httpClient } from 'shared/utils/httpClient';
import { useReactQuery } from 'shared/hooks/reactQuery';
import { IRole } from 'entities/role/model';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from '../../../shared/utils/httpClient';
import { useGetLocale } from 'shared/utils';

export const useGetRole = (
  companyId: string,
  roleId: string,
  options?: UseQueryOptions<AxiosResponse<IRole>, AxiosError>,
) => {
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IRole>(
    getRoleKey(companyId, roleId, defaultLang),
    () => getRole(companyId, roleId, defaultLang),
    options,
  );
};

const getRole = (companyId: string, roleId: string, lang = 'en') => {
  return httpClient.get<IRole>(`roles/${roleId}?languageCode=${lang}&companyID=${companyId}`);
};

const getRoleKey = (companyId: string, roleId: string, lang = 'en') => [
  'role',
  companyId,
  roleId,
  lang
];
