import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';
import { CreationDetails } from 'features/CreationDetails';
import { useGetTracking } from 'entities/tracking/api';
import { TrackingView } from 'features/tracking/TrackingView';
import { TrackingActionButtons } from 'widgets/TrackingActionButtons';

const TrackingViewPageThrowable = () => {
  const { t } = useTranslation(['tracking', 'common']);
  const { trackingId } = useParams();
  const companyIdStore = useStore((state) => state.company?._id);
  const [companyIdURL] = useSearchParams();

  const companyId = companyIdURL.get('company_id') || companyIdStore;

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  if (!trackingId) {
    throw new Error('TrackingId not found');
  }

  const { isLoading, error, data } = useGetTracking(companyId, trackingId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Tracking not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <TrackingView tracking={data.data} />
      <TrackingActionButtons
        tracking={data.data}
        companyId={companyId}
      />
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const TrackingViewPage = () => {
  return (
    <ErrorBoundary>
      <TrackingViewPageThrowable />
    </ErrorBoundary>
  );
};

export { TrackingViewPage };
