import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeaderText,
  TableHeadSortableCell,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import { Checkbox } from 'shared/components';
import { IList, ISort } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';
import { useCheckboxFilter } from 'features/CheckboxListFilter/utils';
import { IActivity } from 'entities/tracking/model';
import { ActivitiesTableRow } from './ActivitiesTableRow';
import { useTopicActivitiesColumnsConfiguration } from './utils/useTopicActivitiesConfiguration';

const useTopicActivitiesTableState = createTableStore();

interface IProps {
  onSortChange: (sort: ISort[]) => void;
  isLoading: boolean;
  offset: number;
  setOffset: (value: number) => void;
  activitiesList?: IList<IActivity[]>;
  limit: number;
}

const TopicActivitiesTable = ({
  onSortChange,
  isLoading,
  activitiesList,
  offset,
  setOffset,
  limit,
}: IProps) => {
  const columnsConfiguration = useTopicActivitiesColumnsConfiguration();
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);

  const tableState = useTopicActivitiesTableState((state) => state, shallow);
  const { isAllSelected, switchAllCheckboxes } = useCheckboxFilter(
    activitiesList?.data || [],
    'activities',
  );

  useEffect(() => {
    if (activitiesList && activitiesList?.total <= 10) {
      setOffset(0);
    }
  }, [activitiesList?.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map(({ title, isSortable, name }) => {
            if (name === 'checkbox') {
              return (
                <TableHeadCell
                  key={name}
                  textAlign="center"
                >
                  <Checkbox
                    size="lg"
                    onChange={() => switchAllCheckboxes(!isAllSelected)}
                    isChecked={isAllSelected}
                  />
                </TableHeadCell>
              );
            }
            return isSortable ? (
              <TableHeadSortableCell
                key={name}
                columnName={name}
              >
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadSortableCell>
            ) : (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody isLoading={isLoading}>
        {activitiesList && activitiesList.total > 0 ? (
          <>
            {activitiesList.data.map((activity) => {
              return (
                <ActivitiesTableRow
                  key={activity._id}
                  activity={activity}
                />
              );
            })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={activitiesList.total}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { TopicActivitiesTable };
