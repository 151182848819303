import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { IMfaCodeFormModel } from 'entities/user/model';

export const useAddMfaCode = () => {
  return useMutation((data: IMfaCodeFormModel) => {
    return addMfaCode(data);
  });
};

const addMfaCode = (formData: IMfaCodeFormModel) => {
  return httpClient.post<IMfaCodeFormModel>('auth/mfa', formData);
};
