import { IRegulation } from 'entities/regulation/model';
import { TableCellText, TableHeaderText } from 'shared/components/Table';
import { ColumnsTitles } from '../types';
import { useTranslation } from 'react-i18next';

interface IProps {
  regulation: IRegulation;
}

const RegulationMobileDetails = ({ regulation }: IProps) => {
  const { t } = useTranslation(['commonFields']);

  return (
    <>
      <TableHeaderText color="black">{t(ColumnsTitles.agency)}</TableHeaderText>
      <TableCellText>{regulation.agency?.name || '-'}</TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.title)}</TableHeaderText>
      <TableCellText>{regulation.title || '-'}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.section)}
      </TableHeaderText>
      <TableCellText>{regulation.section || '-'}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.description)}
      </TableHeaderText>
      <TableCellText>{regulation.description || '-'}</TableCellText>
    </>
  );
};

export { RegulationMobileDetails };
