import { httpClient } from 'shared/utils/httpClient';
import { useReactQuery } from 'shared/hooks/reactQuery';

import { IRegulation } from 'entities/regulation/model';

export const useGetRegulation = (companyId: string, regulationId: string) => {
  return useReactQuery<IRegulation>(
    getRegulationKey(companyId, regulationId),
    () => getRegulation(companyId, regulationId),
  );
};

const getRegulation = (companyId: string, regulationId: string) => {
  return httpClient.get<IRegulation>(
    `regulations/${regulationId}?companyID=${companyId}`,
  );
};

const getRegulationKey = (companyId: string, regulationId: string) => [
  'regulation',
  companyId,
  regulationId,
];
