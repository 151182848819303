import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteTopic = () => {
  return useMutation(
    ({ topicId, companyId }: { topicId: string; companyId: string }) => {
      return deleteTopic(topicId, companyId);
    },
  );
};

const deleteTopic = (topicId: string, companyId: string) => {
  return httpClient.delete(`topics/${topicId}?companyID=${companyId}`);
};
