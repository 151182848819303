import { Icon, IconProps, useTheme } from 'shared/components';

export enum Orientation {
  'Left' = 'left',
  'Right' = 'right',
}

interface IProps extends IconProps {
  orientation: Orientation;
  isDisabled?: boolean;
}

const PaginationArrowIcon = ({
  orientation,
  isDisabled,
  ...restProps
}: IProps) => {
  const { colors } = useTheme();
  return (
    <Icon
      w="17px"
      h="16px"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...(orientation === Orientation.Left && {
        transform: 'rotate(180deg)',
      })}
      {...restProps}
    >
      <path
        d="M8.5 0L7.09 1.41L12.67 7H0.5V9H12.67L7.09 14.59L8.5 16L16.5 8L8.5 0Z"
        fill={isDisabled ? colors.greyText : 'white'}
      />
    </Icon>
  );
};

export { PaginationArrowIcon };
