import { SearchInput } from 'features/SearchInput';
import { Dispatch, SetStateAction } from 'react';
import { Button, Flex, FlexProps, Text } from 'shared/components';
import { TrackingAdvancedSearch } from 'features/tracking/TrackingSearchBar';
import { useTranslation } from 'react-i18next';
import shallow from 'zustand/shallow';
import { useAdvancedSearchModalStore } from '../../../pages/Tracking/List/TrackingListPage';

interface Props extends FlexProps {
  title: string;
  globalFilter: string;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
}

const TrackingSearchBar = ({ title, globalFilter, setGlobalFilter }: Props) => {
  const { t } = useTranslation('common');
  const { clearStore } = useAdvancedSearchModalStore((state) => state, shallow);

  return (
    <Flex
      width="100%"
      flexDir={{ base: 'column', xl: 'row' }}
      gap={{ base: '10px', md: '20px', lg: '30px' }}
      justifyContent={{ xl: 'space-between' }}
      alignItems={{ xl: 'center' }}
    >
      <Text
        minW={{ md: 'max-content' }}
        fontWeight="700"
        size={{ base: 'sm', md: 'md' }}
      >
        {title}
      </Text>
      <Flex
        w={{ base: '100%', xl: '80%' }}
        gap="20px"
        justifyContent="end"
        alignItems="center"
        flexDir={{ base: 'column', lg: 'row' }}
        alignSelf="end"
      >
        <Flex
          wrap="wrap"
          gap="10px"
          alignSelf="start"
        >
          <TrackingAdvancedSearch />
          <Button
            minW="fit-content"
            onClick={() => {
              setGlobalFilter('');
              clearStore();
            }}
          >
            {t('Reset')}
          </Button>
        </Flex>
        <SearchInput
          maxW={{ md: '100%', lg: '50%', '2xl': '50%' }}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Flex>
    </Flex>
  );
};

export { TrackingSearchBar };
