import { ITracking } from 'entities/tracking/model';
import { SMSButton } from './SMSButton';
import { useDisclosure } from 'shared/hooks/chakraUi';
import { TrackingSMSModal } from './TrackingSMSModal';

interface IProps {
  companyId: string;
  tracking: ITracking;
}

const TrackingViewSMS = ({ companyId, tracking }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <TrackingSMSModal
        onClose={onClose}
        isOpen={isOpen}
        companyId={companyId}
        tracking={tracking}
      />
      <SMSButton
        type="submit"
        onClick={() => {
          onOpen();
        }}
      />
    </>
  );
};

export { TrackingViewSMS };
