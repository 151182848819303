import { Flex } from 'shared/components';

import {
  AbbreviationViewRow,
  AccountNumberViewRow,
  ActiveViewRow,
  PhoneViewRow,
  AddressViewRow,
  NearestCrossStreetViewRow,
  ContactPersonNameViewRow,
  ContactPersonNumberViewRow,
  CompanyNameViewRow,
  AttachmentsViewRow,
} from 'features/ViewCommonFields';

import { IVendor } from 'entities/vendor/model';

interface IProps {
  vendor: IVendor;
}

const VendorView = ({ vendor }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <CompanyNameViewRow name={vendor.name} />
      <AbbreviationViewRow abbreviation={vendor.abbreviation} />
      <AccountNumberViewRow accountNumber={vendor.accountNumber} />
      <PhoneViewRow phone={vendor.phoneNumber} />
      <AddressViewRow address={vendor.address} />
      <NearestCrossStreetViewRow
        nearestCrossStreet={vendor.address?.nearestCrossStreet}
      />
      <ContactPersonNameViewRow
        contactPersonName={vendor.contactPerson?.name}
      />
      <ContactPersonNumberViewRow
        contactPersonNumber={vendor.contactPerson?.phoneNumber}
      />
      <ActiveViewRow active={vendor.active} />
      <AttachmentsViewRow
        attachments={vendor.attachments}
        entityName="vendors"
      />
    </Flex>
  );
};

export { VendorView };
