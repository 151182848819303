import useStore from 'shared/store';
import { AddTopic } from 'widgets/Topic';

const AddTopicPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  return <AddTopic companyId={companyId} />;
};

export { AddTopicPage };
