import { format } from 'date-fns';

import { ITracking } from '../../../../entities/tracking/model';

export const useTrackingEmailTitleParser = (tracking: ITracking) => {
  const parsedTrackedBy = tracking.trackedBy
    ? `${tracking.trackedBy.name.firstname} ${tracking.trackedBy.name.lastname}`
    : '';

  const parsedActivity = tracking.activity ? tracking.activity.name : '';

  const parsedDateTime = format(
    new Date(tracking.trackedAt.utc),
    'yyyy-MM-dd, HH:mm',
  );

  return [parsedTrackedBy, parsedActivity, parsedDateTime]
    .filter((value) => value !== '')
    .join(', ');
};
