import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  middleName?: string;
}

const MiddleNameViewRow = ({ middleName }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Middle Name')}>
      <Text>{middleName || '-'}</Text>
    </ViewRow>
  );
};

export { MiddleNameViewRow };
