import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { IUser } from 'entities/user/model';
import { Grid } from 'shared/components';
import { GreenCloseIcon } from 'shared/icons';
import { UserMobileDetails } from './templates/UserMobileDetails';
import { ITracking } from 'entities/tracking/model';
import { TrackingMobileDetails } from './templates/TrackingMobileDetails';
import { RegulationMobileDetails } from './templates/RegulationMobileDetails';
import { IRegulation } from 'entities/regulation/model';
import { ICompany, ILocation } from 'entities/company/model';
import { CompanyMobileDetails } from './templates/CompanyMobileDetails';
import { LocationMobileDetails } from './templates/LocationMobileDetails';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  user?: IUser;
  tracking?: ITracking;
  regulation?: IRegulation;
  company?: ICompany;
  location?: ILocation;
}

const MobileDetailsView = ({
  user,
  tracking,
  regulation,
  company,
  location,
  isOpen,
  onClose,
}: IProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      isCentered
    >
      <ModalOverlay bgColor="grey.300" />
      <ModalContent
        borderRadius="20px"
        mx="15px"
      >
        <ModalCloseButton>
          <GreenCloseIcon />
        </ModalCloseButton>
        <ModalBody
          borderStyle="solid"
          borderWidth="2px"
          borderColor="secondary.500"
          borderRadius="20px"
        >
          <Grid
            border="2p solid green"
            p="20px 0px"
            gap="20px"
          >
            <Grid
              templateColumns="45% 1fr"
              gap="20px"
            >
              {user && <UserMobileDetails user={user} />}
              {tracking && <TrackingMobileDetails tracking={tracking} />}
              {regulation && (
                <RegulationMobileDetails regulation={regulation} />
              )}
              {company && <CompanyMobileDetails company={company} />}
              {location && <LocationMobileDetails location={location} />}
            </Grid>
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export { MobileDetailsView };
