import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  UnorderedList,
  FlexProps,
  SingleTableWrapper,
  EmptyState,
} from 'shared/components';

import { CheckboxListFilter } from 'features/CheckboxListFilter';

import { useGetRoles } from 'entities/role/api';
import { IRole } from 'entities/role/model';

import { RoleRecord } from './RoleRecord';

interface IProps extends FlexProps {
  companyId: string;
  setIsSupervisor: Dispatch<SetStateAction<boolean>>;
  watchRoleIdsArray: (string | undefined)[];
  isDisabled?: boolean;
  activeOnly?: boolean;
}

export const Roles = ({
  companyId,
  setIsSupervisor,
  watchRoleIdsArray,
  activeOnly,
  isDisabled,
  ...restProps
}: IProps) => {
  const { t } = useTranslation('commonFields');

  const [searchValue, setSearchValue] = useState('');
  const { data } = useGetRoles(
    companyId,
    {
      fields: ['name'],
      filters: searchValue,
      customFilters: activeOnly ? { active: true } : undefined,
    },
    { useErrorBoundary: false },
  );

  const result = data?.data.data as IRole[];

  const supervisorRoleIdsArray = result
    ?.map((role) => {
      if (role.privileges.some(privilege =>
        privilege === 'supervisor'
        || privilege === 'company/*'
        || privilege === '*')) {
        return role._id;
      }
    })
    .filter(Boolean);
  useEffect(() => {
    const findSupervisor = watchRoleIdsArray?.some((id) =>
      supervisorRoleIdsArray?.includes(id),
    );
    findSupervisor ? setIsSupervisor(true) : setIsSupervisor(false);
  }, [supervisorRoleIdsArray]);

  return (
    <SingleTableWrapper
      title={t('Role')}
      childContainerStyle={{
        overflow: 'auto',
      }}
      {...restProps}
    >
      {!isDisabled && (
        <CheckboxListFilter
          itemsFormName="roles"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filteredResults={result || []}
          isDisabled={isDisabled}
        />
      )}
      <UnorderedList
        borderWidth="2px"
        borderColor="secondary.500"
        borderStyle="solid"
        borderRadius="4px"
        padding="10px"
        overflow="auto"
        flexGrow="1"
        display="flex"
        flexDir="column"
        gap="5px"
        overflowY="auto"
      >
        {result?.length > 0 ? (
          result.map((role) => {
            return (
              <RoleRecord
                key={role._id}
                role={role}
                isDisabled={isDisabled}
              />
            );
          })
        ) : (
          <EmptyState />
        )}
      </UnorderedList>
    </SingleTableWrapper>
  );
};
