import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export const useRegulationValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');

  return object({
    agencyID: string().required(requiredFieldMessage),
    title: string().required(requiredFieldMessage),
    section: string().required(requiredFieldMessage),
    description: string().required(requiredFieldMessage),
  });
};
