import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export const useLoginFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');

  return object({
    company: string().required(requiredFieldMessage),
    login: string().required(requiredFieldMessage),
    password: string().required(requiredFieldMessage),
  });
};
