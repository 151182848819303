import { ReactNode, useEffect, useState } from 'react';

import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { SaveButton } from 'features/SaveButton';
import {
  ActiveField,
  NameField,
  useContainerProps,
} from 'features/FormCommons';
import { FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';

import { RoleFormType } from 'entities/role/model';
import { useRoleNameValidation } from 'shared/utils/yup';
import { useForm } from 'shared/components/Form';
import { PrivilegesField } from 'features/privileges/PrivilegesField';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

type RoleFormProps = {
  defaultValues?: RoleFormType;
  handleSubmit: (
    data: RoleFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  formId: string;
  additionalActionButtons?: ReactNode;
};

export const defaultAction = {
  name: '',
  active: true,
  privilegesObject: {},
};

const RoleForm = ({
  defaultValues = defaultAction,
  handleSubmit,
  formId,
  isLoading,
  additionalActionButtons,
}: RoleFormProps) => {
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const { deleteRolePrivilege } = LayoutPrivilege();
  const validationSchema = useRoleNameValidation();
  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });
  const isEditForm = formId.includes('edit-role');
  useEffect(() => {
    if (isEditForm) {
      cleanPersistentStore();
    }
  }, []);

  return (
    <Form<RoleFormType>
      onSubmit={(data, { reset }) => {
        const resultData = { ...data };
        if (!deleteRolePrivilege) delete resultData.active;
        handleSubmit(
          resultData,
          () => {
            cleanPersistentStore();
          },
          submitType,
        );
        reset(defaultValues);
      }}
      id={formId}
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <ActiveField isDisabled={isEditForm && !deleteRolePrivilege} />

      <NameField />

      <PrivilegesField />

      <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { RoleForm };
