import styled from '@emotion/styled';

import { useTheme } from 'shared/hooks/chakraUi';

const Spinner = () => {
  const theme = useTheme();
  return (
    <StyledSvg>
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        strokeWidth="10"
        stroke={theme.colors.grey[500]}
      />
      <RotatingCircle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        strokeWidth="10"
        stroke={theme.colors.secondary[500]}
      />
    </StyledSvg>
  );
};

const RotatingCircle = styled.circle`
  stroke-linecap: round;
  animation: dash 3.5s ease-in-out infinite;

  @keyframes dash {
    0% {
      stroke-dasharray: 20, 300;
      stroke-dashoffset: 0;
    }
    30% {
      stroke-dasharray: 50, 300;
      stroke-dashoffset: 0;
    }
    61% {
      stroke-dasharray: 20, 300;
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dasharray: 19, 300;
      stroke-dashoffset: 0;
    }
  }
`;

const StyledSvg = styled.svg`
  rotate: 270deg;
  animation: rotate 3.5s ease-in-out infinite;
  width: 100px;
  height: 100px;

  @keyframes rotate {
    60% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export { Spinner };
