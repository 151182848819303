import { Link } from 'react-router-dom';
import { BreadcrumbData } from 'use-react-router-breadcrumbs';

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from 'shared/components';
import { routeBuilder } from 'shared/utils';

interface IProps {
  breadcrumbs: BreadcrumbData[];
}

const ExpandedBreadcrumbs = ({ breadcrumbs }: IProps) => {
  return (
    <Breadcrumb>
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        const isCurrent = breadcrumbs.length - 1 === index;

        return (
          <BreadcrumbItem
            key={index}
            isCurrentPage={isCurrent}
          >
            {isCurrent ? (
              <Box fontWeight="bold">{breadcrumb}</Box>
            ) : (
              <BreadcrumbLink
                as={Link}
                to={
                  match.pathname === '/'
                    ? routeBuilder.tracking.add.getPath()
                    : match.pathname
                }
              >
                {breadcrumb}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export { ExpandedBreadcrumbs };
