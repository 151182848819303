import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  value?: string;
}

const IncidentLocationViewRow = ({ value }: IProps) => {
  const { t } = useTranslation('tracking');

  return (
    <ViewRow label={t('Incident Location')}>
      <Text>{value || '-'}</Text>
    </ViewRow>
  );
};

export { IncidentLocationViewRow };
