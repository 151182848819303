import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  activity?: string;
}

const ActivityViewRow = ({ activity }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Activity')}>
      <Text>{activity || '-'}</Text>
    </ViewRow>
  );
};

export { ActivityViewRow };
