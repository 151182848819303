import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useBreakpointValue } from '@chakra-ui/react';

import { routes } from './routes';
import { MobileBreadcrumbs } from './MobileBreadcrumbs';
import { ExpandedBreadcrumbs } from './ExpandedBreadcrumbs';

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
  });
  const isMobile = useBreakpointValue({ base: true, lg: false });

  if (isMobile) {
    return <MobileBreadcrumbs breadcrumbs={breadcrumbs} />;
  }

  return <ExpandedBreadcrumbs breadcrumbs={breadcrumbs} />;
};

export { Breadcrumbs };
