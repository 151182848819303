import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteCompany = (companyId: string) => {
  return useMutation(() => {
    return deleteCompany(companyId);
  });
};

const deleteCompany = (companyId: string) => {
  return httpClient.delete(`companies/${companyId}`);
};
