import { useTranslation } from 'react-i18next';
import { ViewRow } from 'shared/components';
import { EmployeesTable } from 'features/EmployeesTable';

interface IProps {
  personId: string;
}

const EmployeesViewRow = ({ personId }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Employees')}>
      <EmployeesTable personId={personId} />
    </ViewRow>
  );
};

export { EmployeesViewRow };
