import { useNavigate } from 'react-router-dom';
import { useSaveTopic } from 'entities/tracking/api';
import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';
import { TopicFormType } from 'entities/tracking/model';
import { TopicForm } from 'features/topics/TopicForm';
import { convertTopicFormData } from 'features/topics/TopicForm/utils/convertTopicFormData';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddTopic = ({ companyId }: IProps) => {
  const { isLoading, mutateAsync } = useSaveTopic(companyId);
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const handleSubmit = async (
    data: TopicFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(convertTopicFormData(data));

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.topics.view.getPath(_id));
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(0);
      }

      successToast({
        message: t('Successfully added item message', { item: t('topic') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('topic') }),
      });
    }
  };

  return (
    <TopicForm
      companyId={companyId}
      formId={`add-topic-form-${companyId}`}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export { AddTopic };
