import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { DateObject } from '../../../shared/utils';

export const useAdvancedSearchValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');

  return object({
    title: string().required(requiredFieldMessage),
    from: string()
      .test(
        'to-test',
        t('Tracking Date From should be earlier than Tracking Date To'),
        function (from) {
          const { to } = this.parent;
          if (!to) {
            return true;
          }
          if (DateObject(from).isEqual(new Date(to))) {
            return true;
          }
          return DateObject(from).isBefore(new Date(to));
        },
      )
      .nullable(),
    to: string()
      .test(
        'to-test',
        t('Tracking Date To should be later than Tracking Date From'),
        function (to) {
          const { from } = this.parent;
          if (!from) {
            return true;
          }
          if (DateObject(to).isEqual(new Date(from))) {
            return true;
          }
          return DateObject(to).isAfter(new Date(from));
        },
      )
      .nullable(),
  });
};
