import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export const useNameValidation = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');

  return object({
    name: string().required(requiredFieldMessage),
  });
};
