import { useTranslation } from 'react-i18next';

import { FormControlProps, TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const PasswordVerifyField = (props: FormControlProps) => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField
      label={`${t('Password Verify')}:`}
      {...props}
    >
      <TextField
        name="passwordVerify"
        type="password"
      />
    </FormField>
  );
};

export { PasswordVerifyField };
