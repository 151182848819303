import { IUserDTO } from 'entities/user/model';
import { Privileges } from 'entities/role/model';

export const mockedUserDTO = (person?: Partial<IUserDTO>): IUserDTO => {
  return {
    _id: '1234567',
    active: true,
    email: 'test.email@gmail.com',
    name: {
      firstname: 'User firstname',
      lastname: 'User lastname',
      middlename: 'User middlename',
      nickname: 'User nickname',
    },
    departments: [{ name: 'Department 2', _id: '123', active: false }],
    locations: [{ name: 'Location 2', _id: '456', active: false }],
    login: 'user login',
    suffix: 'suffix',
    companyID: '56789',
    roleIDs: ['123456'],
    mfaEnabled: true,
    contactPreferences: { email: true, phone: false },
    credits: {
      createdByID: 'User 123',
      updatedByID: 'User 567',
      updatedAt: new Date().toString(),
      createdAt: new Date().toString(),
    },
    roles: [
      {
        _id: '63517c3b375e38dcf2e1b902',
        name: 'admin',
        privileges: [
          Privileges.USERS_READ,
          Privileges.USERS_CREATE,
          Privileges.VENDORS_READ,
          Privileges.VENDORS_CREATE,
          Privileges.CUSTOMERS_READ,
          Privileges.CUSTOMERS_CREATE,
          Privileges.ROLES_DELETE,
        ],
        active: true,
        credits: {
          createdByID: 'User 123',
          updatedByID: 'User 567',
          updatedAt: new Date().toString(),
          createdAt: new Date().toString(),
        },
      },
      {
        _id: '63517c9a375e38dcf2e1b903',
        name: 'accountant',
        privileges: [Privileges.USERS_READ],
        active: true,
        credits: {
          createdByID: 'User 123',
          updatedByID: 'User 567',
          updatedAt: new Date().toString(),
          createdAt: new Date().toString(),
        },
      },
      {
        _id: '63697dcde298da047c665d20',
        name: 'SYS_ADMIN',
        privileges: [Privileges.FULL],
        active: false,
        credits: {
          createdByID: 'User 123',
          updatedByID: 'User 567',
          updatedAt: new Date().toString(),
          createdAt: new Date().toString(),
        },
      },
    ],
    supervisor: {
      name: {
        firstname: 'SuperFirstName',
        lastname: 'SuperLastName',
      },
      _id: '6353',
    },
    ...person,
  };
};
