import { useTranslation } from 'react-i18next';

import { Text, TextProps } from 'shared/components';

const EmptyState = (props: TextProps) => {
  const { t } = useTranslation('common');
  return <Text {...props}>{t('No results found.')}</Text>;
};

export { EmptyState };
