import { useFormContext } from 'react-hook-form';

import { IUser } from 'entities/user/model';

import { TableRow, TableBodyCell } from 'shared/components/Table';
import { Checkbox, TableCellText } from 'shared/components';

interface IProps {
  user: IUser;
}

const SupervisorTableRow = ({ user }: IProps) => {
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = `supervises.${user._id}`;
  const value = watch(name);

  return (
    <TableRow>
      <TableBodyCell>
        <Checkbox
          isDisabled={isSubmitting}
          isChecked={value?.value}
          onChange={(event) => {
            setValue(name, { _id: user._id, value: event.target.checked });
          }}
        />
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{user.name.firstname}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{user.name.lastname}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { SupervisorTableRow };
