import { ILocation } from 'entities/company/model';
import { TableCellText, TableHeaderText } from 'shared/components/Table';
import { ColumnsTitles } from '../types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'shared/components';

interface IProps {
  location: ILocation;
}
const LocationMobileDetails = ({ location }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <TableHeaderText color="black">
        {t(ColumnsTitles.address)}
      </TableHeaderText>
      <Grid>
        <TableCellText>
          {`${location?.address?.street || ''} ${
            location?.address?.streetNumber || ''
          }`}
        </TableCellText>
        <TableCellText>
          {`${location?.address?.city || ''} ${location?.address?.zip || ''}`}
        </TableCellText>
      </Grid>
      <TableHeaderText color="black">
        {t(ColumnsTitles.trackings)}
      </TableHeaderText>
      <TableCellText>{location.counters?.trackings || 0}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.personnel)}
      </TableHeaderText>
      <TableCellText>{location.counters?.users || 0}</TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.active)}</TableHeaderText>
      <TableCellText>{location.active ? t('Yes') : t('No')}</TableCellText>
    </>
  );
};

export { LocationMobileDetails };
