import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { AddVendor } from 'entities/vendor/model';

export const useUpdateVendor = (vendorId: string, companyId: string) => {
  return useMutation((data: AddVendor) => {
    return updateCustomer(data, vendorId, companyId);
  });
};

const updateCustomer = (
  formData: AddVendor,
  vendorId: string,
  companyId: string,
) => {
  return httpClient.patch(
    `vendors/${vendorId}?companyID=${companyId}`,
    formData,
  );
};
