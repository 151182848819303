/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import { UseQueryOptions } from '@tanstack/react-query';

import { IUser, IUserDTO } from 'entities/user/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { useGetLocale, useGetParsedQueryParams } from 'shared/utils';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { IList, IQueryParams, ISort } from 'shared/types';
import { useComputeAttendeeListReduceQuery } from 'features/tracking/utils';
import { IAttendeeListReduce } from 'entities/tracking/model';

interface IUsersQueryParams extends IQueryParams {
  searchFields?: IAttendeeListReduce;
}

export const useGetUsers = (
  companyId: string,
  query?: IUsersQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IUser[]>>, AxiosError>,
  filterOr?: boolean,
) => {
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  const parsedQueryParams = useGetParsedQueryParams(query);
  const advancedQueryParams = useComputeAttendeeListReduceQuery(
    companyId,
    query?.searchFields,
    query?.limit,
    query?.offset,
  );
  const sort = query?.sort || [];
  const computeSorting = (sort: ISort[]) => {
    if (sort.length === 0) {
      return '';
    }
    return sort
      .reduce(
        (result, currentValue) =>
          `${result}${currentValue.name}$${currentValue.sortDirection},`,
        'sort=',
      )
      .slice(0, -1);
  };
  const _lang = query?.languageCode || defaultLang || 'en';
  const boolOnlyParams = parsedQueryParams
    .split('&')
    .filter((param) => !param.startsWith('filters'))
    .join('&');
  let finalQuery = '';
  if (query?.filters === 'true' && !query.globalFilter) {
    finalQuery = `languageCode=${_lang}&companyID=${companyId}&filters=active$true&limit=${
      query?.limit || 10
    }&offset=${query?.offset || 0}`;
  } else {
    finalQuery = query?.filters
      ? query?.filters === 'true' || query?.filters === 'false'
        ? `languageCode=${_lang}&companyID=${companyId}&filters=active$${query?.filters}&${boolOnlyParams}`
        : `languageCode=${_lang}&${
            filterOr ? 'filterOperator=or' : ''
          }&companyID=${companyId}&${parsedQueryParams}`
      : `languageCode=${_lang}&${advancedQueryParams}`;

    const isActivePresent = typeof query?.searchFields?.active !== 'undefined';
    if (isActivePresent && !finalQuery.includes('active$')) {
      if (finalQuery.includes('filters='))
        finalQuery = finalQuery.replace(
          'filters=',
          'filters=active$' + query.searchFields?.active + ',',
        );
      else {
        finalQuery += '&filters=active$' + query.searchFields?.active + ',';
      }
    }
  }

  /**
   * Sorting should be added to every query
   */
  const sortQuery = computeSorting(sort);
  if (sortQuery) {
    finalQuery += `&${sortQuery}`;
  }

  return useReactQuery<IList<IUser[]>>(
    getUsersKey(finalQuery),
    () => getUsers(companyId, finalQuery.replace('&&', '&')),
    options,
  );
};

const getUsers = (companyId?: string, queryParams?: string) => {
  return httpClient.get<IList<IUserDTO[]>>(buildUrl('users', queryParams));
};

const getUsersKey = (queryParams?: string) => ['users', queryParams];
