import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteRole = () => {
  return useMutation(
    ({ roleId, companyId }: { roleId: string; companyId: string }) => {
      return deleteRole(roleId, companyId);
    },
  );
};

const deleteRole = (roleId: string, companyId: string) => {
  return httpClient.delete(`roles/${roleId}?companyID=${companyId}`);
};
