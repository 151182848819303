import { Privileges } from 'entities/role/model';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';
import { privilegesGroups } from 'features/privileges/PrivilegesField/PrivilegesField';
import { useTranslation } from 'react-i18next';

import {
  ViewRow,
  Text,
  SimpleGrid,
  Grid,
  Checkbox,
  Box,
} from 'shared/components';
import useStore from 'shared/store';

interface IProps {
  privilegesItem?: Privileges[];
  privilegesAll?: boolean;
}

const PrivilegesViewRow = ({ privilegesItem, privilegesAll }: IProps) => {
  const { t } = useTranslation(['commonFields', 'privileges']);
  const companyComponents = useStore((state) => state.company?.components || []);
  const hasRegulations = companyComponents.includes(ICompanyComponent.Regulations);
  const hasCustomers = companyComponents.includes(ICompanyComponent.Customers);
  const hasVendors = companyComponents.includes(ICompanyComponent.Vendors);
  return (
    <ViewRow label={t('Privileges')}>
      <SimpleGrid
        columns={{ base: 1, md: 2, xl: 3 }}
        gap="16px"
        w="100%"
      >
        {Object.entries(privilegesGroups).map(([group, privileges]) => {
          if(group === ICompanyComponent.Vendors && !hasVendors) return null;
          if(group === ICompanyComponent.Customers && !hasCustomers) return null;
          if(group === ICompanyComponent.Regulations && !hasRegulations) return null;
          if(group === 'agencies' && !hasRegulations) return null;

          return (
            <Grid
              key={group}
              gridTemplateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: '1fr',
              }}
              borderColor="secondary.500"
              borderRadius="20px"
              borderWidth="2px"
              padding="16px"
              gap={{ base: '10px', md: '16px' }}
            >
              {privileges.map((privilege) => {
                return (
                  <Box key={privilege._id}>
                    <Checkbox
                      isDisabled
                      size="lg"
                      gap="12px"
                      isChecked={
                        privilegesAll
                          ? true
                          : privilegesItem?.join().includes(privilege._id)
                      }
                    >
                      <Text textTransform="capitalize">
                        {t(privilege.name, { ns: 'privileges' })}
                      </Text>
                    </Checkbox>
                  </Box>
                );
              })}
            </Grid>
          );
        })}
      </SimpleGrid>
    </ViewRow>
  );
};

export { PrivilegesViewRow };
