import { useTranslation } from 'react-i18next';

import { Flex, Grid, Image, Text } from 'shared/components';

import { LanguageSwitcher } from 'features/LanguageSwitcher';

import omnis_logo from 'assets/omnistools_logo.png';

const BrandBar = () => {
  const { t } = useTranslation('brandBar');

  return (
    <Flex
      alignItems="center"
      gap="10px"
      h="105px"
      px="30px"
      backgroundColor="white"
      justifyContent="space-between"
    >
      <Grid
        gridTemplateColumns={{ lg: '30% 1fr', xl: '35% 1fr', '2xl': '40% 1fr' }}
        w="100%"
        alignItems="center"
      >
        <Image
          src={omnis_logo}
          alt={t('OmnisTools logo')}
          w={{ lg: '180px', xl: '230px' }}
          h="auto"
          justifySelf="right"
          maxW="max-content"
        />
        <Text
          size={{ lg: 'md', xl: 'xl', '2xl': '2xl' }}
          color="primary.500"
          justifySelf="center"
          pl="10px"
        >
          {t('OmnisTools Efficiency Tracking Software')}
        </Text>
      </Grid>
      <LanguageSwitcher />
    </Flex>
  );
};

export { BrandBar };
