import { IUser } from 'entities/user/model';

import {
  TableBodyCell,
  TableBodyInfoCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';

interface IProps {
  user: IUser;
  isMobile?: boolean;
}

const EmployeesTableRow = ({ user, isMobile }: IProps) => {
  const locationName =
    user.locations &&
    user?.locations?.map((location) => (location?.name ? location.name : '-'));
  const departmentName =
    user.departments &&
    user?.departments?.map((department) =>
      department?.name ? department.name : '-',
    );

  return (
    <TableRow
      _hover={{
        backgroundColor: 'grey.500',
      }}
      cursor="default"
    >
      {isMobile ? (
        <>
          <TableBodyCell>
            <TableCellText>{user.name.lastname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{user.name.firstname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyInfoCell user={user} />
        </>
      ) : (
        <>
          <TableBodyCell>
            <TableCellText>{user.name.lastname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>{user.name.firstname || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>
              {locationName && locationName.length > 0 ? locationName : '-'}
            </TableCellText>
          </TableBodyCell>
          <TableBodyCell>
            <TableCellText>
              {departmentName && departmentName.length > 0
                ? departmentName
                : '-'}
            </TableCellText>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  );
};

export { EmployeesTableRow };
