import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Center, Container, MessagePopups } from 'shared/components';
import { MessageTypeKeys } from 'shared/components/MessagePopups/MessagePopups';
import { ForgotPasswordForm } from 'features/ForgotPasswordForm';
import { routeBuilder } from 'shared/utils';

type PopupType = MessageTypeKeys | false;

const containerWidth = 506;

const ForgotPasswordPage = () => {
  const [errorMessage, setErrorMesage] = useState(
    'Check the correctness of the entered data',
  );

  const popupMessages = {
    success: 'Check your email for a link to reset your password',
    attention: 'Check the correctness of the entered data',
    error: errorMessage,
  };
  const [popup, setPopup] = useState<PopupType>(false);

  const navigate = useNavigate();

  const onSuccess = async () => {
    setPopup('success');
    setTimeout(() => {
      navigate(routeBuilder.signIn.index.getPath());
    }, 3000);
  };

  const onError = (e: unknown) => {
    setPopup('error');
    const error = e as {
      response: {
        data: {
          message: string;
        };
      };
    };
    setErrorMesage(error.response?.data.message);
  };

  const onMessagePopupsClose = () => {
    setPopup(false);
  };

  return (
    <Container
      maxW={containerWidth}
      p={{ base: '15px', xl: '30px' }}
      position="relative"
    >
      {popup && (
        <Center
          position={{ lg: 'absolute' }}
          left={{ lg: containerWidth }}
          mb="40px"
        >
          <MessagePopups
            type={popup}
            message={popup ? popupMessages[popup] : ''}
            onClose={onMessagePopupsClose}
          />
        </Center>
      )}
      <ForgotPasswordForm
        onSuccess={onSuccess}
        onError={onError}
      />
    </Container>
  );
};

export { ForgotPasswordPage };
