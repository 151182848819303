import { SearchInput } from 'features/SearchInput';
import { Dispatch, SetStateAction } from 'react';
import { Flex, FlexProps, Text } from 'shared/components';

interface Props extends FlexProps {
  title: string;
  globalFilter: string;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
}

const SearchBar = ({ title, globalFilter, setGlobalFilter }: Props) => {
  return (
    <Flex
      width="100%"
      flexDir={{ base: 'column', md: 'row' }}
      gap={{ base: '10px', md: '20px', lg: '30px' }}
      justifyContent={{ md: 'space-between' }}
      alignItems={{ md: 'center' }}
    >
      <Text
        minW={{ md: 'max-content' }}
        fontWeight="700"
        size={{ base: 'sm', md: 'md' }}
      >
        {title}
      </Text>
      <SearchInput
        maxW={{ md: '75%', lg: '60%', '2xl': '50%' }}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </Flex>
  );
};

export { SearchBar };
