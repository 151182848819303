import { ReactNode, useEffect, useState } from 'react';

import { FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';

import { AddCustomer } from 'entities/customer/model';
import { AddVendor } from 'entities/vendor/model';

import { SaveButton } from 'features/SaveButton';
import { SaveAndNewButton } from 'features/SaveAndNewButton';
import {
  CityField,
  NearestCrossStreetField,
  ZipField,
  AbbreviationField,
  AccountNumberField,
  StreetField,
  useContainerProps,
  StateField,
  PhoneNumberField,
  ContactPersonNameField,
  ContactPersonNumber,
  CompanyNameField,
  ActiveField,
  DocumentationField,
} from 'features/FormCommons';

import {
  purifyCustomerVendorFormData,
  useCustomerVendorFormValidationSchema,
} from './utils';
import { useForm } from 'shared/components/Form';
import useStore from 'shared/store';
import {
  useSaveCustomerAttachment,
  useSaveVendorAttachment,
} from 'entities/attachments/api';

type CustomerVendorFormProps = {
  handleSubmit: (
    data: AddCustomer,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  defaultValues?: AddCustomerVendorFormData;
  formId: string;
  additionalActionButtons?: ReactNode;
  variant: 'customer' | 'vendor';
};

export type AddCustomerVendorFormData = Omit<
  AddCustomer | AddVendor,
  'attachmentIDs'
>;

const defaultData: AddCustomerVendorFormData = {
  name: '',
  abbreviation: '',
  address: {
    street: '',
    streetNumber: '',
    city: '',
    state: '',
    zip: '',
    nearestCrossStreet: '',
  },
  phoneNumber: undefined,
  contactPerson: {
    name: '',
    phoneNumber: '',
  },
  active: true,
  attachments: [],
};

const CustomerVendorForm = ({
  defaultValues = defaultData,
  handleSubmit,
  formId,
  isLoading,
  additionalActionButtons,
  variant,
}: CustomerVendorFormProps) => {
  const filesToConnect = useStore((state) => state.filesToConnect);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const companyId = useStore((state) => state?.company?._id);

  if (companyId === undefined) throw new Error('Company ID is undefined.');

  const containerProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const validationSchema = useCustomerVendorFormValidationSchema();
  const { methods, cleanPersistentStore } = useForm<AddCustomerVendorFormData>({
    validationSchema,
    defaultValues,
    formId,
  });

  useEffect(() => {
    if (formId.includes('customer-edit') || formId.includes('vendor-edit')) {
      cleanPersistentStore();
    }
  }, []);

  const { mutateAsync, isLoading: isLoadingSaveAttachment } =
    variant === 'customer'
      ? useSaveCustomerAttachment(companyId)
      : useSaveVendorAttachment(companyId);

  return (
    <Form<AddCustomerVendorFormData>
      onSubmit={(data, { reset }) => {
        handleSubmit(
          purifyCustomerVendorFormData(data),
          () => {
            cleanPersistentStore();
            const filesLeft = filesToConnect.filter(
              (file) => file.parent !== formId,
            );
            setFilesToConnect(filesLeft);
          },
          submitType,
        );
        reset(defaultValues);
      }}
      id={formId}
      containerProps={{
        ...containerProps,
      }}
      methods={methods}
    >
      <CompanyNameField />

      <AbbreviationField />

      <AccountNumberField />

      <PhoneNumberField />

      <StreetField />

      <CityField />

      <StateField name="address.state" />

      <ZipField />

      <NearestCrossStreetField />

      <ContactPersonNameField />

      <ContactPersonNumber />

      <ActiveField />

      <DocumentationField
        mutateAsync={mutateAsync}
        isLoading={isLoadingSaveAttachment}
        attachments={
          defaultValues?.attachments ? defaultValues.attachments : []
        }
        entityName="activities"
        formId={formId}
      />

      <FlexWrapper>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { CustomerVendorForm };
