import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';

import { DeleteDepartment } from './DeleteDepartment';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  departmentId: string;
  companyId: string;
  active?: boolean;
}

const DepartmentActionButtons = ({
  departmentId,
  companyId,
  active,
}: IProps) => {
  const {
    createDepartmentPrivilege,
    updateDepartmentPrivilege,
    deleteDepartmentPrivilege,
  } = LayoutPrivilege();
  return (
    <FlexWrapper mt={{ base: '20px', xl: '50px' }}>
      {createDepartmentPrivilege && (
        <AddNewItemButton
          redirectPath={routeBuilder.departments.add.getPath()}
        />
      )}
      {active && deleteDepartmentPrivilege && (
        <DeleteDepartment
          departmentId={departmentId}
          companyId={companyId}
        />
      )}
      {updateDepartmentPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.departments.edit.getPath(departmentId)}
        />
      )}
    </FlexWrapper>
  );
};

export { DepartmentActionButtons };
