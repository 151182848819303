import { Icon, IconProps } from 'shared/components';

const EditIcon = (props: IconProps) => {
  return (
    <Icon
      w="27.5px"
      h="27.5px"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_859_4751)">
        <path
          d="M4.375 30H23.125C24.2871 29.9967 25.4005 29.5325 26.2207 28.7092C27.0409 27.8859 27.501 26.7709 27.5 25.6087V16.1875C27.5 15.856 27.3683 15.538 27.1339 15.3036C26.8995 15.0692 26.5815 14.9375 26.25 14.9375C25.9185 14.9375 25.6005 15.0692 25.3661 15.3036C25.1317 15.538 25 15.856 25 16.1875V25.6087C25.0017 26.108 24.8052 26.5876 24.4536 26.9422C24.1021 27.2967 23.6243 27.4974 23.125 27.5H4.375C3.87572 27.4974 3.39788 27.2967 3.04636 26.9422C2.69483 26.5876 2.49834 26.108 2.5 25.6087V6.89125C2.49834 6.39196 2.69483 5.91241 3.04636 5.55784C3.39788 5.20327 3.87572 5.00265 4.375 5H13.75C14.0815 5 14.3995 4.8683 14.6339 4.63388C14.8683 4.39946 15 4.08152 15 3.75C15 3.41848 14.8683 3.10054 14.6339 2.86612C14.3995 2.6317 14.0815 2.5 13.75 2.5H4.375C3.21288 2.50331 2.09955 2.96754 1.27932 3.79081C0.459102 4.61408 -0.000996364 5.72913 1.62014e-06 6.89125V25.6087C-0.000996364 26.7709 0.459102 27.8859 1.27932 28.7092C2.09955 29.5325 3.21288 29.9967 4.375 30Z"
          fill="#72B42D"
        />
        <path
          d="M11.8186 13.18L10.8324 17.6975C10.7877 17.9024 10.7953 18.1152 10.8545 18.3164C10.9137 18.5176 11.0226 18.7007 11.1711 18.8487C11.3217 18.9931 11.5048 19.099 11.705 19.1576C11.9052 19.2161 12.1165 19.2255 12.3211 19.185L16.8286 18.1962C17.0627 18.1449 17.277 18.0273 17.4461 17.8575L28.8386 6.465C29.1869 6.11677 29.4632 5.70334 29.6517 5.24833C29.8402 4.79332 29.9372 4.30563 29.9372 3.81312C29.9372 3.32061 29.8402 2.83293 29.6517 2.37791C29.4632 1.9229 29.1869 1.50948 28.8386 1.16125C28.1246 0.478918 27.175 0.0981445 26.1874 0.0981445C25.1997 0.0981445 24.2501 0.478918 23.5361 1.16125L12.1611 12.565C11.9904 12.733 11.8715 12.9464 11.8186 13.18ZM25.3036 2.93C25.5414 2.70217 25.858 2.57499 26.1874 2.57499C26.5167 2.57499 26.8333 2.70217 27.0711 2.93C27.3024 3.16578 27.4319 3.48287 27.4319 3.81312C27.4319 4.14338 27.3024 4.46046 27.0711 4.69625L26.1874 5.58L24.4199 3.8125L25.3036 2.93ZM14.1786 14.0725L22.6461 5.58375L24.3961 7.3425L15.9249 15.8337L13.6811 16.3262L14.1786 14.0725Z"
          fill="#72B42D"
        />
      </g>
      <defs>
        <clipPath id="clip0_859_4751">
          <rect
            width="30"
            height="30"
            fill="white"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

export { EditIcon };
