import { useNavigate } from 'react-router-dom';
import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { useTranslation } from 'react-i18next';
import { routeBuilder } from 'shared/utils';
import { IAction } from 'entities/tracking/model';

interface IProps {
  action: IAction;
}
const ActionTableRow = ({ action }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(routeBuilder.actions.view.getPath(action._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{action.name}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{action.active ? t('Yes') : t('No')}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { ActionTableRow };
