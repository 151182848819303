import { ILocation } from 'entities/company/model';
import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  locations?: Partial<ILocation[]>;
}

const LocationsViewRow = ({ locations }: IProps) => {
  const { t } = useTranslation('commonFields');
  const locationName = locations?.map((location) =>
    location?.name ? location.name : '-',
  );

  return (
    <ViewRow label={t('Locations')}>
      <Text>
        {locationName && locationName.length > 0 ? locationName : '-'}
      </Text>
    </ViewRow>
  );
};

export { LocationsViewRow };
