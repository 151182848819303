import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportDataTable } from 'features/ExportDataTable';
import { DepartmentsTable } from 'features/departments/DepartmentsTable';

import { SearchBar } from 'widgets/SearchBar';

import { FlexWrapper } from 'shared/components';
import { ISort } from 'shared/types';
import { useGetParsedQueryParams } from 'shared/utils';
import { buildUrl } from 'shared/utils/httpClient';
import useStore from 'shared/store';
import { lngs } from 'features/LanguageSwitcher/LanguageSwitcher';

const limit = 10;

const DepartmentsList = () => {
  const { t } = useTranslation(['departments', 'common']);
  const [globalFilter, setGlobalFilter] = useState('');
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<ISort[]>();
  const { i18n } = useTranslation();
  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;

  const query = useGetParsedQueryParams({
    sort,
    filters: globalFilter,
  });

  const companyId = useStore((state) => state.company?._id);
  if (!companyId) {
    throw new Error('Company Id is missing');
  }
  const departmentFieldsTranslation = 'fields=name$Departamento,active$Activo';
  const downloadFileUrl = buildUrl(
    'departments',
    resolvedLng === 'es'
      ? `companyID=${companyId}&${query}&${departmentFieldsTranslation}`
      : `companyID=${companyId}&${query}`,
  );

  return (
    <>
      <SearchBar
        title={t('Departments List')}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <DepartmentsTable
        onSortChange={(sort) => setSort(sort)}
        queryParams={{
          limit: limit,
          offset: offset,
          filters: globalFilter,
          sort: sort,
        }}
        setOffset={(value) => setOffset(value)}
      />
      <FlexWrapper>
        <ExportDataTable
          fileUrl={downloadFileUrl}
          fileName={t('Departments List')}
          title={t('Export All Data', { ns: 'common' })}
        />
      </FlexWrapper>
    </>
  );
};

export { DepartmentsList };
