import { Icon, IconProps } from 'shared/components';

const AttentionIcon = (props: IconProps) => (
  <Icon
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="25.7224"
      y="13.5"
      width="5.55556"
      height="21.1111"
      rx="2.77778"
      fill="#FFBB35"
    />
    <circle
      cx="28.5002"
      cy="41.8332"
      r="2.77778"
      fill="#FFBB35"
    />
    <circle
      cx="28.5"
      cy="28.5"
      r="26.5"
      stroke="#FFBB35"
      strokeWidth="3"
    />
  </Icon>
);

export { AttentionIcon };
