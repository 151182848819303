import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

import { DepartmentFormType } from 'entities/company/model';

export const useSaveDepartment = (companyId: string) => {
  return useMutation((data: DepartmentFormType) => {
    return saveDepartment(data, companyId);
  });
};

const saveDepartment = (formData: DepartmentFormType, companyId: string) => {
  return httpClient.post<DepartmentFormType, AxiosResponse<{ _id: string }>>(
    `departments?companyID=${companyId}`,
    formData,
  );
};
