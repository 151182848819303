import { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';

import { useBreakpointValue, useTheme } from 'shared/hooks/chakraUi';

export const useMenuListStyle = (provided: CSSObjectWithLabel) => {
  const { colors } = useTheme();
  const fontSize = useBreakpointValue({ base: '1.6rem', md: '1.8rem' });

  return {
    ...provided,
    borderRadius: '0 0 4px 4px',
    borderColor: colors.secondary[500],
    borderWidth: '0 2px 2px 2px',
    marginTop: '-2px',
    padding: '0px',
    fontSize: fontSize,
  };
};
