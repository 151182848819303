import { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';
import { ControlProps } from 'react-select/dist/declarations/src/components/Control';

import { useBreakpointValue, useTheme } from 'shared/hooks/chakraUi';

export const useControlStyle = (
  provided: CSSObjectWithLabel,
  state: ControlProps,
) => {
  const { colors } = useTheme();
  const fontSize = useBreakpointValue({ base: '1.6rem', md: '1.8rem' });
  const paddingY = '3px';

  return {
    ...provided,
    fontSize: fontSize,
    borderWidth: '2px',
    borderColor: state.menuIsOpen ? colors.secondary[500] : colors.grey[700],
    borderRadius: state.menuIsOpen ? '4px 4px 0px 0px' : '4px',
    transition: 'border-color 0.3s',
    boxShadow: '0 !important',
    paddingTop: paddingY,
    paddingBottom: paddingY,
    '&:hover': {
      borderColor: colors.secondary[500],
    },
  };
};
