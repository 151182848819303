import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from 'shared/components';
import { EmailIcon } from 'shared/icons/EmailIcon';

const EmailButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');

  return (
    <Button
      leftIcon={<EmailIcon />}
      {...props}
    >
      {t('Email')}
    </Button>
  );
};

export { EmailButton };
