import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';

import { useGetRole } from 'entities/role/api';

import { RoleView } from 'features/roles/RoleView';
import { ActionsButtons } from 'features/roles/ActionsButtons';
import { CreationDetails } from 'features/CreationDetails';

const ViewRolesPageThrowable = () => {
  const { t } = useTranslation(['roles', 'common']);
  const { roleId } = useParams();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  if (!roleId) {
    throw new Error('RoleId not found');
  }

  const { isLoading, error, data } = useGetRole(companyId, roleId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Role not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <RoleView role={data.data} />
      <ActionsButtons
        roleId={roleId}
        companyId={companyId}
        active={data.data.active}
        roleProtected={data.data.protected}
      />

      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewRolesPage = () => {
  return (
    <ErrorBoundary>
      <ViewRolesPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewRolesPage };
