import { useNavigate } from 'react-router-dom';

import { useUpdateAction } from 'entities/tracking/api';
import { IAction, ActionFormType } from 'entities/tracking/model';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { ViewButton } from 'features/ViewButton';
import { DeleteAction } from 'features/actions/ActionsButtons';

import { ActionForm } from 'features/actions/ActionForm/ActionForm';
import { useTranslation } from 'react-i18next';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  action: IAction;
}

const EditAction = ({ companyId, action }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { companyID, _id, credits, ...defaultValue } = action;
  const { mutateAsync, isLoading } = useUpdateAction(companyId, _id);
  const navigation = useNavigate();
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();
  const { deleteActionPrivilege } = LayoutPrivilege();

  const handleSubmit = async (
    data: ActionFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(data);
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.actions.view.getPath(_id));
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.actions.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('action') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('action') }),
      });
    }
  };

  return (
    <ActionForm
      formId={`edit-action-form-${_id}-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <ViewButton
            onClick={() => navigation(routeBuilder.actions.view.getPath(_id))}
          />
          {action.active && deleteActionPrivilege && (
            <DeleteAction
              actionId={_id}
              companyId={companyId}
            />
          )}
        </>
      }
      defaultValues={defaultValue}
    />
  );
};

export { EditAction };
