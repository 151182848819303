import { IUser, IUserDTO } from 'entities/user/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from '../../../shared/utils/httpClient';

export const useGetUser = (
  userId: string,
  options?: UseQueryOptions<AxiosResponse<IUser>, AxiosError>,
) => {
  return useReactQuery<IUser>(
    getUserKey(userId),
    () => getUser(userId),
    options,
  );
};

const getUser = (userId: string) => {
  return httpClient.get<IUserDTO>(`users/${userId}`).then((response) => {
    return {
      ...response,
      data: {
        ...response.data,
        credits: {
          ...response.data.credits,
        },
      },
    };
  });
};

const getUserKey = (userId: string) => ['user', userId];
