import { TableCellText, TableCellProps } from 'shared/components';
import { TableBodyCell } from 'shared/components/Table';

interface IProps extends TableCellProps {
  firstName?: string;
  lastName?: string;
}

const SupervisorTableCell = ({ firstName, lastName }: IProps) => {
  const fullName =
    firstName === undefined && lastName === undefined
      ? '-'
      : `${firstName || ''} ${lastName || ''}`;

  return (
    <TableBodyCell>
      <TableCellText>{fullName}</TableCellText>
    </TableBodyCell>
  );
};

export { SupervisorTableCell };
