import { useTranslation } from 'react-i18next';

import { WorldIcon } from 'shared/icons';
import {
  Text,
  Center,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuButtonProps,
} from 'shared/components';

export const lngs = { en: 'English', es: 'Español' };
const lngsKeys = Object.keys(lngs) as (keyof typeof lngs)[];

interface IProps {
  btnProps?: MenuButtonProps;
}

export const LanguageSwitcher = ({ btnProps }: IProps) => {
  const { i18n } = useTranslation();
  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;
  const languageText = lngs[resolvedLng] || 'English';

  return (
    <Menu matchWidth>
      <MenuButton
        padding="14px 30px"
        border="1px solid"
        borderColor="primary.500"
        borderRadius="100px"
        maxW="max-content"
        _hover={{ boxShadow: 'btnPrimary' }}
        {...btnProps}
      >
        <Center gap="15px">
          <WorldIcon />
          <Text fontWeight={{ base: 'bold', lg: '300' }}>{languageText}</Text>
        </Center>
      </MenuButton>
      <MenuList
        marginTop="-8px"
        padding={0}
        overflow="hidden"
        border="1px solid"
        borderColor="primary.500"
        borderRadius="20px"
        minW="auto"
      >
        {lngsKeys.map((lng) => (
          <MenuItem
            key={lng}
            onClick={() => i18n.changeLanguage(lng)}
            p="12px 16px"
            _notLast={{
              borderBottom: '1px solid',
              borderColor: 'primary.500',
            }}
            _hover={{
              backgroundColor: 'primary.200',
            }}
          >
            <Text>{lngs[lng]}</Text>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
