import {
  UnorderedList as ChakraUnorderedList,
  ListProps,
} from '@chakra-ui/react';

export const UnorderedList = (props: ListProps) => {
  return (
    <ChakraUnorderedList
      marginInlineStart={0}
      listStyleType="none"
      {...props}
    />
  );
};
