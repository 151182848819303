import { useTranslation } from 'react-i18next';

export const useEmployeesColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'firstName',
      sortedFields: ['firstName'],
      title: t('First Name'),
      isSortable: false,
    },
    {
      name: 'lastName',
      sortedFields: ['lastName'],
      title: t('Last Name'),
      isSortable: false,
    },
    {
      name: 'locations',
      sortedFields: ['locations'],
      title: t('Locations'),
      isSortable: false,
    },
    {
      name: 'departments',
      sortedFields: ['departments'],
      title: t('Departments'),
      isSortable: false,
    },
  ];
};
