import { Breadcrumb as ChakraBreadcrumb } from '@chakra-ui/react';
import { BreadcrumbProps } from '@chakra-ui/breadcrumb';

import { ChevronRightIcon } from 'shared/icons';

const Breadcrumb = (props: BreadcrumbProps) => {
  return (
    <ChakraBreadcrumb
      spacing="16px"
      separator={<ChevronRightIcon />}
      sx={{
        ol: {
          display: 'flex',
        },
      }}
      {...props}
    />
  );
};

export { Breadcrumb };
