import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeaderText,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import { IList } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';
import { useAttachmentsColumnsConfiguration } from './utils';
import { AttachmentTableRow } from './AttachmentTableRow';
import { IAttachment } from 'entities/attachments/model';

const useAttachmentTableState = createTableStore();

interface IProps {
  isLoading: boolean;
  offset: number;
  setOffset: (value: number) => void;
  attachmentList?: IList<IAttachment[]>;
  limit: number;
  companyId: string;
  entityName: 'activities' | 'trackings' | 'customers' | 'vendors';
  viewMode?: boolean;
}

const AttachmentsTable = ({
  isLoading,
  attachmentList,
  companyId,
  offset,
  setOffset,
  limit,
  entityName,
  viewMode,
}: IProps) => {
  const columnsConfiguration = useAttachmentsColumnsConfiguration(!!viewMode);
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);

  const tableState = useAttachmentTableState((state) => state, shallow);

  useEffect(() => {
    if (attachmentList && attachmentList?.total <= 10) {
      setOffset(0);
    }
  }, [attachmentList?.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map(({ title, name }) => {
            return (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody isLoading={isLoading}>
        {attachmentList && attachmentList.total > 0 ? (
          <>
            {attachmentList.total > 10
              ? attachmentList.data.map(
                  (attachment, i) =>
                    i > offset - 1 && (
                      <AttachmentTableRow
                        key={attachment._id}
                        attachment={attachment}
                        companyId={companyId}
                        viewMode={viewMode}
                        entityName={entityName}
                      />
                    ),
                )
              : attachmentList.data.map(
                  (attachment, i) =>
                    i < 10 && (
                      <AttachmentTableRow
                        key={attachment._id}
                        attachment={attachment}
                        companyId={companyId}
                        viewMode={viewMode}
                        entityName={entityName}
                      />
                    ),
                )}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={attachmentList.total}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { AttachmentsTable };
