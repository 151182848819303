import { useNavigate } from 'react-router-dom';

import { useDisclosure } from 'shared/hooks/chakraUi';
import { routeBuilder, useToast } from 'shared/utils';

import { DeleteButton } from 'features/DeleteButton';
import { DeleteModal } from 'features/DeleteModal';

import { useDeleteRegulation } from 'entities/regulation/api';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
  regulationId: string;
}

const DeleteRegulation = ({ companyId, regulationId }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigation = useNavigate();
  const { mutateAsync, isLoading } = useDeleteRegulation(
    regulationId,
    companyId,
  );
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();

  const handleDelete = async () => {
    try {
      await mutateAsync();
      successToast({
        message: t('Successfully deleted item message', {
          item: t('regulation'),
        }),
      });
      navigation(routeBuilder.regulation.index.getPath());
    } catch (e) {
      errorToast({
        message: t('Error while deleting item message', {
          item: t('regulation'),
        }),
      });
    }
  };

  return (
    <>
      <DeleteButton onClick={onOpen} />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export { DeleteRegulation };
