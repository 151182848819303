import { ITracking } from 'entities/tracking/model';
import { useTranslation } from 'react-i18next';
import { TableCellText, TableHeaderText } from 'shared/components/Table';
import { ColumnsTitles } from '../types';
import { DateObject } from 'shared/utils';
import { Text } from '@chakra-ui/react';

interface IProps {
  tracking: ITracking;
}
const TrackingMobileDetails = ({ tracking }: IProps) => {
  const { t } = useTranslation(['commonFields', 'common']);

  return (
    <>
      <TableHeaderText color="black">
        {t(ColumnsTitles.trackingDate)}
      </TableHeaderText>
      <TableCellText>
        {DateObject(tracking.trackedAt?.utc).getDate()}
      </TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.locations)}
      </TableHeaderText>
      <TableCellText>{tracking.location?.name || '-'}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.departments)}
      </TableHeaderText>
      <TableCellText>{tracking.department?.name || '-'}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.activity)}
      </TableHeaderText>
      <TableCellText>{tracking.activity?.name || '-'}</TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.topic)}</TableHeaderText>
      {tracking?.topics ? (
        tracking?.topics.map((t) => {
          return (
            <TableCellText key={t._id}>{`${t?.name || '-'}`}</TableCellText>
          );
        })
      ) : (
        <Text>-</Text>
      )}

      <TableHeaderText color="black">
        {t(ColumnsTitles.trackedBy)}
      </TableHeaderText>
      {tracking.trackedBy?.name.firstname ||
      tracking.trackedBy?.name.lastname ? (
        <TableCellText>
          {`${tracking.trackedBy?.name.firstname || ''} ${
            tracking.trackedBy?.name.lastname || ''
          } `}
        </TableCellText>
      ) : (
        <Text>-</Text>
      )}

      <TableHeaderText color="black">
        {t(ColumnsTitles.attendee)}
      </TableHeaderText>

      {tracking?.attendees ? (
        tracking?.attendees.map((a) => {
          return (
            <TableCellText key={a._id}>
              {`${a?.name.firstname || ''} ${a?.name.lastname || ''}`}
            </TableCellText>
          );
        })
      ) : (
        <Text>-</Text>
      )}
      <TableHeaderText color="black">
        {t(ColumnsTitles.violation)}
      </TableHeaderText>
      <TableCellText>
        {tracking.violation?.safetyViolation
          ? t('Yes', { ns: 'common' })
          : t('No', { ns: 'common' })}
      </TableCellText>
    </>
  );
};

export { TrackingMobileDetails };
