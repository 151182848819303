import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { IResetPasswordModel } from 'entities/user/model';

export const useResetPassword = () => {
  return useMutation((data: IResetPasswordModel) => {
    return resetPassword(data);
  });
};

const resetPassword = (data: IResetPasswordModel) => {
  return httpClient.post<IResetPasswordModel>('auth/password/reset', data);
};
