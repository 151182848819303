import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { IVendor } from 'entities/vendor/model';

export const useGetVendor = (companyId: string, vendorId: string) => {
  return useReactQuery<IVendor>(getVendorKey(companyId, vendorId), () =>
    getVendor(companyId, vendorId),
  );
};

const getVendor = (companyId: string, vendorId: string) => {
  return httpClient.get<IVendor>(`vendors/${vendorId}?companyID=${companyId}`);
};

const getVendorKey = (companyId: string, vendorId: string) => [
  'vendor',
  vendorId,
  companyId,
];
