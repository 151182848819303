import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportDataTable } from 'features/ExportDataTable';
import { PersonnelTable } from 'features/person/PersonnelTable';

import { SearchBar } from 'widgets/SearchBar';

import { FlexWrapper } from 'shared/components';
import useStore from 'shared/store';
import { useGetParsedQueryParams } from 'shared/utils';
import { buildUrl } from 'shared/utils/httpClient';
import { ISort } from 'shared/types';
import { SwitchInactive } from 'features/SwitchInactive';
import { lngs } from 'features/LanguageSwitcher/LanguageSwitcher';
import { ImportUploadTable } from 'widgets/ImportUploadTable';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

const limit = 10;

export const PersonnelList = () => {
  const { t } = useTranslation(['personnelList', 'common']);
  const company = useStore((state) => state.company);

  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<ISort[]>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [showInactive, setShowInactive] = useState(false);
  const [queryFields, setQueryFields] = useState<string[]>([]);
  const filterActive = !showInactive ? 'true' : '';
  const { createPersonnelPrivilege } = LayoutPrivilege();
  const [isImported, setIsImported] = useState(false);

  const { i18n } = useTranslation();
  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;

  const personnelFieldsTranslation =
    // eslint-disable-next-line max-len
    'fields=name.firstname$Apellido,name.lastname$Primer nombre,login$Acceso,active$Activo';

  const queryPageData = useGetParsedQueryParams({
    sort,
    offset,
    limit,
    fields: queryFields,
    filters: globalFilter ? globalFilter : filterActive,
    globalFilter,
  });

  const queryAllData = useGetParsedQueryParams({
    sort,
    filters: globalFilter ? globalFilter : filterActive,
    fields: queryFields,
    globalFilter,
  });

  const downloadAllDataFileUrl = buildUrl(
    'users',
    `companyID=${company?._id}&${queryAllData}`,
  );
  const downloadPageDataFileUrl = buildUrl(
    'users',
    `companyID=${company?._id}&${queryPageData}`,
  );
  return (
    <>
      <SearchBar
        title={t('Personnel List')}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <SwitchInactive
        label={t('Show inactive Personnel')}
        switchId="inactive-personnel"
        setShowInactive={setShowInactive}
      />
      {company?._id && (
        <PersonnelTable
          setQueryFields={setQueryFields}
          isImported={isImported}
          companyId={company._id}
          showInactive={showInactive}
          onSortChange={(sort) => setSort(sort)}
          queryParams={{
            limit: limit,
            offset: offset,
            sort: sort,
            filters: globalFilter,
            fields: queryFields,
          }}
          setOffset={(value) => setOffset(value)}
        />
      )}
      <FlexWrapper>
        <ExportDataTable
          fileUrl={downloadAllDataFileUrl}
          fileName={t('Personnel List')}
          title={t('Export All Data', { ns: 'common' })}
        />
        <ExportDataTable
          fileUrl={downloadPageDataFileUrl}
          fileName={t('Personnel List')}
          title={t('Export Page Data', { ns: 'common' })}
        />
      </FlexWrapper>
      {createPersonnelPrivilege && (
        <FlexWrapper>
          <ImportUploadTable
            setIsImported={setIsImported}
            title={t('Import Personnel From Excel')}
            companyId={company?._id || ''}
            entityName="personnel"
          />
        </FlexWrapper>
      )}
    </>
  );
};
