import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';
import { ActivityFinalType } from 'entities/tracking/model';

export const useSaveActivity = (companyId: string) => {
  return useMutation((data: ActivityFinalType) => {
    return saveActivity(data, companyId);
  });
};

const saveActivity = (formData: ActivityFinalType, companyId: string) => {
  return httpClient.post<ActivityFinalType, AxiosResponse<{ _id: string }>>(
    `activities?companyID=${companyId}`,
    formData,
  );
};
