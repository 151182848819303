import { useEffect, useMemo } from 'react';
import shallow from 'zustand/shallow';

import {
  createTableStore,
  Table,
  TableBody,
  TableBodyCell,
  TableHead,
  TableHeadCell,
  TableHeaderText,
  TableHeadSortableCell,
  TableRow,
} from 'shared/components/Table';
import { Pagination } from 'shared/components/Pagination';
import { Checkbox } from 'shared/components';
import { IList, ISort } from 'shared/types';

import { EmptyStateTableRow } from 'features/EmptyStateTableRow';
import { useCheckboxFilter } from 'features/CheckboxListFilter/utils';

import { ICustomer } from 'entities/customer/model';

import { useCustomerTrackingColumnsConfiguration } from './utils';
import { CustomerTrackingTableRow } from './CustomerTrackingTableRow';

const useCustomerTrackingTableState = createTableStore();

interface IProps {
  onSortChange: (sort: ISort[]) => void;
  isLoading: boolean;
  offset: number;
  setOffset: (value: number) => void;
  customerList?: IList<ICustomer[]>;
  limit: number;
  customersIDs?: string[];
}

const CustomerTrackingTable = ({
  onSortChange,
  isLoading,
  customerList,
  customersIDs,
  offset,
  setOffset,
  limit,
}: IProps) => {
  const columnsConfiguration = useCustomerTrackingColumnsConfiguration();
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);

  const tableState = useCustomerTrackingTableState((state) => state, shallow);

  const { isAllSelected, switchAllCheckboxes } = useCheckboxFilter(
    customerList?.data || [],
    'customers',
  );

  useEffect(() => {
    if (customerList && customerList?.total <= 10) {
      setOffset(0);
    }
  }, [customerList?.total]);

  return (
    <Table
      data={tableState}
      columnsConfiguration={memoizedColumnsConfiguration}
      onSortChange={onSortChange}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map(({ title, isSortable, name }) => {
            if (name === 'checkbox') {
              return (
                <TableHeadCell
                  key={name}
                  textAlign="center"
                >
                  <Checkbox
                    size="lg"
                    onChange={() => switchAllCheckboxes(!isAllSelected)}
                    isChecked={isAllSelected}
                  />
                </TableHeadCell>
              );
            }
            return isSortable ? (
              <TableHeadSortableCell
                key={name}
                columnName={name}
              >
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadSortableCell>
            ) : (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>

      <TableBody isLoading={isLoading}>
        {customerList && customerList.total > 0 ? (
          <>
            {customerList.data.map((customer) => {
              if (customersIDs) {
                return (
                  <CustomerTrackingTableRow
                    key={customer._id}
                    customer={customer}
                  />
                );
              }
              return (
                <CustomerTrackingTableRow
                  key={customer._id}
                  customer={customer}
                />
              );
            })}
            <TableRow>
              <TableBodyCell
                colSpan={columnsConfiguration.length}
                p="0"
              >
                <Pagination
                  totalItems={customerList.total}
                  onPageChange={({ selected }) => setOffset(limit * selected)}
                  forcePage={offset / limit}
                />
              </TableBodyCell>
            </TableRow>
          </>
        ) : (
          <EmptyStateTableRow colSpan={columnsConfiguration.length} />
        )}
      </TableBody>
    </Table>
  );
};

export { CustomerTrackingTable };
