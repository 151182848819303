import { EmailToSelectField } from './EmailToSelectField';
import { useContainerProps } from '../../../FormCommons';
import {
  Form,
  FormLabel,
  TextareaField,
  TextField,
} from '../../../../shared/components';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';
import { IEmailPayload } from '../../../../entities/email/model';

interface IProps {
  companyId: string;
  methods: UseFormReturn<IEmailPayload>;
  formId: string;
}

const TrackingEmailModalForm = ({ companyId, methods, formId }: IProps) => {
  const containerStyleProps = useContainerProps();
  const { t } = useTranslation('commonFields');

  return (
    <Form<IEmailPayload>
      onSubmit={() => true}
      id={formId}
      containerProps={{ ...containerStyleProps, width: '100%' }}
      methods={methods}
    >
      <EmailToSelectField companyId={companyId} />

      <Flex
        flexDir="column"
        gap="5px"
      >
        <FormLabel
          size="md"
          lineHeight="18px"
          display="flex"
          margin="0"
          alignItems="center"
        >
          {t('Title')}
        </FormLabel>
        <TextField name="title" />
      </Flex>

      <Flex
        flexDir="column"
        gap="5px"
      >
        <FormLabel
          size="md"
          lineHeight="18px"
          display="flex"
          margin="0"
          alignItems="center"
        >
          {t('Message')}
        </FormLabel>
        <TextareaField
          name="message"
          minH="200px"
        />
      </Flex>
    </Form>
  );
};

export { TrackingEmailModalForm };
