import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';
import { DateObject } from 'shared/utils';

interface IProps {
  trackingDate?: string;
}

const TrackingTimeViewRow = ({ trackingDate }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Tracking Time')}>
      <Text>{DateObject(trackingDate).format('hh:mm a')}</Text>
    </ViewRow>
  );
};

export { TrackingTimeViewRow };
