import { Privileges } from 'entities/role/model';
import { useGetMyUser } from 'entities/user/api';

const LayoutPrivilege = () => {
  const { data: myUserData } = useGetMyUser(true);
  // FULL
  const fullSuperAdminPrivilege = myUserData?.data.roles.some((role) => {
    return role.privileges.includes(Privileges.FULL);
  });

  // FULL & FULL OVER COMPANY
  const fullAndFullOverCompany = myUserData?.data.roles.some(
    (role) =>
      role.privileges.includes(Privileges.FULL) ||
      role.privileges.includes(Privileges.FULL_OVER_COMPANY),
  );
  // COMPANY
  const updateCompanyPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.COMPANY_UPDATE)
    );
  });

  // PERSONNEL
  const createPersonnelPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.USERS_CREATE)
    );
  });
  const deletePersonnelPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.USERS_DELETE)
    );
  });
  const updatePersonnelPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.USERS_UPDATE) ||
      role.privileges.includes(Privileges.USERS_DELETE)
    );
  });

  // ROLES
  const createRolePrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ROLES_CREATE)
    );
  });
  const deleteRolePrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ROLES_DELETE)
    );
  });
  const updateRolePrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ROLES_UPDATE)
    );
  });
  const assignRolePrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ROLES_ASSIGN)
    );
  });

  // LOCATIONS
  const createLocationPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.LOCATIONS_CREATE)
    );
  });
  const deleteLocationPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.LOCATIONS_DELETE)
    );
  });
  const updateLocationPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.LOCATIONS_UPDATE)
    );
  });
  // DEPARTMENTS
  const createDepartmentPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.DEPARTMENTS_CREATE)
    );
  });
  const deleteDepartmentPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.DEPARTMENTS_DELETE)
    );
  });
  const updateDepartmentPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.DEPARTMENTS_UPDATE)
    );
  });

  // CUSTOMER
  const createCustomerPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.CUSTOMERS_CREATE)
    );
  });
  const deleteCustomerPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.CUSTOMERS_DELETE)
    );
  });
  const updateCustomerPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.CUSTOMERS_UPDATE)
    );
  });

  // VENDOR
  const createVendorPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.VENDORS_CREATE)
    );
  });
  const deleteVendorPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.VENDORS_DELETE)
    );
  });
  const updateVendorPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.VENDORS_UPDATE)
    );
  });

  // ACTIONS
  const createActionPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ACTIONS_CREATE)
    );
  });
  const deleteActionPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ACTIONS_DELETE)
    );
  });
  const updateActionPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ACTIONS_UPDATE)
    );
  });

  // ACTIVITIES
  const createActivityPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ACTIVITIES_CREATE)
    );
  });
  const deleteActivityPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ACTIVITIES_DELETE)
    );
  });
  const updateActivityPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.ACTIVITIES_UPDATE)
    );
  });

  // TOPICS
  const createTopicPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.TOPICS_CREATE)
    );
  });
  const deleteTopicPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.TOPICS_DELETE)
    );
  });
  const updateTopicPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.TOPICS_UPDATE)
    );
  });

  // TRACKINGS
  const createTrackingPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.TRACKINGS_CREATE)
    );
  });
  const deleteTrackingPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.TRACKINGS_DELETE)
    );
  });
  const updateTrackingPrivilege = myUserData?.data.roles.some((role) => {
    return (
      fullAndFullOverCompany ||
      role.privileges.includes(Privileges.TRACKINGS_UPDATE)
    );
  });
  // SMS
  const smsPrivilege = myUserData?.data.roles.some((role) => {
    return fullAndFullOverCompany || role.privileges.includes(Privileges.SMS);
  });
  // EMAIL
  const emailPrivilege = myUserData?.data.roles.some((role) => {
    return fullAndFullOverCompany || role.privileges.includes(Privileges.EMAIL);
  });

  return {
    fullSuperAdminPrivilege,
    updateCompanyPrivilege,
    createTrackingPrivilege,
    deleteTrackingPrivilege,
    updateTrackingPrivilege,
    smsPrivilege,
    emailPrivilege,
    createTopicPrivilege,
    deleteTopicPrivilege,
    updateTopicPrivilege,
    createActivityPrivilege,
    deleteActivityPrivilege,
    updateActivityPrivilege,
    createActionPrivilege,
    deleteActionPrivilege,
    updateActionPrivilege,
    fullAndFullOverCompany,
    createPersonnelPrivilege,
    deletePersonnelPrivilege,
    updatePersonnelPrivilege,
    createCustomerPrivilege,
    deleteCustomerPrivilege,
    updateCustomerPrivilege,
    createVendorPrivilege,
    deleteVendorPrivilege,
    updateVendorPrivilege,
    createRolePrivilege,
    deleteRolePrivilege,
    updateRolePrivilege,
    assignRolePrivilege,
    createLocationPrivilege,
    deleteLocationPrivilege,
    updateLocationPrivilege,
    createDepartmentPrivilege,
    deleteDepartmentPrivilege,
    updateDepartmentPrivilege,
  };
};

export { LayoutPrivilege };
