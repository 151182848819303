import { useTranslation } from 'react-i18next';

import { Text } from 'shared/components';
import { ICredits } from 'entities/common/model';
import { DateObject } from 'shared/utils';
import { useGetUsers } from 'entities/user/api';
import useStore from 'shared/store';

interface IProps {
  dates?: ICredits;
}

const CreationDetails = ({ dates }: IProps) => {
  if (!dates) {
    return null;
  }

  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  const { t } = useTranslation('creationDetails');
  const { data } = useGetUsers(companyId, undefined, {
    useErrorBoundary: false,
  });

  const createdByUserName = data?.data.data
    .filter((user) => dates?.createdByID?.includes(user._id))
    .map(({ name }) => {
      const firstName = name.firstname !== undefined ? name.firstname : '';
      const lastName = name.lastname !== undefined ? name.lastname : '';
      return `${firstName} ${lastName}`;
    })
    .join('');
  const updatedByUserName = data?.data.data
    .filter((user) => dates?.updatedByID?.includes(user._id))
    .map(({ name }) => {
      const firstName = name.firstname !== undefined ? name.firstname : '';
      const lastName = name.lastname !== undefined ? name.lastname : '';
      return `${firstName} ${lastName}`;
    })
    .join('');

  return (
    <Text
      px="16px"
      py="10px"
      borderWidth="2px"
      borderColor="secondary.500"
      borderRadius="20px"
      w="100%"
    >
      {`
      ${
        dates?.createdAt
          ? `${t('Created date')}: ${DateObject(dates?.createdAt)
              .getDate()
              .concat(' ', DateObject(dates?.createdAt).getTime())} `
          : ''
      } 
      ${dates?.createdByID ? `${t('Created by')}: ${createdByUserName} ` : ''} 
      ${
        dates?.updatedAt
          ? `${t('Modified date')}: ${DateObject(dates?.updatedAt)
              .getDate()
              .concat(' ', DateObject(dates?.updatedAt).getTime())} `
          : ''
      } 
      ${dates?.updatedByID ? `${t('Modified by')}: ${updatedByUserName} ` : ''} 
      `}
    </Text>
  );
};

export { CreationDetails };
