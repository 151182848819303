import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  email?: string;
}

const EmailViewRow = ({ email }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Email')}>
      <Text>{email || '-'}</Text>
    </ViewRow>
  );
};

export { EmailViewRow };
