import { DeleteCustomer } from 'features/customer/DeleteCustomer';
import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';

import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  customerId: string;
  active?: boolean;
}

const CustomerActionButtons = ({ customerId, companyId, active }: IProps) => {
  const {
    createCustomerPrivilege,
    deleteCustomerPrivilege,
    updateCustomerPrivilege,
  } = LayoutPrivilege();

  return (
    <FlexWrapper>
      {createCustomerPrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.customer.add.getPath()} />
      )}
      {active && deleteCustomerPrivilege && (
        <DeleteCustomer
          companyId={companyId}
          customerId={customerId}
        />
      )}
      {updateCustomerPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.customer.edit.getPath(customerId)}
        />
      )}
    </FlexWrapper>
  );
};

export { CustomerActionButtons };
