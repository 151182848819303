import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  firstName?: string;
  lastName?: string;
}

const TrackedByViewRow = ({ firstName, lastName }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Tracked By')}>
      <Text>
        {firstName || lastName ? `${firstName || ''} ${lastName || ''} ` : '-'}
      </Text>
    </ViewRow>
  );
};

export { TrackedByViewRow };
