import { Icon, IconProps } from 'shared/components';

const ErrorIcon = (props: IconProps) => (
  <Icon
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="28.5"
      cy="28.5"
      r="26.5"
      stroke="#D92626"
      strokeWidth="3"
    />
    <path
      d="M19 18L39 38"
      stroke="#D92626"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39 18L19 38"
      stroke="#D92626"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export { ErrorIcon };
