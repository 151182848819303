import { Text, TextProps } from 'shared/components/Text';

type IProps = TextProps;

const RedText = ({ children, ...restProps }: IProps) => {
  return (
    <Text
      {...restProps}
      color="red.500"
    >
      {children}
    </Text>
  );
};

export { RedText };
