import { useTranslation } from 'react-i18next';

import { Button, ButtonProps } from 'shared/components';
import { MessageIcon } from 'shared/icons';

const SendSMSButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');

  //@todo: Send SMS logic maybe

  return (
    <Button
      type="submit"
      rightIcon={<MessageIcon boxSize="30px" />}
      {...props}
    >
      {t('Send')}
    </Button>
  );
};

export { SendSMSButton };
