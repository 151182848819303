import { IRole, Privileges, RoleFormType } from '../../entities/role/model';

const convertToRoleFormData = (role: Partial<IRole>): RoleFormType => {
  return {
    name: role?.name || '',
    active: role?.active || false,
    privilegesObject: role?.privileges?.length
      ? role.privileges
          .filter((privilege) => privilege !== Privileges.FULL)
          .reduce((acc, privilege) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            acc[privilege] = { _id: privilege, value: true };
            return acc;
          }, {})
      : {},
  };
};

export { convertToRoleFormData };
