import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  name?: string;
}

const CompanyNameViewRow = ({ name }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Company Name')}>
      <Text>{name || '-'}</Text>
    </ViewRow>
  );
};

export { CompanyNameViewRow };
