import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { ErrorBoundary, Select, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';
import { useGetTopics } from 'entities/tracking/api';

interface IProps {
  companyId: string;
  name?: string;
}

type OptionType = { value: string; label: string };

const defaultName = 'topics';

const TopicSelectFieldThrowable = ({
  companyId,
  name = defaultName,
}: IProps) => {
  const { t } = useTranslation('commonFields');
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();
  const { isLoading, data } = useGetTopics(companyId);

  const options = [
    { value: null, label: t('By Topic') },
    ...(data
      ? data.data.data.map((topic) => {
          return {
            value: topic._id,
            label: topic.name,
          };
        })
      : []),
  ];

  const value = watch(name);

  if (!options) {
    return null;
  }

  return (
    <FormField label={`${t('Topic')}:`}>
      <Select
        data-testid={name}
        inputId={name}
        isDisabled={isSubmitting}
        isSearchable={false}
        value={options?.find(
          (option) => (option as OptionType).value === value,
        )}
        onChange={(value) => setValue(name, (value as OptionType).value)}
        options={options}
        isLoading={isLoading}
        placeholder={t('Select one')}
      />
    </FormField>
  );
};

const TopicSelectField = ({ companyId, name }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Topic')}:`}>
          <SelectField name={name || defaultName} />
        </FormField>
      )}
    >
      <TopicSelectFieldThrowable
        companyId={companyId}
        name={name}
      />
    </ErrorBoundary>
  );
};

export { TopicSelectField };
