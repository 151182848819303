import React from 'react';
import ReactDOM from 'react-dom/client';
import { ColorModeScript } from '@chakra-ui/react';

import { App } from 'app/App';
import reportWebVitals from './reportWebVitals';
import 'shared/utils/i18n';
import { isMockedApi } from 'shared/utils';
import { registerLocales } from 'shared/utils/registerLocales';

if (isMockedApi()) {
  (async () => {
    const { browserWorker } = await import('shared/utils/msw');
    browserWorker()?.start();
  })();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

registerLocales();

root.render(
  <React.StrictMode>
    <React.Suspense>
      <ColorModeScript initialColorMode={'light'} />
      <App />
    </React.Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
