import { useGetMyUser } from 'entities/user/api';
import { Privileges } from 'entities/role/model';
import { ViewCompanyPage } from '../ViewCompanyPage';
import useStore from 'shared/store';
import { CompanyList } from './CompanyList';

const CompanyListPage = () => {
  const { data: myUserData } = useGetMyUser(true);
  const companyId = useStore((state) => state.company?._id);
  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const isSuperAdmin = myUserData?.data.roles.some((role) =>
    role.privileges.includes(Privileges.FULL),
  );

  return !isSuperAdmin ? (
    <ViewCompanyPage companyID={companyId} />
  ) : (
    <CompanyList isSuperAdmin={isSuperAdmin} />
  );
};

export { CompanyListPage };
