import { useTranslation } from 'react-i18next';

export const useCompaniesColumnsConfiguration = () => {
  const { t } = useTranslation(['commonFields', 'companiesList']);
  return [
    {
      name: 'login-abbreviation',
      sortedFields: ['login', 'abbreviation'],
      title: t('Login Code - Abbreviation', { ns: 'companiesList' }),
      isSortable: true,
    },
    {
      name: 'name',
      sortedFields: ['name'],
      title: t('Name'),
      isSortable: true,
    },
    {
      name: 'naisc-sic',
      sortedFields: ['code.naisc', 'code.sic'],
      title: t('NAISC Code - SIC Code', { ns: 'companiesList' }),
      isSortable: false,
    },
    {
      name: 'address',
      sortedFields: [
        'address.street',
        'address.streetNumber',
        'address.city',
        'address.state',
        'address.zip',
      ],
      title: t('Address'),
      isSortable: true,
    },
    {
      name: 'phone',
      sortedFields: ['phoneNumber'],
      title: t('Phone'),
      isSortable: true,
    },
    {
      name: 'counts',
      sortedFields: [],
      title: t('Counts', { ns: 'companiesList' }),
      isSortable: false,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active'),
      isSortable: true,
    },
  ];
};
