import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { AttachmentsTable } from 'features/AttachmentsTable';
import { FileUpload } from 'features/FileUpload';
import { Flex, SingleTableWrapper, Text } from 'shared/components';
import useStore from 'shared/store';
import { useToast } from '../../shared/utils';

interface IProps {
  isLoading: boolean;
  mutateAsync: UseMutateAsyncFunction<
    AxiosResponse<{
      _id: string;
    }>,
    unknown,
    FormData
  >;
  entityName: 'activities' | 'trackings';
  formId: string;
  attachments: { _id: string; filename: string }[];
}

export interface IFileToConnect {
  parent: string;
  name: string;
  _id: string;
}

const limit = 10;

const DocumentationSection = ({
  mutateAsync,
  isLoading,
  formId,
  entityName,
  attachments,
}: IProps) => {
  const { t } = useTranslation(['common', 'trackingForm', 'popupMessage']);
  const { register } = useForm();
  const companyId = useStore((state) => state.company?._id);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);
  const { successToast, errorToast } = useToast();

  const [offset, setOffset] = useState(0);

  // const isLoading = false;
  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFile = e.target.files[0];
      if (!newFile) {
        return;
      }

      const formData = new FormData();
      formData.append('File', newFile, newFile.name);

      try {
        const {
          data: { _id },
        } = await mutateAsync(formData);

        setFilesToConnect([
          ...filesToConnect,
          { _id, name: newFile.name, parent: formId },
        ]);
        successToast({
          message: t('Successfully added item message', {
            item: t('attachment'),
            ns: 'popupMessage',
          }),
        });
      } catch (e) {
        errorToast({
          message: t('Error while adding item message', {
            item: t('attachment'),
            ns: 'popupMessage',
          }),
        });
      }
    }
  };

  const handleUploadClick = async () => {
    return;
  };

  useEffect(() => {
    if (attachments?.length) {
      setFilesToConnect(
        attachments.map((attachment) => ({
          _id: attachment._id,
          name: attachment.filename,
          parent: formId,
        })),
      );
    }
  }, [attachments, formId, setFilesToConnect]);

  return (
    <SingleTableWrapper title={t('Documentation')}>
      <Flex
        flexDir={{ base: 'column', md: 'row', lg: 'row' }}
        gap={{ base: '10px', md: '20px' }}
        justifyContent={{ md: 'space-between' }}
        w="100%"
        mb={{ base: '10px', md: '20px' }}
      >
        <Text maxW={{ xl: '80%' }}>
          {t(
            'Click Upload to add other documents, pictures, videos, forms etc.',
            { ns: 'trackingForm' },
          )}
        </Text>
        <FileUpload
          register={register('file_')}
          handleUploadClick={handleUploadClick}
          handleFileChange={handleFileChange}
          justifyContent="end"
        />
      </Flex>
      <AttachmentsTable
        isLoading={isLoading}
        limit={limit}
        offset={offset}
        setOffset={(value) => setOffset(value)}
        attachmentList={{
          data: filesToConnect.filter((file) => file.parent === formId),
          total: filesToConnect.filter((file) => file.parent === formId).length,
        }}
        companyId={companyId}
        entityName={entityName}
      />
    </SingleTableWrapper>
  );
};

export { DocumentationSection };
