import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { LoginForm } from 'features/LoginForm';
import { Center, Container, MessagePopups } from 'shared/components';
import { MessageTypeKeys } from 'shared/components/MessagePopups/MessagePopups';
import { useMyUserData } from 'shared/hooks/auth';
import { useLocation } from 'react-router-dom';

const containerWidth = 506;
const popupMessages = {
  success: 'You have successfully logged in',
  attention: 'Check the correctness of the entered data',
  error: 'Invalid credentials. Please login again.',
};

type PopupType = MessageTypeKeys | false;

const LoginPage = () => {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const { refetch } = useMyUserData();
  const [popup, setPopup] = useState<PopupType>(false);

  const onSuccess = async () => {
    await refetch();
    const redirect = new URLSearchParams(search).get('redirect');
    if(redirect) {
      if (new URL(redirect).host === window.location.host) window.location.href = redirect;
      else window.open(redirect, '_blank');
    }
    setPopup('success');
  };

  const onError = () => {
    setPopup('error');
  };

  const onMessagePopupsClose = () => {
    setPopup(false);
  };

  useEffect(() => {
    queryClient.clear();
  }, []);

  return (
    <Container
      maxW={containerWidth}
      p={{ base: '15px', xl: '30px' }}
      position="relative"
    >
      {popup && (
        <Center
          position={{ lg: 'absolute' }}
          left={{ lg: containerWidth }}
          mb="40px"
        >
          <MessagePopups
            type={popup}
            message={popup ? popupMessages[popup] : ''}
            onClose={onMessagePopupsClose}
          />
        </Center>
      )}
      <LoginForm
        onSuccess={onSuccess}
        onError={onError}
      />
    </Container>
  );
};

export { LoginPage };
