import { Icon, IconProps } from 'shared/components';

export enum Orientation {
  'Left' = 'left',
  'Right' = 'right',
}

interface IProps extends IconProps {
  orientation?: Orientation;
}

const ArrowIcon = ({
  orientation = Orientation.Left,
  ...restProps
}: IProps) => {
  return (
    <Icon
      w="13px"
      h="12px"
      viewBox="0 0 13 12"
      xmlns="http://www.w3.org/2000/svg"
      {...(orientation === Orientation.Right && {
        transform: 'rotate(180deg)',
      })}
      {...restProps}
    >
      <path
        d="M4.40717 5.00002L7.70427 1.70291L6.29006 0.288696L0.578735 6.00002L6.29006 11.7113L7.70427 10.2971L4.40716 7.00002H13V5.00002H4.40717Z"
        fill="#5F646A"
      />
    </Icon>
  );
};

export { ArrowIcon };
