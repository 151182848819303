import { useTranslation } from 'react-i18next';

export const useSupervisorColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'checkbox',
      sortedFields: [],
      title: '',
      isSortable: false,
    },
    {
      name: 'firstName',
      sortedFields: ['firstName'],
      title: t('First Name'),
      isSortable: false,
    },
    {
      name: 'lastName',
      sortedFields: ['lastName'],
      title: t('Last Name'),
      isSortable: false,
    },
  ];
};
