import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { CompanyFormType } from 'entities/company/model';

export const useUpdateCompany = (companyId: string) => {
  return useMutation((data: CompanyFormType) => {
    return saveCompany(companyId, data);
  });
};

const saveCompany = (companyId: string, formData: CompanyFormType) => {
  return httpClient.patch<CompanyFormType>(`companies/${companyId}`, formData);
};
