import { countries } from 'entities/common/countries';
import { useTranslation } from 'react-i18next';

import { SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const CountryField = () => {
  const { t } = useTranslation('commonFields');

  const options = countries.map((country) => {
    return {
      value: country.abbreviation,
      label: country.name,
    };
  });

  return (
    <FormField label={`${t('Country')}:`}>
      <SelectField
        name="address.country"
        options={options}
        placeholder={t('Select one')}
      />
    </FormField>
  );
};

export { CountryField };
