import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';
import useStore from 'shared/store';
import { useGetTopic } from 'entities/tracking/api';
import { EditTopic } from 'widgets/Topic';

const EditTopicPageThrowable = () => {
  const { t } = useTranslation(['topics', 'common']);
  const companyId = useStore((state) => state.company?._id);
  const { topicId } = useParams();

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  if (!topicId) {
    throw new Error('TopicId not found');
  }

  const { isLoading, error, data } = useGetTopic(companyId, topicId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('Topic not found')}</Text>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { credits, _id, companyID, ...defaultValue } = data.data;

  return (
    <EditTopic
      companyId={companyId}
      topic={data.data}
    />
  );
};

export const EditTopicPage = () => {
  return (
    <ErrorBoundary>
      <EditTopicPageThrowable />
    </ErrorBoundary>
  );
};
