import { Dispatch, useEffect, useState } from 'react';

import { PlusIcon } from '../../shared/icons/PlusIcon';
import { Button, Checkbox, Text } from '../../shared/components';
import { Box, Flex } from '@chakra-ui/react';
import {
  FilterByDepartment,
  FilterByLocation,
  FilterByRole,
} from '../SupervisorTableFilters';
import { FilterBySupervisor } from './FilterBySupervisor';
import {
  FilterAction,
  FilterActionType,
  IFiltersState,
} from '../../widgets/EmployeeTracking/utils/attendeeListReducer';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
  filters: IFiltersState;
  dispatch: Dispatch<FilterAction>;
}

const AttendeeListReduce = ({ companyId, filters, dispatch }: IProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { t } = useTranslation('trackingForm');
  const { locationFilter, supervisorFilter, roleFilter, departmentFilter, active } =
    filters;
  useEffect(() => {
    dispatch({ type: FilterActionType.CLEAR_FILTERS });
  }, [isFilterOpen]);

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      gap="10px"
    >
      <Button
        leftIcon={<PlusIcon />}
        width="fit-content"
        onClick={() => setIsFilterOpen((prevState) => !prevState)}
      >
        {t('Attendee List Reduce (optional)')}
      </Button>
      {isFilterOpen && (
        <Flex
          w="100%"
          gap="20px"
          borderWidth="2px"
          borderColor="secondary.500"
          borderRadius="5px"
          flexDir="column"
          p="10px"
          overflowWrap="anywhere"
          boxSizing="border-box"
        >
          <Box w="100%">
            <Checkbox
              isChecked={!(active || false)}
              size="lg"
              onChange={(event) => {
                dispatch({
                  type: FilterActionType.MUTATE_FILTERS,
                  payload: { active: !(event.target.checked || false) as unknown as boolean },
                });
              }}
            >
              <Text>
                {t('Show inactive')}
              </Text>
            </Checkbox>
          </Box>
          <Box w="100%">
            <FilterByLocation
              companyId={companyId}
              locationFilter={locationFilter}
              onChange={(value) => {
                dispatch({
                  type: FilterActionType.MUTATE_FILTERS,
                  payload: { locationFilter: value },
                });
              }}
            />
          </Box>

          <Box w="100%">
            <FilterByDepartment
              companyId={companyId}
              department={departmentFilter}
              onChange={(value) => {
                dispatch({
                  type: FilterActionType.MUTATE_FILTERS,
                  payload: { departmentFilter: value },
                });
              }}
            />
          </Box>

          <Box w="100%">
            <FilterBySupervisor
              companyId={companyId}
              supervisorFilter={supervisorFilter}
              onChange={(value) => {
                dispatch({
                  type: FilterActionType.MUTATE_FILTERS,
                  payload: { supervisorFilter: value },
                });
              }}
            />
          </Box>

          <Box w="100%">
            <FilterByRole
              companyId={companyId}
              onChange={(value) => {
                dispatch({
                  type: FilterActionType.MUTATE_FILTERS,
                  payload: { roleFilter: value },
                });
              }}
              role={roleFilter}
            />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export { AttendeeListReduce };
