import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { usePhoneBasicValidation } from 'shared/utils/yup';

export const useCompanyFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');
  const phoneValidationSchema = usePhoneBasicValidation();

  return object({
    name: string().required(requiredFieldMessage).nullable(),
    phoneNumber: phoneValidationSchema.required(requiredFieldMessage),
    fax: phoneValidationSchema,
    login: string()
      .min(2, t('Login must be at least 2 characters long'))
      .required(requiredFieldMessage)
      .nullable(),
  });
};
