import { useTranslation } from 'react-i18next';
import { ViewRow, Text } from 'shared/components';
import { ISupervisor } from 'entities/user/model';

interface IProps {
  supervisor?: ISupervisor;
}

const SupervisorViewRow = ({ supervisor }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Supervisor')}>
      <Text>{supervisor && supervisor.name ?
        `${supervisor.name.firstname} ${supervisor.name.lastname}` :
        '-'
      }</Text>
    </ViewRow>
  );
};

export { SupervisorViewRow };
