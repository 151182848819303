import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const ContactPersonNumber = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t("Contact Person's Number")}:`}>
      <TextField name="contactPerson.phoneNumber" />
    </FormField>
  );
};

export { ContactPersonNumber };
