import { useTranslation } from 'react-i18next';

import {
  FormControlProps,
  ErrorBoundary,
  SelectField,
} from 'shared/components';
import { FormField } from 'shared/components/Form';

import { useGetAgencies } from 'entities/agency/api';

interface IProps extends FormControlProps {
  companyId: string;
}

const AgencyFieldThrowable = ({ companyId, ...restProps }: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetAgencies(
    companyId,
    {
      sort: [{ name: 'name', sortDirection: 'asc' }],
    },
    { useErrorBoundary: false },
  );

  const options = data?.data.data.map((agency) => {
    return {
      value: agency._id,
      label: agency.name,
    };
  });

  return (
    <FormField
      label={`${t('Agency')}:`}
      {...restProps}
    >
      <SelectField
        name="agencyID"
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
      />
    </FormField>
  );
};

const AgencyField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('Agency')}:`}>
          <SelectField
            name="agencyID"
            isDisabled
          />
        </FormField>
      )}
    >
      <AgencyFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { AgencyField };
