import { useTranslation } from 'react-i18next';

import { ChildrenProp } from 'shared/types';
import { SingleTableWrapper } from 'shared/components';

type Props = ChildrenProp;

const SupervisorTableFiltersContainer = ({ children }: Props) => {
  const { t } = useTranslation('common');

  return (
    <SingleTableWrapper
      title={t('Filters')}
      w="100%"
      borderRadius="5px"
      overflow="none"
    >
      {children}
    </SingleTableWrapper>
  );
};

export { SupervisorTableFiltersContainer };
