import { httpClient } from 'shared/utils/httpClient';
import { IAction } from '../model';
import { useReactQuery } from 'shared/hooks/reactQuery';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from '../../../shared/utils/httpClient';

export const useGetAction = (
  companyId: string,
  actionId: string,
  options?: UseQueryOptions<AxiosResponse<IAction>, AxiosError>,
) => {
  return useReactQuery<IAction>(
    getActionsKey(companyId, actionId),
    () => getAction(companyId, actionId),
    options,
  );
};

const getAction = (companyId: string, actionId: string) => {
  return httpClient.get<IAction>(`actions/${actionId}?companyID=${companyId}`);
};

const getActionsKey = (companyId: string, actionId: string) => [
  'action',
  companyId,
  actionId,
];
