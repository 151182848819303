import {
  compareDesc,
  format as formatFns,
  toDate as toDateFns,
  isPast as isPastFns,
  isFuture as isFutureFns,
  isAfter as isAfterFns,
  isBefore as isBeforeFns,
  getHours as getHoursFns,
  setHours as setHoursFns,
  setMinutes as setMinutesFns,
} from 'date-fns';

export const DateObject = (
  date: Date | number | string = toDateFns(new Date()),
) => {
  const format = (
    format = 'MM/dd/yyyy',
    options?: {
      locale?: Locale;
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
      firstWeekContainsDate?: number;
      useAdditionalWeekYearTokens?: boolean;
      useAdditionalDayOfYearTokens?: boolean;
    },
  ) => {
    return formatFns(new Date(date), format, options);
  };

  const getFullDateTimeFormat = () => {
    const format = 'MM/dd/yyyy kk:mm:ss';
    return formatFns(new Date(date), format);
  };

  const isPast = () => {
    return isPastFns(new Date(date));
  };

  const isFuture = () => {
    return isFutureFns(new Date(date));
  };

  const isEqual = (comparedDate: Date) => {
    return compareDesc(new Date(date), comparedDate) === 0;
  };

  const getTime = () => {
    const format = 'H:mm:ss';
    return formatFns(new Date(date), format);
  };

  const getDate = () => {
    const format = 'MM/dd/yyyy';
    return formatFns(new Date(date), format);
  };

  const toNumber = () => {
    return new Date(date).getTime();
  };

  const isAfter = (comparedDate: Date) => {
    return isAfterFns(new Date(date), new Date(comparedDate));
  };

  const isBefore = (comparedDate: Date) => {
    return isBeforeFns(new Date(date), new Date(comparedDate));
  };

  const getHours = () => {
    return getHoursFns(new Date(date));
  };

  const setHours = (hours: number) => {
    return DateObject(setHoursFns(new Date(date), hours));
  };

  const decreaseHours = (hours: number) => {
    return DateObject(setHoursFns(new Date(date), getHours() - hours));
  };

  const increaseHours = (hours: number) => {
    return DateObject(setHoursFns(new Date(date), getHours() + hours));
  };

  const setMinutes = (hours: number) => {
    return DateObject(setMinutesFns(new Date(date), hours));
  };

  return {
    format,
    getFullDateTimeFormat,
    isEqual,
    getTime,
    getDate,
    toNumber,
    isPast,
    isFuture,
    isAfter,
    isBefore,
    setHours,
    setMinutes,
    increaseHours,
    decreaseHours,
  };
};

export type DateObjectType = ReturnType<typeof DateObject>;
