import { ReactNode } from 'react';

import { useLogoutUser } from 'entities/user/api/useLogoutUser';

type NavItemLogoutProps = {
  children: ReactNode;
};

export const NavItemLogout = ({ children }: NavItemLogoutProps) => {
  const logout = useLogoutUser();

  return (
    <span
      style={{ cursor: 'pointer' }}
      onClick={() => {
        logout.mutate();
      }}
    >
      {children}
    </span>
  );
};
