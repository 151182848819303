export const SaveIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.094 3.125a2.969 2.969 0 0 0-2.969 2.969v17.812c0 1.64 1.33 2.969 2.969 2.969h17.812c1.64 0 2.969-1.33 2.969-2.969V10.292c0-.787-.313-1.542-.87-2.099l-4.198-4.198a2.969 2.969 0 0 0-2.1-.87H6.095ZM5.5 5.5v4.75h14.25V5.5H5.5ZM15 22.125A3.563 3.563 0 1 0 15 15a3.563 3.563 0 0 0 0 7.125Z"
        fill="#72B42D"
      />
    </svg>
  );
};
