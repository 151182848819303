import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetMyUser } from 'entities/user/api';
import useStore from 'shared/store';
import { Privileges } from 'entities/role/model';
import { routeBuilder } from '../../utils';

const useMyUserData = () => {
  const navigation = useNavigate();
  const location = useLocation();

  const setIsLoggedIn = useStore((state) => state.setIsLoggedIn);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const setIsMFALoggedIn = useStore((state) => state.setIsMFALoggedIn);
  const setRoles = useStore((state) => state.setRoles);
  const setPrivileges = useStore((state) => state.setPrivileges);
  const privileges = useStore((state) => state.privileges);
  const setCompany = useStore((state) => state.setCompany);
  const company = useStore((state) => state.company);

  const {
    isLoading,
    data: myUserData,
    error,
    refetch,
  } = useGetMyUser(isLoggedIn);

  useEffect(() => {
    if (!isLoading) {
      setIsLoggedIn(myUserData !== undefined);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error?.response?.data?.message?.match(/multifactor/i)) {
        setIsMFALoggedIn(false);
      }
    }
  }, [isLoading, myUserData, error?.response?.data]);

  useEffect(() => {
    if (myUserData?.data) {
      const { roles: userRoles, company: userCompany } = myUserData.data;

      if (userRoles?.length) {
        setRoles(userRoles.map((role) => role.name));

        setPrivileges(
          userRoles.reduce<Privileges[]>((acc, role) => {
            return [...acc, ...role.privileges];
          }, []),
        );
        // setPrivileges([Privileges.ACTIONS_CREATE, Privileges.ACTIVITIES_CREATE]);
      }

      if (userCompany?._id && !company?._id) {
        // initial company set
        setCompany(userCompany);
      }
    }
  }, [myUserData]);

  useEffect(() => {
    if (isLoggedIn) {
      switch (location.pathname) {
        // redirect after login
        case routeBuilder.signIn.index.getPath():
          // super admin
          if (privileges.find((privilege) => privilege === Privileges.FULL)) {
            navigation(routeBuilder.tracking.add.getPath());
            break;
          }

          // company admin
          if (
            privileges.find(
              (privilege) => privilege === Privileges.FULL_OVER_COMPANY,
            )
          ) {
            navigation(routeBuilder.tracking.add.getPath());
            break;
          }

          // has tracking add privilege
          if (
            privileges.find(
              (privilege) => privilege === Privileges.TRACKINGS_CREATE,
            )
          ) {
            navigation(routeBuilder.tracking.add.getPath());
            break;
          }

          // has tracking read, update or delete privilege
          if (
            privileges.includes(Privileges.TRACKINGS_READ) ||
            privileges.includes(Privileges.TRACKINGS_UPDATE) ||
            privileges.includes(Privileges.TRACKINGS_DELETE)
          ) {
            navigation(routeBuilder.tracking.index.getPath());
            break;
          }

          navigation(routeBuilder.profile.index.getPath());
          break;
      }
    }
  }, [isLoggedIn, privileges]);

  return { isLoading, refetch };
};
export { useMyUserData };
