import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  fax?: string;
}

const FaxViewRow = ({ fax }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Fax')}>
      <Text>{fax || '-'}</Text>
    </ViewRow>
  );
};

export { FaxViewRow };
