import { useTranslation } from 'react-i18next';
import { Flex, Text } from 'shared/components';

const Page404 = () => {
  const { t } = useTranslation('common');
  return (
    <Flex
      flexDir="column"
      textAlign="center"
      height="100%"
      py={{ base: '50px', xl: '80px' }}
      gap={{ base: '10px', xl: '20px' }}
    >
      <Text
        mt={'auto'}
        size={{ base: '2xl', sm: '3xl', md: '4xl', xl: '5xl', '2xl': '5xl' }}
        fontWeight="bold"
        color="primary.500"
      >
        {t('PAGE NOT FOUND')}
      </Text>
      <Text size={{ base: 'xs', md: 'sm', xl: 'md' }}>
        {t('ERROR CODE: 404')}
      </Text>
    </Flex>
  );
};

export { Page404 };
