interface IProps {
  size?: number;
}

const BreadcrumbHomeIcon = ({ size = 20 }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12.5V19.5C3 20.0523 3.44772 20.5 4 20.5H8L8 14.5H12V20.5H16C16.5523 20.5 17 20.0523 17 19.5V12.5H18.9931C19.873 12.5 20.328 11.4125 19.726 10.7484L10.7329 0.828421C10.3359 0.390526 9.66409 0.390527 9.26712 0.828421L0.274044 10.7484C-0.328017 11.4125 0.126991 12.5 1.00693 12.5H3Z"
        fill="#006FCA"
      />
    </svg>
  );
};

export { BreadcrumbHomeIcon };
