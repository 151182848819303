import { Icon, IconProps } from 'shared/components';

const PlusIcon = (props: IconProps) => (
  <Icon
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2491_921)">
      <path
        d="M27.9395 12.4629H18.3828V2.56055C18.3828 1.14258 17.2344 -0.00585938 15.8164 -0.00585938C14.3984 -0.00585938 13.25 1.14258 13.25 2.56055V12.4629H3.05469C1.64844 12.4629 0.5 13.6113 0.5 15.0293C0.5 16.4473 1.64844 17.5957 3.06641 17.5957H13.2617V27.4395C13.2617 28.8574 14.4102 30.0059 15.8281 30.0059C17.2461 30.0059 18.3945 28.8574 18.3945 27.4395V17.5957H27.9512C29.3691 17.5957 30.5176 16.4473 30.5176 15.0293C30.5 13.6113 29.3574 12.4629 27.9395 12.4629Z"
        fill="#72B42D"
      />
    </g>
    <defs>
      <clipPath id="clip0_2491_921">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export { PlusIcon };
