import { useTranslation } from 'react-i18next';

import { Text } from 'shared/components';

interface IProps {
  translationKey: string;
}

const BreadcrumbTranslation = ({ translationKey }: IProps) => {
  const { t } = useTranslation('breadcrumbs');
  return (
    <Text
      color="black"
      marginInlineStart={0}
    >
      {t(translationKey)}
    </Text>
  );
};

export { BreadcrumbTranslation };
