import { IDescription } from 'entities/common/model';
import { lngs } from 'features/LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  description?: IDescription;
}

const TrackingDescriptionViewRow = ({ description }: IProps) => {
  const { t, i18n } = useTranslation('commonFields');

  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;

  const defaultContent = description?.translations
    .filter((lang) => lang.languageCode.includes('en'))
    .map((l) => l.content);

  const content =
    description?.translations.find((lang) =>
      lang.languageCode.includes(resolvedLng),
    )?.content || defaultContent;

  return (
    <ViewRow label={t('Description')}>
      <Text>{content || '-'}</Text>
    </ViewRow>
  );
};

export { TrackingDescriptionViewRow };
