import { Icon, IconProps } from 'shared/components';

const InfoIcon = (props: IconProps) => (
  <Icon
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    bgColor="#fff"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4177 2.5C24.654 2.5 27.5002 5.4725 27.5002 9.895V20.105C27.5002 24.5275 24.654 27.5 20.4152 27.5H9.58024C5.34524 27.5 2.50024 24.5275 2.50024 20.105V9.895C2.50024 5.4725 5.34524 2.5 9.58024 2.5H20.4177ZM20.4177 4.375H9.58024C6.41899 4.375 4.37524 6.54125 4.37524 9.895V20.105C4.37524 23.4587 6.41899 25.625 9.58024 25.625H20.4152C23.5802 25.625 25.6252 23.4587 25.6252 20.105V9.895C25.6252 6.54125 23.5802 4.375 20.4177 4.375ZM14.9927 14.0625C15.5102 14.0625 15.9302 14.4825 15.9302 15V20C15.9302 20.5175 15.5102 20.9375 14.9927 20.9375C14.4752 20.9375 14.0552 20.5175 14.0552 20V15C14.0552 14.4825 14.4752 14.0625 14.9927 14.0625ZM14.9989 9.00513C15.6901 9.00513 16.2489 9.56388 16.2489 10.2551C16.2489 10.9464 15.6901 11.5051 14.9989 11.5051C14.3076 11.5051 13.7426 10.9464 13.7426 10.2551C13.7426 9.56388 14.2964 9.00513 14.9864 9.00513H14.9989Z"
      fill="#72B42D"
    />
  </Icon>
);

export { InfoIcon };
