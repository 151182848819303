import { ReactNode, useEffect, useState } from 'react';

import { SaveButton } from 'features/SaveButton';
import { PasswordField, useContainerProps } from 'features/FormCommons';

import { Button, FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';

import {
  useChangeMyPasswordValidation,
  useChangePasswordValidation,
} from 'shared/utils/yup';
import { useForm } from 'shared/components/Form';
import { IChangePassword } from 'entities/user/model';
import { CancelIcon } from 'shared/icons';
import { useTranslation } from 'react-i18next';
import { routeBuilder } from 'shared/utils';
import { Link } from 'react-router-dom';
import { useGetMyUser } from 'entities/user/api';

type ChangePasswordFormProps = {
  handleSubmit: (
    data: IChangePassword,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  defaultValues?: IChangePassword;
  formId: string;
  additionalActionButtons?: ReactNode;
  userId: string;
};

const defaultData = {
  password: '',
};

const ChangePasswordForm = ({
  defaultValues = defaultData,
  formId,
  isLoading,
  additionalActionButtons,
  handleSubmit,
  userId,
}: ChangePasswordFormProps) => {
  const { t } = useTranslation('common');
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );

  const { data: myUserData } = useGetMyUser(true);

  const changeMyPassword = myUserData?.data._id === userId;

  const validationSchema = changeMyPassword
    ? useChangeMyPasswordValidation()
    : useChangePasswordValidation();

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
    persistenceProps: {
      exclude: ['password', 'repeatPassword'],
    },
  });

  useEffect(() => {
    if (!changeMyPassword) {
      methods.setValue('userID', userId);
    } else {
      methods.setValue('userID', undefined);
    }
  }, [changeMyPassword]);

  return (
    <Form<IChangePassword>
      onSubmit={(data, { reset }) =>
        handleSubmit(
          data,
          () => {
            reset();
            cleanPersistentStore();
          },
          submitType,
        )
      }
      id={formId}
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      {changeMyPassword && (
        <PasswordField
          isRequired
          label="Current Password"
          name={'oldPassword'}
        />
      )}
      <PasswordField
        label="New Password"
        isRequired
      />
      <PasswordField
        isRequired
        label="Repeat New Password"
        name="repeatPassword"
      />

      <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <Button
          as={Link}
          to={routeBuilder.personnel.view.getPath(userId)}
          leftIcon={<CancelIcon />}
        >
          {t('Cancel')}
        </Button>
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { ChangePasswordForm };
