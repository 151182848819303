import { Flex, FlexProps, ModalCloseButton, Text } from 'shared/components';
import { CloseIcon } from 'shared/icons';

interface Props extends FlexProps {
  title: string;
  isModal?: boolean;
  childContainerStyle?: FlexProps;
}

const SingleTableWrapper = ({
  title,
  isModal,
  childContainerStyle,
  ...props
}: Props) => {
  const borderRadius = props.borderRadius || '20px';

  return (
    <Flex
      flexDirection="column"
      borderStyle="solid"
      borderWidth="2px"
      borderColor="secondary.500"
      borderRadius={borderRadius}
      boxSizing="border-box"
      maxHeight="100%"
      {...props}
    >
      <Flex
        justifyContent={isModal ? 'space-between' : 'center'}
        alignItems="flex-start"
        p={{ base: '10px', xl: '10px 20px' }}
        borderStyle="solid"
        borderWidth="2px"
        borderColor="secondary.500"
        borderTopRadius={borderRadius}
        backgroundColor="primary.500"
        margin="-2px"
      >
        <Text
          fontWeight="700"
          color="white"
          overflowWrap="anywhere"
        >
          {title}
        </Text>
        {isModal && (
          <ModalCloseButton position="unset">
            <CloseIcon boxSize={{ base: '16px', xl: '19px' }} />
          </ModalCloseButton>
        )}
      </Flex>
      <Flex
        m={{ base: '20px 10px', md: '20px' }}
        flexGrow="1"
        flexDirection="column"
        bgColor="white"
        h="100%"
        {...childContainerStyle}
      >
        {props.children}
      </Flex>
    </Flex>
  );
};

export { SingleTableWrapper };
