import { useTranslation } from 'react-i18next';

import { timezonesList } from 'shared/utils';
import { Text, ViewRow } from 'shared/components';

interface IProps {
  zone?: string;
}

const ZoneViewRow = ({ zone }: IProps) => {
  const { t } = useTranslation('commonFields');
  const zoneName = timezonesList.find(
    (timeZone) => timeZone.timezone === zone,
  )?.name;

  return (
    <ViewRow label={t('Time Zone')}>
      <Text>{zoneName || zone || '-'}</Text>
    </ViewRow>
  );
};

export { ZoneViewRow };
