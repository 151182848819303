import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export const useTrackingFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');

  return object({
    trackedAt: string().required(requiredFieldMessage).nullable(),
    description: string().required(requiredFieldMessage),
    trackingTime: object({
      time: string().required(requiredFieldMessage).nullable(),
      zone: string().required(requiredFieldMessage).nullable(),
    }),
  });
};
