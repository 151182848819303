import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';
import { TrackingFormType } from 'entities/tracking/model';
import { TrackingFinalType } from '../model/TrackingFinalType';

export const useUpdateTracking = (trackingId: string, companyId: string) => {
  return useMutation((data: Partial<TrackingFinalType>) => {
    return updateTracking(data, trackingId, companyId);
  });
};

const updateTracking = (
  formData: Partial<TrackingFinalType>,
  trackingId: string,
  companyId: string,
) => {
  return httpClient.patch<TrackingFormType, AxiosResponse<{ _id: string }>>(
    `trackings/${trackingId}?companyID=${companyId}`,
    formData,
  );
};
