import { useTranslation } from 'react-i18next';

import { Button } from 'shared/components';

interface IProps {
  resetFilters: () => void;
}

const ResetButton = ({ resetFilters }: IProps) => {
  const { t } = useTranslation('common');
  return (
    <Button
      variant={'link'}
      onClick={resetFilters}
    >
      {t('Reset')}
    </Button>
  );
};

export { ResetButton };
