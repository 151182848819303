import { Icon, IconProps } from 'shared/components';

const GreenCloseIcon = (props: IconProps) => (
  <Icon
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    bgColor="#fff"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9098 1.06066C16.4956 1.64645 16.3056 2.78614 15.4855 3.60624L3.60612 15.4856C2.78602 16.3057 1.64633 16.4957 1.06054 15.9099C0.474753 15.3241 0.664703 14.1844 1.4848 13.3643L13.3642 1.48492C14.1843 0.664823 15.324 0.474874 15.9098 1.06066Z"
      fill="#72B42D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.47523 1.06066C2.06101 0.474874 3.20071 0.664823 4.02081 1.48492L15.9002 13.3643C16.7203 14.1844 16.9103 15.3241 16.3245 15.9099C15.7387 16.4957 14.599 16.3057 13.7789 15.4856L1.89949 3.60624C1.07939 2.78614 0.889442 1.64645 1.47523 1.06066Z"
      fill="#72B42D"
    />
  </Icon>
);

export { GreenCloseIcon };
