import { useNavigate } from 'react-router-dom';

import { useSaveUser } from 'entities/user/api';
import { PersonnelFormType } from 'entities/user/model';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { PersonnelForm } from 'features/person/PersonnelForm/PersonnelForm';
import { mapPersonnelFormData } from './mapPersonnelFormData';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddPersonnel = ({ companyId }: IProps) => {
  const { mutateAsync, isLoading } = useSaveUser(companyId);
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const handleSubmit = async (
    data: PersonnelFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(mapPersonnelFormData(data));
      // if(submitType === SubmitFormType.SaveAndNew) { console.log(' SAve AND NEW'); }
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.personnel.view.getPath(_id));
      }

      successToast({
        message: t('Successfully added item message', { item: t('person') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('person') }),
      });
    }
  };

  return (
    <PersonnelForm
      companyId={companyId}
      formId={`add-personnel-form-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export { AddPersonnel };
