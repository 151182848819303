import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  sic?: number;
}

const SICViewRow = ({ sic }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('SIC Code')}>
      <Text>{sic || '-'}</Text>
    </ViewRow>
  );
};

export { SICViewRow };
