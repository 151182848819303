import { Icon, IconProps } from 'shared/components';

const PdfIcon = (props: IconProps) => (
  <Icon
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    bgColor="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M49.8438 16.3125H38.0625V4.53125C38.0625 4.2909 37.967 4.06039 37.7971 3.89043C37.6271 3.72048 37.3966 3.625 37.1562 3.625H11.7812C11.5409 3.625 11.3104 3.72048 11.1404 3.89043C10.9705 4.06039 10.875 4.2909 10.875 4.53125V29.9062C10.875 30.1466 10.9705 30.3771 11.1404 30.5471C11.3104 30.717 11.5409 30.8125 11.7812 30.8125H36.25V45.3125H11.7812C11.5409 45.3125 11.3104 45.408 11.1404 45.5779C10.9705 45.7479 10.875 45.9784 10.875 46.2188V53.4688C10.875 53.7091 10.9705 53.9396 11.1404 54.1096C11.3104 54.2795 11.5409 54.375 11.7812 54.375H49.8438C50.0841 54.375 50.3146 54.2795 50.4846 54.1096C50.6545 53.9396 50.75 53.7091 50.75 53.4688V17.2188C50.75 16.9784 50.6545 16.7479 50.4846 16.5779C50.3146 16.408 50.0841 16.3125 49.8438 16.3125Z"
      fill="#EEEEEE"
    />
    <path
      d="M37.1562 29H8.15625C7.65574 29 7.25 29.4057 7.25 29.9062V46.2188C7.25 46.7193 7.65574 47.125 8.15625 47.125H37.1562C37.6568 47.125 38.0625 46.7193 38.0625 46.2188V29.9062C38.0625 29.4057 37.6568 29 37.1562 29Z"
      fill="#F2786B"
    />
    <path
      d="M50.4872 16.5757L37.7997 3.8882C37.6729 3.76039 37.511 3.67323 37.3345 3.63782C37.158 3.60242 36.975 3.62037 36.8087 3.6894C36.6425 3.75842 36.5006 3.87539 36.4011 4.02539C36.3016 4.1754 36.249 4.35163 36.25 4.53164V17.2191C36.25 17.4595 36.3455 17.69 36.5154 17.86C36.6854 18.0299 36.9159 18.1254 37.1563 18.1254H49.8438C50.0238 18.1264 50.2 18.0739 50.35 17.9743C50.5 17.8748 50.617 17.7329 50.686 17.5667C50.755 17.4004 50.773 17.2174 50.7376 17.0409C50.7022 16.8644 50.615 16.7025 50.4872 16.5757Z"
      fill="#E0E0E0"
    />
    <path
      d="M17.7931 37.1366C17.7931 37.4799 17.7386 37.8066 17.6297 38.1169C17.5208 38.4272 17.364 38.6863 17.1594 38.8942C17.0339 39.023 16.8953 39.1418 16.7435 39.2507C16.5949 39.3596 16.4299 39.4521 16.2484 39.528C16.0668 39.6039 15.8688 39.6633 15.6542 39.7062C15.443 39.7491 15.2053 39.7706 14.9413 39.7706H13.9709V42.1818H12.1191V34.8096H14.9809C15.4067 34.8096 15.7731 34.8492 16.08 34.9284C16.3903 35.0043 16.6709 35.1264 16.9217 35.2948C17.199 35.4796 17.4135 35.7272 17.5654 36.0374C17.7172 36.3444 17.7931 36.7108 17.7931 37.1366ZM15.8919 37.1811C15.8919 36.9732 15.8424 36.795 15.7433 36.6464C15.6443 36.4946 15.5255 36.3906 15.3869 36.3345C15.2119 36.2586 15.0436 36.2157 14.8819 36.2058C14.7234 36.1926 14.5072 36.186 14.2333 36.186H13.9709V38.3942H14.0996C14.2745 38.3942 14.4396 38.3925 14.5947 38.3892C14.7498 38.3859 14.8852 38.3744 15.0007 38.3546C15.0997 38.3381 15.2037 38.3067 15.3126 38.2605C15.4248 38.211 15.5106 38.1565 15.5701 38.0971C15.6955 37.975 15.7797 37.8479 15.8226 37.7159C15.8688 37.5838 15.8919 37.4056 15.8919 37.1811Z"
      fill="white"
    />
    <path
      d="M25.9767 38.5031C25.9767 39.1896 25.8265 39.8052 25.5261 40.3499C25.2258 40.8912 24.8462 41.3071 24.3874 41.5975C24.0045 41.8451 23.587 42.0052 23.1348 42.0778C22.6859 42.1471 22.2072 42.1818 21.6989 42.1818H19.3917V34.8096H21.6791C22.1973 34.8096 22.6826 34.8426 23.1348 34.9086C23.587 34.9746 24.0111 35.1347 24.4072 35.3888C24.8924 35.6991 25.2753 36.1183 25.5558 36.6464C25.8364 37.1712 25.9767 37.7901 25.9767 38.5031ZM24.0755 38.4882C24.0755 38.003 23.9913 37.5888 23.823 37.2455C23.6579 36.8989 23.4021 36.6283 23.0555 36.4335C22.8047 36.2949 22.5406 36.219 22.2634 36.2058C21.9894 36.1926 21.661 36.186 21.2781 36.186H21.2434V40.8004H21.2781C21.694 40.8004 22.0439 40.7938 22.3277 40.7806C22.6149 40.7641 22.8872 40.675 23.1447 40.5132C23.4483 40.3218 23.6794 40.0577 23.8378 39.7211C23.9962 39.3844 24.0755 38.9734 24.0755 38.4882Z"
      fill="white"
    />
    <path
      d="M32.6205 36.2157H29.526V37.6069H32.3729V39.0131H29.526V42.1818H27.6842V34.8096H32.6205V36.2157Z"
      fill="white"
    />
    <path
      d="M26.2537 17.7189C24.8434 16.3796 23.8222 14.849 23.8222 14.849C24.2113 14.2751 25.1352 10.9747 23.725 10.018C22.3147 9.0614 21.5853 10.8312 21.5853 10.8312C20.9531 13.0314 22.4606 15.0404 22.4606 15.0404L20.7586 18.7234C20.5641 18.7234 15.1663 20.7802 17.0142 23.3152C18.9107 25.8503 21.5367 19.7279 21.5367 19.7279C22.5579 19.393 25.6701 18.9626 25.6701 18.9626C26.8859 20.5888 28.3447 21.115 28.3447 21.115C30.5817 21.689 30.8248 19.8714 30.8248 19.8714C30.8734 17.4798 26.2537 17.7189 26.2537 17.7189ZM17.9381 22.4543C17.8895 22.4543 17.8895 22.4064 17.8895 22.4064C17.5977 21.7368 19.8347 20.4453 19.8347 20.4453C19.8347 20.4453 18.6189 22.7413 17.9381 22.4543ZM22.9955 10.879C23.6277 11.453 23.0928 13.4141 23.0928 13.4141C23.0928 13.4141 22.3634 11.453 22.9955 10.879ZM22.412 18.2929L23.2873 16.1883L24.6489 17.8146L22.412 18.2929ZM29.6091 19.8235C29.2201 20.4453 27.6153 19.1061 27.4694 18.9626C27.7126 18.9626 29.8036 19.1061 29.6091 19.8235Z"
      fill="#F2786B"
    />
  </Icon>
);

export { PdfIcon };
