import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Center, Container, MessagePopups, Spinner } from 'shared/components';
import { MessageTypeKeys } from '../../shared/components/MessagePopups/MessagePopups';
import { routeBuilder } from '../../shared/utils';
import { ResetPasswordForm } from '../../features/ResetPasswordForm';

type PopupType = MessageTypeKeys | false;

const containerWidth = 506;

const popupMessages = {
  success: 'You have successfully reset password',
  attention: 'Check the correctness of the entered data',
  error: 'Check the correctness of the entered data',
};

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const aid = searchParams.get('aid');

  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState<PopupType>(false);

  const onSuccess = async () => {
    setPopup('success');
    setTimeout(() => {
      navigate(routeBuilder.signIn.index.getPath(), { replace: true });
    }, 3000);
  };

  const onError = () => {
    setPopup('error');
  };

  const onMessagePopupsClose = () => {
    setPopup(false);
  };

  useEffect(() => {
    if (!code || !aid) {
      navigate(routeBuilder.signIn.index.getPath(), { replace: true });
    }
  }, [code, aid]);

  if (!code || !aid) {
    return null;
  }

  return (
    <Container
      maxW={containerWidth}
      p={{ base: '15px', xl: '30px' }}
      position="relative"
    >
      {isLoading && (
        <Center my="25px">
          <Spinner />
        </Center>
      )}
      {popup && (
        <Center
          position={{ lg: 'absolute' }}
          left={{ lg: containerWidth }}
          mb="40px"
        >
          <MessagePopups
            type={popup}
            message={popup ? popupMessages[popup] : ''}
            onClose={onMessagePopupsClose}
          />
        </Center>
      )}
      <ResetPasswordForm
        onSuccess={onSuccess}
        onError={onError}
        setIsSpinnerVisible={setIsLoading}
        code={code}
        aid={aid}
      />
    </Container>
  );
};

export { ResetPasswordPage };
