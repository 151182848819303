import { Icon, IconProps } from 'shared/components';

const ChangePasswordIcon = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 399 395"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="#72B42D"
        fillRule="evenodd"
        d="M138 5.605c-7.92 1.438-17.341 4.05-23.276 6.453C84.951 24.117 62.55 51.397 56.585 82.86 55.368 89.281 55 97.925 55 120.11V149h31.746l.52-28.75c.579-32.034 1.108-35.443 7.487-48.25 4.7-9.434 15.951-21.145 25.247-26.281 13.22-7.303 32.242-9.838 46.54-6.204C188.308 45.048 206.19 62.69 212.644 85c1.23 4.252 1.703 12.134 2.083 34.75l.492 29.25h31.926l-.374-32.75c-.369-32.239-.417-32.887-3.078-41.532-8.552-27.786-27.136-49.274-52.915-61.185C178.492 7.857 169.249 5.83 154 5.47c-7.425-.175-14.625-.114-16 .135m124.748 159.147L167 260.504V293h32.504l95.991-95.995 95.991-95.995-15.981-16.005C366.715 76.202 359.292 69 359.01 69c-.283 0-43.601 43.088-96.262 95.752m-233.985 1.762c-9.088 3.338-16.492 10.634-19.904 19.615C7.099 190.76 7 195.614 7 276.973c0 85.315.016 85.994 2.135 91.658 2.791 7.461 8.858 13.955 16.365 17.519l6 2.848h239l6-2.88c7.305-3.507 12.543-8.807 15.931-16.118l2.549-5.5.01-67.995.01-67.996-40.255 40.246L214.491 309H151v-63.491l40.245-40.254L231.491 165l-99.496.07c-81.73.058-100.162.316-103.232 1.444"
      />
    </Icon>
  );
};

export { ChangePasswordIcon };
