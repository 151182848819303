import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { routeBuilder } from 'shared/utils';

import { IVendor } from 'entities/vendor/model';

interface IProps {
  vendor: IVendor;
}
const VendorTableRow = ({ vendor }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(routeBuilder.vendor.view.getPath(vendor._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{vendor.name}</TableCellText>
      </TableBodyCell>

      <TableBodyCell>
        <TableCellText>{vendor.active ? t('Yes') : t('No')}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { VendorTableRow };
