import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const FirstNameField = () => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField
      label={`${t('First Name')}:`}
      isRequired
    >
      <TextField name="name.firstname" />
    </FormField>
  );
};

export { FirstNameField };
