import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { Text } from 'shared/components';
import { PaginationArrowIcon } from 'shared/icons';
import { Orientation } from 'shared/icons/PaginationArrowIcon/PaginationArrowIcon';

import { PaginationContainer } from './PaginationContainer';
import { useTranslation } from 'react-i18next';

interface IProps extends Omit<ReactPaginateProps, 'pageCount' | 'forcePage'> {
  totalItems: number;
  forcePage: number;
  itemsPerPage?: number;
}

const Pagination = ({
  itemsPerPage = 10,
  totalItems,
  forcePage,
  ...restProps
}: IProps) => {
  const { t } = useTranslation('pagination');
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  return (
    <PaginationContainer>
      <Text
        color="white"
        fontWeight="bold"
      >
        {t('pages', {
          currentPage: pageCount > 1 ? forcePage + 1 : 1,
          totalPages: pageCount,
        })}
      </Text>
      <ReactPaginate
        breakLabel="..."
        pageRangeDisplayed={restProps.pageRangeDisplayed || 5}
        pageCount={pageCount}
        renderOnZeroPageCount={() => null}
        activeClassName="active-page"
        pageClassName="page-item"
        className="react-paginate"
        pageLinkClassName="page-link"
        activeLinkClassName="active-link"
        previousClassName="previous-page-btn"
        nextClassName="next-page-btn"
        pageLabelBuilder={(page) => <Text fontWeight="bold">{page}</Text>}
        previousLabel={
          <PaginationArrowIcon
            orientation={Orientation.Left}
            isDisabled={forcePage === 0}
          />
        }
        nextLabel={
          <PaginationArrowIcon
            orientation={Orientation.Right}
            isDisabled={forcePage === pageCount - 1}
          />
        }
        forcePage={forcePage + 1 > pageCount ? 0 : forcePage}
        {...restProps}
      />
    </PaginationContainer>
  );
};

export { Pagination };
