import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const ZipField = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Zip')}:`}>
      <TextField name="address.zip" />
    </FormField>
  );
};

export { ZipField };
