import { Button, Modal } from 'shared/components';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from 'shared/icons';
import { IModalProps } from 'shared/components/Modal';
import { TrackingAdvancedSearchModalForm } from './TrackingAdvancedSearchModalForm';
import { useForm } from 'shared/components/Form';
import { IAdvancedSearchForm } from 'entities/tracking/model/IAdvancedSearchForm';
import { formsKeys } from 'shared/utils/formsKeys';
import { useAdvancedSearchModalStore } from 'pages/Tracking/List/TrackingListPage';
import shallow from 'zustand/shallow';
import { useAdvancedSearchValidationSchema } from 'features/report/utils';

interface IProps extends Omit<IModalProps, 'title'> {
  companyId: string;
}

const defaultValues: IAdvancedSearchForm = {
  from: null,
  to: null,
  trackedBy: null,
  activity: null,
  customerIDs: null,
  attendees: null,
  keywords: '',
  department: null,
  location: null,
  topics: null,
  incidentLocation: '',
  violation: {
    safetyViolation: null,
    actionID: null,
  },
};

const TrackingAdvancedSearchModal = ({ companyId, ...restProps }: IProps) => {
  const { t } = useTranslation('tracking');
  const validationSchema = useAdvancedSearchValidationSchema();

  const { methods, cleanPersistentStore } = useForm<IAdvancedSearchForm>({
    defaultValues,
    formId: formsKeys.tracking.advancedSearch.getKey(),
    validationSchema,
  });

  const { setValues, clearStore } = useAdvancedSearchModalStore(
    (state) => state,
    shallow,
  );

  const resetForm = () => {
    Object.keys(defaultValues).forEach((key) => {
      methods.setValue(
        key as keyof typeof defaultValues,
        defaultValues[key as keyof typeof defaultValues],
      );
    });
  };

  return (
    <Modal
      {...restProps}
      title={t('Choose Search Terms', { ns: 'common' })}
      content={
        <TrackingAdvancedSearchModalForm
          companyId={companyId}
          methods={methods}
          formId={formsKeys.tracking.advancedSearch.getKey()}
        />
      }
      size="5xl"
      scrollBehavior="inside"
      modalContentStyleProps={{
        overflow: 'auto',
      }}
    >
      <Button
        leftIcon={<SearchIcon fill="secondary.500" />}
        onClick={() => {
          setValues(methods.getValues());
        }}
      >
        {t('Search')}
      </Button>
      <Button
        onClick={() => {
          resetForm();
          cleanPersistentStore();
          clearStore();
        }}
      >
        {t('Reset')}
      </Button>
    </Modal>
  );
};

export { TrackingAdvancedSearchModal };
