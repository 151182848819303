import { useMutation } from 'shared/hooks/reactQuery';

import { httpClient } from 'shared/utils/httpClient';

export const useDeleteTrackingAttachment = () => {
  return useMutation(
    ({
      attachmentId,
      companyId,
    }: {
      attachmentId: string;
      companyId: string;
    }) => {
      return deleteAttachment(attachmentId, companyId);
    },
  );
};

const deleteAttachment = (attachmentId: string, companyId: string) => {
  return httpClient.delete(
    `trackings/attachments/${attachmentId}?companyID=${companyId}`,
  );
};
