import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { IChangePassword } from '../model';

type ChangePasswordFinalType = Omit<IChangePassword, 'repeatPassword'>;

export const useChangePassword = (companyId: string) => {
  return useMutation((data: ChangePasswordFinalType) => {
    return updatePassword(data, companyId);
  });
};

const updatePassword = (
  formData: ChangePasswordFinalType,
  companyId: string,
) => {
  return httpClient.patch(
    `auth/password/change?companyID=${companyId}`,
    formData,
  );
};
