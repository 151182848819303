import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const EmailField = () => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField label={`${t('Email')}:`}>
      <TextField name="email" autoCapitalize='off'  />
    </FormField>
  );
};

export { EmailField };
