import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  phone?: string;
}

const PhoneViewRow = ({ phone }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Phone')}>
      <Text>{phone || '-'}</Text>
    </ViewRow>
  );
};

export { PhoneViewRow };
