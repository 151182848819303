import { Flex } from 'shared/components';

import {
  AgencyViewRow,
  DescriptionViewRow,
  SectionViewRow,
  TitleViewRow,
} from 'features/ViewCommonFields';

import { IRegulation } from 'entities/regulation/model';

interface IProps {
  regulation: IRegulation;
}
const RegulationView = ({ regulation }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <AgencyViewRow agency={regulation.agency?.name} />
      <TitleViewRow title={regulation.title} />
      <SectionViewRow section={regulation.section} />
      <DescriptionViewRow description={regulation.description} />
    </Flex>
  );
};

export { RegulationView };
