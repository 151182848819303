import { TopicFormType } from 'entities/tracking/model';

export const convertTopicFormData = (data: TopicFormType) => {
  const activities =
    data.activities &&
    Object.keys(data.activities)?.filter(
      (key) => data.activities && data.activities[key]?.value,
    );

  return {
    active: data.active,
    activityIDs: activities,
    description: data.description,
    name: data.name,
  };
};
