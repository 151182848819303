import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  contactPersonNumber?: string;
}

const ContactPersonNumberViewRow = ({ contactPersonNumber }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t("Contact Person's Number")}>
      <Text>{contactPersonNumber || '-'}</Text>
    </ViewRow>
  );
};

export { ContactPersonNumberViewRow };
