import { Dispatch, SetStateAction } from 'react';
import {
  Input,
  InputLeftElement,
  InputGroup,
  InputGroupProps,
} from 'shared/components';
import { SearchIcon } from 'shared/icons';
import { useTranslation } from 'react-i18next';
import { useAdvancedSearchModalStore } from '../../pages/Tracking/List/TrackingListPage';
import shallow from 'zustand/shallow';

export type SearchInputProps = InputGroupProps & {
  globalFilter: string;
  setGlobalFilter: Dispatch<SetStateAction<string>>;
};

export const SearchInput = ({
  globalFilter,
  setGlobalFilter,
  ...props
}: SearchInputProps) => {
  const { t } = useTranslation('common');

  const { values, clearStore } = useAdvancedSearchModalStore(
    (state) => state,
    shallow,
  );

  return (
    <InputGroup
      display="flex"
      alignItems="center"
      {...props}
    >
      <InputLeftElement
        width="max-content"
        height="max-content"
        pointerEvents="none"
        top="initial"
        marginLeft="18px"
        zIndex="0"
      >
        <SearchIcon />
      </InputLeftElement>
      <Input
        type="search"
        alignItems="center"
        placeholder={t('Search')}
        borderRadius="4px"
        borderColor="secondary.500"
        w="100%"
        height="auto"
        p="8px 10px 8px 48px"
        color="black"
        fontSize={{ base: '1.6rem', md: '1.8rem' }}
        _placeholder={{
          color: 'black',
        }}
        value={globalFilter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (values) {
            clearStore();
          }
          setGlobalFilter(e.target.value);
        }}
      />
    </InputGroup>
  );
};
