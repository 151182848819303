import { TableColumnHeaderProps, Center } from 'shared/components';

import { TableSortChevrons } from 'shared/icons';

import { TableHeadCell } from './TableHeadCell';
import { useTableContextConsumer } from './TableContextProvider';

interface IProps extends TableColumnHeaderProps {
  columnName: string;
}

const TableHeadSortableCell = ({
  children,
  columnName,
  ...restProps
}: IProps) => {
  const {
    sort,
    setSort,
    currentlySortedColumnName,
    setCurrentlySortedColumnName,
    columnsConfiguration,
  } = useTableContextConsumer();

  const onChangeSort = () => {
    if (columnName === currentlySortedColumnName) {
      const currentSortDirection = sort && sort[0].sortDirection;
      setSort(
        sort?.map((value) => {
          return {
            name: value.name,
            sortDirection: currentSortDirection === 'desc' ? 'asc' : 'desc',
          };
        }),
      );
      return;
    }
    setCurrentlySortedColumnName(columnName);

    const column = columnsConfiguration.find(
      (column) => column.name === columnName,
    );

    column &&
      setSort(
        column.sortedFields.map((sortedField) => {
          return {
            name: sortedField,
            sortDirection: 'asc',
          };
        }),
      );
  };

  return (
    <TableHeadCell {...restProps}>
      <Center
        justifyContent="space-between"
        onClick={onChangeSort}
        cursor="pointer"
      >
        {children}
        <TableSortChevrons
          sortDirection={
            currentlySortedColumnName === columnName
              ? sort[0].sortDirection
              : null
          }
        />
      </Center>
    </TableHeadCell>
  );
};

export { TableHeadSortableCell };
