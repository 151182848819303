import React from 'react';
import { useUpdateCompany } from '../../entities/company/api';
import { useNavigate } from 'react-router-dom';
import { convertData } from '../../features/company/CompanyForm/utils';
import { routeBuilder, useToast } from '../../shared/utils';
import { CompanyFormType, ICompany } from '../../entities/company/model';
import { CompanyForm } from '../../features/company/CompanyForm';
import { SubmitFormType } from '../../shared/types';
import { ViewButton } from '../../features/ViewButton';
import { useTranslation } from 'react-i18next';
import { DeleteCompany } from 'features/company/DeleteCompany';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  company: ICompany;
}

const EditCompany = ({ company }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, credits, roles, counters, ...defaultData } = company;
  const { mutateAsync, isLoading } = useUpdateCompany(_id);
  const navigation = useNavigate();
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();
  const { fullSuperAdminPrivilege } = LayoutPrivilege();

  const viewPath = routeBuilder.company.view.getPath(_id);

  const handleSubmit = async (
    data: CompanyFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    const dataForBackend = convertData(data);

    try {
      await mutateAsync(dataForBackend);
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.company.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('company') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('company') }),
      });
    }
  };

  return (
    <CompanyForm
      formId={`edit-company-form-${_id}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          {company.active && fullSuperAdminPrivilege && (
            <DeleteCompany companyId={_id} />
          )}
        </>
      }
      defaultValues={defaultData}
    />
  );
};

export { EditCompany };
