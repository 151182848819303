import { UseQueryOptions } from '@tanstack/react-query';

import { IVendor } from 'entities/vendor/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { useGetParsedQueryParams } from 'shared/utils';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';

export const useGetVendors = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IVendor[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  return useReactQuery<IList<IVendor[]>>(
    getVendorsKey(companyId, parsedQueryParams),
    () => getVendors(companyId, parsedQueryParams),
    options,
  );
};

const getVendors = (companyId?: string, queryParams?: string) => {
  return httpClient.get<IList<IVendor[]>>(
    buildUrl(
      'vendors',
      `companyID=${companyId}&${queryParams ? queryParams : ''}`,
    ),
  );
};

const getVendorsKey = (companyId: string, queryParams?: string) => [
  'vendors',
  companyId,
  queryParams,
];
