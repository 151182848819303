import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';

import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';
import { DeleteRegulation } from './DeleteRegulation';

interface IProps {
  companyId: string;
  regulationId: string;
}

const RegulationActionButtons = ({ regulationId, companyId }: IProps) => {
  return (
    <FlexWrapper>
      <AddNewItemButton redirectPath={routeBuilder.regulation.add.getPath()} />
      <DeleteRegulation
        companyId={companyId}
        regulationId={regulationId}
      />
      <EditItemButton
        redirectPath={routeBuilder.regulation.edit.getPath(regulationId)}
      />
    </FlexWrapper>
  );
};

export { RegulationActionButtons };
