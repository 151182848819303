export const routesNames = Object.freeze({
  /* for public routes */
  signIn: 'sign-in',
  forgotPassword: 'forgot-password',
  resetPassword: 'password/reset',
  changePassword: 'change-password',
  news: 'news',
  about: 'about',
  partners: 'partners',
  regulation: 'regulation',
  solutions: 'solutions',
  vendor: 'vendor',
  contact: 'contact',

  /* for protected routes */
  dashboard: 'dashboard',
  company: 'company',
  personnel: 'personnel',
  roles: 'roles',
  locations: 'locations',
  departments: 'departments',
  tracking: 'tracking',
  actions: 'actions',
  topics: 'topics',
  activities: 'activities',
  customer: 'customer',
  profile: 'profile',
  agency: 'agency',
  report: 'report',

  list: '',
  view: 'view',
  add: 'add',
  edit: 'edit',
});
