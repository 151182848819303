import { ReactNode } from 'react';
import { Box } from 'shared/components';
import { Flex, Center, UnorderedList, ListItem } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { SignInIcon, HomeIcon } from 'shared/icons';
import { Privileges } from 'entities/role/model';
import { NavItemText } from './NavItemText';
import { navItemStyles } from './navItemStyles';
import { NavItemMenu } from './NavItemMenu';
import { NavItemLogout } from './NavItemLogout';
import { NavItemHome } from './NavItemHome';
import {
  filterWithPrivileges,
  menuProtectedBuilder,
  menuPublicBuilder,
} from 'shared/utils/navigation';
import { routeBuilder } from 'shared/utils';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

export interface IMenuItemWrapper {
  allowedPrivileges: Privileges[];
  componentName?: ICompanyComponent;
  component: ReactNode;
}

const iconMargin = '8px';

const TEST_MENU_ITEMS = [
  'Overview',
  'Industries',
  'Advisory Board',
  'Add Personnel',
].map((item) => ({
  key: 'test',
  label: item,
  path: item,
  allowedPrivileges: [],
}));

const protectedItems: IMenuItemWrapper[] = [
  {
    allowedPrivileges: menuProtectedBuilder.dashboard.getAllowedPrivileges(),
    component: (
      <NavItemHome
        link={routeBuilder.tracking.add.getPath()}
        iconMargin={'4px'}
      />
    ),
  },
  {
    allowedPrivileges: menuProtectedBuilder.company.getAllowedPrivileges(),
    component: (
      <NavItemMenu
        label={
          menuProtectedBuilder.company.rootLabel ||
          menuProtectedBuilder.company.getLabel('Company')
        }
        menuItems={menuProtectedBuilder.company.getItems()}
        mainKey="company"
      />
    ),
  },
  {
    allowedPrivileges: menuProtectedBuilder.tracking.getAllowedPrivileges(),
    component: (
      <NavItemMenu
        label={
          menuProtectedBuilder.tracking.rootLabel ||
          menuProtectedBuilder.tracking.getLabel('Tracking')
        }
        menuItems={menuProtectedBuilder.tracking.getItems()}
        mainKey="tracking"
      />
    ),
  },
  {
    allowedPrivileges: menuProtectedBuilder.customer.getAllowedPrivileges(),
    componentName: ICompanyComponent.Customers,
    component: (
      <NavItemMenu
        label={
          menuProtectedBuilder.customer.rootLabel ||
          menuProtectedBuilder.customer.getLabel('Customer')
        }
        menuItems={menuProtectedBuilder.customer.getItems()}
        mainKey="customer"
      />
    ),
  },
  {
    allowedPrivileges: menuProtectedBuilder.vendor.getAllowedPrivileges(),
    componentName: ICompanyComponent.Vendors,
    component: (
      <NavItemMenu
        label={
          menuProtectedBuilder.vendor.rootLabel ||
          menuProtectedBuilder.vendor.getLabel('Vendor')
        }
        menuItems={menuProtectedBuilder.vendor.getItems()}
        mainKey="vendor"
      />
    ),
  },
  {
    allowedPrivileges: menuProtectedBuilder.regulation.getAllowedPrivileges(),
    componentName: ICompanyComponent.Regulations,
    component: (
      <NavItemMenu
        label={
          menuProtectedBuilder.regulation.rootLabel ||
          menuProtectedBuilder.regulation.getLabel('Regulation')
        }
        menuItems={menuProtectedBuilder.regulation.getItems()}
        mainKey="regulation"
      />
    ),
  },
  {
    allowedPrivileges: menuProtectedBuilder.profile.getAllowedPrivileges(),
    component: (
      <Link to={menuProtectedBuilder.profile.getPath()}>
        <Center
          {...navItemStyles}
          marginTop="0"
        >
          <NavItemText {...navItemStyles}>
            {menuProtectedBuilder.profile.getLabel()}
          </NavItemText>
        </Center>
      </Link>
    ),
  },
  {
    allowedPrivileges: [],
    component: (
      <NavItemLogout>
        <Center
          {...navItemStyles}
          marginTop="0"
        >
          <NavItemText>Logout</NavItemText>
          {/*<Box marginLeft={iconMargin}>
            <LogoutIcon />
          </Box>*/}
        </Center>
      </NavItemLogout>
    ),
  },
];

const publicItems = [
  {
    component: (
      <Link to={menuPublicBuilder.home.getPath()}>
        <Center {...navItemStyles}>
          <NavItemText>{menuPublicBuilder.home.getLabel()}</NavItemText>
        </Center>
      </Link>
    ),
  },
  {
    component: (
      <NavItemMenu
        label={menuPublicBuilder.about.getLabel()}
        menuItems={menuPublicBuilder.about.getItems()}
        mainKey="about"
      />
    ),
  },
  {
    component: (
      <NavItemMenu
        label={menuPublicBuilder.solutions.getLabel()}
        menuItems={menuPublicBuilder.solutions.getItems()}
        mainKey="solutions"
      />
    ),
  },
  {
    component: (
      <Link to={menuPublicBuilder.news.getPath()}>
        <Center {...navItemStyles}>
          <NavItemText {...navItemStyles}>
            {menuPublicBuilder.news.getLabel()}
          </NavItemText>
        </Center>
      </Link>
    ),
  },
  {
    component: (
      <NavItemMenu
        label={menuPublicBuilder.partners.getLabel()}
        menuItems={menuPublicBuilder.partners.getItems()}
        mainKey="partners"
      />
    ),
  },
  {
    component: (
      <NavItemMenu
        label={menuPublicBuilder.covid.getLabel()}
        menuItems={menuPublicBuilder.covid.getItems()}
        mainKey="covid"
      />
    ),
  },
  {
    component: (
      <Link to={menuPublicBuilder.contact.getPath()}>
        <Center {...navItemStyles}>
          <NavItemText {...navItemStyles}>
            {menuPublicBuilder.contact.getLabel()}
          </NavItemText>
        </Center>
      </Link>
    ),
  },
  {
    component: (
      <Link to={menuPublicBuilder.signIn.getPath()}>
        <Center {...navItemStyles}>
          <NavItemText>{menuPublicBuilder.signIn.getLabel()}</NavItemText>
          <Box marginLeft={iconMargin}>
            <SignInIcon />
          </Box>
        </Center>
      </Link>
    ),
  },
];

export const Navigation = ({
  isLoggedIn,
  activePrivileges,
  components = []
}: {
  isLoggedIn: boolean;
  activePrivileges: Privileges[];
  components?: ICompanyComponent[];
}) => {
  const buildPublic = () => {
    return publicItems.map((item, i) => {
      return (
        <ListItem
          key={`public-${i}`}
          _last={{ ml: 'auto' }}
        >
          {item.component}
        </ListItem>
      );
    });
  };

  const buildProtected = () => {
    return protectedItems
      .filter(filterWithPrivileges(activePrivileges))
      .filter((item) => item.componentName ? components.includes(item.componentName) : true)
      .map((item, i) => {
        return (
          <ListItem
            key={`protected-${i}`}
            _last={{ ml: 'auto' }}
          >
            {item.component}
          </ListItem>
        );
      });
  };

  return (
    <Flex
      as={'nav'}
      padding={{ lg: '10px', xl: '12px 30px' }}
      backgroundColor={'primary.500'}
      borderRadius="24px"
    >
      <UnorderedList
        marginInlineStart={'0'}
        display={'flex'}
        alignItems="center"
        listStyleType="none"
        flexGrow="1"
      >
        {isLoggedIn ? buildProtected() : buildPublic()}
      </UnorderedList>
    </Flex>
  );
};
