import { reduce } from 'lodash';

import { IQueryParams, ISort } from '../types';

export const useGetParsedQueryParams = (queryParams?: IQueryParams) => {

  if (!queryParams) {
    return '';
  }
  const { sort, limit, offset } = queryParams;
  const computedSorting = sort && computeSorting(sort);
  const computedGlobalFilter = computeGlobalFilter(queryParams);
  const computedLimit =
    limit !== undefined && !isNaN(limit) ? `limit=${limit}` : '';
  const computedOffset =
    offset !== undefined && !isNaN(offset) ? `offset=${offset}` : '';
  return [computedSorting, computedGlobalFilter, computedLimit, computedOffset]
    .filter((value) => value !== '')
    .join('&');
};

const computeSorting = (sort: ISort[]) => {
  if (sort.length === 0) {
    return '';
  }
  return sort
    .reduce(
      (result, currentValue) =>
        `${result}${currentValue.name}$${currentValue.sortDirection},`,
      'sort=',
    )
    .slice(0, -1);
};

const parseCustomFilters = (filters: Record<string, unknown>) =>
  Object.entries(filters)
    .map(([key, value]) => `${key}$${value}`)
    .join(',');

const computeGlobalFilter = (queryParams: IQueryParams) => {
  const { filters, fields, customFilters, globalFilter } = queryParams;
  if (filters === 'true' && !globalFilter) {
    return 'filters=active$true';
  }
  if (!filters || !fields) {
    return customFilters ? `filters=${parseCustomFilters(customFilters)}` : '';
  }
  const result = reduce(
    fields,
    (result, field) => {
      return `${result}${field}$${filters},`;
    },
    'filters=',
  );
  return customFilters
    ? result + parseCustomFilters(customFilters)
    : result.slice(0, -1);
};
