import { reduce } from 'lodash';
import {
  useParseActivity,
  useParseDepartment,
  useParseLocation,
  useParseTopic,
  useParseUser,
} from './helpers';
import { IReportForm } from 'entities/tracking/model/IReportForm';
import { IAdvancedSearchForm } from 'entities/tracking/model/IAdvancedSearchForm';
import { useGetLocale } from 'shared/utils';

export const useComputeAdvancedSearchQuery = (
  companyId: string,
  advancedSearchForm?: IAdvancedSearchForm | IReportForm,
  limit?: number,
  offset?: number,
  languageCode?: string
) => {
  // const location = useParseLocation(
  //   companyId,
  //   advancedSearchForm?.location || '',
  // );
  // const department = useParseDepartment(
  //   companyId,
  //   advancedSearchForm?.department || '',
  // );
  // const activity = useParseActivity(
  //   companyId,
  //   advancedSearchForm?.activity || '',
  // );
  const topic = useParseTopic(companyId, advancedSearchForm?.topics || '');
  const attendees = useParseUser(advancedSearchForm?.attendees || '');
  const trackedBy = useParseUser(advancedSearchForm?.trackedBy || '');

  const translationsLang = languageCode || advancedSearchForm?.language || 'en';

  const computedLimit =
    limit !== undefined && !isNaN(limit) ? `limit=${limit}` : '';
  const computedOffset =
    offset !== undefined && !isNaN(offset) ? `offset=${offset}` : '';

  const computedTitle =
    advancedSearchForm?.title !== undefined || advancedSearchForm?.title !== ''
      ? `title=${advancedSearchForm?.title}`
      : '';

  const reportFieldsTranslations =
    // eslint-disable-next-line max-len
    'incidentLocation$Ubicación del incidente,trackedAt$Fecha de seguimiento,location$Ubicacione,department$Departamento,activity$Actividad,trackedBy$Rastreado por,attendees$Asistentes,violation$Violación,description$Descripción,attachments$Archivos adjuntos';
  const computedFieldsTranslations =
    advancedSearchForm?.language !== 'en'
      ? `fields=${reportFieldsTranslations}`
      : '';

  const isEmptyObject =
    advancedSearchForm &&
    Object.values(advancedSearchForm).every((value) => !value || value === '');

  if (!advancedSearchForm || isEmptyObject) {
    return [
      `languageCode=${translationsLang}&companyID=${companyId}`,
      computedLimit,
      computedOffset
    ]
      .filter((value) => value !== '')
      .join('&');
  }

  const dateTo = advancedSearchForm?.to && new Date(advancedSearchForm?.to);
  const valueEndDay =
    dateTo &&
    new Date(
      dateTo.getFullYear(),
      dateTo.getMonth(),
      dateTo.getDate(),
      23,
      59,
      59,
      999,
    );

  const payload: Record<string, unknown> = {
    from:
      advancedSearchForm?.from &&
      new Date(advancedSearchForm?.from).toISOString(),
    to: valueEndDay && new Date(valueEndDay).toISOString(),
    // 'location.name': location,
    // 'department.name': department,
    customerIDs: advancedSearchForm?.customerIDs,
    vendorIDs: advancedSearchForm?.vendorIDs,
    // 'activity.name': activity,
    'topics.name': topic,
    'trackedBy.name.firstname': trackedBy?.name.firstname,
    'trackedBy.name.lastname': trackedBy?.name.lastname,
    'attendees.name.firstname': attendees?.name.firstname,
    'attendees.name.lastname': attendees?.name.lastname,
    'violation.actionDetails':
      advancedSearchForm.violation?.actionDetail || undefined,
    'violation.actionID': advancedSearchForm.violation?.actionID || undefined,
    'incidentLocation': advancedSearchForm?.incidentLocation || undefined,
    keywords:
      advancedSearchForm?.keywords &&
      advancedSearchForm?.keywords.replace(/[^a-zA-Z0-9 ]/g, ''),
  };
  if(advancedSearchForm.location) payload['location._id'] = advancedSearchForm.location;
  if(advancedSearchForm.department) payload['department._id'] = advancedSearchForm.department;
  if(advancedSearchForm.activity) payload['activity._id'] = advancedSearchForm.activity;
  // if(advancedSearchForm.topic) payload['location.name'] = advancedSearchForm.location;

  const isEmptyPayload = Object.keys(payload).every(
    (key) =>
      !payload[key as keyof typeof payload] ||
      payload[key as keyof typeof payload] === '',
  );

  if (isEmptyPayload) {
    return [`companyID=${companyId}`, computedLimit, computedOffset]
      .filter((value) => value !== '')
      .join('&');
  }

  const computedFilters = reduce(
    Object.keys(payload),
    (result, key) => {
      if (
        !payload[key as keyof typeof payload] ||
        payload[key as keyof typeof payload] === ''
      ) {
        return result;
      }
      return `${result}${key}$${payload[key as keyof typeof payload]},`;
    },
    `languageCode=${translationsLang}`
      +`&companyID=${companyId}&filterOperator=and&filters=`,
  ).slice(0, -1);

  return [
    computedFilters,
    computedTitle,
    computedLimit,
    computedOffset,
    computedFieldsTranslations,
  ]
    .filter((value) => value !== '')
    .join('&');
};
