import { useTranslation } from 'react-i18next';

import { ViewRow } from 'shared/components';
import { ActivitiesTable } from 'features/activites/ActivitiesTable';
import { useState } from 'react';
import { ISort } from 'shared/types';

interface IProps {
  topicId?: string;
}

// TODO fix limit when filtering by array will be added
const limit = 1000;

const ActivitiesViewRow = ({ topicId }: IProps) => {
  const { t } = useTranslation('commonFields');
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<ISort[]>();

  return (
    <ViewRow label={t('Activities')}>
      <ActivitiesTable
        onSortChange={(sort) => setSort(sort)}
        queryParams={{
          limit: limit,
          offset: offset,
          sort: sort,
        }}
        setOffset={(value) => setOffset(value)}
        topicId={topicId}
      />
    </ViewRow>
  );
};

export { ActivitiesViewRow };
