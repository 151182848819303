import { useTranslation } from 'react-i18next';
import { NavText } from '../NavText';
import useStore from 'shared/store';

export type NavItemTextProp = { children: string };

export const NavItemText = ({ children }: NavItemTextProp) => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  const keyPrefix = !isLoggedIn ? 'public' : 'protected';
  const { t } = useTranslation('navigation', {
    keyPrefix,
  });

  return (
    <NavText
      isLoggedIn={isLoggedIn}
      whiteSpace={'nowrap'}
    >
      {t(children)}
    </NavText>
  );
};
