import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { IconButton } from 'shared/components';
import { ViewIcon } from 'shared/icons';
import { DeleteAttachment } from 'widgets/DocumentationSection/DeleteAttachment';
import { useTranslation } from 'react-i18next';
import { IAttachment } from 'entities/attachments/model';
import { apiUrl } from 'shared/utils/httpClient/apiUrl';

interface IProps {
  attachment: IAttachment;
  companyId: string;
  entityName: 'activities' | 'trackings' | 'customers' | 'vendors';
  viewMode?: boolean;
}

const AttachmentTableRow = ({
  attachment,
  companyId,
  entityName,
  viewMode,
}: IProps) => {
  const { t } = useTranslation('common');
  return (
    <TableRow
      key={attachment._id}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{`${attachment.name}`}</TableCellText>
      </TableBodyCell>
      <TableBodyCell
        w={{ base: '50px', md: '60px', lg: '50px', '2xl': '60px' }}
        textAlign="center"
      >
        <IconButton
          aria-label="view document"
          bg="none"
          icon={<ViewIcon />}
          onClick={() => {
            window.open(
              // eslint-disable-next-line max-len
              `${apiUrl}/${entityName}/attachments/${attachment._id}?companyID=${companyId}`,
              '_blank',
            );
          }}
        />
      </TableBodyCell>
      {!viewMode && (
        <TableBodyCell
          w={{ base: '50px', md: '60px', lg: '50px', '2xl': '60px' }}
          textAlign="center"
        >
          <DeleteAttachment
            title={t('Confirm Delete')}
            message={t('Do you really want to delete?')}
            companyId={companyId}
            attachmentId={attachment._id}
          />
        </TableBodyCell>
      )}
    </TableRow>
  );
};

export { AttachmentTableRow };
