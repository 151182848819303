import { CSSObjectWithLabel } from 'react-select/dist/declarations/src/types';
import { OptionProps } from 'react-select/dist/declarations/src/components/Option';

import { useBreakpointValue, useTheme } from 'shared/hooks/chakraUi';

export const useOptionStyle = (
  provided: CSSObjectWithLabel,
  state: OptionProps,
) => {
  const paddingY = useBreakpointValue({ base: '6px', md: '8px' });
  const { colors } = useTheme();

  return {
    ...provided,
    paddingTop: paddingY,
    paddingBottom: paddingY,
    borderWidth: '2px 0 0 0',
    borderColor: colors.secondary[500],
    backgroundColor: state.isSelected ? colors.secondary[500] : '#fff',
    color: 'black',
    '&:hover': {
      backgroundColor: colors.secondary[200],
      color: 'black',
    },
  };
};
