import { useFormContext } from 'react-hook-form';

import { Checkbox, ListItem, Text } from 'shared/components';

import { IRole } from 'entities/role/model';

interface IProps {
  role: IRole;
  isDisabled?: boolean;
}

const RoleRecord = ({ role, isDisabled }: IProps) => {
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = `roles.${role._id}`;
  const value = watch(name);

  return (
    <ListItem
      display="flex"
      key={role._id}
    >
      <Checkbox
        isDisabled={isSubmitting || isDisabled}
        size="lg"
        gap="12px"
        isChecked={value?.value}
        onChange={(event) => {
          setValue(name, {
            _id: role._id,
            value: event.target.checked,
          });
        }}
      >
        <Text size={{ base: 'sm', md: 'md' }}>{role.name}</Text>
      </Checkbox>
    </ListItem>
  );
};

export { RoleRecord };
