import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Box, Flex, VStack, SingleTableWrapper, Text } from 'shared/components';
import { ISelectOption } from 'shared/components/Select';
import {
  FilterByDepartment,
  FilterByLocation,
  FilterByRole,
  ResetButton,
  SupervisorTableFiltersContainer,
} from 'features/SupervisorTableFilters';
import { SupervisorTable } from 'features/SupervisorTable/SupervisorTable';
import { useGetUsers } from 'entities/user/api';
import { SelectAllButton } from 'features/CheckboxListFilter/SelectAllButton';

interface IProps {
  companyId: string;
  personId?: string;
}

const SupervisorContainer = ({ companyId, personId }: IProps) => {
  const { setValue, watch } = useFormContext();
  const { t } = useTranslation('person');

  const [locationFilter, setLocationFilter] = useState<ISelectOption | null>(
    null,
  );
  const [departmentFilter, setDepartmentFilter] =
    useState<ISelectOption | null>(null);
  const [roleFilter, setRoleFilter] = useState<ISelectOption | null>(null);

  const { data, isLoading } = useGetUsers(companyId, undefined, {
    useErrorBoundary: false,
  });


  const usersWhoCanBeSupervised = data?.data.data
    .filter((person) => person.active)
    .filter((person) => personId !== person._id)
    .filter((user) =>
      user.supervisor?._id === personId ? user : !user.supervisor,
    );
  const defaultItems =
    usersWhoCanBeSupervised
      ?.map((result) => result._id)
      ?.reduce((acc, userId) => {
        return { ...acc, [userId]: { _id: userId, value: false } };
      }, {}) || {};

  const items = { ...defaultItems, ...watch('supervises') } as {
    [key: string]: { _id: string; value: boolean };
  };

  const filteredPersons =
    (usersWhoCanBeSupervised &&
      usersWhoCanBeSupervised
        .filter((user) =>
          locationFilter?.value
            ? user.locations
                ?.map((location) => location?._id)
                .includes(locationFilter.value)
            : user,
        )
        .filter((user) =>
          departmentFilter?.value
            ? user.departments
                ?.map((department) => department?._id)
                .includes(departmentFilter.value)
            : user,
        )
        .filter((user) =>
          roleFilter?.value
            ? user.roles?.map((role) => role?._id).includes(roleFilter.value)
            : user,
        )) ||
    [];
  const isAllSelected =
    items &&
    filteredPersons &&
    filteredPersons?.every((filteredPerson) => {
      return Object.keys(items).find(
        (key) => key === filteredPerson._id && items[key].value,
      );
    });

  const switchAllCheckboxes = (value: boolean) => {
    Object.keys(items)
      .map((key) => {
        setValue(`supervises.${key}`, items[key]);
        return items[key];
      })
      .filter((item) => {
        return filteredPersons.find(
          (filteredPerson) => filteredPerson._id === item?._id,
        );
      })
      .forEach((item) => {
        setValue(`supervises.${item._id}`, { ...item, value: value });
      });
  };

  const resetAllCheckboxes = () => {
    Object.keys(items).map((key) =>
      setValue(`supervises.${key}`, { ...items[key], value: false }),
    );
  };
  const resetFilters = () => {
    setLocationFilter(null);
    setDepartmentFilter(null);
    setRoleFilter(null);
    resetAllCheckboxes();
  };

  return (
    <SingleTableWrapper
      title={t('Supervised Employees')}
      w="100%"
      maxW={{ lg: '800px' }}
    >
      <VStack gap="20px">
        <Flex
          w="100%"
          justifyContent="center"
        >
          <SupervisorTableFiltersContainer>
            <Flex
              direction="column"
              gap="20px"
            >
              <Flex
                direction={{ base: 'column', md: 'row' }}
                gap="10px"
              >
                <Box w={{ base: '100%', md: '33%' }}>
                  <FilterByLocation
                    companyId={companyId}
                    onChange={(value) => {
                      setLocationFilter(value);
                    }}
                    locationFilter={locationFilter}
                  />
                </Box>
                <Box w={{ base: '100%', md: '33%' }}>
                  <FilterByDepartment
                    companyId={companyId}
                    onChange={(value) => {
                      setDepartmentFilter(value);
                    }}
                    department={departmentFilter}
                  />
                </Box>
                <Box w={{ base: '100%', md: '33%' }}>
                  <FilterByRole
                    companyId={companyId}
                    onChange={(value) => {
                      setRoleFilter(value);
                    }}
                    role={roleFilter}
                  />
                </Box>
              </Flex>
              <Flex gap="20px">
                {/* <SelectAllButton
                  checkAllCheckboxes={() => switchAllCheckboxes(true)}
                  isDisabled={isAllSelected}
                /> */}
                <SelectAllButton
                  onClick={() => switchAllCheckboxes(true)}
                  disabled={isAllSelected}
                />
                <ResetButton resetFilters={resetFilters} />
              </Flex>
            </Flex>
          </SupervisorTableFiltersContainer>
        </Flex>
        {filteredPersons.length < 1
          ? <Text>{t('No results found')}</Text>
          :(
            <SupervisorTable
              isLoading={isLoading}
              users={filteredPersons}
              isAllChecked={isAllSelected}
              switchAll={() => switchAllCheckboxes(!isAllSelected)}
            />
          )}
      </VStack>
    </SingleTableWrapper>
  );
};

export { SupervisorContainer };
