import { useTranslation } from 'react-i18next';

export const useActivityTopicsColumnsConfig = () => {
  const { t } = useTranslation(['topics', 'commonFields']);
  return [
    {
      name: 'checkbox',
      sortedFields: [],
      title: '',
      isSortable: false,
    },
    {
      name: 'topic',
      sortedFields: ['name'],
      title: t('Topic'),
      isSortable: true,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active', { ns: 'commonFields' }),
      isSortable: true,
    },
  ];
};
