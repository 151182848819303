import { useNavigate } from 'react-router-dom';

import { useSaveRole } from 'entities/role/api';
import { RoleFormType } from 'entities/role/model';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { RoleForm } from 'features/roles/RoleForm';
import { useTranslation } from 'react-i18next';
import { convertRoleFormData } from './convertRoleFormData';

interface IProps {
  companyId: string;
}

const AddRole = ({ companyId }: IProps) => {
  const navigation = useNavigate();
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();

  const { mutateAsync, isLoading } = useSaveRole(companyId);

  const handleSubmit = async (
    data: RoleFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(convertRoleFormData(data));

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.roles.view.getPath(_id));
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(0);
      }
      successToast({
        message: t('Successfully added item message', { item: t('role') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('role') }),
      });
    }
  };

  return (
    <RoleForm
      formId={`add-role-form-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export { AddRole };
