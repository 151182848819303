import { Flex, FlexProps } from 'shared/components';

const CheckboxListFilterContainer = ({ children, ...restProps }: FlexProps) => {
  return (
    <Flex
      marginBottom={{ base: '10px', xl: '20px' }}
      flexDir="column"
      gap={{ base: '10px', xl: '10px' }}
      {...restProps}
    >
      {children}
    </Flex>
  );
};

export { CheckboxListFilterContainer };
