import { ITextFieldProps, TextField } from 'shared/components/Form';

type IProps = ITextFieldProps;

const ForgotPasswordFormInput = ({ ...props }: IProps) => {
  return (
    <TextField
      padding="12px 16px"
      fontSize="1.8rem"
      h={47}
      borderWidth="1px"
      {...props}
    />
  );
};

export { ForgotPasswordFormInput };
