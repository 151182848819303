import { useNavigate } from 'react-router-dom';

import { useDisclosure } from 'shared/hooks/chakraUi';
import { routeBuilder, useToast } from 'shared/utils';

import { DeleteButton } from 'features/DeleteButton';
import { DeleteModal } from 'features/DeleteModal';

import { useDeleteVendor } from 'entities/vendor/api';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
  vendorId: string;
}

const DeleteVendor = ({ companyId, vendorId }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigation = useNavigate();
  const { isLoading, mutateAsync } = useDeleteVendor(vendorId, companyId);
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();

  const handleDelete = async () => {
    try {
      await mutateAsync();
      navigation(routeBuilder.vendor.index.getPath());
      successToast({
        message: t('Successfully deleted item message', {
          item: t('vendor'),
        }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while deleting item message', {
          item: t('vendor'),
        }),
      });
    }
  };

  return (
    <>
      <DeleteButton onClick={onOpen} />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        onDelete={handleDelete}
      />
    </>
  );
};

export { DeleteVendor };
