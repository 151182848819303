import { Outlet, useNavigate } from 'react-router-dom';

import useStore from '../../../store';
import { routeBuilder } from '../../../utils';
import { useEffect } from 'react';

const PublicLayout = () => {
  const navigate = useNavigate();
  const isMFALoggedIn = useStore((state) => state.isMFALoggedIn);

  useEffect(() => {
    if (!isMFALoggedIn) {
      navigate(routeBuilder.mfaCode.index.getPath(''), { replace: true });
    }
  }, [isMFALoggedIn]);

  return <Outlet />;
};

export { PublicLayout };
