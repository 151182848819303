import { ChakraProvider as Provider } from '@chakra-ui/react';

import { ChildrenProp } from 'shared/types';

import { themeConfiguration } from 'shared/utils/theme/themeConfiguration';

type IProps = ChildrenProp;

const ChakraProvider = ({ children }: IProps) => {
  return <Provider theme={themeConfiguration}>{children}</Provider>;
};

export { ChakraProvider };
