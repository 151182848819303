import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';

import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';
import { DeleteTracking } from 'features/tracking/DeleteTracking';
import { TrackingViewEmail } from '../../features/TrackingEmail';
import { ITracking } from '../../entities/tracking/model';
import { TrackingViewSMS } from 'features/tracking/TrackingSMS/TrackingViewSMS';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  tracking: ITracking;
}

const TrackingActionButtons = ({ tracking, companyId }: IProps) => {
  const {
    createTrackingPrivilege,
    updateTrackingPrivilege,
    deleteTrackingPrivilege,
    smsPrivilege,
    emailPrivilege,
  } = LayoutPrivilege();

  return (
    <FlexWrapper>
      {createTrackingPrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.tracking.add.getPath()} />
      )}
      {updateTrackingPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.tracking.edit.getPath(tracking._id)}
        />
      )}
      {emailPrivilege && (
        <TrackingViewEmail
          tracking={tracking}
          companyId={companyId}
        />
      )}
      {smsPrivilege && (
        <TrackingViewSMS
          tracking={tracking}
          companyId={companyId}
        />
      )}
      {deleteTrackingPrivilege && (
        <DeleteTracking
          companyId={companyId}
          trackingId={tracking._id}
        />
      )}
    </FlexWrapper>
  );
};

export { TrackingActionButtons };
