import { useNavigate } from 'react-router-dom';

import useStore from 'shared/store';
import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { useSaveCustomer } from 'entities/customer/api';
import { AddCustomer } from 'entities/customer/model';

import { CustomerVendorForm } from 'widgets/CustomerVendorForm/CustomerVendorForm';
import { useTranslation } from 'react-i18next';

const CustomerAddPage = () => {
  const companyId = useStore((state) => state.company?._id);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);

  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const formId = `customer-add-form-${companyId}`;

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  const mutation = useSaveCustomer(companyId);

  const handleSubmit = async (
    data: AddCustomer,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutation.mutateAsync({
        ...data,
        attachmentIDs: [
          ...filesToConnect
            .filter((file) => file.parent === formId)
            .map((filesToConnect) => filesToConnect._id),
        ],
      });

      setFilesToConnect(
        filesToConnect.filter((file) => file.parent !== formId),
      );

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.customer.view.getPath(_id));
      }

      successToast({
        message: t('Successfully added item message', { item: t('customer') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('customer') }),
      });
    }
  };

  return (
    <CustomerVendorForm
      handleSubmit={handleSubmit}
      isLoading={mutation.isLoading}
      formId={formId}
      variant="customer"
    />
  );
};

export { CustomerAddPage };
