import { useTranslation } from 'react-i18next';

import { TextareaField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const ActionDetailsField = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Action Details')}:`}>
      <TextareaField
        name="violation.actionDetail"
        height="150px"
      />
    </FormField>
  );
};

export { ActionDetailsField };
