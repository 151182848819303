import { useNavigate } from 'react-router-dom';

import { IRole, RoleFormType } from 'entities/role/model';
import { useUpdateRole } from 'entities/role/api';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { RoleForm } from 'features/roles/RoleForm';
import { ViewButton } from 'features/ViewButton';
import { DeleteRole } from 'features/roles/ActionsButtons';
import { useTranslation } from 'react-i18next';
import { convertToRoleFormData } from './convertToRoleFormData';
import { convertRoleFormData } from './convertRoleFormData';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  role: IRole;
  companyId: string;
}

const EditRole = ({ role, companyId }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { companyID, _id, credits, ...defaultValue } = role;
  const navigation = useNavigate();
  const { mutateAsync, isLoading } = useUpdateRole(companyId, _id);
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();
  const { deleteRolePrivilege } = LayoutPrivilege();

  const handleSubmit = async (
    data: RoleFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(convertRoleFormData(data));
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.roles.view.getPath(_id));
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.roles.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('role') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('role') }),
      });
    }
  };

  return (
    <RoleForm
      formId={`edit-role-form-${_id}-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <ViewButton
            onClick={() => navigation(routeBuilder.roles.view.getPath(_id))}
          />
          {role.active && !role.protected && deleteRolePrivilege && (
            <DeleteRole
              roleId={_id}
              companyId={companyId}
            />
          )}
        </>
      }
      defaultValues={convertToRoleFormData(defaultValue)}
    />
  );
};

export { EditRole };
