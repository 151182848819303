import { useMenuListStyle } from './useMenuListStyle';
import { useMenuStyle } from './useMenuStyle';
import { useControlStyle } from './useControlStyle';
import { useOptionStyle } from './useOptionStyle';
import { useIndicatorSeparatorStyle } from './useIndicatorSeparatorStyle';
import { useIndicatorContainerStyle } from './useIndicatorContainerStyle';
import { useDropdownIndicatorStyle } from './useDropdownIndicatorStyle';

export const useCustomSelectStyle = () => {
  return {
    menuList: useMenuListStyle,
    menu: useMenuStyle,
    control: useControlStyle,
    option: useOptionStyle,
    indicatorSeparator: useIndicatorSeparatorStyle,
    indicatorsContainer: useIndicatorContainerStyle,
    dropdownIndicator: useDropdownIndicatorStyle,
  };
};
