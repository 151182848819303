import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';
import useStore from 'shared/store';

import { useGetLocation } from 'entities/company/api';
import { EditLocation } from 'widgets/Location/EditLocation';

const EditLocationPageThrowable = () => {
  const { t } = useTranslation(['person', 'common']);
  const companyId = useStore((state) => state.company?._id);
  const { locationId } = useParams();

  if (!locationId) {
    throw new Error('LocationId not found');
  }

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  const { isLoading, error, data } = useGetLocation(companyId, locationId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('User not found')}</Text>;
  }

  return (
    <EditLocation
      companyId={companyId}
      location={data.data}
    />
  );
};

export const EditLocationPage = () => {
  return (
    <ErrorBoundary>
      <EditLocationPageThrowable />
    </ErrorBoundary>
  );
};
