import { useNavigate } from 'react-router-dom';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';
import { useSaveActivity } from 'entities/tracking/api/useSaveActivity';
import { ActivityFormType } from 'entities/tracking/model';
import { convertActivityFormData } from 'widgets/Activity/convertActivityFormData';
import { ActivityForm } from 'features/activites/ActivityForm';
import useStore from 'shared/store';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddActivity = ({ companyId }: IProps) => {
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);

  const { mutateAsync, isLoading } = useSaveActivity(companyId);
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const handleSubmit = async (
    data: ActivityFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync({
        ...convertActivityFormData(data),
        attachmentIDs: [
          ...filesToConnect
            .filter((file) => file.parent === `add-activity-form-${companyId}`)
            .map((filesToConnect) => filesToConnect._id),
        ],
      });

      setFilesToConnect(
        filesToConnect.filter(
          (file) => file.parent !== `add-activity-form-${companyId}`,
        ),
      );

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.activities.view.getPath(_id));
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(0);
      }
      successToast({
        message: t('Successfully added item message', { item: t('activity') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('activity') }),
      });
    }
  };

  return (
    <ActivityForm
      companyId={companyId}
      formId={`add-activity-form-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export { AddActivity };
