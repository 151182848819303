import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { SaveButton } from 'features/SaveButton';
import { ReactNode, useEffect, useState } from 'react';
import { FlexWrapper, Form } from 'shared/components';
import {
  ActiveField,
  DescriptionField,
  DocumentationField,
  NameField,
  useContainerProps,
} from 'features/FormCommons';
import { useNameValidation } from 'shared/utils/yup';
import { ActivityTopicField } from './ActivityTopicField';
import { ActivityFormType } from 'entities/tracking/model';
import { SubmitFormType } from 'shared/types';
import { useForm } from 'shared/components/Form';
import { useSaveActivityAttachment } from 'entities/attachments/api';
import useStore from 'shared/store';

type ActivityFormProps = {
  defaultValues?: ActivityFormType;
  handleSubmit: (
    data: ActivityFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  isLoading: boolean;
  formId: string;
  additionalActionButtons?: ReactNode;
  companyId: string;
};

export const defaultActivity: ActivityFormType = {
  name: '',
  active: true,
  description: '',
  topics: {},
  attachmentIDs: [],
};

const ActivityForm = ({
  defaultValues = defaultActivity,
  handleSubmit,
  formId,
  isLoading,
  additionalActionButtons,
  companyId,
}: ActivityFormProps) => {
  const validationSchema = useNameValidation();
  const containerProps = useContainerProps();

  const filesToConnect = useStore((state) => state.filesToConnect);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);

  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });

  useEffect(() => {
    if (formId.includes('edit-activity')) {
      cleanPersistentStore();
    }
  }, []);

  const { mutateAsync, isLoading: isLoadingSaveActivityAttachment } =
    useSaveActivityAttachment(companyId);

  return (
    <Form<ActivityFormType>
      id={formId}
      onSubmit={(data, { reset }) => {
        handleSubmit(
          data,
          () => {
            cleanPersistentStore();
            const filesLeft = filesToConnect.filter(
              (file) => file.parent !== formId,
            );
            setFilesToConnect(filesLeft);
          },
          submitType,
        );
        reset(defaultValues);
      }}
      containerProps={{
        ...containerProps,
      }}
      methods={methods}
    >
      <ActiveField />

      <NameField />

      <DescriptionField />

      <ActivityTopicField companyId={companyId} />

      <DocumentationField
        mutateAsync={mutateAsync}
        isLoading={isLoadingSaveActivityAttachment}
        attachments={
          defaultValues?.attachments ? defaultValues.attachments : []
        }
        entityName="activities"
        formId={formId}
      />

      <FlexWrapper>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { ActivityForm };
