import React, {
  ReactNode,
  forwardRef,
  memo,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  ActivitiesField,
  DepartmentsField,
  DescriptionField,
  LocationsField,
  TrackedByField,
  TrackingDateField,
  TrackingTime,
  useContainerProps,
} from 'features/FormCommons';

import {
  Flex,
  FlexWrapper,
  Form,
  Grid,
  getDefaultTimezonePickerValue,
} from 'shared/components';

import { EmployeeTracking } from 'widgets/EmployeeTracking';
import { CustomerTracking } from 'widgets/CustomerTracking';
import { VendorsTracking } from 'widgets/VendorsTracking';
import { TopicsListField } from 'widgets/TopicsListField';
import { SafetyViolation } from './SafetyViolation';
import { DocumentationSection } from 'widgets/DocumentationSection';
import { useSaveTrackingAttachment } from 'entities/attachments/api';
import { useGetActivity } from 'entities/tracking/api';
import { IViolation, TrackingFormType } from 'entities/tracking/model';
import { SubmitFormType } from 'shared/types';
import { SaveButton } from '../../SaveButton';
import { SaveAndNewButton } from '../../SaveAndNewButton';
import { useForm } from 'shared/components/Form';
import { useTrackingFormValidationSchema } from './utils';
import useStore from 'shared/store';
import { DateObject } from 'shared/utils';
import { EmailButton } from '../../TrackingEmail/EmailButton';
import { SMSButton } from '../TrackingSMS/SMSButton';
import { FormsToCompleteField } from './FormsToCompleteField';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';
import { IncidentLocationField } from 'features/FormCommons/components/IncidentLocationField';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';
import { ISelectFieldProps } from 'shared/components/Form/SelectField';

const defaultSelectProps: Partial<ISelectFieldProps> = {
  isSearchable: true,
  defaultSelectSingleOption: true,
};

const TrackingTimeWrapper = forwardRef(
  (props: { formId: string; value?: { time: string; zone: string } }, ref) => {
    const [time, setTime] = React.useState(props.value || { time: '', zone: '' });
    React.useEffect(() => {
      const currentTime = {
        time: DateObject(new Date()).getFullDateTimeFormat(),
        zone: getDefaultTimezonePickerValue().zone || '',
      };
      const defaultTime = props.formId.includes('edit')
        ? props.value || currentTime
        : currentTime;
      setTime(defaultTime);
    }, []);
    return (
      <TrackingTime
        trackingTime={time}
        ref={ref}
      />
    );
  },
);
const TrackingTimeWrapperMemoized = memo(
  TrackingTimeWrapper,
  (oldProps, newProps) => oldProps.formId === newProps.formId
  && oldProps.value?.time === newProps.value?.time
  && oldProps.value?.zone === newProps.value?.zone
);

TrackingTimeWrapper.displayName = 'TrackingTimeWrapper';
type TrackingFormProps = {
  companyId: string;
  defaultValues?: Partial<TrackingFormType>;
  isLoading: boolean;
  handleSubmit: (
    data: TrackingFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  formId: string;
  additionalActionButtons?: ReactNode;
  attendeesIDs?: string[];
  customerIDs?: string[];
  vendorIDs?: string[];
  violation?: IViolation;
  attachmentsFromApi?: { _id: string; filename: string }[];
};

const defaultData: TrackingFormType = {
  trackedAt: new Date(),
  trackingTime: {
    time: DateObject(new Date())
      .getDate()
      .concat(' ', DateObject(new Date()).getTime()),
    zone: getDefaultTimezonePickerValue().zone || '',
  },
  locations: [],
  departments: [],
  trackedBy: [],
  activities: '',
  description: '',
  topics: {},
  attendeesIDs: [],
  attendees: {},
  customerIDs: [],
  customers: {},
  vendorIDs: [],
  vendors: {},
  attachments: [],
  violation: {},
};

const TrackingForm = ({
  companyId,
  defaultValues = defaultData,
  isLoading,
  handleSubmit,
  formId,
  additionalActionButtons,
  attendeesIDs,
  customerIDs,
  vendorIDs,
  violation,
  attachmentsFromApi,
}: TrackingFormProps) => {
  const companyComponents = useStore((state) => state.company?.components || []);
  const hasCustomers = companyComponents.includes(ICompanyComponent.Customers);
  const hasVendors = companyComponents.includes(ICompanyComponent.Vendors);

  const validationSchema = useTrackingFormValidationSchema();
  const { smsPrivilege, emailPrivilege } = LayoutPrivilege();
  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues: { ...defaultData, ...defaultValues },
    formId,
  });
  const timePickerRef = useRef() as {
    current: { getTime: () => string; getZone: () => string };
  };
  const isEditing = formId.includes('edit-tracking');

  useEffect(() => {
    if (isEditing) {
      cleanPersistentStore();
    }
  }, []);
  // console.log('render');
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const [selectedActivityTopicsIds, setSelectedActivityTopicsIds] = useState<
    string[]
  >(
    defaultValues?.topics
      ? Object.values(defaultValues?.topics).map((topic) => topic._id)
      : [],
  );
  const [selectedActivityAttachment, setSelectedActivityAttachment] = useState<
    {
      _id: string;
      filename: string;
    }[]
  >([]);
  const filesToConnect = useStore((state) => state.filesToConnect);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);

  const {
    mutateAsync: mutateAsyncSaveTrackingAttachment,
    isLoading: isLoadingSaveTrackingAttachment,
  } = useSaveTrackingAttachment(companyId);

  const activityWatch = methods.watch('activities')?.toString() || '';
  const defaultActivity = defaultValues.activities || '';
  const activityId = !methods.watch('activities')
    ? defaultActivity
    : activityWatch;

  const { refetch } = useGetActivity(companyId, activityId, {
    useErrorBoundary: false,
  });

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  useEffect(() => {
    refetch().then(({ data }) => {
      if (data?.data.attachments) {
        setSelectedActivityAttachment(data?.data.attachments);
      } else {
        setSelectedActivityAttachment([]);
      }
      if (data?.data?.topicIDs?.length && data.data.topicIDs.length > 0) {
        setSelectedActivityTopicsIds(data.data.topicIDs);
      } else {
        setSelectedActivityTopicsIds([]);
      }
    });
  }, [activityId]);
  return (
    <Form<TrackingFormType>
      id={formId}
      onSubmit={(data, { reset }) => {
        const timeVal = (timePickerRef.current?.getTime?.() || '').split?.(' ')[1];
        const dateVal = (document.getElementById('tracking-datefield') as HTMLInputElement)?.value;
        const time = new Date(
          (timeVal && dateVal)
            ? `${dateVal} ${timeVal}`
            : data.trackedAt,
        );
        handleSubmit(
          {
            ...data,
            trackedAt: time,
            trackingTime: {
              time: time.toISOString(),
              zone:
                timePickerRef.current?.getZone?.() || data.trackingTime.zone,
            },
            attachments: filesToConnect
              .filter((file) => file.parent === formId)
              .map((file) => file._id),
          },
          () => {
            cleanPersistentStore();
            const filesLeft = filesToConnect.filter(
              (file) => file.parent !== formId,
            );
            setFilesToConnect(filesLeft);
          },
          submitType,
        );
        reset(defaultValues);
      }}
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <Grid
        gridTemplateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
        gap="20px"
        w="100%"
      >
        <Flex
          width="100%"
          direction={{
            base: 'column',
            md: 'row',
          }}
        >
          <Flex
            flexDir="column"
            flexGrow="1"
            gap={{
              base: '10px',
              xl: '20px',
            }}
          >
            <TrackingDateField id="tracking-datefield" />

            <TrackingTimeWrapperMemoized
              formId={formId}
              value={defaultValues.trackingTime}
              ref={timePickerRef}
            />
            <LocationsField
              companyId={companyId}
              name="locationID"
              inputProps={defaultSelectProps}
            />

            <DepartmentsField
              companyId={companyId}
              name="departmentID"
              inputProps={defaultSelectProps}
            />

            <ActivitiesField
              name="activities"
              companyId={companyId}
              inputProps={defaultSelectProps}
            />

            <TrackedByField
              companyId={companyId}
              inputProps={defaultSelectProps}
              isEditing={isEditing}
            />

            <IncidentLocationField />

            <DescriptionField isRequired />
          </Flex>
        </Flex>

        <EmployeeTracking attendeesIDs={attendeesIDs} />
        {hasCustomers && (
          <CustomerTracking customersIDs={customerIDs} />
        )}
        {hasVendors && (
          <VendorsTracking vendorIDs={vendorIDs} />
        )}
        {selectedActivityAttachment?.length > 0 && (
          <FormsToCompleteField
            entityName="trackings"
            attachments={selectedActivityAttachment}
          />
        )}

        <DocumentationSection
          mutateAsync={mutateAsyncSaveTrackingAttachment}
          isLoading={isLoadingSaveTrackingAttachment}
          entityName="trackings"
          formId={formId}
          attachments={attachmentsFromApi ? attachmentsFromApi : []}
        />
        <SafetyViolation
          companyId={companyId}
          violation={violation}
        />

        {selectedActivityTopicsIds?.length > 0 && (
          <TopicsListField
            selectedActivityTopicsIds={
              // eslint-disable-next-line no-nested-ternary
              selectedActivityTopicsIds?.length
                ? selectedActivityTopicsIds
                : defaultValues?.topics
                ? Object.values(defaultValues?.topics).map((topic) => topic._id)
                : []
            }
          />
        )}
      </Grid>

      <FlexWrapper wrap="wrap">
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {emailPrivilege && (
          <EmailButton
            type="submit"
            onClick={() => {
              setSubmitType(SubmitFormType.Email);
            }}
            isLoading={isLoading}
          />
        )}
        {smsPrivilege && (
          <SMSButton
            type="submit"
            onClick={() => {
              setSubmitType(SubmitFormType.SMS);
            }}
            isLoading={isLoading}
          />
        )}
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { TrackingForm };
