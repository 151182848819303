import { useNavigate } from 'react-router-dom';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { IAgency, IAgencyPayload } from 'entities/agency/model';
import { useUpdateAgency } from 'entities/agency/api';

import { AgencyForm } from 'features/agency/AgencyForm';
import { ViewButton } from 'features/ViewButton';
import { DeleteAgency } from 'features/agency/AgencyActionButtons/DeleteAgency';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
  agency: IAgency;
}
const EditAgency = ({ agency, companyId }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { companyID, credits, _id, ...defaultValue } = agency;
  const { mutateAsync, isLoading } = useUpdateAgency(companyId, _id);
  const navigation = useNavigate();
  const viewPath = routeBuilder.agency.view.getPath(_id);
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();

  const handleSubmit = async (
    data: IAgencyPayload,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(data);
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.agency.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('agency') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('agency') }),
      });
    }
  };

  return (
    <AgencyForm
      formId={`edit-agency-form-${_id}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          <DeleteAgency
            agencyId={_id}
            companyId={companyId}
          />
        </>
      }
      defaultValues={defaultValue}
    />
  );
};

export { EditAgency };
