import { Text, TextProps } from 'shared/components';

export type NavTextProps = TextProps & { isLoggedIn: boolean };

export const NavText = ({ isLoggedIn, ...props }: NavTextProps) => {
  const isPublic = !isLoggedIn;

  return (
    <Text
      color="white"
      size={{ base: isPublic ? 'md' : 'sm', xl: isPublic ? 'lg' : 'md' }}
      fontWeight={700}
      {...props}
    />
  );
};
