import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { usePhoneBasicValidation } from 'shared/utils/yup';

export const useCustomerVendorFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');
  const phoneValidationSchema = usePhoneBasicValidation();

  return object({
    name: string().required(requiredFieldMessage),
    phoneNumber: phoneValidationSchema.nullable(),
    contactPerson: object({
      phoneNumber: phoneValidationSchema.nullable(),
    }),
  });
};
