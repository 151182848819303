import { TrackingFormType } from 'entities/tracking/model';
import { TrackingFinalType } from 'entities/tracking/model/TrackingFinalType';
import { DateObject } from 'shared/utils';

export const convertTrackingFormData = (data: TrackingFormType) => {
  const {
    trackedAt,
    trackingTime,
    description,
    trackedBy,
    activities,
    topics,
    attachments,
    attendees,
    customers,
    vendors,
    violation,
    locationID,
    departmentID,
    incidentLocation
  } = data;

  const trackingDate = DateObject(trackedAt).getDate();
  const trackingsTime = DateObject(trackingTime.time).getTime();
  const concat = trackingDate.concat(' ', trackingsTime);

  const trackingData: TrackingFinalType = {
    trackedAt: {
      utc: trackedAt
        ? new Date(concat).toISOString()
        : new Date().toISOString(),
      timeZone: trackingTime.zone,
    },

    description,
    incidentLocation,
    topicIDs:
      topics &&
      Object.keys(topics)?.filter(
        (key) => topics && activities && topics[key].value,
      ),

    attendeesIDs:
      attendees &&
      Object.keys(attendees)?.filter(
        (key) => attendees && attendees[key].value,
      ),

    customerIDs:
      customers &&
      Object.keys(customers)?.filter(
        (key) => customers && customers[key].value,
      ),

    vendorIDs:
      vendors &&
      Object.keys(vendors)?.filter((key) => vendors && vendors[key].value),

    attachmentIDs: attachments,
    violation: violation && {
      safetyViolation: violation?.safetyViolation,
      description: violation?.description,
      actionID: violation?.actionID,
      actionDetail: violation?.actionDetail,
    },
  };

  const transformedData = Object.entries(trackingData).reduce(
    (acc, [key, value]) => {
      if (
        // eslint-disable-next-line no-nested-ternary
        value?.length
          ? value.length > 0
          : typeof value === 'object'
          ? Object.keys(value).length > 0
          : value
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[key] = value;
      }

      return acc;
    },
    {},
  );

  return {
    ...transformedData,
    trackedByID: trackedBy?.some((el) => el) ? trackedBy?.join() : null,
    locationID: locationID && locationID,
    departmentID: departmentID && departmentID,
    activityID: activities || null,
  } as Partial<TrackingFinalType>;
};
