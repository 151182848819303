import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { ICustomer } from 'entities/customer/model';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';

export const useGetCustomer = (
  companyId: string,
  customerId: string,
  options?: UseQueryOptions<AxiosResponse<ICustomer>, AxiosError>,
) => {
  return useReactQuery<ICustomer>(
    getCustomerKey(companyId, customerId),
    () => getCustomer(companyId, customerId),
    options,
  );
};

const getCustomer = (companyId: string, customerId: string) => {
  return httpClient.get<ICustomer>(
    `customers/${customerId}?companyID=${companyId}`,
  );
};

const getCustomerKey = (companyId: string, customerId: string) => [
  'customer',
  customerId,
  companyId,
];
