import { useTranslation } from 'react-i18next';

import { Text, ViewRow } from 'shared/components';
import { useState } from 'react';
import { ISort } from 'shared/types';
import { CustomersTable } from 'features/customer/CustomersTable';

interface IProps {
  customerIDs?: string[];
}

// TODO fix limit when filtering by array will be added
const limit = 1000;

const CustomersViewRow = ({ customerIDs }: IProps) => {
  const { t } = useTranslation('commonFields');
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<ISort[]>();

  return (
    <ViewRow label={t('Customers')}>
      {customerIDs && customerIDs.length > 0 ? (
        <CustomersTable
          onSortChange={(sort) => setSort(sort)}
          queryParams={{
            limit: limit,
            offset: offset,
            sort: sort,
          }}
          setOffset={(value) => setOffset(value)}
          customerIDs={customerIDs}
        />
      ) : (
        <Text>-</Text>
      )}
    </ViewRow>
  );
};

export { CustomersViewRow };
