import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteActivity = (activityId: string, companyId: string) => {
  return useMutation(() => {
    return deleteActivity(activityId, companyId);
  });
};

const deleteActivity = (activityId: string, companyId: string) => {
  return httpClient.delete(`activities/${activityId}?companyID=${companyId}`);
};
