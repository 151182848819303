import { useTranslation } from 'react-i18next';

import { FormControlProps, TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

interface IProps extends FormControlProps {
  label?: string;
  name?: string;
}

const PasswordField = ({
  label = 'Password',
  name = 'password',
  ...props
}: IProps) => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField
      label={`${t(label)}:`}
      {...props}
    >
      <TextField
        name={name}
        type="password"
      />
    </FormField>
  );
};

export { PasswordField };
