import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, SimpleGrid, SingleTableWrapper } from 'shared/components';
import { Privileges } from 'entities/role/model';
import { CheckboxListFilter } from '../../CheckboxListFilter';
import { PrivilegesFieldItem } from '../PrivilegesFieldItem';
import { privilegesBuilder } from 'shared/utils';
import useStore from 'shared/store';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

export type IPrivilegeItem = {
  _id: string;
  name: string;
  value: string;
};

const forbiddenPrivileges = [Privileges.FULL, Privileges.FULL_OVER_COMPANY];
const privileges: IPrivilegeItem[] = Object.values(Privileges)
  .filter(
    (privilege) =>
      !forbiddenPrivileges.find(
        (forbiddenPrivilege) => forbiddenPrivilege === privilege,
      ),
  )
  .map((privilege) => {
    return {
      _id: privilege,
      name: `${privilegesBuilder(privilege).resource}${
        privilegesBuilder(privilege).action
          ? `: ${privilegesBuilder(privilege).action}`
          : ''
      }`,
      value: privilege,
    };
  });
export const privilegesGroups: {
  [key: string]: IPrivilegeItem[];
} = privileges.reduce((acc, privilege) => {
  const group = privilege._id.slice(0, privilege._id.indexOf('/'));

  if (!acc[group]) {
    acc[group] = [];
  }

  acc[group].push(privilege);

  return acc;
}, {} as { [key: string]: IPrivilegeItem[] });

const PrivilegesField = () => {
  const { t } = useTranslation('commonFields');

  const [searchValue, setSearchValue] = useState('');

  const companyComponents = useStore((state) => state.company?.components || []);
  const hasRegulations = companyComponents.includes(ICompanyComponent.Regulations);
  const hasCustomers = companyComponents.includes(ICompanyComponent.Customers);
  const hasVendors = companyComponents.includes(ICompanyComponent.Vendors);

  return (
    <SingleTableWrapper
      title={t('Privileges')}
      childContainerStyle={{}}
    >
      <CheckboxListFilter
        itemsFormName={'privilegesObject'}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        filteredResults={privileges}
        hiddenSearchInput
      />
      <SimpleGrid
        columns={{ base: 1, md: 2, xl: 3 }}
        gap="16px"
        w="100%"
      >
        {Object.entries(privilegesGroups).map(([group, privileges]) => {
          if(group === ICompanyComponent.Vendors && !hasVendors) return null;
          if(group === ICompanyComponent.Customers && !hasCustomers) return null;
          if(group === ICompanyComponent.Regulations && !hasRegulations) return null;
          if(group === 'agencies' && !hasRegulations) return null;

          return (
            <Grid
              key={group}
              gridTemplateColumns={{
                base: '1fr',
                sm: 'repeat(2, 1fr)',
                md: '1fr',
              }}
              borderColor="secondary.500"
              borderRadius="20px"
              borderWidth="2px"
              padding="16px"
              gap={{ base: '10px', md: '16px' }}
            >
              {privileges.map((privilege) => {
                return (
                  <PrivilegesFieldItem
                    key={privilege._id}
                    privilegeItem={privilege}
                  />
                );
              })}
            </Grid>
          );
        })}
      </SimpleGrid>
    </SingleTableWrapper>
  );
};

export { PrivilegesField };
