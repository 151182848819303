import { useTranslation } from 'react-i18next';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';

export const useGetLocale = () => {
  const { i18n } = useTranslation();

  switch (i18n.language) {
    case 'en':
      return en;
    case 'es':
      return es;
    default:
      return en;
  }
};
