import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportDataTable } from 'features/ExportDataTable';

import { SearchBar } from 'widgets/SearchBar';

import { CustomersTable } from 'features/customer/CustomersTable';

import { FlexWrapper } from 'shared/components';
import { useGetParsedQueryParams } from 'shared/utils';
import { buildUrl } from 'shared/utils/httpClient';
import { ISort } from 'shared/types';
import useStore from 'shared/store';
import { lngs } from 'features/LanguageSwitcher/LanguageSwitcher';
import { ImportUploadTable } from 'widgets/ImportUploadTable';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

const limit = 10;

const CustomersListPage = () => {
  const { t } = useTranslation(['customersList', 'common']);
  const [globalFilter, setGlobalFilter] = useState('');
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState<ISort[]>();
  const { i18n } = useTranslation();
  const resolvedLng = i18n.resolvedLanguage as keyof typeof lngs;
  const { createCustomerPrivilege } = LayoutPrivilege();
  const [isImported, setIsImported] = useState(false);
  const query = useGetParsedQueryParams({
    sort,
    filters: globalFilter,
  });

  const companyId = useStore((state) => state.company?._id);
  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const customerFieldsTranslation = 'fields=name$Cliente,active$Activo';

  const downloadFileUrl = buildUrl(
    'customers',
    resolvedLng === 'es'
      ? `companyID=${companyId}&${query}&${customerFieldsTranslation}`
      : `companyID=${companyId}&${query}`,
  );

  return (
    <>
      <SearchBar
        title={t('Customers List')}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <CustomersTable
        isImported={isImported}
        onSortChange={(sort) => setSort(sort)}
        queryParams={{
          limit: limit,
          offset: offset,
          filters: globalFilter,
          sort: sort,
        }}
        setOffset={(value) => setOffset(value)}
      />
      <FlexWrapper>
        <ExportDataTable
          fileUrl={downloadFileUrl}
          fileName={t('Customers List')}
          title={t('Export All Data', { ns: 'common' })}
        />
      </FlexWrapper>
      {createCustomerPrivilege && (
        <FlexWrapper>
          <ImportUploadTable
            setIsImported={setIsImported}
            title={t('Import Customer From Excel')}
            companyId={companyId}
            entityName="customer"
          />
        </FlexWrapper>
      )}
    </>
  );
};

export { CustomersListPage };
