import { IRole, Privileges, RoleFormType } from '../../entities/role/model';

const convertRoleFormData = (formData: RoleFormType): Partial<IRole> => {
  const { name, active, privilegesObject } = formData;
  return {
    name,
    active,
    privileges: Object.keys(privilegesObject)
      .filter((key) => privilegesObject[key]?.value)
      .map((key) => privilegesObject[key]._id as Privileges),
  };
};

export { convertRoleFormData };
