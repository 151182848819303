import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteAction = () => {
  return useMutation(
    ({ actionId, companyId }: { actionId: string; companyId: string }) => {
      return deleteAction(actionId, companyId);
    },
  );
};

const deleteAction = (actionId: string, companyId: string) => {
  return httpClient.delete(`actions/${actionId}?companyID=${companyId}`);
};
