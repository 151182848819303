import { useTranslation } from 'react-i18next';

export const useCustomersColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'customer-name',
      sortedFields: ['name'],
      title: t('Customer'),
      isSortable: true,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active', { ns: 'commonFields' }),
      isSortable: true,
    },
  ];
};
