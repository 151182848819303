import { FieldPrototype } from './FieldPrototype';
import { Controller } from 'react-hook-form';
import { ZoneSelect } from '../ZoneSelect/ZoneSelect';

export interface IZonePickerField {
  name: string;
}

const ZoneSelectField = ({ name }: IZonePickerField) => {
  return (
    <FieldPrototype name={name}>
      <Controller
        name={name}
        render={({
          field: { onChange, value },
          formState: { isSubmitting },
        }) => {
          return (
            <ZoneSelect
              data-testid={name}
              isDisabled={isSubmitting}
              onChange={(value) => {
                onChange(value?.value);
              }}
              zone={value}
            />
          );
        }}
      />
    </FieldPrototype>
  );
};

export { ZoneSelectField };
