import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';

import { useGetCompany } from 'entities/company/api';
import { CompanyView } from 'features/company/CompanyView';
import { CompanyActionButtons } from 'widgets/CompanyActionButtons';
import { CreationDetails } from 'features/CreationDetails';

interface IProps {
  companyID?: string;
}

const ViewCompanyPageThrowable = ({ companyID }: IProps) => {
  const { t } = useTranslation(['departments', 'common']);
  const { companyId } = useParams();
  const id = companyId || companyID;
  if (!id) {
    throw new Error('CompanyId not found');
  }

  const { isLoading, error, data } = useGetCompany(id);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Company not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <CompanyView company={data.data} />
      <CompanyActionButtons
        companyId={id}
        active={data.data.active}
      />
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewCompanyPage = ({ companyID }: IProps) => {
  return (
    <ErrorBoundary>
      <ViewCompanyPageThrowable companyID={companyID} />
    </ErrorBoundary>
  );
};

export { ViewCompanyPage };
