import { useToast } from 'shared/utils';
import { httpClient } from 'shared/utils/httpClient';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

export enum ReportReportFileFormat {
  XLSX = 'xlsx',
  PDF = 'pdf',
}

export const useHandleDownloadReport = () => {
  const { errorToast } = useToast();
  const { t } = useTranslation('validationMessages');
  return async (fileUrl: string, format: ReportReportFileFormat) => {
    const fileUrlWithFormat =
      format === ReportReportFileFormat.PDF
        ? fileUrl
        : `${fileUrl}&format=${format}`;

    httpClient
      .get(fileUrlWithFormat, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse<Blob>) => {
        if (response.status === 204) {
          errorToast({
            message: t('No tracking events matched your selections'),
          });
        } else {
          const type = response.headers['content-type'];
          const blob = new Blob([response.data], {
            type: type,
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `Report.${format}`;
          link.click();
        }
      })
      .catch(() => {
        errorToast({
          message: t('Error during downloading file'),
        });
      });
  };
};
