import { ReactNode } from 'react';

import { MobileMenuItem } from './MobileMenuItem';
import {
  IMenuBuilderValue,
  menuProtectedBuilder,
  menuPublicBuilder,
} from 'shared/utils/navigation';
import { Privileges } from 'entities/role/model';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

export interface IMenuItemMobile {
  component: ReactNode;
  path: string | null;
  allowedPrivileges: Privileges[];
  componentName?: ICompanyComponent;
  items?: IMenuItemMobile[];
}

const mobileItemBuilder = (
  menuBuilderKeyArr: IMenuBuilderValue[],
): IMenuItemMobile[] => {
  return menuBuilderKeyArr.map((menuBuilderValue) => ({
    component: <MobileMenuItem>{menuBuilderValue.getLabel()}</MobileMenuItem>,
    componentName: menuBuilderValue.componentName,
    path: menuBuilderValue.getItems().length
      ? null
      : menuBuilderValue.getPath(),
    allowedPrivileges: menuBuilderValue.getAllowedPrivileges(),
    items: menuBuilderValue.getItems().map((subItem) => ({
      component: <MobileMenuItem>{subItem.label}</MobileMenuItem>,
      path: subItem.path,
      allowedPrivileges: subItem.allowedPrivileges,
    })),
  }));
};

const { company, tracking, customer, vendor, regulation, profile } =
  menuProtectedBuilder;


export const protectedItemsMobile: IMenuItemMobile[] = mobileItemBuilder([
  company,
  tracking,
  customer,
  vendor,
  regulation,
  profile,
]);

const { home, about, solutions, news, partners, covid, contact, signIn } =
  menuPublicBuilder;

export const publicItemsMobile: IMenuItemMobile[] = mobileItemBuilder([
  home,
  about,
  solutions,
  news,
  partners,
  covid,
  contact,
  signIn,
]);
