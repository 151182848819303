import { Icon, IconProps } from 'shared/components';

const CalendarIcon = (props: IconProps) => {
  return (
    <Icon
      width="26px"
      height="27px"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.09091 2C6.43821 2 5.90909 2.52807 5.90909 3.17949V4.94872H2.36364C1.05824 4.94872 0 6.00487 0 7.30769V22.641C0 23.9439 1.05824 25 2.36364 25H23.6364C24.9418 25 26 23.9439 26 22.641V7.30769C26 6.00487 24.9418 4.94872 23.6364 4.94872H20.0909V3.17949C20.0909 2.52807 19.5618 2 18.9091 2H17.7273C17.0746 2 16.5455 2.52807 16.5455 3.17949V4.94872H9.45455V3.17949C9.45455 2.52807 8.92543 2 8.27273 2H7.09091ZM3.71429 12.1329H22.2857V21.3543H3.71429V12.1329Z"
        fill="#4B5563"
      />
    </Icon>
  );
};

export { CalendarIcon };
