import { FunctionComponent, ReactNode } from 'react';
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from 'react-error-boundary';

import { ErrorPageStrategy } from './ErrorPageStrategy';

interface IProps {
  children: ReactNode;
  fallback?: FunctionComponent<FallbackProps>;
}

const ErrorBoundary = ({
  fallback = ({ error }: FallbackProps) => <ErrorPageStrategy error={error} />,
  children,
  ...restProps
}: IProps) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={fallback}
      {...restProps}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export { ErrorBoundary };
