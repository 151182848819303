import { Button, ButtonProps } from '../../../shared/components';
import { SearchIcon } from '../../../shared/icons';
import { useTranslation } from 'react-i18next';

const TrackingAdvancedSearchButton = (props: ButtonProps) => {
  const { t } = useTranslation('common');

  return (
    <Button
      leftIcon={<SearchIcon fill="secondary.500" />}
      {...props}
    >
      {t('Advanced Search')}
    </Button>
  );
};

export { TrackingAdvancedSearchButton };
