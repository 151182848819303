import { useTranslation } from 'react-i18next';

import { FormField, ZoneSelectField } from 'shared/components/Form';

const TimeZoneField = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Time Zone')}:`}>
      <ZoneSelectField name={'timeZone'} />
    </FormField>
  );
};

export { TimeZoneField };
