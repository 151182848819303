import { chakra, Text, Box, Flex, FlexProps } from 'shared/components';

type IProps = FlexProps & {
  label: string;
};

const ViewRow = ({ label, children, ...props }: IProps) => {
  return (
    <Flex
      alignItems="flex-start"
      gap={{ base: '0px', sm: '10px', md: '16px' }}
      flexDir={{ base: 'column', xl: 'row' }}
      m="0px"
      w="100%"
      overflow="auto"
      {...props}
    >
      <chakra.label
        w="100%"
        minW="140px"
        maxW={{ xl: '230px' }}
        h="27px"
        flex="none"
      >
        <Text fontWeight={{ base: 'bold', xl: 'normal' }}>{label}:</Text>
      </chakra.label>
      <Box w="100%">{children}</Box>
    </Flex>
  );
};

export { ViewRow };
