import { useTranslation } from 'react-i18next';
import { PersonRolesTable } from 'features/person/PersonRolesTable';
import { ViewRow } from 'shared/components';
import { IRole } from 'entities/role/model';

interface IProps {
  roles?: IRole[];
}

const RolesViewRow = ({ roles }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Role')}>
      {roles && <PersonRolesTable roles={roles} />}
    </ViewRow>
  );
};

export { RolesViewRow };
