export const textareaConfiguration = {
  defaultProps: {
    focusBorderColor: 'secondary.500',
    variant: 'outline',
  },
  variants: {
    outline: {
      borderColor: 'grey.700',
      borderWidth: '2px',
      borderRadius: '4px',
      _hover: {
        borderColor: 'secondary.500',
      },
      _active: {
        borderColor: 'secondary.500',
      },
      fontSize: {
        base: '1.4rem',
        sm: '1.6rem',
        md: '1.8rem',
      },
    },
  },
};
