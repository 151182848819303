import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { DateObject, useGetLocale } from 'shared/utils';
import { Flex, Text } from 'shared/components';
import { ArrowIcon } from 'shared/icons';
import { Orientation } from 'shared/icons/ArrowIcon';

type Props = ReactDatePickerCustomHeaderProps;

const DatepickerHeader = ({ decreaseMonth, increaseMonth, date }: Props) => {
  const dateObject = DateObject(date);
  const locale = useGetLocale();

  const arrowIconStyle = {
    w: '40px',
    h: '32px',
    p: '10px',
    _hover: {
      cursor: 'pointer',
    },
  };

  return (
    <Flex
      backgroundColor="white"
      gap="10px"
      alignItems="center"
      justifyContent="space-between"
      mb="20px"
    >
      <ArrowIcon
        {...arrowIconStyle}
        onClick={decreaseMonth}
      />
      <Text
        fontWeight="700"
        size="sm"
        textTransform="capitalize"
      >
        {dateObject.format('LLLL yyyy', { locale: locale })}
      </Text>
      <ArrowIcon
        {...arrowIconStyle}
        orientation={Orientation.Right}
        onClick={increaseMonth}
      />
    </Flex>
  );
};

export { DatepickerHeader };
