import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  firstName?: string;
}

const FirstNameViewRow = ({ firstName }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('First Name')}>
      <Text>{firstName || '-'}</Text>
    </ViewRow>
  );
};

export { FirstNameViewRow };
