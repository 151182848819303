import { useTranslation } from 'react-i18next';

import { SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';

type Props = {
  safetyViolation?: boolean;
  name?: string;
};

export { SafetyViolationField };

const SafetyViolationField = ({
  safetyViolation,
  name = 'violation.safetyViolation',
}: Props) => {
  const { t } = useTranslation('commonFields');

  const options = [
    { value: null, label: t('By Safety Violation') },
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ];

  return (
    <FormField label={`${t('Safety Violation')}:`}>
      {/*<YesNoSelectField name="safety-violation" />*/}
      <SelectField
        name={name}
        options={options}
        defaultValue={safetyViolation}
      />
    </FormField>
  );
};
