import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { routeBuilder } from 'shared/utils';
import { IRole } from 'entities/role/model';

interface IProps {
  role: IRole;
}
const RolesTableRow = ({ role }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(routeBuilder.roles.view.getPath(role._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{role.name}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{role.active ? t('Yes') : t('No')}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { RolesTableRow };
