import {
  Center, Table, Tr, Td, Th, Spinner,
  Tbody, Thead, TableContainer
} from '@chakra-ui/react';
import { ImportResults } from './ImportResults.type';
import { useTranslation } from 'react-i18next';

type ImportUploadModalProps = {
  isLoading: boolean;
  results: ImportResults;
}

const colorMappings = {
  info: '#4299E1',
  warning: '#ED8936',
  error: '#E53E3E',
  success: '#48BB78',
};

export default function ImportUploadModalContent(props: ImportUploadModalProps) {
  const { t } = useTranslation(['popupMessage']);

  const messageMap = props.results?.messageMap || [];
  const messages = props.results?.messages || [];
  return  props.isLoading ? (
        <Center w="100%" my="25px">
          <Spinner />
        </Center>
      ) : (
      <TableContainer
        background="white"
        w="100%"
        maxH="50vh"
        overflowY="scroll"
      >
        <Table fontSize={18} size="lg">
          <Thead
            background="white"
            position="sticky"
            top={0}
            zIndex="docked"
          >
            <Tr>
              <Th>{t('Row')}</Th>
              <Th>{t('Message type')}</Th>
              <Th>{t('Message')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {messages.map(({ message, row }) => (
              <Tr
                key={`${row}-${message}`}
                color={colorMappings[messageMap[message].type] || 'grey'}
              >
                <Td>{row}</Td>
                <Td>{messageMap[message].type}</Td>
                <Td>
                  {messageMap[message].msg}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      );
}