import { UseQueryOptions } from '@tanstack/react-query';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { useGetParsedQueryParams } from 'shared/utils';
import { IList, IQueryParams } from 'shared/types';

import { ICompany } from 'entities/company/model';

export const useGetCompanies = (
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<ICompany[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  return useReactQuery<IList<ICompany[]>>(
    getCompaniesKey(parsedQueryParams),
    () => getCompanies(parsedQueryParams),
    options,
  );
};

const getCompanies = (query?: string) => {
  return httpClient.get<IList<ICompany[]>>(buildUrl('companies', query));
};

const getCompaniesKey = (query?: string) => ['companies', query];
