import { useTranslation } from 'react-i18next';

import { Select } from 'shared/components';
import { ISelectOption } from 'shared/components/Select';

import { useGetUsers } from '../../entities/user/api';
import { Privileges } from '../../entities/role/model';

interface IProps {
  supervisorFilter: ISelectOption | null;
  onChange: (value: ISelectOption | null) => void;
  companyId: string;
}

const FilterBySupervisor = ({
  onChange,
  supervisorFilter,
  companyId,
}: IProps) => {
  const { t } = useTranslation('common');
  const { isLoading, data, isError } = useGetUsers(companyId, undefined, {
    useErrorBoundary: false,
  });

  const options = [
    { value: null, label: 'By Supervisor' },
    ...(data
      ? data.data.data
          .filter((user) =>
            user?.roles?.find((role) =>
              role?.privileges?.find(
                (privilege) => privilege === Privileges.SUPERVISOR,
              ),
            ),
          )
          .map((user) => {
            return {
              value: user._id,
              label: `${user.name.firstname} ${user.name.lastname}`,
            };
          })
      : []),
  ];

  return (
    <Select
      isLoading={isLoading}
      isDisabled={isError}
      placeholder={t('By Supervisor')}
      options={options}
      value={supervisorFilter}
      onChange={(newValue) => {
        const selectedOption = newValue as ISelectOption;
        if (!selectedOption.value) {
          onChange(null);
          return;
        }
        onChange(newValue as ISelectOption);
      }}
    />
  );
};

export { FilterBySupervisor };
