import create from 'zustand';
import { IAdvancedSearchForm } from '../../../entities/tracking/model/IAdvancedSearchForm';
import { formsKeys } from '../../../shared/utils';

interface IStore {
  values?: IAdvancedSearchForm;
  clearStore: () => void;
  setValues: (values: IAdvancedSearchForm) => void;
}

export const createAdvancedSearchModalStore = create<IStore>((set) => ({
  clearStore: () =>
    set((state) => {
      const getStorage = () => window.localStorage;
      getStorage().removeItem(formsKeys.tracking.advancedSearch.getKey());
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { values, ...restStore } = state;
      return {
        ...restStore,
        values: undefined,
      };
    }),

  setValues: (values) => set((state) => ({ ...state, values })),
}));
