import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Text, ErrorBoundary, ViewPageWrapper } from 'shared/components';
import useStore from 'shared/store';

import { useGetAgency } from 'entities/agency/api';

import { AgencyView } from 'features/agency/AgencyView';
import { AgencyActionButtons } from 'features/agency/AgencyActionButtons';
import { CreationDetails } from 'features/CreationDetails';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

const ViewAgencyPageThrowable = () => {
  const { t } = useTranslation('common');
  const { agencyId } = useParams();
  const companyId = useStore((state) => state.company?._id);
  const { fullSuperAdminPrivilege } = LayoutPrivilege();

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  if (!agencyId) {
    throw new Error('AgencyId not found');
  }

  const { isLoading, error, data } = useGetAgency(companyId, agencyId);

  if (isLoading) {
    return <Text size="lg">{t('Loading')}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('Agency not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <ViewPageWrapper>
      <AgencyView agency={data.data} />

      {fullSuperAdminPrivilege && (
        <AgencyActionButtons
          agencyId={agencyId}
          companyId={companyId}
        />
      )}
      {data?.data && <CreationDetails dates={data.data.credits} />}
    </ViewPageWrapper>
  );
};

const ViewAgencyPage = () => {
  return (
    <ErrorBoundary>
      <ViewAgencyPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewAgencyPage };
