import { BoxProps, Flex } from 'shared/components';

const PaginationContainer = ({ children, ...restProps }: BoxProps) => {
  return (
    <Flex
      sx={{
        '.react-paginate': {
          display: 'flex',
          flexDir: 'row',
          gap: '10px',
          listStyleType: 'none',
          alignItems: 'center',
          color: 'white',
        },
        '.active-page': {
          backgroundColor: 'secondary.500',
          borderRadius: '20px',
        },
        '.page-item': {
          width: { base: '30px', xl: '35px' },
          height: { base: '30px', xl: '35px' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      bgColor="primary.500"
      justifyContent={{ base: 'center', md: 'space-between' }}
      alignItems="center"
      p="10px 20px 10px 10px"
      gap="15px"
      flexWrap="wrap"
      {...restProps}
    >
      {children}
    </Flex>
  );
};

export { PaginationContainer };
