import { useGetActivities } from 'entities/tracking/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from 'shared/components/Form';
import { ISort } from 'shared/types';
import { TopicActivitiesTable } from './TopicActivitiesTable';
import { useTopicActivitiesColumnsConfiguration } from './utils/useTopicActivitiesConfiguration';
import { SearchInput } from 'features/CheckboxListFilter/SearchInput';

type Props = {
  companyId: string;
};

const TopicActivitiesField = ({ companyId }: Props) => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = useState('');
  const columnsConfiguration = useTopicActivitiesColumnsConfiguration();
  const [tableSort, setTableSort] = useState<ISort[]>([]);
  const [offset, setOffset] = useState(0);
  const limit = 10;

  const { data, isLoading } = useGetActivities(
    companyId,
    {
      fields: columnsConfiguration
        .map((columnConfiguration) => columnConfiguration.sortedFields)
        .flat(),
      filters: searchValue,
      sort:
        tableSort.length === 0
          ? [{ name: 'name', sortDirection: 'asc' }]
          : tableSort,
      offset: offset,
      limit: limit,
    },
    { useErrorBoundary: false },
  );

  return (
    <FormField
      gridTemplateColumns={{ md: '250px 1fr' }}
      label={`${t('Activities')}:`}
    >
      <SearchInput
        searchValue={searchValue}
        setSearchValue={(value) => setSearchValue(value)}
        pb={{ base: '10px', md: '15px', xl: '20px' }}
      />
      <TopicActivitiesTable
        activitiesList={data?.data}
        onSortChange={(sort) => setTableSort(sort)}
        isLoading={isLoading}
        limit={limit}
        offset={offset}
        setOffset={(value) => setOffset(value)}
      />
    </FormField>
  );
};

export { TopicActivitiesField };
