import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const NameField = (props?: { autoCapitalize?: string }) => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField
      isRequired
      label={`${t('Name')}:`}
    >
      <TextField name="name" {...props} />
    </FormField>
  );
};

export { NameField };
