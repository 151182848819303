import { IconButton, useDisclosure } from 'shared/components';
import { TableBodyCell } from 'shared/components/Table';
import { InfoIcon } from 'shared/icons';
import { MobileDetailsView } from './MobileDetailsView';
import { IUser } from 'entities/user/model';
import { ITracking } from 'entities/tracking/model';
import { IRegulation } from 'entities/regulation/model';
import { ICompany, ILocation } from 'entities/company/model';

interface IProps {
  user?: IUser;
  tracking?: ITracking;
  regulation?: IRegulation;
  company?: ICompany;
  location?: ILocation;
}

const TableBodyInfoCell = ({
  user,
  tracking,
  regulation,
  company,
  location,
}: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <TableBodyCell onClick={(e) => e.stopPropagation()}>
      <IconButton
        aria-label="button open a window with full information"
        icon={<InfoIcon />}
        onClick={onOpen}
      />
      <MobileDetailsView
        isOpen={isOpen}
        onClose={onClose}
        user={user}
        tracking={tracking}
        regulation={regulation}
        company={company}
        location={location}
      />
    </TableBodyCell>
  );
};

export { TableBodyInfoCell };
