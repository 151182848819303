import { routeBuilder } from 'shared/utils';
import { Privileges } from 'entities/role/model';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';

export interface IMenuBuilderSubItem {
  rootLabel?: string;
  key: string;
  label: string;
  path: string;
  allowedPrivileges: Privileges[];
}

export interface IMenuBuilderValue {
  componentName?: ICompanyComponent;
  rootLabel?: string;
  getLabel: (label?: string) => string;
  getPath: () => string;
  getAllowedPrivileges: () => Privileges[];
  getItems: () => IMenuBuilderSubItem[];
}

export interface IMenuBuilder {
  [key: string]: IMenuBuilderValue;
}

const menuProtectedBuilder: IMenuBuilder = Object.freeze({
  dashboard: {
    rootLabel: 'Dashboard',
    getLabel: (label: string | undefined = undefined) =>
      label || routeBuilder.dashboard.index.getLabel(),
    getPath: () => routeBuilder.dashboard.index.getPath(),
    getAllowedPrivileges: () =>
      routeBuilder.dashboard.index.getAllowedPrivileges(),
    getItems: () => [],
  },
  company: {
    rootLabel: 'Company',
    getLabel: (label: string | undefined = undefined) =>
      label || routeBuilder.company.index.getLabel(),
    getPath: () => routeBuilder.company.index.getLabel(),
    getAllowedPrivileges: () => [
      ...routeBuilder.company.index.getAllowedPrivileges(),
      ...routeBuilder.company.add.getAllowedPrivileges(),
      ...routeBuilder.personnel.index.getAllowedPrivileges(),
      ...routeBuilder.personnel.add.getAllowedPrivileges(),
      ...routeBuilder.roles.index.getAllowedPrivileges(),
      ...routeBuilder.roles.add.getAllowedPrivileges(),
      ...routeBuilder.locations.index.getAllowedPrivileges(),
      ...routeBuilder.locations.add.getAllowedPrivileges(),
      ...routeBuilder.departments.index.getAllowedPrivileges(),
      ...routeBuilder.departments.add.getAllowedPrivileges(),
    ],
    getItems: () => [
      {
        rootLabel: 'Company',
        key: 'company',
        label: routeBuilder.company.index.getLabel(),
        path: routeBuilder.company.index.getPath(),
        allowedPrivileges: routeBuilder.company.index.getAllowedPrivileges(),
      },
      {
        key: 'company',
        label: routeBuilder.company.add.getLabel(),
        path: routeBuilder.company.add.getPath(),
        allowedPrivileges: routeBuilder.company.add.getAllowedPrivileges(),
      },

      {
        rootLabel: 'Personnel',
        key: 'personnel',
        label: routeBuilder.personnel.index.getLabel(),
        path: routeBuilder.personnel.index.getPath(),
        allowedPrivileges: routeBuilder.personnel.index.getAllowedPrivileges(),
      },
      {
        key: 'personnel',
        label: routeBuilder.personnel.add.getLabel(),
        path: routeBuilder.personnel.add.getPath(),
        allowedPrivileges: routeBuilder.personnel.add.getAllowedPrivileges(),
      },

      {
        rootLabel: 'Roles',
        key: 'roles',
        label: routeBuilder.roles.index.getLabel(),
        path: routeBuilder.roles.index.getPath(),
        allowedPrivileges: routeBuilder.roles.index.getAllowedPrivileges(),
      },
      {
        key: 'roles',
        label: routeBuilder.roles.add.getLabel(),
        path: routeBuilder.roles.add.getPath(),
        allowedPrivileges: routeBuilder.roles.add.getAllowedPrivileges(),
      },

      {
        rootLabel: 'Locations',
        key: 'locations',
        label: routeBuilder.locations.index.getLabel(),
        path: routeBuilder.locations.index.getPath(),
        allowedPrivileges: routeBuilder.locations.index.getAllowedPrivileges(),
      },
      {
        key: 'locations',
        label: routeBuilder.locations.add.getLabel(),
        path: routeBuilder.locations.add.getPath(),
        allowedPrivileges: routeBuilder.locations.add.getAllowedPrivileges(),
      },

      {
        rootLabel: 'Departments',
        key: 'departments',
        label: routeBuilder.departments.index.getLabel(),
        path: routeBuilder.departments.index.getPath(),
        allowedPrivileges:
          routeBuilder.departments.index.getAllowedPrivileges(),
      },
      {
        key: 'departments',
        label: routeBuilder.departments.add.getLabel(),
        path: routeBuilder.departments.add.getPath(),
        allowedPrivileges: routeBuilder.departments.add.getAllowedPrivileges(),
      },
    ],
  },
  tracking: {
    rootLabel: 'Tracking',
    getLabel: (label: string | undefined = undefined) =>
      label || routeBuilder.tracking.index.getLabel(),
    getPath: () => routeBuilder.tracking.index.getLabel(),
    getAllowedPrivileges: () => [
      ...routeBuilder.tracking.index.getAllowedPrivileges(),
      ...routeBuilder.tracking.add.getAllowedPrivileges(),
      ...routeBuilder.actions.index.getAllowedPrivileges(),
      ...routeBuilder.actions.add.getAllowedPrivileges(),
      ...routeBuilder.topics.index.getAllowedPrivileges(),
      ...routeBuilder.topics.add.getAllowedPrivileges(),
      ...routeBuilder.report.index.getAllowedPrivileges(),
    ],
    getItems: () => [
      {
        rootLabel: 'Tracking',
        key: 'tracking',
        label: routeBuilder.tracking.index.getLabel(),
        path: routeBuilder.tracking.index.getPath(),
        allowedPrivileges: routeBuilder.tracking.index.getAllowedPrivileges(),
      },
      {
        key: 'tracking',
        label: routeBuilder.tracking.add.getLabel(),
        path: routeBuilder.tracking.add.getPath(),
        allowedPrivileges: routeBuilder.tracking.add.getAllowedPrivileges(),
      },
      {
        rootLabel: 'Actions',
        key: 'actions',
        label: routeBuilder.actions.index.getLabel(),
        path: routeBuilder.actions.index.getPath(),
        allowedPrivileges: routeBuilder.actions.index.getAllowedPrivileges(),
      },
      {
        key: 'actions',
        label: routeBuilder.actions.add.getLabel(),
        path: routeBuilder.actions.add.getPath(),
        allowedPrivileges: routeBuilder.actions.add.getAllowedPrivileges(),
      },
      {
        rootLabel: 'Activities',
        key: 'activities',
        label: routeBuilder.activities.index.getLabel(),
        path: routeBuilder.activities.index.getPath(),
        allowedPrivileges: routeBuilder.activities.index.getAllowedPrivileges(),
      },
      {
        key: 'activities',
        label: routeBuilder.activities.add.getLabel(),
        path: routeBuilder.activities.add.getPath(),
        allowedPrivileges: routeBuilder.activities.add.getAllowedPrivileges(),
      },
      {
        rootLabel: 'Topics',
        key: 'topics',
        label: routeBuilder.topics.index.getLabel(),
        path: routeBuilder.topics.index.getPath(),
        allowedPrivileges: routeBuilder.topics.index.getAllowedPrivileges(),
      },
      {
        key: 'topics',
        label: routeBuilder.topics.add.getLabel(),
        path: routeBuilder.topics.add.getPath(),
        allowedPrivileges: routeBuilder.topics.add.getAllowedPrivileges(),
      },
      {
        key: 'tracking',
        label: routeBuilder.report.index.getLabel(),
        path: routeBuilder.report.index.getPath(),
        allowedPrivileges: routeBuilder.report.index.getAllowedPrivileges(),
      },
    ],
  },
  customer: {
    rootLabel: 'Customer',
    componentName: ICompanyComponent.Customers,
    getLabel: (label: string | undefined = undefined) =>
      label || routeBuilder.customer.index.getLabel(),
    getPath: () => routeBuilder.customer.index.getPath(),
    getAllowedPrivileges: () => [
      ...routeBuilder.customer.index.getAllowedPrivileges(),
      ...routeBuilder.customer.add.getAllowedPrivileges(),
    ],
    getItems: () => [
      {
        rootLabel: 'Customer',
        key: 'customer',
        label: routeBuilder.customer.index.getLabel(),
        path: routeBuilder.customer.index.getPath(),
        allowedPrivileges: routeBuilder.customer.index.getAllowedPrivileges(),
      },
      {
        key: 'customer',
        label: routeBuilder.customer.add.getLabel(),
        path: routeBuilder.customer.add.getPath(),
        allowedPrivileges: routeBuilder.customer.add.getAllowedPrivileges(),
      },
    ],
  },
  vendor: {
    rootLabel: 'Vendor',
    componentName: ICompanyComponent.Vendors,
    getLabel: (label: string | undefined = undefined) =>
      label || routeBuilder.vendor.index.getLabel(),
    getPath: () => routeBuilder.vendor.index.getPath(),
    getAllowedPrivileges: () => [
      ...routeBuilder.vendor.index.getAllowedPrivileges(),
      ...routeBuilder.vendor.add.getAllowedPrivileges(),
    ],
    getItems: () => [
      {
        rootLabel: 'Vendor',
        key: 'vendor',
        label: routeBuilder.vendor.index.getLabel(),
        path: routeBuilder.vendor.index.getPath(),
        allowedPrivileges: routeBuilder.vendor.index.getAllowedPrivileges(),
      },
      {
        key: 'vendor',
        label: routeBuilder.vendor.add.getLabel(),
        path: routeBuilder.vendor.add.getPath(),
        allowedPrivileges: routeBuilder.vendor.add.getAllowedPrivileges(),
      },
    ],
  },
  regulation: {
    rootLabel: 'Regulation',
    componentName: ICompanyComponent.Regulations,
    getLabel: (label: string | undefined = undefined) =>
      label || routeBuilder.regulation.index.getLabel(),
    getPath: () => routeBuilder.regulation.index.getPath(),
    getAllowedPrivileges: () => [
      ...routeBuilder.regulation.index.getAllowedPrivileges(),
    ],
    getItems: () => [
      {
        rootLabel: 'Regulation',
        key: 'regulation',
        label: routeBuilder.regulation.index.getLabel(),
        path: routeBuilder.regulation.index.getPath(),
        allowedPrivileges: routeBuilder.regulation.index.getAllowedPrivileges(),
      },
      {
        key: 'regulation',
        label: routeBuilder.regulation.add.getLabel(),
        path: routeBuilder.regulation.add.getPath(),
        allowedPrivileges: routeBuilder.regulation.add.getAllowedPrivileges(),
      },
      {
        rootLabel: 'Agencies',
        key: 'agency',
        label: routeBuilder.agency.index.getLabel(),
        path: routeBuilder.agency.index.getPath(),
        allowedPrivileges: routeBuilder.agency.index.getAllowedPrivileges(),
      },
      {
        key: 'agency',
        label: routeBuilder.agency.add.getLabel(),
        path: routeBuilder.agency.add.getPath(),
        allowedPrivileges: routeBuilder.agency.add.getAllowedPrivileges(),
      },
    ],
  },
  profile: {
    getLabel: () => routeBuilder.profile.index.getLabel(),
    getPath: () => routeBuilder.profile.index.getPath(),
    getAllowedPrivileges: () =>
      routeBuilder.profile.index.getAllowedPrivileges(),
    getItems: () => [],
  },
});

const menuPublicBuilder: IMenuBuilder = Object.freeze({
  home: {
    getLabel: () => routeBuilder.home.index.getLabel(),
    getPath: () => routeBuilder.home.index.getPath(),
    getItems: () => [],
    getAllowedPrivileges: () => [],
  },
  about: {
    getLabel: () => routeBuilder.about.index.getLabel(),
    getPath: () => routeBuilder.about.index.getPath(),
    getItems: () => [
      {
        key: 'about',
        label: routeBuilder.about.company.getLabel(),
        path: routeBuilder.about.company.getPath(),
        allowedPrivileges: [],
      },
      {
        key: 'about',
        label: routeBuilder.about.board.getLabel(),
        path: routeBuilder.about.board.getPath(),
        allowedPrivileges: [],
      },
    ],
    getAllowedPrivileges: () => [],
  },
  solutions: {
    getLabel: () => routeBuilder.solutions.index.getLabel(),
    getPath: () => routeBuilder.solutions.index.getPath(),
    getItems: () => [
      {
        key: 'solutions',
        label: routeBuilder.solutions.overview.getLabel(),
        path: routeBuilder.solutions.overview.getPath(),
        allowedPrivileges: [],
      },
      {
        key: 'solutions',
        label: routeBuilder.solutions.industries.getLabel(),
        path: routeBuilder.solutions.industries.getPath(),
        allowedPrivileges: [],
      },
    ],
    getAllowedPrivileges: () => [],
  },
  news: {
    getLabel: () => routeBuilder.news.index.getLabel(),
    getPath: () => routeBuilder.news.index.getPath(),
    getItems: () => [],
    getAllowedPrivileges: () => [],
  },
  partners: {
    getLabel: () => routeBuilder.partners.index.getLabel(),
    getPath: () => routeBuilder.partners.index.getPath(),
    getItems: () => [
      {
        key: 'partners',
        label: routeBuilder.partners.corporations.getLabel(),
        path: routeBuilder.partners.corporations.getPath(),
        allowedPrivileges: [],
      },
      {
        key: 'partners',
        label: routeBuilder.partners.testimonials.getLabel(),
        path: routeBuilder.partners.testimonials.getPath(),
        allowedPrivileges: [],
      },
    ],
    getAllowedPrivileges: () => [],
  },
  covid: {
    getLabel: () => routeBuilder.covid.index.getLabel(),
    getPath: () => routeBuilder.covid.index.getPath(),
    getItems: () => [
      {
        key: 'covid',
        label: routeBuilder.covid.english.getLabel(),
        path: routeBuilder.covid.english.getPath(),
        allowedPrivileges: [],
      },
      {
        key: 'covid',
        label: routeBuilder.covid.spanish.getLabel(),
        path: routeBuilder.covid.spanish.getPath(),
        allowedPrivileges: [],
      },
    ],
    getAllowedPrivileges: () => [],
  },
  contact: {
    getLabel: () => routeBuilder.contact.index.getLabel(),
    getPath: () => routeBuilder.contact.index.getPath(),
    getItems: () => [],
    getAllowedPrivileges: () => [],
  },
  signIn: {
    getLabel: () => routeBuilder.signIn.index.getLabel(),
    getPath: () => routeBuilder.signIn.index.getPath(),
    getItems: () => [],
    getAllowedPrivileges: () => [],
  },
});

export { menuProtectedBuilder, menuPublicBuilder };
