export const LogoutIcon = () => {
  return (
    <svg
      width="31"
      height="27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        clipPath="url(#a)"
        fill="#72B42D"
        stroke="#00467F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.143 11c-.694 0-1.252.372-1.604.833A2.756 2.756 0 0 0 12 13.5c0 .613.188 1.206.54 1.667.351.461.909.833 1.603.833h13.714c.694 0 1.252-.372 1.604-.833.35-.461.539-1.054.539-1.667s-.188-1.206-.54-1.667c-.351-.461-.909-.833-1.603-.833H14.143Z" />
        <path d="m5.294 21.664-.71.696.71-.696a.163.163 0 0 1-.044-.114V5.45c0-.047.018-.087.044-.114a.113.113 0 0 1 .081-.036h4.5C11.069 5.3 12 4.317 12 3.15 12 1.983 11.07 1 9.875 1h-4.5a4.338 4.338 0 0 0-3.101 1.311A4.49 4.49 0 0 0 1 5.45v16.1a4.49 4.49 0 0 0 1.274 3.139A4.338 4.338 0 0 0 5.375 26h4.5C11.069 26 12 25.017 12 23.85c0-1.167-.93-2.15-2.125-2.15h-4.5a.113.113 0 0 1-.08-.036ZM24.787 13.5l-4.14 3.925a2.044 2.044 0 0 0 0 2.983c.832.79 2.16.79 2.992 0l5.714-5.416a2.044 2.044 0 0 0 0-2.984L23.64 6.592a2.188 2.188 0 0 0-2.992 0 2.044 2.044 0 0 0 0 2.983l.688-.726-.688.726 4.14 3.925Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            d="M0 0h31v27H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
