import useStore from 'shared/store';

import { AddAgency } from 'widgets/Agency/AddAgency';

const AddAgencyPage = () => {
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  return <AddAgency companyId={companyId} />;
};

export { AddAgencyPage };
