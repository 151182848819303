import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

export const useDeleteVendor = (vendorId: string, companyId: string) => {
  return useMutation(() => {
    return deleteCustomer(vendorId, companyId);
  });
};

const deleteCustomer = (vendorId: string, companyId: string) => {
  return httpClient.delete(`vendors/${vendorId}?companyID=${companyId}`);
};
