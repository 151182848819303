import { Table as ChakraTable, TableContainer } from '@chakra-ui/react';

import { Box, BoxProps } from 'shared/components';

import { getBorder } from 'shared/utils';
import { TableContextProvider } from './TableContextProvider';
import { IColumnConfiguration, ITableStore } from './types';
import { useEffect } from 'react';
import { ISort } from '../../types';

interface ITableProps extends BoxProps {
  data: ITableStore;
  columnsConfiguration?: IColumnConfiguration[];
  onSortChange?: (sort: ISort[]) => void;
}

const Table = ({
  children,
  data,
  columnsConfiguration,
  onSortChange,
  ...restProps
}: ITableProps) => {
  useEffect(() => {
    data.setColumnsConfiguration(columnsConfiguration || []);
  }, [columnsConfiguration]);

  useEffect(() => {
    onSortChange && onSortChange(data.sort);
  }, [data.sort]);

  return (
    <TableContextProvider data={data}>
      <Box
        borderRadius="20px"
        {...getBorder(2, 'solid', 'secondary.500')}
        overflow="hidden"
        w="100%"
        {...restProps}
      >
        <TableContainer>
          <ChakraTable variant="unstyled">{children}</ChakraTable>
        </TableContainer>
      </Box>
    </TableContextProvider>
  );
};

export { Table };
