import { useReactQuery } from 'shared/hooks/reactQuery';
import {
  buildUrl,
  httpClient,
  AxiosError,
  AxiosResponse,
} from 'shared/utils/httpClient';

import { IActivity } from 'entities/tracking/model';
import { IList, IQueryParams } from 'shared/types';
import { UseQueryOptions } from '@tanstack/react-query';
import { useGetLocale, useGetParsedQueryParams } from 'shared/utils';

export const useGetActivities = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IActivity[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IList<IActivity[]>>(
    getActivitiesKey(companyId, parsedQueryParams, defaultLang),
    () => getActivities(companyId, parsedQueryParams, defaultLang),
    options,
  );
};

const getActivities = (companyId: string, query?: string, lang = 'en') => {
  return httpClient.get<IList<IActivity[]>>(
    buildUrl('activities', `languageCode=${lang}&companyID=${companyId}&${query}`),
  );
};

const getActivitiesKey = (companyId: string, query: string, lang = 'en') => [
  'activities',
  companyId,
  query,
  lang
];
