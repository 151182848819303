import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ErrorBoundary, Text } from 'shared/components';

import { useGetMyUser, useGetUser } from 'entities/user/api';

import { EditPersonnel } from 'widgets/Personnel';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

const EditPersonnelPageThrowable = () => {
  const { t } = useTranslation(['person', 'common']);
  const { userId } = useParams();
  const { data: myUserData } = useGetMyUser(true);
  const { updatePersonnelPrivilege } = LayoutPrivilege();

  if (!userId) {
    throw new Error('UserId not found');
  }

  const { isLoading, error, data } = useGetUser(userId);

  const allowEditProfile =
    myUserData?.data._id === data?.data._id || updatePersonnelPrivilege;

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return <Text size="lg">{t('User not found')}</Text>;
  }

  if (!allowEditProfile) {
    throw new Error('not allowed');
  }

  return (
    allowEditProfile && (
      <EditPersonnel
        person={data.data}
        companyId={data.data.companyID}
      />
    )
  );
};

export const EditPersonnelPage = () => {
  return (
    <ErrorBoundary>
      <EditPersonnelPageThrowable />
    </ErrorBoundary>
  );
};
