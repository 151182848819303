import { useTranslation } from 'react-i18next';
import { useEffect, useReducer, useState } from 'react';

import { SingleTableWrapper } from 'shared/components';
import useStore from 'shared/store';
import { ISort } from 'shared/types';

import { CheckboxListFilter } from 'features/CheckboxListFilter';

import { useGetUsers } from 'entities/user/api';

import { EmployeeTrackingTable } from './EmployeeTrackingTable';
import { useEmployeeTrackingColumnsConfiguration } from './utils';
import { Box, Flex } from '@chakra-ui/react';
import { AttendeeListReduce } from '../../features/AttendeeListReduce';
import {
  attendeeListReduce,
  FilterActionType,
} from './utils/attendeeListReducer';
import { IUser } from 'entities/user/model';

const limit = 10;

type Props = {
  attendeesIDs?: string[];
};

const initialAttendeeListState = {
  locationFilter: null,
  departmentFilter: null,
  roleFilter: null,
  supervisorFilter: null,
  active: true,
};

const EmployeeTracking = ({ attendeesIDs }: Props) => {
  const { t } = useTranslation('trackingForm');
  const companyId = useStore((state) => state.company?._id);
  const columnsConfiguration = useEmployeeTrackingColumnsConfiguration();
  const [searchValue, setSearchValue] = useState('');
  const [tableSort, setTableSort] = useState<ISort[]>([]);
  const [offset, setOffset] = useState(0);
  const [filtered, setFiltered] = useState<IUser[]>([]);
  const [filters, dispatch] = useReducer(
    attendeeListReduce,
    initialAttendeeListState,
  );

  useEffect(() => {
    const isEmptyObject =
      filters &&
      Object.values(filters).every(
        (filter) => !filter || filter.value === null,
      );
    !isEmptyObject && setSearchValue('');
  }, [filters]);

  if (!companyId) {
    throw new Error('Company Id is missing');
  }

  const { data, isLoading } = useGetUsers(
    companyId,
    {
      fields: columnsConfiguration
        .map((columnConfiguration) => columnConfiguration.sortedFields)
        .flat(),
      offset: offset,
      limit: limit,
      filters: searchValue,
      sort:
        tableSort.length === 0
          ? [{ name: 'name.lastname', sortDirection: 'asc' }]
          : tableSort,
      searchFields: {
        active: typeof filters.active === 'boolean' ? filters.active : true,
        roleId: filters.roleFilter?.value,
        departmentId: filters.departmentFilter?.value,
        locationId: filters.locationFilter?.value,
        supervisorId: filters.supervisorFilter?.value,
      },
    },
    { useErrorBoundary: false },
    true
  );

  useEffect(() => {
    setFiltered(data?.data.data.filter(user => user.active) || []);
  }, [data?.data.data || []]);

  return (
    <SingleTableWrapper overflowX="hidden" title={t('Employee Tracking')}>
      <Flex
        flexDir="column"
        gap="20px"
        alignItems="center"
      >
        <Box w="100%">
          <CheckboxListFilter
            itemsFormName="attendees"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filteredResults={data?.data.data || []}
            resetAdvancedFilters={() =>
              dispatch({ type: FilterActionType.CLEAR_FILTERS })
            }
          />
          <EmployeeTrackingTable
            usersList={data?.data}
            attendeesIDs={attendeesIDs}
            onSortChange={(sort) => setTableSort(sort)}
            isLoading={isLoading}
            limit={limit}
            offset={offset}
            setOffset={(value) => setOffset(value)}
          />
        </Box>
        <AttendeeListReduce
          companyId={companyId}
          dispatch={dispatch}
          filters={filters}
        />
      </Flex>
    </SingleTableWrapper>
  );
};

export { EmployeeTracking };
