import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { DeleteLocation } from 'features/location/DeleteLocation';
import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  locationId: string;
  companyId: string;
  active?: boolean;
}

const LocationActionButtons = ({ locationId, companyId, active }: IProps) => {
  const {
    createLocationPrivilege,
    deleteLocationPrivilege,
    updateLocationPrivilege,
  } = LayoutPrivilege();
  return (
    <FlexWrapper>
      {createLocationPrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.locations.add.getPath()} />
      )}
      {active && deleteLocationPrivilege && (
        <DeleteLocation
          locationId={locationId}
          companyId={companyId}
        />
      )}
      {updateLocationPrivilege && (
        <EditItemButton
          redirectPath={routeBuilder.locations.edit.getPath(locationId)}
        />
      )}
    </FlexWrapper>
  );
};

export { LocationActionButtons };
