import { AddNewItemButton } from 'features/AddNewItemButton';

import { routeBuilder } from 'shared/utils';

const AddNewPersonButton = () => {
  return (
    <AddNewItemButton redirectPath={routeBuilder.personnel.add.getPath()} />
  );
};

export { AddNewPersonButton };
