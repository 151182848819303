import { useTranslation } from 'react-i18next';

export const useDepartmentsColumnsConfiguration = () => {
  const { t } = useTranslation(['commonFields', 'departments']);
  return [
    {
      name: 'department',
      sortedFields: ['name'],
      title: t('Department', { ns: 'departments' }),
      isSortable: true,
    },
    {
      name: 'active',
      sortedFields: ['active'],
      title: t('Active'),
      isSortable: true,
    },
  ];
};
