enum Privileges {
  FULL = '*',
  FULL_OVER_COMPANY = 'company/*',
  COMPANY_UPDATE = 'company/update',
  USERS_CREATE = 'users/create',
  USERS_READ = 'users/read',
  USERS_UPDATE = 'users/update',
  USERS_DELETE = 'users/delete',
  CUSTOMERS_CREATE = 'customers/create',
  CUSTOMERS_READ = 'customers/read',
  CUSTOMERS_UPDATE = 'customers/update',
  CUSTOMERS_DELETE = 'customers/delete',
  VENDORS_CREATE = 'vendors/create',
  VENDORS_READ = 'vendors/read',
  VENDORS_UPDATE = 'vendors/update',
  VENDORS_DELETE = 'vendors/delete',
  ROLES_CREATE = 'roles/create',
  ROLES_UPDATE = 'roles/update',
  ROLES_DELETE = 'roles/delete',
  ROLES_ASSIGN = 'roles/assign',
  TRACKINGS_CREATE = 'trackings/create',
  TRACKINGS_READ = 'trackings/read',
  TRACKINGS_UPDATE = 'trackings/update',
  TRACKINGS_DELETE = 'trackings/delete',
  TRACKINGS_AUTHORITY = 'trackings/authority',
  ACTIVITIES_CREATE = 'activities/create',
  ACTIVITIES_READ = 'activities/read',
  ACTIVITIES_UPDATE = 'activities/update',
  ACTIVITIES_DELETE = 'activities/delete',
  TOPICS_CREATE = 'topics/create',
  TOPICS_READ = 'topics/read',
  TOPICS_UPDATE = 'topics/update',
  TOPICS_DELETE = 'topics/delete',
  DEPARTMENTS_CREATE = 'departments/create',
  DEPARTMENTS_READ = 'departments/read',
  DEPARTMENTS_UPDATE = 'departments/update',
  DEPARTMENTS_DELETE = 'departments/delete',
  LOCATIONS_CREATE = 'locations/create',
  LOCATIONS_READ = 'locations/read',
  LOCATIONS_UPDATE = 'locations/update',
  LOCATIONS_DELETE = 'locations/delete',
  ACTIONS_CREATE = 'actions/create',
  ACTIONS_READ = 'actions/read',
  ACTIONS_UPDATE = 'actions/update',
  ACTIONS_DELETE = 'actions/delete',
  AGENCIES_READ = 'agencies/read',
  REGULATIONS_READ = 'regulations/read',
  SMS = 'sms',
  EMAIL = 'email',
  SUPERVISOR = 'supervisor',
}

export { Privileges };
