import { Controller } from 'react-hook-form';

import { Datepicker, DatepickerProps } from 'shared/components/Datepicker';

import { FieldPrototype } from './FieldPrototype';

export interface IDatepickerFieldProps
  extends Omit<DatepickerProps, 'selected' | 'onChange'> {
  name: string;
}

const DatepickerField = ({ name, ...restProps }: IDatepickerFieldProps) => {
  return (
    <FieldPrototype name={name}>
      <Controller
        name={name}
        render={(props) => {
          const {
            field: { onChange, onBlur, value },
            formState: { isSubmitting },
          } = props;
          return (
            <Datepicker
              selected={value}
              onChange={onChange}
              disabled={isSubmitting}
              onBlur={onBlur}
              {...restProps}
            />
          );
        }}
      />
    </FieldPrototype>
  );
};

export { DatepickerField };
