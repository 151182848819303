import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  departmentName?: string;
}

const DepartmentNameViewRow = ({ departmentName }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Department Name')}>
      <Text>{departmentName || '-'}</Text>
    </ViewRow>
  );
};

export { DepartmentNameViewRow };
