import { DepartmentFormType, IDepartment } from 'entities/company/model';
import { useUpdateDepartment } from 'entities/company/api/useUpdateDepartment';
import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';
import { useNavigate } from 'react-router-dom';
import { DepartmentForm } from 'features/departments/DepartmentForm';
import { DepartmentViewButton } from 'features/departments/DepartmentViewButton';
import { DeleteDepartment } from 'features/departments/DepartmentActionButtons';
import { useTranslation } from 'react-i18next';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  companyId: string;
  department: IDepartment;
}
const EditDepartment = ({ department, companyId }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { companyID, credits, _id, ...defaultValue } = department;
  const { mutateAsync, isLoading } = useUpdateDepartment(_id, companyId);
  const navigation = useNavigate();
  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();
  const { deleteDepartmentPrivilege } = LayoutPrivilege();

  const handleSubmit = async (
    data: DepartmentFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(data);

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.departments.view.getPath(_id));
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.departments.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', {
          item: t('department'),
        }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', {
          item: t('department'),
        }),
      });
    }
  };

  return (
    <DepartmentForm
      formId={`edit-department-form-${_id}-${companyId}`}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      additionalActionButtons={
        <>
          <DepartmentViewButton departmentId={_id} />
          {department.active && deleteDepartmentPrivilege && (
            <DeleteDepartment
              departmentId={_id}
              companyId={companyId}
            />
          )}
        </>
      }
      defaultValues={defaultValue}
    />
  );
};

export { EditDepartment };
