import { useTranslation } from 'react-i18next';

export const useTopicsTrackingColumnsConfiguration = () => {
  const { t } = useTranslation('trackingForm');
  return [
    {
      name: 'checkbox',
      sortedFields: [],
      title: '',
      isSortable: false,
    },
    {
      name: 'name',
      sortedFields: ['name'],
      title: t('Topic'),
      isSortable: true,
    },
  ];
};
