import { httpClient } from 'shared/utils/httpClient';
import { useReactQuery } from 'shared/hooks/reactQuery';
import { IAgency } from '../model';

export const useGetAgency = (companyId: string, agencyId: string) => {
  return useReactQuery<IAgency>(getAgencyKey(companyId, agencyId), () =>
    getAgency(companyId, agencyId),
  );
};

const getAgency = (companyId: string, agencyId: string) => {
  return httpClient.get<IAgency>(`agencies/${agencyId}?companyID=${companyId}`);
};

const getAgencyKey = (companyId: string, agencyId: string) => [
  'agency',
  companyId,
  agencyId,
];
