import { UseQueryOptions } from '@tanstack/react-query';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { buildUrl, httpClient } from 'shared/utils/httpClient';
import { IList, IQueryParams } from 'shared/types';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { useGetLocale, useGetParsedQueryParams } from 'shared/utils';

import { IRole } from 'entities/role/model';

export const useGetRoles = (
  companyId: string,
  query?: IQueryParams,
  options?: UseQueryOptions<AxiosResponse<IList<IRole[]>>, AxiosError>,
) => {
  const parsedQueryParams = useGetParsedQueryParams(query);
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IList<IRole[]>>(
    getRolesKey(companyId, parsedQueryParams, defaultLang),
    () => getRoles(companyId, parsedQueryParams, defaultLang),
    options,
  );
};

const getRoles = (companyId: string, query?: string, lang = 'en') => {
  return httpClient.get<IList<IRole[]>>(
    buildUrl('roles', `languageCode=${lang}&companyID=${companyId}&${query}`),
  );
};

const getRolesKey = (companyId: string, query: string, lang = 'en') => [
  'roles',
  companyId,
  query,
  lang
];
