import { ChangeEvent, useRef } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, InputGroup, InputGroupProps } from 'shared/components';
import { UploadIcon } from 'shared/icons';

type FileUploadProps = InputGroupProps & {
  register: UseFormRegisterReturn;
  accept?: string;
  multiple?: boolean;
  handleUploadClick: () => Promise<void>;
  handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const FileUpload = ({
  register,
  accept,
  handleUploadClick,
  handleFileChange,
  ...props
}: FileUploadProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref } = register as {
    ref: (instance: HTMLInputElement | null) => void;
  };

  const handleClick = () => inputRef.current?.click();
  const { t } = useTranslation('common');

  return (
    <InputGroup
      onClick={handleClick}
      {...props}
    >
      <input
        type="file"
        hidden
        accept={accept}
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
        onChange={handleFileChange}
      />
      <Button
        onClick={handleUploadClick}
        maxW={{ md: '166px' }}
        w={{ base: '100%' }}
        rightIcon={<UploadIcon />}
      >
        {t('Upload')}
      </Button>
    </InputGroup>
  );
};

export { FileUpload };
