import { useReactQuery } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { IActivity } from 'entities/tracking/model';
import { UseQueryOptions } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';
import { useGetLocale } from 'shared/utils';

export const useGetActivity = (
  companyId: string,
  activityId: string,
  options?: UseQueryOptions<AxiosResponse<IActivity>, AxiosError>,
) => {
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<IActivity>(
    getActivityKey(companyId, activityId, defaultLang),
    () => getActivity(companyId, activityId, defaultLang),
    options,
  );
};

const getActivity = (companyId: string, activityId: string, lang = 'en') => {
  return httpClient.get<IActivity>(
    `activities/${activityId}?languageCode=${lang}&companyID=${companyId}`,
  );
};

const getActivityKey = (companyId: string, activityId: string, lang = 'en') => [
  'activity',
  activityId,
  companyId,
  lang
];
