import { Icon, IconProps } from '@chakra-ui/react';

export const SuccessIcon = (props: IconProps) => (
  <Icon
    width="57"
    height="57"
    viewBox="0 0 57 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="28.5"
      cy="28.5"
      r="26.5"
      stroke="#72B42D"
      strokeWidth="3"
    />
    <path
      d="M14 28.75L23.5 38.25L42.5 19.25"
      stroke="#72B42D"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
