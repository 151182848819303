import { useTranslation } from 'react-i18next';

import { SingleTableWrapper, Grid } from 'shared/components';

import {
  ActionDetailsField,
  SafetyViolationField,
  ViolationActionField,
  ViolationDescriptionField,
} from 'features/FormCommons';
import { IViolation } from '../../../../entities/tracking/model';

type Props = {
  companyId: string;
  violation?: IViolation;
};

const SafetyViolation = ({ companyId, violation }: Props) => {
  const { t } = useTranslation('trackingForm');
  return (
    <SingleTableWrapper title={t('Safety Violation')}>
      <Grid gap={{ base: '10px', xl: '20px' }}>
        <SafetyViolationField safetyViolation={violation?.safetyViolation} />

        <ViolationDescriptionField />

        <ViolationActionField companyId={companyId} />

        <ActionDetailsField />
      </Grid>
    </SingleTableWrapper>
  );
};

export { SafetyViolation };
