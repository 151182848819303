import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AxiosError, AxiosResponse } from 'shared/utils/httpClient';

export const useReactQuery = <RData, TError = AxiosError>(
  key: QueryKey,
  fetcher: () => Promise<AxiosResponse<RData>>,
  options?: UseQueryOptions<AxiosResponse<RData>, TError>,
) => {
  const config = {
    useErrorBoundary: true,
    suspense: false,
    ...options,
  };

  return useQuery<AxiosResponse<RData>, TError>(key, fetcher, config);
};
