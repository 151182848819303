import { useTranslation } from 'react-i18next';

import {
  ViewRow,
  RadioProps,
  RadioGroup,
  Stack,
  Radio,
  Text,
} from 'shared/components';

interface IProps extends RadioProps {
  contactPreferences: { phone: boolean; email: boolean };
}

const NotificationViewRow = ({
  contactPreferences = {
    email: true,
    phone: true,
  },
}: IProps) => {
  const { t } = useTranslation('commonFields');
  const notificationsValue = ['Email', 'SMS', 'Both'];

  let defaultValue = '';

  if (!contactPreferences?.phone) {
    defaultValue = 'Email';
  }
  if (!contactPreferences?.email) {
    defaultValue = 'SMS';
  }

  return (
    <ViewRow label={t('Notification')}>
      <RadioGroup
        name="contactPreferences"
        value={defaultValue || 'Both'}
      >
        <Stack
          direction="row"
          gap={6}
        >
          {notificationsValue.map((value) => (
            <Radio
              key={value}
              value={value}
              sx={{
                w: '20px',
                h: '20px',
                '&[data-checked]': {
                  color: 'secondary.500',
                  bgColor: 'secondary.500',
                },
              }}
              isDisabled
            >
              <Text>{t(value)}</Text>
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </ViewRow>
  );
};

export { NotificationViewRow };
