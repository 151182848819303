import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

interface IProps {
  isRequired?: boolean;
}

const PhoneNumberField = ({ isRequired = false }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField
      isRequired={isRequired}
      label={`${t('Phone')}:`}
    >
      <TextField
        name="phoneNumber"
        type="tel"
      />
    </FormField>
  );
};

export { PhoneNumberField };
