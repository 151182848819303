import { useTranslation } from 'react-i18next';

import { DatepickerField, FormField } from 'shared/components/Form';

const TrackingDateField = (props: { id: string }) => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField
      label={`${t('Tracking Date')}:`}
      isRequired
    >
      <DatepickerField id={props.id} name="trackedAt" />
    </FormField>
  );
};

export { TrackingDateField };
