import { useMutation } from 'shared/hooks/reactQuery';

import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

import { IRole } from 'entities/role/model';

export const useSaveRole = (companyId: string) => {
  return useMutation((data: Partial<IRole>) => {
    return saveRole(data, companyId);
  });
};

const saveRole = (formData: Partial<IRole>, companyId: string) => {
  return httpClient.post<Partial<IRole>, AxiosResponse<{ _id: string }>>(
    `roles?companyID=${companyId}`,
    formData,
  );
};
