import { ReactNode } from 'react';

import useStore from 'shared/store';

import { Privileges } from 'entities/role/model';

interface IProps {
  allowedPrivileges?: Privileges[];
  children: ReactNode;
}

const ProtectedRoute = ({
  allowedPrivileges,
  children,
}: IProps): JSX.Element => {
  const userPrivileges = useStore((state) => state.privileges);

  if (userPrivileges.find((privilege) => privilege === Privileges.FULL)) {
    return children as JSX.Element;
  }

  const isAllowed = !!(
    allowedPrivileges &&
    (allowedPrivileges.length === 0 ||
      allowedPrivileges.find((role) => userPrivileges.includes(role)))
  );

  if (isAllowed) {
    return children as JSX.Element;
  }

  throw new Error('not allowed');
};

export { ProtectedRoute };
