import { useTranslation } from 'react-i18next';

export const useEmployeeTrackingColumnsConfiguration = () => {
  const { t } = useTranslation('commonFields');
  return [
    {
      name: 'checkbox',
      sortedFields: [],
      title: '',
      isSortable: false,
    },
    {
      name: 'name.lastName',
      sortedFields: ['name.lastname'],
      title: t('Last Name'),
      isSortable: true,
    },
    {
      name: 'name.firstName',
      sortedFields: ['name.firstname'],
      title: t('First Name'),
      isSortable: true,
    },
  ];
};
