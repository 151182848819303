import { useChangePassword } from 'entities/user/api';
import { IChangePassword } from 'entities/user/model';
import { ChangePasswordForm } from 'features/person/ChangePasswordForm';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useStore from 'shared/store';
import { SubmitFormType } from 'shared/types';
import { routeBuilder, useToast } from 'shared/utils';

const ChangePasswordPage = () => {
  const { userId } = useParams();
  if (!userId) {
    throw new Error('UserId not found');
  }
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  const { isLoading, mutateAsync } = useChangePassword(companyId);
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const handleSubmit = async (
    data: IChangePassword,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      await mutateAsync(data);

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.personnel.view.getPath(userId));
      }
      successToast({
        message: t('Successfully edited item message', { item: t('password') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('password') }),
      });
    }
  };

  return (
    <ChangePasswordForm
      formId={`change-password-${userId}-${companyId}`}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      userId={userId}
    />
  );
};

export { ChangePasswordPage };
