import shallow from 'zustand/shallow';

import { IUser } from 'entities/user/model';

import { Center, Checkbox, TableHeaderText } from 'shared/components';

import {
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  createTableStore,
  TableBodyCell,
} from 'shared/components/Table';

import { SupervisorTableRow } from './SupervisorTableRow';
import { useSupervisorColumnsConfiguration } from './utils';
import { useEffect, useMemo, useState } from 'react';
import { Pagination } from 'shared/components/Pagination';
import { TableSortChevrons } from 'shared/icons/TableSortChevrons/TableSortChevrons';
import { SortDirection } from '@tanstack/react-table';

interface IProps {
  isLoading?: boolean;
  users: IUser[];
  isAllChecked: boolean;
  switchAll: () => void;
}

const useSupervisorTableStore = createTableStore();
const dict = { '1': 'asc', '-1': 'desc' };

type sortKey = 'firstname' | 'lastname';

const SupervisorTable = ({
  users,
  isAllChecked,
  switchAll,
  isLoading,
}: IProps) => {
  const columnsConfiguration = useSupervisorColumnsConfiguration();
  const memoizedColumnsConfiguration = useMemo(() => columnsConfiguration, []);
  const tableState = useSupervisorTableStore((state) => state, shallow);
  const [page, setPage] = useState(0);

  const [sortConfig, setSortConfig] =
    useState<{ column: sortKey | null; dir: number; }>
      ({ column: null, dir: 1 });

  const [sortedUsers, setSortedUsers] = useState(users);

  useEffect(() => {
    const col = sortConfig.column;
    if (col) {
      setSortedUsers(
        [...users].sort((a, b) =>
          (a.name[col] || '').localeCompare(b.name[col] || '') * sortConfig.dir
        )
      );
    }
  }, [users, sortConfig]);

  useEffect(() => {
    setPage(0);
  }, [sortConfig]);

  const handleChangeSort = (column: sortKey) => () => {
    setSortConfig((config) => ({
      column,
      dir: config.column !== column ? 1 : config.dir * -1
    }));
  };

  return (
    <Table
      data={tableState}
      borderRadius="10px"
      columnsConfiguration={memoizedColumnsConfiguration}
    >
      <TableHead>
        <TableRow>
          {columnsConfiguration.map((column) => {
            if (column.name === 'checkbox') {
              return (
                <TableHeadCell
                  w="45px"
                  key={column.name}
                >
                  <Checkbox
                    isChecked={isAllChecked}
                    onChange={switchAll}
                  />
                </TableHeadCell>
              );
            }
            return (
              <TableHeadCell key={column.name}>
                <Center
                  justifyContent="space-between"
                  onClick={handleChangeSort(column.name.toLowerCase() as sortKey)}
                  cursor="pointer"
                >
                  <TableHeaderText>{column.title}</TableHeaderText>
                  <TableSortChevrons
                    sortDirection={
                      sortConfig.column === column.name.toLowerCase()
                        ? (dict[sortConfig.dir as unknown as ('1' | '-1')]
                            || 'asc') as SortDirection
                        : null
                    }
                  />
                </Center>
              </TableHeadCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody isLoading={isLoading}>
        {sortedUsers.slice(page*10, page*10+10).map((user) => {
          return (
            <SupervisorTableRow
              key={user._id}
              user={user}
            />
          );
        })}
        {sortedUsers.length >= 10 && (
          <TableRow>
            <TableBodyCell
              colSpan={columnsConfiguration.length}
              p="0"
            >
              <Pagination
                totalItems={sortedUsers.length}
                onPageChange={({ selected }) => setPage(selected)}
                forcePage={page}
                itemsPerPage={10}
                pageRangeDisplayed={3}
              />
            </TableBodyCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export { SupervisorTable };
