import { useTranslation } from 'react-i18next';

import { FormControlProps, TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const IncidentLocationField = (props: FormControlProps) => {
  const { t } = useTranslation('tracking');
  return (
    <FormField
      {...props}
      label={`${t('Incident Location')}:`}
    >
      <TextField
        name="incidentLocation"
      />
    </FormField>
  );
};

export { IncidentLocationField };
