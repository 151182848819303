import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';
import { TopicFinalType } from '../model';

export const useUpdateTopic = (topicId: string, companyId: string) => {
  return useMutation((data: TopicFinalType) => {
    return updateTopic(data, topicId, companyId);
  });
};

const updateTopic = (
  formData: TopicFinalType,
  topicId: string,
  companyId: string,
) => {
  return httpClient.patch(`topics/${topicId}?companyID=${companyId}`, formData);
};
