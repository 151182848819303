export enum ColumnsTitles {
  lastName = 'Last Name',
  firstName = 'First Name',
  locations = 'Locations',
  departments = 'Departments',
  supervisor = 'Supervisor',
  roles = 'Role',
  login = 'Login',
  active = 'Active',
  trackingDate = 'Tracking Date',
  activity = 'Activity',
  topic = 'Topic',
  trackedBy = 'Tracked By',
  attendee = 'Attendee',
  violation = 'Violation',
  agency = 'Agency',
  title = 'Title',
  section = 'Section',
  description = 'Description',
  loginCode = 'Login Code - Abbreviation',
  name = 'Name',
  naiscCode = 'NAISC Code - SIC Code',
  address = 'Address',
  phone = 'Phone',
  counts = 'Counts',
  trackings = 'Trackings',
  personnel = 'Personnel',
}
