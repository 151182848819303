import { Icon, IconProps } from 'shared/components';

const CancelIcon = (props: IconProps) => (
  <Icon
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6169 1.76777C17.5932 2.74408 17.7198 4.20036 16.8997 5.02046L5.02034 16.8999C4.20024 17.72 2.74396 17.5933 1.76765 16.617C0.791336 15.6407 0.664702 14.1844 1.4848 13.3643L13.3642 1.48492C14.1843 0.664823 15.6406 0.791456 16.6169 1.76777Z"
      fill="#72B42D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.76788 1.76777C2.74419 0.791456 4.20047 0.664823 5.02057 1.48492L16.9 13.3643C17.7201 14.1844 17.5934 15.6407 16.6171 16.617C15.6408 17.5933 14.1845 17.72 13.3644 16.8999L1.48503 5.02046C0.664934 4.20036 0.791567 2.74408 1.76788 1.76777Z"
      fill="#72B42D"
    />
  </Icon>
);

export { CancelIcon };
