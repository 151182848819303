import { ILocation } from 'entities/company/model';

import { Flex } from 'shared/components';

import {
  ActiveViewRow,
  AddressViewRow,
  LocationNameViewRow,
  NearestCrossStreetViewRow,
  PhoneViewRow,
  ZoneViewRow,
} from 'features/ViewCommonFields';

interface IProps {
  location: ILocation;
}

const LocationView = ({ location }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <LocationNameViewRow locationName={location.name} />
      <PhoneViewRow phone={location?.phoneNumber || ''} />
      <AddressViewRow address={location.address} />
      <NearestCrossStreetViewRow
        nearestCrossStreet={location?.address?.nearestCrossStreet}
      />
      <ZoneViewRow zone={location.timeZone} />
      <ActiveViewRow active={location.active} />
    </Flex>
  );
};

export { LocationView };
