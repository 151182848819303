import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStore from 'shared/store';
import { ErrorBoundary, Text } from 'shared/components';
import { useGetActivity } from 'entities/tracking/api';
import { EditActivity } from 'widgets/Activity';

const EditActivityPageThrowable = () => {
  const { t } = useTranslation(['common', 'activities']);
  const companyId = useStore((state) => state.company?._id);
  const { activityId } = useParams();

  if (!companyId) {
    throw new Error('Missing companyID');
  }
  if (!activityId) {
    throw new Error('ActivityId not found');
  }

  const { isLoading, error, data } = useGetActivity(companyId, activityId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    return (
      <Text size="lg">{t('Activity not found', { ns: 'activities' })}</Text>
    );
  }

  return (
    <EditActivity
      activity={data.data}
      companyId={data.data.companyID}
    />
  );
};

export const EditActivityPage = () => {
  return (
    <ErrorBoundary>
      <EditActivityPageThrowable />
    </ErrorBoundary>
  );
};
