import { Icon, IconProps } from 'shared/components';

const ViewIcon = (props: IconProps) => (
  <Icon
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0001 4.63074C11.9101 4.63074 9.03653 6.21109 6.7201 8.15359C3.39332 10.9457 1.23225 14.4354 1.23225 14.4354C1.01796 14.7815 1.01796 15.2186 1.23225 15.5647C1.23225 15.5647 3.39332 19.0543 6.7201 21.8465C9.03653 23.789 11.9101 25.3693 15.0001 25.3693C18.0901 25.3693 20.9637 23.789 23.2801 21.8465C26.6069 19.0543 28.768 15.5647 28.768 15.5647C28.9822 15.2186 28.9822 14.7815 28.768 14.4354C28.768 14.4354 26.6069 10.9457 23.2801 8.15359C20.9637 6.21109 18.0901 4.63074 15.0001 4.63074ZM14.9165 9.98788C12.1501 9.98788 9.90439 12.2336 9.90439 15C9.90439 17.7665 12.1501 20.0122 14.9165 20.0122C17.684 20.0122 19.9297 17.7665 19.9297 15C19.9297 12.2336 17.684 9.98788 14.9165 9.98788ZM14.9165 12.1307C16.5012 12.1307 17.7869 13.4165 17.7869 15C17.7869 16.5836 16.5012 17.8693 14.9165 17.8693C13.333 17.8693 12.0472 16.5836 12.0472 15C12.0472 13.4165 13.333 12.1307 14.9165 12.1307Z"
      fill="#72B42D"
    />
  </Icon>
);

export { ViewIcon };
