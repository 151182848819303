import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Flex } from 'shared/components';

import { ZoneSelect } from 'shared/components/ZoneSelect';
import { ITimeZonePickerValue } from './ITimeZonePickerValue';
import { TimePicker } from '../TimePicker/TimePicker';

interface IProps {
  onChange: (value: ITimeZonePickerValue) => void;
  value: ITimeZonePickerValue | null;
  isDisabled?: boolean;
}

const TimeZonePicker = forwardRef(
  ({ onChange, isDisabled, value }: IProps, ref) => {
    const [time, setTime] = useState<undefined | string>(value?.time);
    const [zone, setZone] = useState<undefined | string>(value?.zone);
    useImperativeHandle(ref, () => {
      return {
        getTime: () => time,
        getZone: () => zone,
        setTime,
        setZone,
      };
    });

    useEffect(() => {
      setTime(value?.time);
    }, []);

    useEffect(() => {
      if (value?.time) setTime(value.time);
      if (value?.zone) setZone(value.zone);
    }, [value?.time || '', value?.zone || '']);


    useEffect(() => {
      onChange({ time, zone });
    }, [time, zone]);

    return (
      <Flex
        gap="5px"
        flexDir={{ base: 'column', md: 'row', lg: 'column', xl: 'row' }}
      >
        <TimePicker
          onChange={setTime}
          time={time}
        />
        <ZoneSelect
          zone={zone}
          isDisabled={isDisabled}
          onChange={(value) => {
            setZone(value?.value);
          }}
        />
      </Flex>
    );
  },
);

TimeZonePicker.displayName = 'TimeZonePicker';

export { TimeZonePicker };
