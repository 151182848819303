export const textConfiguration = {
  sizes: {
    '2xs': {
      fontSize: '1.2rem',
    },
    xs: {
      fontSize: '1.4rem',
    },
    sm: {
      fontSize: '1.6rem',
    },
    md: {
      fontSize: '1.8rem',
    },
    lg: {
      fontSize: '2rem',
    },
    xl: {
      fontSize: '2.2rem',
    },
    '2xl': {
      fontSize: '2.4rem',
    },
    '3xl': {
      fontSize: '3.0rem',
    },
    '4xl': {
      fontSize: '4.0rem',
    },
    '5xl': {
      fontSize: '5.0rem',
    },
  },
  defaultProps: {
    size: {
      base: 'xs',
      sm: 'sm',
      '2xl': 'md',
    },
  },
};
