import {
  ITextFieldProps,
  FormControl,
  FormLabel,
  Text,
} from 'shared/components';

import { ResetPasswordFormInput } from './ResetPasswordFormInput';

interface IProps {
  label: string;
  inputProps: ITextFieldProps;
}

const ResetPasswordFormField = ({ label, inputProps }: IProps) => {
  return (
    <FormControl
      gap="10px"
      display={'flex'}
      flexDirection="column"
    >
      <FormLabel
        lineHeight="27px"
        fontWeight={700}
      >
        <Text size={{ base: 'md', md: 'lg', xl: 'xl' }}>{label}</Text>
      </FormLabel>
      <ResetPasswordFormInput {...inputProps} />
    </FormControl>
  );
};

export { ResetPasswordFormField };
