import { Box } from '@chakra-ui/react';

import { Flex } from 'shared/components';
import { Breadcrumbs } from 'features/Breadcrumbs';
import { CompanySwitcher } from 'features/CompanySwitcher';

const BreadcrumbsBar = () => {
  return (
    <Flex
      paddingY="15px"
      paddingX={{ base: '20px', lg: '30px' }}
      backgroundColor={'grey.500'}
      alignItems={{ base: 'initial', lg: 'center' }}
      justifyContent="space-between"
      gap={{ base: '10px', lg: '20px' }}
      flexDir={{
        base: 'column',
        lg: 'row',
      }}
    >
      <Box
        maxW={{ base: '100%', lg: '60%' }}
        overflowX="auto"
        sx={{
          '&::-webkitScrollbar': {
            display: 'none',
          },
          '&': {
            MsOverflowStyle: 'none' /* IE and Edge */,
            scrollbarWidth: 'none' /* Firefox */,
          },
        }}
      >
        <Breadcrumbs />
      </Box>
      <CompanySwitcher />
    </Flex>
  );
};

export { BreadcrumbsBar };
