import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetUser } from 'entities/user/api';

import { PersonViewContainer } from 'widgets/PersonViewContainer';

import { Text, ErrorBoundary } from 'shared/components';
import useStore from 'shared/store';

const ViewPersonPageThrowable = () => {
  const { t } = useTranslation(['person', 'common']);
  const { userId } = useParams();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    throw new Error('CompanyId not found');
  }

  if (!userId) {
    throw new Error('UserId not found');
  }

  const { isLoading, error, data } = useGetUser(userId);

  if (isLoading) {
    return <Text size="lg">{t('Loading', { ns: 'common' })}...</Text>;
  }

  if (!data) {
    return <Text size="lg">{t('User not found')}</Text>;
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <PersonViewContainer
      person={data.data}
      companyId={companyId}
    />
  );
};

const ViewPersonPage = () => {
  return (
    <ErrorBoundary>
      <ViewPersonPageThrowable />
    </ErrorBoundary>
  );
};

export { ViewPersonPage };
