import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

export const useRoleNameValidation = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');
  const nameExistsMessage = t('Name already exists');
  const systemRoleNames = ['database admin', 'supervisor', 'employee'];

  return object({
    name: string()
      .test('systemRoleNames', nameExistsMessage, (value) =>
        !value ? true : !systemRoleNames.includes(value.toLowerCase()),
      )
      .required(requiredFieldMessage),
  });
};
