import { useTranslation } from 'react-i18next';

import { TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

interface IProps {
  isRequired?: boolean;
}

const AbbreviationField = ({ isRequired = false }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField
      isRequired={isRequired}
      label={`${t('Abbreviation')}:`}
    >
      <TextField name="abbreviation" />
    </FormField>
  );
};

export { AbbreviationField };
