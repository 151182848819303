import { AddCustomer } from 'entities/customer/model';
import { AddVendor } from 'entities/vendor/model';
import { AddCustomerVendorFormData } from '../CustomerVendorForm';

export const purifyCustomerVendorFormData = (
  data: AddCustomerVendorFormData,
): AddVendor | AddCustomer => {
  return {
    ...data,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    phoneNumber: data.phoneNumber !== '' ? data.phoneNumber : null,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //  @ts-ignore
    contactPerson:
      data.contactPerson?.name || data.contactPerson?.phoneNumber
        ? {
            name:
              data.contactPerson.name !== '' ? data.contactPerson.name : null,
            phoneNumber:
              data.contactPerson.phoneNumber !== ''
                ? data.contactPerson.phoneNumber
                : null,
          }
        : null,
  };
};
