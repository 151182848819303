import {
  TableHead,
  TableHeadCell,
  TableHeadSortableCell,
  TableHeaderText,
  TableRow,
} from 'shared/components/Table';
interface IProps {
  isMobile?: boolean;
  columnsConfiguration: {
    name: string;
    sortedFields: string[];
    title: string;
    isSortable: boolean;
  }[];
}

const ResponsiveHeadTableRow = ({ isMobile, columnsConfiguration }: IProps) => {
  return (
    <TableHead>
      <TableRow>
        {isMobile ? (
          <>
            {columnsConfiguration.map(
              (column, i) =>
                i < 2 &&
                (column.isSortable ? (
                  <TableHeadSortableCell
                    key={column.name}
                    columnName={column.name}
                  >
                    <TableHeaderText>{column.title}</TableHeaderText>
                  </TableHeadSortableCell>
                ) : (
                  <TableHeadCell key={column.name}>
                    <TableHeaderText>{column.title}</TableHeaderText>
                  </TableHeadCell>
                )),
            )}

            <TableHeadCell>
              <TableHeaderText>{'Info'}</TableHeaderText>
            </TableHeadCell>
          </>
        ) : (
          columnsConfiguration.map(({ title, isSortable, name }) => {
            return isSortable ? (
              <TableHeadSortableCell
                key={name}
                columnName={name}
              >
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadSortableCell>
            ) : (
              <TableHeadCell key={name}>
                <TableHeaderText>{title}</TableHeaderText>
              </TableHeadCell>
            );
          })
        )}
      </TableRow>
    </TableHead>
  );
};

export { ResponsiveHeadTableRow };
