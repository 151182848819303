import { Route, Routes } from 'react-router-dom';

import { LoginPage } from 'pages/LoginPage';
import { HomePage } from 'pages/HomePage';
import { AddPersonnelPage } from 'pages/Company/Personnel/AddPersonnelPage';
import { PersonnelList } from 'pages/Company/Personnel/PersonnelList';
import { EditPersonnelPage } from 'pages/Company/Personnel/EditPersonnelPage';
import { AddTrackingPage } from 'pages/Tracking/Add';
import { ProfilePage } from 'pages/ProfilePage';
import { CompanyListPage } from 'pages/Company/List';
import { Providers } from 'app/providers/Providers';

import {
  ErrorBoundary,
  Layout,
  ProtectedLayout,
  ProtectedRoute,
  PublicLayout,
} from 'shared/components';

import { ViewPersonPage } from 'pages/Company/Personnel/ViewPersonPage';
import { VendorViewPage } from 'pages/Vendor/View';
import { ViewCompanyPage } from 'pages/Company/ViewCompanyPage';
import { ViewLocationPage } from 'pages/Company/Locations/View';
import { ViewDepartmentsPage } from 'pages/Company/Departments/ViewDepartmentsPage';
import { ViewRolesPage } from 'pages/Company/Personnel/Roles/ViewRolesPage';
import { ViewActionPage } from 'pages/Tracking/Actions/ViewActionPage';
import { ViewCustomerPage } from 'pages/Customer/View';

import { AddCompanyPage } from 'pages/Company/AddCompanyPage';
import { EditCompanyPage } from 'pages/Company/EditCompanyPage';
import { LocationsListPage } from 'pages/Company/Locations/List';
import { AddLocationsPage } from 'pages/Company/Locations/AddLocationsPage';
import { DepartmentsList } from 'pages/Company/Departments/DepartmentsList';
import { AddDepartmentsPage } from 'pages/Company/Departments/AddDepartmentsPage';
import { EditDepartmentPage } from 'pages/Company/Departments/EditDepartmentPage';
import { RolesList } from 'pages/Company/Personnel/Roles/RolesList';
import { AddRolesPage } from 'pages/Company/Personnel/Roles/AddRolesPage';
import { EditRolesPage } from 'pages/Company/Personnel/Roles/EditRolesPage';
import { routeBuilder } from 'shared/utils';
import { AddActionPage } from 'pages/Tracking/Actions/AddActionPage';
import { ActionsList } from 'pages/Tracking/Actions/ActionsList';
import { EditActionPage } from 'pages/Tracking/Actions/EditActionPage';
import { EditLocationPage } from 'pages/Company/Locations/EditLocationPage';
import { CustomerAddPage } from '../pages/Customer/Add/CustomerAddPage';
import { CustomerEditPage } from 'pages/Customer/Edit';
import { CustomersListPage } from 'pages/Customer/List';
import { TopicsList } from 'pages/Tracking/Topics/TopicsList';
import { VendorAddPage } from 'pages/Vendor/Add';
import { VendorEditPage } from 'pages/Vendor/Edit';
import { VendorsListPage } from 'pages/Vendor/List';
import { AddTopicPage } from 'pages/Tracking/Topics/AddTopicPage';
import { ActivitiesListPage } from 'pages/Tracking/Activities/ActivitiesListPage';
import { AddAgencyPage } from 'pages/Regulation/Agencies/Add';
import { ViewAgencyPage } from 'pages/Regulation/Agencies/View';
import { EditAgencyPage } from 'pages/Regulation/Agencies/Edit';
import { ListAgencyPage } from 'pages/Regulation/Agencies/List';
import { AddActivityPage } from 'pages/Tracking/Activities/AddActivityPage';
import { ListRegulationPage } from 'pages/Regulation/List';
import { AddRegulationPage } from 'pages/Regulation/Add';
import { ViewRegulationPage } from 'pages/Regulation/View';
import { EditRegulationPage } from 'pages/Regulation/Edit';
import { ViewActivityPage } from 'pages/Tracking/Activities/ViewActivityPage';
import { EditActivityPage } from 'pages/Tracking/Activities/EditActivityPage';
import { EditTopicPage } from 'pages/Tracking/Topics/EditTopicPage';
import { ViewTopicPage } from 'pages/Tracking/Topics/ViewTopicPage';
import { ReportPage } from '../pages/Tracking/Report';
import { TrackingListPage } from 'pages/Tracking/List';
import { TrackingViewPage } from 'pages/Tracking/View';
import { EditTrackingPage } from '../pages/Tracking/Edit';
import { Page404 } from 'pages/404';
import { MFACodePage } from '../pages/MFACodePage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';
import { ResetPasswordPage } from '../pages/ResetPasswordPage';
import { ChangePasswordPage } from 'pages/ChangePasswordPage';
import useStore from 'shared/store';
import { Navigate } from 'react-router-dom';
import { ICompanyComponent } from 'entities/role/model/ICompanyComponent';


const MainLayout = (props: { isLoggedIn: boolean }) => {
  const params = new URLSearchParams(window.location.search);
  const redirect = params.get('redirect');

  if(props.isLoggedIn && redirect){
    const companyID = params.get('company_id');
    const redirectURL = new URL(redirect);
    if(companyID) { redirectURL.searchParams.append('company_id', companyID); }

    if (new URL(redirect).host === window.location.host)
      window.location.href = redirectURL.toString();
    else window.open(redirectURL.toString(), '_blank');
  }
  return props.isLoggedIn ? <AddTrackingPage /> : <PublicLayout />;
};


function App() {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const companyComponents = useStore((state) => state.company?.components || []);
  const hasRegulations = companyComponents.includes(ICompanyComponent.Regulations);
  const hasCustomers = companyComponents.includes(ICompanyComponent.Customers);
  const hasVendors = companyComponents.includes(ICompanyComponent.Vendors);


  return (
    <Providers>
      <Layout>
        <ErrorBoundary>
          <Routes>
            <Route
              path="/"
              element={<MainLayout isLoggedIn={isLoggedIn} />}
            >
              <Route
                index
                element={<Navigate to={routeBuilder.signIn.index.getPath()} />}
              />
              <Route
                path={routeBuilder.signIn.index.getPath()}
                element={<LoginPage />}
              />
              <Route
                path={routeBuilder.forgotPassword.index.getPath()}
                element={<ForgotPasswordPage />}
              />
              <Route
                path={routeBuilder.resetPassword.index.getPath()}
                element={<ResetPasswordPage />}
              />
              <Route
                path={routeBuilder.mfaCode.index.getPath(':mfaCodeParam')}
                element={<MFACodePage />}
              />
              <Route
                path={routeBuilder.mfaCode.index.getPath('')}
                element={<MFACodePage />}
              />
            </Route>

            <Route
              path={routeBuilder.dashboard.index.getPath()}
              element={<ProtectedLayout />}
            >
              <Route path={routeBuilder.profile.index.getPath()}>
                <Route
                  index
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.profile.index.getAllowedPrivileges()}
                    >
                      <ProfilePage />
                    </ProtectedRoute>
                  }
                />
              </Route>

              {/* company */}
              <Route path={routeBuilder.company.index.getPath()}>
                <Route
                  index
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.company.index.getAllowedPrivileges()}
                    >
                      <CompanyListPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={routeBuilder.company.add.getPath()}
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.company.add.getAllowedPrivileges()}
                    >
                      <AddCompanyPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={routeBuilder.company.edit.getPath(':companyId')}
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.company.edit.getAllowedPrivileges()}
                    >
                      <EditCompanyPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={routeBuilder.company.view.getPath(':companyId')}
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.company.view.getAllowedPrivileges()}
                    >
                      <ViewCompanyPage />
                    </ProtectedRoute>
                  }
                />
                <Route path={routeBuilder.personnel.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.personnel.index.getAllowedPrivileges()}
                      >
                        <PersonnelList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.personnel.add.getPath()}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.personnel.add.getAllowedPrivileges()}
                      >
                        <AddPersonnelPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.personnel.edit.getPath(':userId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.personnel.edit.getAllowedPrivileges()}
                      >
                        <EditPersonnelPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.personnel.view.getPath(':userId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.personnel.view.getAllowedPrivileges()}
                      >
                        <ViewPersonPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.personnel.changePassword.getPath(
                      ':userId',
                    )}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.personnel.view.getAllowedPrivileges()}
                      >
                        <ChangePasswordPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                <Route path={routeBuilder.roles.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.roles.index.getAllowedPrivileges()}
                      >
                        <RolesList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.roles.add.getPath()}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.roles.add.getAllowedPrivileges()}
                      >
                        <AddRolesPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.roles.edit.getPath(':roleId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.roles.edit.getAllowedPrivileges()}
                      >
                        <EditRolesPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.roles.view.getPath(':roleId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.roles.view.getAllowedPrivileges()}
                      >
                        <ViewRolesPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                <Route path={routeBuilder.locations.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.locations.index.getAllowedPrivileges()}
                      >
                        <LocationsListPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.locations.add.getPath()}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.locations.add.getAllowedPrivileges()}
                      >
                        <AddLocationsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.locations.edit.getPath(':locationId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.locations.edit.getAllowedPrivileges()}
                      >
                        <EditLocationPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.locations.view.getPath(':locationId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.locations.view.getAllowedPrivileges()}
                      >
                        <ViewLocationPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                <Route path={routeBuilder.departments.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.departments.index.getAllowedPrivileges()}
                      >
                        <DepartmentsList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.departments.add.getPath()}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.departments.add.getAllowedPrivileges()}
                      >
                        <AddDepartmentsPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.departments.edit.getPath(
                      ':departmentId',
                    )}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.departments.edit.getAllowedPrivileges()}
                      >
                        <EditDepartmentPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.departments.view.getPath(
                      ':departmentId',
                    )}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.departments.view.getAllowedPrivileges()}
                      >
                        <ViewDepartmentsPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
              </Route>
              {/* company END */}

              <Route path={routeBuilder.tracking.index.getPath()}>
                <Route
                  index
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.tracking.index.getAllowedPrivileges()}
                    >
                      <TrackingListPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={routeBuilder.tracking.add.getPath()}
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.tracking.add.getAllowedPrivileges()}
                    >
                      <AddTrackingPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={routeBuilder.tracking.edit.getPath(':trackingId')}
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.tracking.edit.getAllowedPrivileges()}
                    >
                      <EditTrackingPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={routeBuilder.tracking.view.getPath(':trackingId')}
                  element={
                    <ProtectedRoute
                      allowedPrivileges={routeBuilder.tracking.view.getAllowedPrivileges()}
                    >
                      <TrackingViewPage />
                    </ProtectedRoute>
                  }
                />

                <Route path={routeBuilder.report.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.report.index.getAllowedPrivileges()}
                      >
                        <ReportPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                {/* Tracking END */}

                <Route path={routeBuilder.topics.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.topics.index.getAllowedPrivileges()}
                      >
                        <TopicsList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="add"
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.topics.add.getAllowedPrivileges()}
                      >
                        <AddTopicPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.topics.edit.getPath(':topicId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.topics.edit.getAllowedPrivileges()}
                      >
                        <EditTopicPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.topics.view.getPath(':topicId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.topics.view.getAllowedPrivileges()}
                      >
                        <ViewTopicPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                {/* Topics END */}

                <Route path={routeBuilder.activities.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.activities.index.getAllowedPrivileges()}
                      >
                        <ActivitiesListPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.activities.add.getPath()}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.activities.add.getAllowedPrivileges()}
                      >
                        <AddActivityPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.activities.edit.getPath(':activityId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.activities.edit.getAllowedPrivileges()}
                      >
                        <EditActivityPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.activities.view.getPath(':activityId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.activities.view.getAllowedPrivileges()}
                      >
                        <ViewActivityPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>

                {/* Activities END */}
                <Route path={routeBuilder.actions.index.getPath()}>
                  <Route
                    index
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.actions.index.getAllowedPrivileges()}
                      >
                        <ActionsList />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.actions.add.getPath()}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.actions.add.getAllowedPrivileges()}
                      >
                        <AddActionPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.actions.edit.getPath(':actionId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.actions.edit.getAllowedPrivileges()}
                      >
                        <EditActionPage />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={routeBuilder.actions.view.getPath(':actionId')}
                    element={
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.actions.view.getAllowedPrivileges()}
                      >
                        <ViewActionPage />
                      </ProtectedRoute>
                    }
                  />
                </Route>
              </Route>

              {/*Customer start*/}
              <Route path={routeBuilder.customer.index.getPath()}>
                <Route
                  index
                  element={
                    hasCustomers ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.customer.index.getAllowedPrivileges()}
                      >
                        <CustomersListPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.customer.add.getPath()}
                  element={
                    hasCustomers ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.customer.add.getAllowedPrivileges()}
                      >
                        <CustomerAddPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.customer.view.getPath(':customerId')}
                  element={
                    hasCustomers ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.customer.view.getAllowedPrivileges()}
                      >
                        <ViewCustomerPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.customer.edit.getPath(':customerId')}
                  element={
                    hasCustomers ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.customer.edit.getAllowedPrivileges()}
                      >
                        <CustomerEditPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
              </Route>
              {/*Customer end*/}

              {/*Vendor start*/}
              <Route path={routeBuilder.vendor.index.getPath()}>
                <Route
                  index
                  element={
                    hasVendors ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.vendor.index.getAllowedPrivileges()}
                      >
                        <VendorsListPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.vendor.add.getPath()}
                  element={
                    hasVendors ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.vendor.add.getAllowedPrivileges()}
                      >
                        <VendorAddPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.vendor.view.getPath(':vendorId')}
                  element={
                    hasVendors ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.vendor.view.getAllowedPrivileges()}
                      >
                      <VendorViewPage />
                    </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.vendor.edit.getPath(':vendorId')}
                  element={
                    hasVendors ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.vendor.edit.getAllowedPrivileges()}
                      >
                        <VendorEditPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
              </Route>

              {/*Regulation start*/}
              <Route path={routeBuilder.regulation.index.getPath()}>
                <Route
                  index
                  element={
                    hasRegulations ? (
                      <ProtectedRoute
                      allowedPrivileges={routeBuilder.regulation.index.getAllowedPrivileges()}
                    >
                      <ListRegulationPage />
                    </ProtectedRoute>
                    )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.regulation.add.getPath()}
                  element={
                    hasRegulations ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.regulation.add.getAllowedPrivileges()}
                      >
                        <AddRegulationPage />
                      </ProtectedRoute>
                    )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.regulation.view.getPath(':regulationId')}
                  element={
                    hasRegulations ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.regulation.view.getAllowedPrivileges()}
                      >
                        <ViewRegulationPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />
                <Route
                  path={routeBuilder.regulation.edit.getPath(':regulationId')}
                  element={
                    hasRegulations ? (
                      <ProtectedRoute
                        allowedPrivileges={routeBuilder.regulation.edit.getAllowedPrivileges()}
                      >
                        <EditRegulationPage />
                      </ProtectedRoute>
                      )
                    : <Navigate to={routeBuilder.tracking.add.getPath()} />
                  }
                />

                <Route path={routeBuilder.agency.index.getPath()}>
                  <Route
                    index
                    element={
                      hasRegulations ? (
                        <ProtectedRoute
                          allowedPrivileges={routeBuilder.agency.index.getAllowedPrivileges()}
                        >
                          <ListAgencyPage />
                        </ProtectedRoute>
                        )
                      : <Navigate to={routeBuilder.tracking.add.getPath()} />
                    }
                  />
                  <Route
                    path={routeBuilder.agency.add.getPath()}
                    element={
                      hasRegulations ? (
                        <ProtectedRoute
                          allowedPrivileges={routeBuilder.agency.add.getAllowedPrivileges()}
                        >
                          <AddAgencyPage />
                        </ProtectedRoute>
                        )
                      : <Navigate to={routeBuilder.tracking.add.getPath()} />
                    }
                  />
                  <Route
                    path={routeBuilder.agency.view.getPath(':agencyId')}
                    element={
                      hasRegulations ? (
                        <ProtectedRoute
                          allowedPrivileges={routeBuilder.agency.view.getAllowedPrivileges()}
                        >
                          <ViewAgencyPage />
                        </ProtectedRoute>
                        )
                      : <Navigate to={routeBuilder.tracking.add.getPath()} />
                    }
                  />
                  <Route
                    path={routeBuilder.agency.edit.getPath(':agencyId')}
                    element={
                      hasRegulations ? (
                        <ProtectedRoute
                          allowedPrivileges={routeBuilder.agency.edit.getAllowedPrivileges()}
                        >
                        <EditAgencyPage />
                      </ProtectedRoute>
                        )
                      : <Navigate to={routeBuilder.tracking.add.getPath()} />
                    }
                  />
                </Route>
              </Route>
              {/*Regulation end*/}
            </Route>
            <Route
              path="*"
              element={<Page404 />}
            />
          </Routes>
        </ErrorBoundary>
      </Layout>
    </Providers>
  );
}

export { App };
