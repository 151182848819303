import { useTranslation } from 'react-i18next';

import { Flex, TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const StreetField = () => {
  const { t } = useTranslation('commonFields');

  return (
    <FormField label={`${t('Street')}:`}>
      <Flex
        dir="row"
        gap="10px"
      >
        <TextField
          name="address.streetNumber"
          placeholder={t('Street Number')}
        />
        <TextField
          name="address.street"
          placeholder={t('Street Name')}
        />
      </Flex>
    </FormField>
  );
};

export { StreetField };
