import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { ActivityFinalType } from '../model';

export const useUpdateActivity = (activityId: string, companyId: string) => {
  return useMutation((data: ActivityFinalType) => {
    return updateActivity(data, activityId, companyId);
  });
};

const updateActivity = (
  formData: ActivityFinalType,
  activityId: string,
  companyId: string,
) => {
  return httpClient.patch(
    `activities/${activityId}?companyID=${companyId}`,
    formData,
  );
};
