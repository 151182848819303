import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/components';
import { ViewIcon } from 'shared/icons';
import { routeBuilder } from 'shared/utils';

interface IProps {
  departmentId: string;
}

const DepartmentViewButton = ({ departmentId }: IProps) => {
  const navigation = useNavigate();
  const { t } = useTranslation('common');

  return (
    <Button
      rightIcon={<ViewIcon bgColor="none" />}
      onClick={() =>
        navigation(routeBuilder.departments.view.getPath(departmentId))
      }
    >
      {t('View')}
    </Button>
  );
};

export { DepartmentViewButton };
