import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup, RadioProps, Stack, Text } from 'shared/components';

interface IProps extends RadioProps {
  contactPreferences: { phone: boolean; email: boolean };
}
const NotificationRadioGroup = ({
  contactPreferences = {
    email: true,
    phone: true,
  },
  ...props
}: IProps) => {
  const {
    setValue,
    formState: { isSubmitting },
  } = useFormContext();
  const { t } = useTranslation('commonFields');

  const notificationsValue = ['Email', 'SMS', 'Both'];
  let defaultValue = '';

  if (!contactPreferences?.phone) {
    defaultValue = 'Email';
  }
  if (!contactPreferences?.email) {
    defaultValue = 'SMS';
  }

  const handlePreferencesChange = (preferencesValue: string) => {
    switch (preferencesValue) {
      case 'Email':
        return setValue('contactPreferences', {
          email: true,
          phone: false,
        });
      case 'SMS':
        return setValue('contactPreferences', {
          email: false,
          phone: true,
        });
      case 'Both':
        return setValue('contactPreferences', {
          email: true,
          phone: true,
        });
      default:
        return setValue('contactPreferences', {
          email: true,
          phone: true,
        });
    }
  };

  return (
    <RadioGroup
      name="contactPreferences"
      onChange={(value) => handlePreferencesChange(value)}
      defaultValue={defaultValue || 'Both'}
    >
      <Stack
        direction="row"
        gap={6}
      >
        {notificationsValue.map((value) => (
          <Radio
            isDisabled={isSubmitting}
            key={value}
            value={value}
            sx={{
              w: '20px',
              h: '20px',
              '&[data-checked]': {
                color: 'secondary.500',
                bgColor: 'secondary.500',
              },
            }}
            {...props}
          >
            <Text>{t(value)}</Text>
          </Radio>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export { NotificationRadioGroup };
