import { Text } from 'shared/components';
import { VStack } from '@chakra-ui/react';
import { DateObject, DateObjectType } from '../../utils';
import { Dispatch, SetStateAction } from 'react';
import { range } from 'lodash';

interface IProps {
  time: DateObjectType;
  setTime: Dispatch<SetStateAction<DateObjectType>>;
}

const MinutesBox = ({ time = DateObject(new Date()), setTime }: IProps) => {
  const minutes = range(0, 60).map((minute) => {
    if (minute < 10) {
      return `0${minute}`;
    }
    return minute.toString();
  });

  const currentMinute = time?.format('mm');

  return (
    <VStack
      overflowY="scroll"
      h="100px"
    >
      {minutes.map((minute) => (
        <Text
          cursor="pointer"
          textAlign="center"
          w="30px"
          px="5px"
          key={minute}
          size="xs"
          _hover={{
            backgroundColor: 'gray.200',
          }}
          onClick={() => {
            setTime(time.setMinutes(Number(minute)));
          }}
          backgroundColor={
            currentMinute === minute ? 'gray.400' : 'transparent'
          }
        >
          {minute}
        </Text>
      ))}
    </VStack>
  );
};

export { MinutesBox };
