import { object, ref, string } from 'yup';
import { useTranslation } from 'react-i18next';

import { usePhoneBasicValidation } from 'shared/utils/yup';

export const usePersonnelFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const requiredFieldMessage = t('Required field');
  const matchPassword = t('Your passwords do not match.');
  const errorEmailMessage = t('email must be a valid email');

  return object({
    name: object({
      firstname: string().required(requiredFieldMessage).nullable(),
      lastname: string().required(requiredFieldMessage).nullable(),
    }),
    phoneNumber: usePhoneBasicValidation(),
    email: string()
      .email(errorEmailMessage)
      .when(['mfaEnabled'], {
        is: true,
        then: string().required(requiredFieldMessage),
      })
      .nullable(),
    login: string()
      .nullable()
      .when({
        is: (val?: string) => Boolean(val?.length),
        then: (schema) => schema.min(4, t('login must be longer than 3 characters'))
      }),
    password: string().nullable(),
    passwordVerify: string()
      .oneOf([ref('password'), null], matchPassword)
      .nullable(),
  });
};
