import { useGetAuthorityUsers } from 'entities/user/api/useGetAuthorityUsers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormField, SelectField } from 'shared/components/Form';

type IProps = {
  companyId: string;
  isDisabled?: boolean;
  currentValue?: string;
  omit?: string[];
}

const SupervisorField = ({ companyId, isDisabled, omit, ...props }: IProps) => {
  const { t } = useTranslation('commonFields');
  const [currentValue, setCurrentValue] = useState<string>('');
  const toOmit = omit || [];
  const { isLoading, data } = useGetAuthorityUsers(
    companyId,
    {
      sort: [{ name: 'name.lastname', sortDirection: 'asc' }],
    },
    { useErrorBoundary: false },
  );
  const options = [
    { value: '', label: t('No supervisor') },
    ...(data?.data
      ? data.data.filter(
        (user) => (user.active || user._id === currentValue)
          && !toOmit.includes(user._id)
        ).map((user) => {
          return {
            value: user._id,
            label: `${user.name.firstname} ${user.name.lastname}`,
          };
        })
      : []),
  ];

  useEffect(() => {
    if(props.currentValue && !currentValue) setCurrentValue(props.currentValue);
  }, [props.currentValue || null]);

  return (
    <FormField label={`${t('Supervisor')}:`}>
      <SelectField
        name="supervisorID"
        isLoading={isLoading}
        options={options}
        placeholder={t('Select one')}
        isDisabled={isDisabled}
      />
    </FormField>
  );
};

export { SupervisorField };
