import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { IEmailPayload } from '../model';

export const useSendEmail = () => {
  return useMutation((data: IEmailPayload) => {
    return sendEmail(data);
  });
};

const sendEmail = (formData: IEmailPayload) => {
  return httpClient.post<IEmailPayload>('email', formData);
};
