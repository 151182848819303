import { Icon, IconProps } from 'shared/components';

interface IProps extends IconProps {
  isChecked?: boolean;
  isIndeterminate?: boolean;
}

const CheckIcon = (props: IProps) => {
  const { isIndeterminate, isChecked, ...rest } = props;

  return isChecked ? (
    <Icon
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2827 2.21302C15.5472 2.44445 15.574 2.84648 15.3425 3.11098L6.43345 13.2928C6.31747 13.4253 6.15175 13.5039 5.97572 13.5098C5.79969 13.5156 5.62911 13.4483 5.50457 13.3237L1.68639 9.50554C1.43787 9.25702 1.43787 8.8541 1.68639 8.60559C1.9349 8.35707 2.33782 8.35707 2.58634 8.60559L5.92355 11.9428L14.3847 2.27288C14.6161 2.00839 15.0182 1.98158 15.2827 2.21302Z"
        fill="#72B42D"
        stroke="#72B42D"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </Icon>
  ) : null;
};

export { CheckIcon };
