import { useTranslation } from 'react-i18next';

import { FormControlProps, TextField } from 'shared/components';
import { FormField } from 'shared/components/Form';

const LoginField = (props: FormControlProps) => {
  const { t } = useTranslation('commonFields');
  return (
    <FormField label={`${t('Login')}:`}>
      <TextField
        name="login"
        autoCapitalize='off'
        {...props}
      />
    </FormField>
  );
};

export { LoginField };
