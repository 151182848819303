import { Icon, IconProps } from 'shared/components';

interface IProps extends IconProps {
  orientation?: 'up' | 'down';
}

export const TimepickerChevronIcon = ({
  orientation,
  ...restProps
}: IProps) => (
  <Icon
    width="13px"
    height="7px"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...(orientation === 'down' && { transform: 'rotate(180deg)' })}
    {...restProps}
  >
    <svg>
      <path
        d="M1.5 6.25L6.5 1.25L11.5 6.25"
        stroke="#4B5563"
        strokeLinejoin="round"
      />
    </svg>
  </Icon>
);
