import { ICompany } from 'entities/company/model';
import { TableCellText, TableHeaderText } from 'shared/components/Table';
import { ColumnsTitles } from '../types';
import { useTranslation } from 'react-i18next';
import { Grid } from 'shared/components';

interface IProps {
  company: ICompany;
}
const CompanyMobileDetails = ({ company }: IProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <TableHeaderText color="black">
        {t(ColumnsTitles.loginCode)}
      </TableHeaderText>
      <TableCellText>
        {`${company.login} - ${company.abbreviation}` || '-'}
      </TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.name)}</TableHeaderText>
      <TableCellText>{company.name || '-'}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.naiscCode)}
      </TableHeaderText>
      <TableCellText>{`${company?.code?.NAISC || ''} - ${
        company?.code?.SIC || ''
      }`}</TableCellText>
      <TableHeaderText color="black">
        {t(ColumnsTitles.address)}
      </TableHeaderText>
      <Grid>
        <TableCellText>
          {`${company?.address?.street || ''} ${
            company?.address?.streetNumber || ''
          }` || '-'}
        </TableCellText>
        <TableCellText>
          {`${company?.address?.city || ''} ${company?.address?.zip || ''}`}
        </TableCellText>
      </Grid>

      <TableHeaderText color="black">{t(ColumnsTitles.phone)}</TableHeaderText>
      <TableCellText>
        {`${company.phoneNumber || '-'} 
        ${`${company.fax || ''}`}`}
      </TableCellText>
      <TableHeaderText color="black">{t(ColumnsTitles.counts)}</TableHeaderText>
      <Grid>
        <TableCellText
          display="flex"
          justifyContent="space-between"
        >
          <span>{`${t('Locations')}:`}</span>
          <span>{`${company?.counters?.locations || '0'}`}</span>
        </TableCellText>
        <TableCellText
          display="flex"
          justifyContent="space-between"
        >
          <span>{`${t('Departments')}:`}</span>
          <span>{`${company?.counters?.departments || '0'}`}</span>
        </TableCellText>
        <TableCellText
          display="flex"
          justifyContent="space-between"
        >
          <span>{`${t('Activities')}:`}</span>
          <span>{`${company?.counters?.activities || '0'}`}</span>
        </TableCellText>
        <TableCellText
          display="flex"
          justifyContent="space-between"
        >
          <span>{`${t('Actions')}:`}</span>
          <span>{`${company?.counters?.actions || '0'}`}</span>
        </TableCellText>
        <TableCellText
          display="flex"
          justifyContent="space-between"
        >
          <span>{`${t('Trackings')}:`}</span>
          <span>{`${company?.counters?.trackings || '0'}`}</span>
        </TableCellText>
      </Grid>
      <TableHeaderText color="black">{t(ColumnsTitles.active)}</TableHeaderText>
      <TableCellText>{company.active ? t('Yes') : t('No')}</TableCellText>
    </>
  );
};

export { CompanyMobileDetails };
