import { Td } from '@chakra-ui/react';
import { TableCellProps } from '@chakra-ui/table';

import { getBorder } from 'shared/utils';

const borderRight = getBorder(2, 'solid', 'secondary.500', 'Right');

const TableBodyCell = ({ children, ...restProps }: TableCellProps) => {
  return (
    <Td
      _notLast={borderRight}
      p="12px 8px"
      cursor="pointer"
      {...restProps}
    >
      {children}
    </Td>
  );
};

export { TableBodyCell };
