import { SortDirection } from '@tanstack/react-table';
import { TableSortChevronUp, TableSortChevronDown } from 'shared/icons';
import { Center, Box } from 'shared/components';

type TableSortChevronsProps = {
  sortDirection?: SortDirection | null;
};

export const TableSortChevrons = ({
  sortDirection = null,
}: TableSortChevronsProps) => {
  return (
    <Center flexDirection="column">
      {sortDirection ? (
        sortDirection === 'asc' ? (
          <TableSortChevronUp />
        ) : (
          <TableSortChevronDown />
        )
      ) : (
        <>
          <TableSortChevronUp />
          <Box marginTop="5px">
            <TableSortChevronDown />
          </Box>
        </>
      )}
    </Center>
  );
};
