import { object } from 'yup';
import { useTranslation } from 'react-i18next';
import { usePhoneBasicValidation } from 'shared/utils/yup';

export const useTrackingSMSModalFormValidationSchema = () => {
  const { t } = useTranslation('validationMessages');
  const phoneValidationSchema = usePhoneBasicValidation();
  const requiredFieldMessage = t('Required field');

  return object({
    phoneNumber: phoneValidationSchema.required(requiredFieldMessage),
  });
};
