export const getBorder = (
  width: number,
  style: 'solid',
  // how to get type of possible colors strings from theme?
  color: 'secondary.500',
  direction: 'Top' | 'Right' | 'Bottom' | 'Left' | '' = '',
) => {
  const borderWidthProperty = `border${direction}Width` as const;
  const borderStyleProperty = `border${direction}Style` as const;
  const borderColorProperty = `border${direction}Color` as const;

  const borderProps = {
    [borderWidthProperty]: `${width}px`,
    [borderStyleProperty]: style,
    [borderColorProperty]: color,
  };

  // I've tried to make it understand return type but with no success
  return borderProps;
};
