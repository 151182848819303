import React from 'react';

const AddCircleIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0.53125C6.29245 0.53125 0.835327 6.1248 0.835327 13C0.835327 19.8752 6.29245 25.4688 13 25.4688C19.7075 25.4688 25.1646 19.8752 25.1646 13C25.1646 6.1248 19.7075 0.53125 13 0.53125ZM17.6787 13.9591H13.9357V17.7957C13.9357 18.0501 13.8371 18.294 13.6616 18.4739C13.4861 18.6538 13.2481 18.7548 13 18.7548C12.7518 18.7548 12.5138 18.6538 12.3383 18.4739C12.1628 18.294 12.0642 18.0501 12.0642 17.7957V13.9591H8.32126C8.07308 13.9591 7.83507 13.8581 7.65959 13.6782C7.4841 13.4983 7.38551 13.2544 7.38551 13C7.38551 12.7456 7.4841 12.5017 7.65959 12.3218C7.83507 12.1419 8.07308 12.0409 8.32126 12.0409H12.0642V8.20433C12.0642 7.94995 12.1628 7.70599 12.3383 7.52612C12.5138 7.34625 12.7518 7.24519 13 7.24519C13.2481 7.24519 13.4861 7.34625 13.6616 7.52612C13.8371 7.70599 13.9357 7.94995 13.9357 8.20433V12.0409H17.6787C17.9268 12.0409 18.1649 12.1419 18.3403 12.3218C18.5158 12.5017 18.6144 12.7456 18.6144 13C18.6144 13.2544 18.5158 13.4983 18.3403 13.6782C18.1649 13.8581 17.9268 13.9591 17.6787 13.9591Z"
        fill="#72B42D"
      />
      <path
        d="M17.6787 13.9591H13.9357V17.7957C13.9357 18.0501 13.8371 18.294 13.6616 18.4739C13.4861 18.6538 13.2481 18.7548 13 18.7548C12.7518 18.7548 12.5138 18.6538 12.3383 18.4739C12.1628 18.294 12.0642 18.0501 12.0642 17.7957V13.9591H8.32126C8.07308 13.9591 7.83507 13.8581 7.65959 13.6782C7.4841 13.4983 7.38551 13.2544 7.38551 13C7.38551 12.7456 7.4841 12.5017 7.65959 12.3218C7.83507 12.1419 8.07308 12.0409 8.32126 12.0409H12.0642V8.20433C12.0642 7.94995 12.1628 7.70599 12.3383 7.52612C12.5138 7.34625 12.7518 7.24519 13 7.24519C13.2481 7.24519 13.4861 7.34625 13.6616 7.52612C13.8371 7.70599 13.9357 7.94995 13.9357 8.20433V12.0409H17.6787C17.9268 12.0409 18.1649 12.1419 18.3403 12.3218C18.5158 12.5017 18.6144 12.7456 18.6144 13C18.6144 13.2544 18.5158 13.4983 18.3403 13.6782C18.1649 13.8581 17.9268 13.9591 17.6787 13.9591Z"
        fill="#006FCA"
      />
    </svg>
  );
};

export { AddCircleIcon };
