import { IUser, IUserMe } from 'entities/user/model';

import {
  DepartmentsViewRow,
  FirstNameViewRow,
  LastNameViewRow,
  LocationsViewRow,
  MiddleNameViewRow,
  NicknameViewRow,
  SuffixViewRow,
  EmailViewRow,
  LoginViewRow,
  RolesViewRow,
  ActiveViewRow,
  EmployeesViewRow,
  PhoneViewRow,
  TwoFactorAuthView,
  NotificationViewRow,
} from 'features/ViewCommonFields';
import { SupervisorViewRow } from 'features/ViewCommonFields/SupervisorViewRow';

interface IProps {
  person: IUserMe | IUser;
}

const PersonView = ({ person }: IProps) => {
  const {
    locations,
    name,
    email,
    active,
    login,
    suffix,
    departments,
    roles,
    mfaEnabled,
    contactPreferences,
    supervisor,
    _id,
  } = person;

  return (
    <>
      <LocationsViewRow locations={locations} />

      <DepartmentsViewRow departments={departments} />

      <FirstNameViewRow firstName={name.firstname} />

      <MiddleNameViewRow middleName={name.middlename} />

      <LastNameViewRow lastName={name.lastname} />

      <NicknameViewRow nickname={name.nickname} />

      <PhoneViewRow phone={person.phoneNumber} />

      <SuffixViewRow suffix={suffix} />

      <EmailViewRow email={email} />

      <LoginViewRow login={login} />

      <SupervisorViewRow supervisor={supervisor} />

      <RolesViewRow roles={roles} />

      <TwoFactorAuthView mfaEnabled={mfaEnabled} />

      <ActiveViewRow active={active} />

      <NotificationViewRow contactPreferences={contactPreferences} />

      <EmployeesViewRow personId={_id} />
    </>
  );
};

export { PersonView };
