import useStore from 'shared/store';
import { useNavigate } from 'react-router-dom';

import { useSaveVendor } from 'entities/vendor/api';
import { AddVendor } from 'entities/vendor/model';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { CustomerVendorForm } from 'widgets/CustomerVendorForm/CustomerVendorForm';
import { useTranslation } from 'react-i18next';

const VendorAddPage = () => {
  const companyId = useStore((state) => state.company?._id);
  const setFilesToConnect = useStore((state) => state.setFilesToConnect);
  const filesToConnect = useStore((state) => state.filesToConnect);

  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const formId = `vendor-add-form-${companyId}`;

  if (!companyId) {
    throw new Error('Missing companyID');
  }

  const mutation = useSaveVendor(companyId);
  const navigation = useNavigate();

  const handleSubmit = async (
    data: AddVendor,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutation.mutateAsync({
        ...data,
        attachmentIDs: [
          ...filesToConnect
            .filter((file) => file.parent === formId)
            .map((filesToConnect) => filesToConnect._id),
        ],
      });

      setFilesToConnect(
        filesToConnect.filter((file) => file.parent !== formId),
      );

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.vendor.view.getPath(_id));
      }

      successToast({
        message: t('Successfully added item message', { item: t('vendor') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('vendor') }),
      });
    }
  };

  return (
    <CustomerVendorForm
      handleSubmit={handleSubmit}
      isLoading={mutation.isLoading}
      formId={formId}
      variant="vendor"
    />
  );
};

export { VendorAddPage };
