import { Flex } from 'shared/components';

import {
  AbbreviationViewRow,
  AccountNumberViewRow,
  ActiveViewRow,
  AddressViewRow,
  AttachmentsViewRow,
  CompanyNameViewRow,
  ContactPersonNameViewRow,
  ContactPersonNumberViewRow,
  NearestCrossStreetViewRow,
  PhoneViewRow,
} from 'features/ViewCommonFields';

import { ICustomer } from 'entities/customer/model';

interface IProps {
  customer: ICustomer;
}

const CustomerView = ({ customer }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <CompanyNameViewRow name={customer.name} />
      <AbbreviationViewRow abbreviation={customer.abbreviation} />
      <AccountNumberViewRow accountNumber={customer.accountNumber} />
      <PhoneViewRow phone={customer.phoneNumber} />
      <AddressViewRow address={customer.address} />
      <NearestCrossStreetViewRow
        nearestCrossStreet={customer.address?.nearestCrossStreet}
      />
      <ContactPersonNameViewRow
        contactPersonName={customer.contactPerson?.name}
      />
      <ContactPersonNumberViewRow
        contactPersonNumber={customer.contactPerson?.phoneNumber}
      />
      <ActiveViewRow active={customer.active} />
      <AttachmentsViewRow
        attachments={customer.attachments}
        entityName="customers"
      />
    </Flex>
  );
};

export { CustomerView };
