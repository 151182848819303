import { TrackingAdvancedSearchButton } from './TrackingAdvancedSearchButton';
import { useDisclosure } from '../../../shared/hooks/chakraUi';
import { TrackingAdvancedSearchModal } from './TrackingAdvancedSearchModal';
import useStore from '../../../shared/store';

const TrackingAdvancedSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const companyId = useStore((state) => state.company?._id);

  if (!companyId) {
    return null;
  }

  return (
    <>
      <TrackingAdvancedSearchModal
        companyId={companyId}
        isOpen={isOpen}
        onClose={onClose}
      />
      <TrackingAdvancedSearchButton
        onClick={onOpen}
        whiteSpace="nowrap"
        minW="fit-content"
      />
    </>
  );
};

export { TrackingAdvancedSearch };
