import { Accordion as ChakraAccordion, AccordionProps } from '@chakra-ui/react';

const Accordion = ({ children, ...restProps }: AccordionProps) => {
  return (
    <ChakraAccordion
      allowToggle
      {...restProps}
    >
      {children}
    </ChakraAccordion>
  );
};

export { Accordion };
