import { Navigate, Outlet } from 'react-router-dom';

import useStore from '../../../store';
import { routeBuilder } from '../../../utils';

const ProtectedLayout = () => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);

  if (!isLoggedIn) {
    return (
      <Navigate
        to={routeBuilder.signIn.index.getPath()}
        replace
      />
    );
  }

  return <Outlet />;
};

export { ProtectedLayout };
