import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

import { AddCustomer } from 'entities/customer/model';

export const useSaveCustomer = (companyId: string) => {
  return useMutation((data: AddCustomer) => {
    return saveCustomer(data, companyId);
  });
};

const saveCustomer = (formData: AddCustomer, companyId: string) => {
  return httpClient.post<AddCustomer, AxiosResponse<{ _id: string }>>(
    `customers?companyID=${companyId}`,
    formData,
  );
};
