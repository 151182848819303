import { Icon, IconProps } from 'shared/components';

const XmlIcon = (props: IconProps) => (
  <Icon
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    bgColor="white"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M49.8438 16.3125H38.0625V4.53125C38.0625 4.2909 37.967 4.06039 37.7971 3.89043C37.6271 3.72048 37.3966 3.625 37.1562 3.625H11.7812C11.5409 3.625 11.3104 3.72048 11.1404 3.89043C10.9705 4.06039 10.875 4.2909 10.875 4.53125V29.9062C10.875 30.1466 10.9705 30.3771 11.1404 30.5471C11.3104 30.717 11.5409 30.8125 11.7812 30.8125H36.25V45.3125H11.7812C11.5409 45.3125 11.3104 45.408 11.1404 45.5779C10.9705 45.7479 10.875 45.9784 10.875 46.2188V53.4688C10.875 53.7091 10.9705 53.9396 11.1404 54.1096C11.3104 54.2795 11.5409 54.375 11.7812 54.375H49.8438C50.0841 54.375 50.3146 54.2795 50.4846 54.1096C50.6545 53.9396 50.75 53.7091 50.75 53.4688V17.2188C50.75 16.9784 50.6545 16.7479 50.4846 16.5779C50.3146 16.408 50.0841 16.3125 49.8438 16.3125Z"
      fill="#EEEEEE"
    />
    <path
      d="M37.1562 29H8.15625C7.65574 29 7.25 29.4057 7.25 29.9062V46.2188C7.25 46.7193 7.65574 47.125 8.15625 47.125H37.1562C37.6568 47.125 38.0625 46.7193 38.0625 46.2188V29.9062C38.0625 29.4057 37.6568 29 37.1562 29Z"
      fill="#7F7F7F"
    />
    <path
      d="M50.4872 16.5757L37.7997 3.8882C37.6729 3.76039 37.511 3.67323 37.3345 3.63782C37.158 3.60242 36.975 3.62037 36.8087 3.6894C36.6425 3.75842 36.5006 3.87539 36.4011 4.02539C36.3016 4.1754 36.249 4.35163 36.25 4.53164V17.2191C36.25 17.4595 36.3455 17.69 36.5154 17.86C36.6854 18.0299 36.9159 18.1254 37.1563 18.1254H49.8438C50.0238 18.1264 50.2 18.0739 50.35 17.9743C50.5 17.8748 50.617 17.7329 50.686 17.5667C50.755 17.4004 50.773 17.2174 50.7376 17.0409C50.7022 16.8644 50.615 16.7025 50.4872 16.5757Z"
      fill="#E0E0E0"
    />
    <path
      d="M18.3574 42.1818H16.2086L14.7926 39.83L13.3469 42.1818H11.2971L13.6885 38.4684L11.3912 34.8096H13.535L14.8619 37.0326L16.2235 34.8096H18.2782L15.966 38.3942L18.3574 42.1818Z"
      fill="white"
    />
    <path
      d="M27.1995 42.1818H25.3577V37.295L24.0159 40.4489H22.7534L21.4116 37.295V42.1818H19.6688V34.8096H21.8028L23.4366 38.4486L25.0655 34.8096H27.1995V42.1818Z"
      fill="white"
    />
    <path
      d="M34.2591 42.1818H29.3378V34.8096H31.1895V40.7757H34.2591V42.1818Z"
      fill="white"
    />
  </Icon>
);

export { XmlIcon };
