import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  locationName?: string;
}

const LocationNameViewRow = ({ locationName }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Location Name')}>
      <Text>{locationName || '-'}</Text>
    </ViewRow>
  );
};

export { LocationNameViewRow };
