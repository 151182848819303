import { ChildrenProp } from 'shared/types';
import { Flex, Footer, Header, Container } from 'shared/components';
import { useMyUserData } from 'shared/hooks/auth';

type Props = ChildrenProp;

const Layout = ({ children }: Props) => {
  useMyUserData(); // 1. useMyUserData hook is called here

  return (
    <Flex
      flexDirection="column"
      minHeight="100vh"
      paddingX={{ base: '15px', sm: '20px', md: '25px' }}
      maxWidth="100%"
    >
      <Header />
      <Container
        as="main"
        display="grid"
        gap="20px"
        backgroundColor="white"
        maxW="1600px"
        p={{ base: '20px 0', lg: '30px 0' }}
        h="auto"
      >
        {children}
      </Container>
      <Footer />
    </Flex>
  );
};

export { Layout };
