import { useTranslation } from 'react-i18next';

import { ErrorBoundary, SelectField } from 'shared/components';
import { FormField } from 'shared/components/Form';
import { useBreakpointValue } from 'shared/hooks/chakraUi';

import { useGetEmailAddressList } from 'entities/email/api';
import { IEmailAddress } from 'entities/email/model';

import {
  AutoComplete,
  AutoCompleteGroup,
  AutoCompleteGroupTitle,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';
import { FormLabel } from 'shared/components';
import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

interface IProps {
  companyId: string;
}

const EmailToSelectFieldThrowable = ({ companyId }: IProps) => {
  const { t } = useTranslation('commonFields');
  const { isLoading, data } = useGetEmailAddressList(companyId);
  const height = useBreakpointValue({ base: '38px', md: '46px' });

  const options = data?.data;

  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = 'recipient';
  const value = watch(name);

  return (
    <Flex
      flexDir="column"
      gap="5px"
    >
      <FormLabel
        size="md"
        lineHeight="18px"
        display="flex"
        margin="0"
        alignItems="center"
      >
        {`${t('To')}:`}
      </FormLabel>
      <AutoComplete
        openOnFocus
        defaultValue={''}
        emptyState={''}
        onChange={(val) => {
          setValue(name, val);
        }}
        suggestWhenEmpty={true}
        disableFilter={isSubmitting}
        value={value}
      >
        <AutoCompleteInput
          isDisabled={isLoading}
          onChange={(e) => setValue(name, e.target.value)}
          borderColor="grey.700"
          _hover={{
            borderColor: 'secondary.500',
          }}
          _active={{
            borderColor: 'secondary.500',
          }}
          borderRadius="4px"
          borderWidth="2px"
          height={height}
          fontSize={{ base: '1.6rem', md: '1.8rem' }}
        />
        <AutoCompleteList>
          {options &&
            Object.entries(options).map(
              ([group, emails]: [string, IEmailAddress[]]) => (
                <AutoCompleteGroup
                  key={group}
                  showDivider
                >
                  <AutoCompleteGroupTitle
                    fontSize={{ base: '1.6rem', md: '1.8rem' }}
                  >
                    {group}
                  </AutoCompleteGroupTitle>
                  {emails?.map((email) => (
                    <AutoCompleteItem
                      key={email._id}
                      value={email.email}
                      fontSize={{ base: '1rem', md: '1.2rem' }}
                    >
                      {`${email.email} (${email.name})`}
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteGroup>
              ),
            )}
        </AutoCompleteList>
      </AutoComplete>
    </Flex>
  );
};

const EmailToSelectField = (props: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ErrorBoundary
      fallback={() => (
        <FormField label={`${t('To')}:`}>
          <SelectField
            name="recipient"
            isDisabled
          />
        </FormField>
      )}
    >
      <EmailToSelectFieldThrowable {...props} />
    </ErrorBoundary>
  );
};

export { EmailToSelectField };
