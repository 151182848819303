import { useTranslation } from 'react-i18next';

import { Text, ViewRow } from 'shared/components';
import { useState } from 'react';
import useStore from 'shared/store';
import { AttachmentsTable } from 'features/AttachmentsTable';

interface IProps {
  attachments?: { _id: string; filename: string }[];
  entityName: 'activities' | 'trackings' | 'customers' | 'vendors';
}

const limit = 10;

const AttachmentsViewRow = ({ attachments, entityName }: IProps) => {
  const { t } = useTranslation('commonFields');

  const companyId = useStore((state) => state.company?._id);

  const [offset, setOffset] = useState(0);

  return (
    <ViewRow label={t('Attachments')}>
      {companyId && attachments && attachments.length > 0 ? (
        <AttachmentsTable
          attachmentList={{
            data: attachments.map((attachment) => ({
              _id: attachment._id,
              name: attachment.filename,
              companyId,
            })),
            total: attachments.length,
          }}
          companyId={companyId}
          offset={offset}
          setOffset={setOffset}
          limit={limit}
          isLoading={false}
          entityName={entityName}
          viewMode
        />
      ) : (
        <Text>-</Text>
      )}
    </ViewRow>
  );
};

export { AttachmentsViewRow };
