import { FlexWrapper } from 'shared/components';
import { routeBuilder } from 'shared/utils';

import { AddNewItemButton } from 'features/AddNewItemButton';
import { EditItemButton } from 'features/EditItemButton';
import { DeleteTopic } from './DeleteTopic';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  topicId: string;
  companyId: string;
  active?: boolean;
}

const TopicButtons = ({ topicId, companyId, active }: IProps) => {
  const { createTopicPrivilege, deleteTopicPrivilege, updateTopicPrivilege } =
    LayoutPrivilege();
  return (
    <FlexWrapper mt={{ base: '20px', lg: '50px' }}>
      {createTopicPrivilege && (
        <AddNewItemButton redirectPath={routeBuilder.topics.add.getPath()} />
      )}
      {active && deleteTopicPrivilege && (
        <DeleteTopic
          topicId={topicId}
          companyId={companyId}
        />
      )}
      {updateTopicPrivilege && (
        <EditItemButton
          redirectPath=""
          onClick={() =>
            (window.location.href = routeBuilder.topics.edit.getPath(topicId))
          }
        />
      )}
    </FlexWrapper>
  );
};

export { TopicButtons };
