import { useNavigate } from 'react-router-dom';

import { routeBuilder, useToast } from 'shared/utils';
import { SubmitFormType } from 'shared/types';

import { useSaveAgency } from 'entities/agency/api';
import { IAgencyPayload } from 'entities/agency/model';

import { AgencyForm } from 'features/agency/AgencyForm';
import { useTranslation } from 'react-i18next';

interface IProps {
  companyId: string;
}

const AddAgency = ({ companyId }: IProps) => {
  const navigation = useNavigate();
  const { successToast, errorToast } = useToast();
  const { t } = useTranslation('popupMessage');

  const { mutateAsync, isLoading } = useSaveAgency(companyId);

  const handleSubmit = async (
    data: IAgencyPayload,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const {
        data: { _id },
      } = await mutateAsync(data);

      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(routeBuilder.agency.view.getPath(_id));
      }
      successToast({
        message: t('Successfully added item message', { item: t('agency') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while adding item message', { item: t('agency') }),
      });
    }
  };

  return (
    <AgencyForm
      formId="add-agency-form"
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    />
  );
};

export { AddAgency };
