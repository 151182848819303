import { useTranslation } from 'react-i18next';

import { Flex, Text, ViewRow } from 'shared/components';

import { IAddress } from 'entities/common/model';

interface IProps {
  address?: IAddress;
}

const AddressViewRow = ({ address }: IProps) => {
  const { t } = useTranslation('commonFields');
  return (
    <ViewRow label={t('Address')}>
      <Flex flexDir="column">
        <Text>{`${address?.streetNumber || ''} ${address?.street || ''}`}</Text>
        <Text>
          {[address?.city, address?.state, address?.zip]
            .filter((element) => element)
            .join(', ')}
        </Text>
        <Text>{address?.country}</Text>
      </Flex>
    </ViewRow>
  );
};

export { AddressViewRow };
