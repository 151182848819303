import { Thead, TableHeadProps } from '@chakra-ui/react';

const TableHead = ({ children, ...restProps }: TableHeadProps) => {
  return (
    <Thead
      backgroundColor="primary.500"
      data-testid="thead"
      {...restProps}
    >
      {children}
    </Thead>
  );
};

export { TableHead };
