import { ReactNode, useEffect, useState } from 'react';

import { SaveAndNewButton } from 'features/SaveAndNewButton';
import { SaveButton } from 'features/SaveButton';

import { FlexWrapper, Form } from 'shared/components';
import { SubmitFormType } from 'shared/types';

import { LocationFormType } from 'entities/company/model';

import { defaultLocation, useLocationFormValidationSchema } from './utils';

import {
  ActiveField,
  CityField,
  CountryField,
  LocationNameField,
  NearestCrossStreetField,
  PhoneNumberField,
  StateField,
  StreetField,
  TimeZoneField,
  useContainerProps,
  ZipField,
} from 'features/FormCommons';
import { useForm } from 'shared/components/Form';

interface IProps {
  handleSubmit: (
    data: LocationFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => void;
  defaultValues?: LocationFormType;
  isLoading: boolean;
  formId: string;
  additionalActionButtons?: ReactNode;
}

const LocationForm = ({
  defaultValues = defaultLocation,
  handleSubmit,
  isLoading,
  formId,
  additionalActionButtons,
}: IProps) => {
  const containerStyleProps = useContainerProps();
  const [submitType, setSubmitType] = useState<SubmitFormType>(
    SubmitFormType.Save,
  );
  const validationSchema = useLocationFormValidationSchema();

  const { methods, cleanPersistentStore } = useForm({
    validationSchema,
    defaultValues,
    formId,
  });

  useEffect(() => {
    if (formId.includes('edit-location')) {
      cleanPersistentStore();
    }
  }, []);

  return (
    <Form<LocationFormType>
      onSubmit={(data, { reset }) =>
        handleSubmit(
          data,
          () => {
            cleanPersistentStore();
            reset(defaultValues);
          },
          submitType,
        )
      }
      containerProps={{
        ...containerStyleProps,
      }}
      methods={methods}
    >
      <LocationNameField />

      <PhoneNumberField />

      <StreetField />

      <CityField />

      <StateField name="address.state" />

      <CountryField />

      <ZipField />

      <NearestCrossStreetField />

      <TimeZoneField />

      <ActiveField />

      <FlexWrapper mt={{ base: '30px', lg: '50px' }}>
        <SaveButton
          handleClick={() => setSubmitType(SubmitFormType.Save)}
          isLoading={isLoading}
        />
        <SaveAndNewButton
          handleClick={() => setSubmitType(SubmitFormType.SaveAndNew)}
          isLoading={isLoading}
        />
        {additionalActionButtons}
      </FlexWrapper>
    </Form>
  );
};

export { LocationForm };
