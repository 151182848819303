import { useTranslation } from 'react-i18next';

import {
  InputGroup,
  InputLeftElement,
  Input,
  InputGroupProps,
} from 'shared/components';
import { RolesSearchIcon } from 'shared/icons';

interface IProps extends InputGroupProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
}

const SearchInput = ({ setSearchValue, searchValue, ...props }: IProps) => {
  const { t } = useTranslation('common');
  return (
    <InputGroup
      flexGrow="1"
      display="flex"
      alignItems="center"
      {...props}
    >
      <InputLeftElement
        width="max-content"
        height="max-content"
        pointerEvents="none"
        top="initial"
        marginLeft="11px"
      >
        <RolesSearchIcon />
      </InputLeftElement>
      <Input
        type="search"
        alignItems="center"
        placeholder={t('Search')}
        borderRadius="4px"
        borderColor="greyBorder"
        paddingLeft="37px"
        h="40px"
        fontSize={{ base: '1.6rem', md: '1.8rem' }}
        _placeholder={{
          color: 'greyText',
          fontWeight: 400,
        }}
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />
    </InputGroup>
  );
};

export { SearchInput };
