import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  nickname?: string;
}

const NicknameViewRow = ({ nickname }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Nickname')}>
      <Text>{nickname || '-'}</Text>
    </ViewRow>
  );
};

export { NicknameViewRow };
