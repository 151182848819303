import { useTranslation } from 'react-i18next';

import { ViewRow, Text } from 'shared/components';

interface IProps {
  actionDetail?: string;
}

const ViolationActionDetailsViewRow = ({ actionDetail }: IProps) => {
  const { t } = useTranslation('commonFields');

  return (
    <ViewRow label={t('Action Details')}>
      <Text>{actionDetail || '-'}</Text>
    </ViewRow>
  );
};

export { ViolationActionDetailsViewRow };
