import { ICompany } from 'entities/company/model';

import {
  TableBodyCell,
  TableBodyInfoCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { useNavigate } from 'react-router-dom';
import { routeBuilder } from 'shared/utils';
import { useTranslation } from 'react-i18next';

interface IProps {
  company: ICompany;
  isMobile?: boolean;
}
const CompaniesTableRow = ({ company, isMobile }: IProps) => {
  const navigation = useNavigate();
  const { t } = useTranslation(['companiesList', 'common']);

  const handleRedirect = () => {
    navigation(routeBuilder.company.view.getPath(company._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      {isMobile ? (
        <>
          <TableBodyCell>
            <TableCellText>
              {`${company.login} - ${company.abbreviation}`}
            </TableCellText>
          </TableBodyCell>

          <TableBodyCell>
            <TableCellText>{company.name || '-'}</TableCellText>
          </TableBodyCell>
          <TableBodyInfoCell company={company} />
        </>
      ) : (
        <>
          <TableBodyCell>
            <TableCellText>
              {`${company.login} - ${company.abbreviation}`}
            </TableCellText>
          </TableBodyCell>

          <TableBodyCell>
            <TableCellText>{company.name || '-'}</TableCellText>
          </TableBodyCell>

          <TableBodyCell>
            <TableCellText>{`${company?.code?.NAISC || ''} - ${
              company?.code?.SIC || ''
            }`}</TableCellText>
          </TableBodyCell>

          <TableBodyCell maxW="250px">
            <TableCellText>
              {`${company?.address?.streetNumber || ''} ${
                company?.address?.street || ''
              }`}
            </TableCellText>
            <TableCellText>{company?.address?.state || ''}</TableCellText>
            <TableCellText>
              {`${company?.address?.city || ''} ${company?.address?.zip || ''}`}
            </TableCellText>
          </TableBodyCell>

          <TableBodyCell>
            <TableCellText>{`${company.phoneNumber || '-'}`}</TableCellText>
            <TableCellText>{`${company.fax || ''}`}</TableCellText>
          </TableBodyCell>

          <TableBodyCell maxW="250px">
            <TableCellText
              display="flex"
              justifyContent="space-between"
            >
              <span>{`${t('Locations')}:`}</span>
              <span>{`${company?.counters?.locations || '0'}`}</span>
            </TableCellText>
            <TableCellText
              display="flex"
              justifyContent="space-between"
            >
              <span>{`${t('Departments')}:`}</span>
              <span>{`${company?.counters?.departments || '0'}`}</span>
            </TableCellText>
            <TableCellText
              display="flex"
              justifyContent="space-between"
            >
              <span>{`${t('Activities')}:`}</span>
              <span>{`${company?.counters?.activities || '0'}`}</span>
            </TableCellText>
            <TableCellText
              display="flex"
              justifyContent="space-between"
            >
              <span>{`${t('Actions')}:`}</span>
              <span>{`${company?.counters?.actions || '0'}`}</span>
            </TableCellText>
            <TableCellText
              display="flex"
              justifyContent="space-between"
            >
              <span>{`${t('Trackings')}:`}</span>
              <span>{`${company?.counters?.trackings || '0'}`}</span>
            </TableCellText>
          </TableBodyCell>

          <TableBodyCell>
            <TableCellText>
              {company.active
                ? t('Yes', { ns: 'common' })
                : t('No', { ns: 'common' })}
            </TableCellText>
          </TableBodyCell>
        </>
      )}
    </TableRow>
  );
};

export { CompaniesTableRow };
