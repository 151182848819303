import { Flex } from 'shared/components';

import { AbbreviationViewRow, NameViewRow } from 'features/ViewCommonFields';

import { IAgency } from 'entities/agency/model';

interface IProps {
  agency: IAgency;
}
const AgencyView = ({ agency }: IProps) => {
  return (
    <Flex
      gap={{ base: '10px', lg: '15px' }}
      flexDir="column"
      justifyContent="center"
      w="100%"
    >
      <NameViewRow name={agency.name} />
      <AbbreviationViewRow abbreviation={agency.abbreviation} />
    </Flex>
  );
};

export { AgencyView };
