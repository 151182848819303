import { useNavigate } from 'react-router-dom';

import { SubmitFormType } from 'shared/types';
import { routeBuilder, useToast } from 'shared/utils';

import { useGetUsers, useUpdateUser } from 'entities/user/api';
import { IUser, PersonnelFormType } from 'entities/user/model';

import { PersonnelForm } from 'features/person/PersonnelForm';
import { ViewButton } from 'features/ViewButton';
import { DeletePerson } from 'features/person/PersonActionButtons/DeletePerson/DeletePerson';
import { useTranslation } from 'react-i18next';
import { convertEditFormData } from './convertEditFormData';
import { useGetRoles } from 'entities/role/api';
import { LayoutPrivilege } from 'shared/components/layout/LayoutPrivilege';

interface IProps {
  person: IUser;
  companyId: string;
}

const EditPersonnel = ({ person, companyId }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, credits, companyID, ...defaultData } = person;
  const navigation = useNavigate();
  const { isLoading, mutateAsync } = useUpdateUser(_id, companyId);
  const { data: usersData } = useGetUsers(companyId, undefined, {
    useErrorBoundary: false,
  });

  const { data: roleData } = useGetRoles(
    companyId,
    {
      fields: ['privileges'],
      filters: 'supervisor',
    },
    { useErrorBoundary: false },
  );
  const supervisorRoles = roleData?.data.data.map((role) => role?._id) || [];

  const { t } = useTranslation('popupMessage');
  const { successToast, errorToast } = useToast();

  const {
    deletePersonnelPrivilege,
    updatePersonnelPrivilege,
    assignRolePrivilege,
  } = LayoutPrivilege();

  const viewPath = routeBuilder.personnel.view.getPath(_id);

  const handleSubmit = async (
    data: PersonnelFormType,
    reset: () => void,
    submitType: SubmitFormType,
  ) => {
    try {
      const dataForBEdependingOnPrivilege = convertEditFormData(
        data,
        supervisorRoles,
      );
      deletePersonnelPrivilege
        ? dataForBEdependingOnPrivilege.active
        : delete dataForBEdependingOnPrivilege.active;

      assignRolePrivilege
        ? dataForBEdependingOnPrivilege.roleIDs
        : delete dataForBEdependingOnPrivilege.roleIDs;

      if (!updatePersonnelPrivilege) {
        /*eslint-disable no-unused-vars*/
        const {
          active,
          departmentIDs,
          locationIDs,
          mfaEnabled,
          supervisesIDs,
          login,
          ...dataWithoutUpdatePrivilege
        } = convertEditFormData(data, supervisorRoles);
        assignRolePrivilege
          ? dataWithoutUpdatePrivilege.roleIDs
          : delete dataWithoutUpdatePrivilege.roleIDs;
        await mutateAsync(dataWithoutUpdatePrivilege);
      }

      await mutateAsync(dataForBEdependingOnPrivilege);
      reset();
      if (submitType === SubmitFormType.Save) {
        navigation(viewPath);
      }
      if (submitType === SubmitFormType.SaveAndNew) {
        navigation(routeBuilder.personnel.add.getPath());
      }
      successToast({
        message: t('Successfully edited item message', { item: t('person') }),
      });
    } catch (e) {
      errorToast({
        message: t('Error while editing item message', { item: t('person') }),
      });
    }
  };

  return (
    <PersonnelForm
      companyId={companyId}
      personId={_id}
      defaultValues={{
        ...defaultData,
        supervisorID: person?.supervisor?._id || undefined,
        roles: person?.roleIDs?.reduce((acc, roleId) => {
          return { ...acc, [roleId]: { value: true, _id: roleId } };
        }, {}),
        locationIDs: person.locations?.map((location) => location?._id).join(),
        departmentIDs: person.departments
          ?.map((department) => department?._id)
          .join(),
        supervises: usersData?.data.data
          .filter((user) => user.supervisor?._id === _id)
          .reduce((acc, supervisor) => {
            return {
              ...acc,
              [supervisor._id]: { value: true, _id: supervisor._id },
            };
          }, {}),
        phoneNumber: defaultData.phoneNumber || '',
      }}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formId={`edit-personnnel-form-${_id}-${companyId}`}
      additionalActionButtons={
        <>
          <ViewButton onClick={() => navigation(viewPath)} />
          {person.active && deletePersonnelPrivilege && (
            <DeletePerson
              userId={_id}
              companyId={companyId}
            />
          )}
        </>
      }
    />
  );
};

export { EditPersonnel };
