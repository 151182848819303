import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient, AxiosResponse } from 'shared/utils/httpClient';

import { IRegulationPayload } from 'entities/regulation/model';

export const useSaveRegulation = (companyId: string) => {
  return useMutation((data: IRegulationPayload) => {
    return saveRegulation(data, companyId);
  });
};

const saveRegulation = (formData: IRegulationPayload, companyId: string) => {
  return httpClient.post<IRegulationPayload, AxiosResponse<{ _id: string }>>(
    `regulations?companyID=${companyId}`,
    formData,
  );
};
