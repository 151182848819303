import { Tr } from '@chakra-ui/react';
import { TableRowProps } from '@chakra-ui/table';

import { getBorder } from 'shared/utils';

const borderBottom = getBorder(2, 'solid', 'secondary.500', 'Bottom');

const TableRow = ({ children, ...restProps }: TableRowProps) => {
  return (
    <Tr
      _notLast={borderBottom}
      {...restProps}
    >
      {children}
    </Tr>
  );
};

export { TableRow };
