import { TextProps } from 'shared/components';

import { TableText } from './TableText';

export const TableCellText = (textProps: TextProps) => {
  return (
    <TableText
      fontWeight="400"
      {...textProps}
    />
  );
};
