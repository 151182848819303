import { useReactQuery } from 'shared/hooks/reactQuery';
import { AxiosError, AxiosResponse, httpClient } from 'shared/utils/httpClient';
import { ITopic } from '../model';
import { UseQueryOptions } from '@tanstack/react-query';
import { useGetLocale } from 'shared/utils';

export const useGetTopic = (
  companyId: string,
  topicId: string,
  options?: UseQueryOptions<AxiosResponse<ITopic>, AxiosError>,
) => {
  const { code: lang } = useGetLocale();
  const defaultLang = (lang || '').slice(0, 2) || 'en';
  return useReactQuery<ITopic>(
    getTopicKey(companyId, topicId, defaultLang),
    () => getTopic(companyId, topicId, defaultLang),
    options,
  );
};

const getTopic = (companyId: string, topicId: string, lang = 'en') => {
  return httpClient.get<ITopic>(`topics/${topicId}?languageCode=${lang}&companyID=${companyId}`);
};

const getTopicKey = (companyId: string, topicId: string, lang = 'en') => [
  'topic',
  topicId,
  companyId,
  lang
];
