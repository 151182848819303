import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { IAgencyPayload } from 'entities/agency/model';

export const useUpdateAgency = (companyId: string, agencyId: string) => {
  return useMutation((data: IAgencyPayload) => {
    return updateAgency(data, companyId, agencyId);
  });
};

const updateAgency = (
  formData: IAgencyPayload,
  companyId: string,
  agencyId: string,
) => {
  return httpClient.patch(
    `agencies/${agencyId}?companyID=${companyId}`,
    formData,
  );
};
