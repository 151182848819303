import ReactSelect, { GroupBase } from 'react-select';
import { Props as ReactSelectProps } from 'react-select';

import { useCustomSelectStyle } from './customStyle';
import React, { forwardRef } from 'react';
import SelectProps from 'react-select/base';

type Props = ReactSelectProps;

const Select = forwardRef<
  SelectProps<unknown, boolean, GroupBase<unknown>>,
  Props
>((props: Props, ref) => {
  const customStyle = useCustomSelectStyle();
  return (
    <ReactSelect
      ref={ref}
      styles={customStyle}
      placeholder=""
      isSearchable={false}
      {...props}
    />
  );
});

Select.displayName = 'Select';

export { Select };
