import { useNavigate } from 'react-router-dom';
import { IDepartment } from 'entities/company/model';
import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { useTranslation } from 'react-i18next';
import { routeBuilder } from 'shared/utils';

interface IProps {
  department: IDepartment;
}
const DepartmentTableRow = ({ department }: IProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(routeBuilder.departments.view.getPath(department._id));
  };

  return (
    <TableRow
      onClick={handleRedirect}
      cursor="pointer"
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell>
        <TableCellText>{department.name}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{department.active ? t('Yes') : t('No')}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { DepartmentTableRow };
