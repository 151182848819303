import { useFormContext } from 'react-hook-form';

import {
  TableBodyCell,
  TableCellText,
  TableRow,
} from 'shared/components/Table';
import { Checkbox } from 'shared/components';

import { IVendor } from 'entities/vendor/model';

interface IProps {
  vendor: IVendor;
}

const VendorTrackingTableRow = ({ vendor }: IProps) => {
  const {
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const name = `vendors.${vendor._id}`;
  const value = watch(name);

  return (
    <TableRow
      key={vendor._id}
      _hover={{
        backgroundColor: 'grey.500',
      }}
    >
      <TableBodyCell textAlign="center">
        <Checkbox
          isDisabled={isSubmitting}
          size="lg"
          isChecked={value?.value}
          onChange={(event) => {
            setValue(name, {
              _id: vendor._id,
              value: event.target.checked,
            });
          }}
        />
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{`${vendor.name}`}</TableCellText>
      </TableBodyCell>
      <TableBodyCell>
        <TableCellText>{vendor.abbreviation || '-'}</TableCellText>
      </TableBodyCell>
    </TableRow>
  );
};

export { VendorTrackingTableRow };
