import { useMutation } from 'shared/hooks/reactQuery';
import { httpClient } from 'shared/utils/httpClient';

import { AddCustomer } from 'entities/customer/model';

export const useUpdateCustomer = (customerId: string, companyId: string) => {
  return useMutation((data: AddCustomer) => {
    return updateCustomer(data, customerId, companyId);
  });
};

const updateCustomer = (
  formData: AddCustomer,
  customerId: string,
  companyId: string,
) => {
  return httpClient.patch(
    `customers/${customerId}?companyID=${companyId}`,
    formData,
  );
};
