export const buttonConfiguration = {
  variants: {
    primary: {
      p: {
        base: '10px 20px',
        '2xl': '12px 25px',
      },
      fontSize: {
        base: '1.4rem',
        sm: '1.6rem',
        '2xl': '1.8rem',
      },
      minH: {
        base: '50px',
        '2xl': '54px',
      },
      bgColor: 'primary.500',
      color: 'white',
      borderRadius: '100px',
      _hover: {
        bgColor: 'primary.600',
        boxShadow: 'btnPrimary',
      },
      _active: {
        bgColor: 'primary.700',
        boxShadow: 'btnPrimary',
      },
    },
    secondary: {
      p: {
        base: '12px',
        md: '15px',
        xl: '17px',
      },
      fontSize: {
        base: '1.8rem',
        md: '2.0rem',
        xl: '2.2rem',
      },
      borderWidth: '2px',
      borderColor: 'transparent',
      bgColor: 'secondary.500',
      color: 'white',
      _hover: {
        boxShadow: 'btn',
        _disabled: {
          bgColor: 'secondary.300',
        },
      },
      _active: {
        borderColor: 'primary.700',
        _disabled: {
          border: 'none',
        },
      },
      _disabled: {
        opacity: 1,
        bgColor: 'secondary.300',
      },
    },
    outline: {
      p: {
        base: '10px',
        md: '12px',
        xl: '12px',
      },
      fontSize: {
        base: '1.4rem',
        md: '1.6rem',
        xl: '1.8rem',
      },
      bgColor: 'white',
      color: 'black.400',
      borderColor: 'black.400',
      w: '100%',
      h: '52px',
      _hover: {
        bgColor: 'white',
        boxShadow: 'btn',
      },
      _active: {
        bgColor: 'white',
        borderColor: 'secondary.500',
      },
      _disabled: {
        color: 'grey.400',
      },
    },
    link: {
      color: 'black',
      fontWeight: '400',
      whiteSpace: 'nowrap',
      fontSize: {
        base: '1.4rem',
        sm: '1.6rem',
        '2xl': '1.8rem',
      },
      _hover: {
        textDecoration: 'none',
        color: 'primary.500',
      },
      _active: {
        color: 'primary.700',
      },
      _disabled: {
        opacity: 1,
        color: 'greyText',
        cursor: 'default',
        _active: {
          color: 'greyText',
        },
        _hover: {
          color: 'greyText',
        },
      },
    },
  },
};
