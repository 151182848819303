import { useTranslation } from 'react-i18next';
import { FormField, SelectField } from 'shared/components/Form';

interface IProps {
  isDisabled?: boolean;
}

const TwoFactorAuthField = ({ isDisabled }: IProps) => {
  const { t } = useTranslation('commonFields');
  const options = [
    { value: false, label: t('No') },
    { value: true, label: t('Yes') },
  ];
  return (
    <FormField label={`${t('Two-Factor Authentication')}:`}>
      <SelectField
        name="mfaEnabled"
        options={options}
        defaultValue={options[0]}
        isDisabled={isDisabled}
      />
    </FormField>
  );
};

export { TwoFactorAuthField };
