import useStore from 'shared/store';
import {
  getParsedDescription,
  getParsedTrackedAt,
  useGetParsedCustomers,
  useGetParsedVendors,
  useGetParsedViolation,
} from './emailHelpers';
import { ITracking } from 'entities/tracking/model';
import { routeBuilder } from 'shared/utils';
import { apiUrl } from 'shared/utils/httpClient/apiUrl';

export const useTrackingEmailMessageParser = (
  tracking: ITracking,
  companyId: string,
) => {
  const company = useStore((state) => state.company);
  const parsedEmployees = tracking.attendees
    ? `Employees: ${tracking.attendees.reduce((acc, employee) => {
        return `${acc} ${employee.name.firstname} ${employee.name.lastname}, `;
      }, '')}`
    : '';

  const parsedCustomers = useGetParsedCustomers(
    companyId,
    tracking?.customerIDs,
  );

  const parsedActivity = tracking.activity
    ? `Activity: ${tracking.activity.name}`
    : '';

  const parsedDepartments = tracking.department
    ? `Department: ${tracking.department.name}`
    : '';

  const parsedTrackedAt = getParsedTrackedAt(new Date(tracking?.trackedAt.utc));

  const parsedDescription = getParsedDescription(tracking?.description);

  const parsedLocations = tracking.location
    ? `Site: ${tracking.location.name}`
    : '';

  const parsedVendors = useGetParsedVendors(companyId, tracking.vendorIDs);

  const parsedTopics = tracking.topics
    ? `Topics: ${tracking.topics.reduce((acc, topic) => {
        return `${acc} ${topic.name}, `;
      }, '')}`
    : '';

  const parsedDocumentation = tracking.attachments
    ? `Documentation: ${tracking.attachments.reduce((acc, attachment) => {
        // eslint-disable-next-line max-len
        // eslint-disable-next-line max-len
        return `${acc}\n-${attachment.filename} (${process.env.REACT_APP_API_URL}/trackings/attachments/${attachment._id}?companyID=${company?._id}), `;
      }, '')}`
    : '';

  const parsedViolation = useGetParsedViolation(companyId, tracking?.violation);

  const parsedTrackedBy = tracking.trackedBy
    ? `Tracked By: ${tracking.trackedBy.name.firstname} ${tracking.trackedBy.name.lastname}`
    : '';

  const parsedIncidentLocation = tracking.incidentLocation
    ? `Incident location: ${tracking.incidentLocation}`
    : '';

  const targetURL = new URL(`${apiUrl}/redirect`);
  targetURL.searchParams.append('url',
    company?._id
    ? `${window.location.pathname}?company_id=${company?._id}`
    : window.location.pathname
  );
  const trackingOrigin = `\nFull tracking can be seen at ${targetURL.toString()}`;

  return [
    company?.name,
    parsedTrackedAt,
    parsedLocations,
    parsedDepartments,
    parsedActivity,
    parsedTopics,
    parsedTrackedBy,
    parsedEmployees,
    parsedCustomers,
    parsedIncidentLocation,
    parsedDescription,
    parsedVendors,
    parsedViolation,
    parsedDocumentation,
    trackingOrigin
  ]
    .filter((value) => value !== '')
    .join('\n');
};
