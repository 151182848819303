import { IUserMe } from 'entities/user/model';

import { useReactQuery } from 'shared/hooks/reactQuery';
import { useGetLocale } from 'shared/utils';
import { httpClient } from 'shared/utils/httpClient';

export const useGetMyUser = (enabled: boolean) => {
  const { code: _lang } = useGetLocale();
  const lang = (_lang || '').slice(0, 2) || 'en';
  return useReactQuery<IUserMe>(getMyUserKey(lang), () => getMyUser(lang), {
    useErrorBoundary: () => {
      return false;
    },
    retry: false,
    enabled,
  });
};

const getMyUser = (lang = 'en') => {
  return httpClient.get<IUserMe>(`users/me?languageCode=${lang}`).then((response) => response);
};

export const getMyUserKey = (lang = 'en') => ['user', 'me', lang];
