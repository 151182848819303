import { Flex } from 'shared/components';

import { SearchInput } from './SearchInput';
import { CheckboxListFilterContainer } from './CheckboxListFilterContainer';
import { useCheckboxFilter } from './utils';
import { SelectAllButton } from './SelectAllButton';
import { ResetButton } from './ResetButton';

type CheckboxListFilterProps<T> = {
  itemsFormName: string;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  filteredResults: T[];
  resetAdvancedFilters?: () => void;
  hiddenSearchInput?: boolean;
  isDisabled?: boolean;
};

export const CheckboxListFilter = <T extends { _id: string }>({
  searchValue,
  isDisabled,
  setSearchValue,
  itemsFormName,
  filteredResults,
  hiddenSearchInput,
  resetAdvancedFilters,
}: CheckboxListFilterProps<T>) => {
  const { isAllSelected, switchAllCheckboxes, resetAllCheckboxes } =
    useCheckboxFilter(filteredResults, itemsFormName);
  return (
    <CheckboxListFilterContainer>
      {!hiddenSearchInput && (
        <SearchInput
          searchValue={searchValue}
          setSearchValue={(value) => setSearchValue(value)}
        />
      )}
      <Flex
        alignItems="center"
        gap="20px"
      >
        <SelectAllButton
          onClick={() => switchAllCheckboxes(true)}
          disabled={isAllSelected || isDisabled}
        />
        <ResetButton
          onClick={() => {
            resetAllCheckboxes();
            setSearchValue('');
            resetAdvancedFilters && resetAdvancedFilters();
          }}
        />
      </Flex>
    </CheckboxListFilterContainer>
  );
};
