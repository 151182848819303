import { PersonnelFormType } from 'entities/user/model';

export const mapPersonnelFormData = (data: PersonnelFormType) => {
  const supervises = data.supervises
    ? Object.keys(data.supervises)
        ?.filter((key) => data.supervises && data.supervises[key].value)
        .map((key) => key)
    : undefined;

  const roles =
    data.roles &&
    Object.keys(data.roles)
      ?.filter((key) => data.roles && data.roles[key].value)
      .reduce(
        (result: string[], key) =>
          data.roles ? [...result, data.roles[key]._id] : [...result],
        [],
      );

  const location: string[] | undefined = data.locationIDs
    ? [data.locationIDs]
    : [];
  const department: string[] | undefined = data.departmentIDs
    ? [data.departmentIDs]
    : [];

  return {
    locationIDs: location || [],
    departmentIDs: department || [],
    name: {
      firstname: data.name.firstname,
      middlename: data.name.middlename,
      lastname: data.name.lastname,
      nickname: data.name.nickname,
    },
    suffix: data.suffix,
    email: data.email,
    login: data.login || undefined,
    password: data.password || undefined,
    phoneNumber: data.phoneNumber || undefined,
    active: data.active,
    roleIDs: roles,
    supervisesIDs: supervises,
    supervisorID: data.supervisorID || undefined,
    contactPreferences: data.contactPreferences || undefined,
    mfaEnabled: data.mfaEnabled,
  };
};
